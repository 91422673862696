
<label for="files" class="btn">NACHWEISDOKUMENT HOCHLADEN</label>
<input id="files" type="file" class="file-input"
       accept="application/pdf"
       data-max-size="10240"
       multiple
       (click)="changeFileVals()"
       (change)="onChange($event)" style="visibility:hidden;"/>

<label *ngIf="files?.length < 1" style="color:orange"> <br/> Für Ihre Namensänderung ist ein Nachweisdokument erforderlich.</label>

<div *ngIf="files.length" class="file-section">
  Übersicht der hochgeladenen Dokumente:
  <section *ngFor="let file of files; let i = index" [attr.data-index]="i">

      {{i+1}}. Dokumentname: {{file.name}} [{{file.size/1000}} Kb]
        <button mat-icon-button (click)="removeSelectedFile(i)">
          <mat-icon>delete</mat-icon>
        </button>


  </section>
</div>
