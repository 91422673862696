
<div class="info">
  <div class="info-child">
    <p>Derzeit sind keine Änderungen in Ihren Antragsdaten möglich.</p>

    <p>Bitte teilen Sie uns Ihren Änderungswunsch formlos und vom Antragsteller oder Bevollmächtigten unterschrieben über die Upload-Funktion (<mat-icon>publish</mat-icon>-Symbol) mit.</p>

    <p>Soweit vorhanden, laden Sie bitte gleich geeignete Nachweise mit hoch. Ihre Änderungsanfrage wird schnellstmöglich bearbeitet. Sie erhalten von uns eine schriftliche Bestätigung nach Prüfung.</p>

<p>Ihr Bundesamt für Wirtschaft und Ausfuhrkontrolle</p>

</div>
 <button mat-raised-button (click)="close()" color="primary" class="pos">Schließen</button>
</div>
