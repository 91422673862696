<div role="main" fxFlexFill fxLayout="row" fxLayoutAlign="center">
  <div
    fxFlex="100%"
    fxFlex.md="100%"
    fxFlex.sm="100%"
    fxLayout="column"
    fxLayoutAlign="center"
  >
    <div
      class="content md-padding"
      role="main"
      fxFlexOffset="50px"
      fxLayout
      fxLayoutAlign="center"
      fxLayout.xs="column"
      fxLayoutAlign.xs="start"
    >
      <form [formGroup]="form" (ngSubmit)="onSubmit()" action="">
        <mat-card
          class="mat-mdc-card-none-shadow"
          ngClass.gt-xs="overview-card"
          fxLayoutAlign="space-arround center"
          fxLayout.xs="column"
          fxFlex="90%"
          fxFlex.md="85%"
          fxFlex.lt-md="85%"
        >
          <div
            fxLayout="column"
            fxFlex="90%"
            fxLayout.xs="column"
            fxLayoutAlign.xs="center"
          >
            <div fxFlexAlign="center" fxFlexOffset="30px">
              <mat-card-title class="mat-headline-2">
                Willkommen auf dem Antragsportal des BAFA
              </mat-card-title>

              <mat-card-subtitle class="mat-body-1">
                <div>
                  Dieses Portal bietet Ihnen eine Oberfläche, mit der Sie
                  Anträge stellen und Verwendungsnachweise einreichen können.
                </div>
                <div>
                  Nach der Anmeldung können Sie einen neuen Antrag stellen, den
                  Status vorhandener Anträge überprüfen sowie Dokumente
                  hochladen.
                </div>
                <div>
                  Weiterhin bietet Ihnen das Portal die Möglichkeit, einen
                  gestellten Antrag zu stornieren bzw. Ihre hinterlegten
                  persönlichen Daten zu überarbeiten.
                </div>
              </mat-card-subtitle>
            </div>
          </div>
        </mat-card>

        <mat-card
          class="mat-mdc-card-none-shadow"
          ngClass.gt-xs="overview-card"
          fxFlex="80%"
          fxFlex.md="100%"
          fxFlex.lt-md="100%"
        >
          <div role="main" fxFlex="100%">
            <mat-card class="dialog-box" fxFlex="90%">
              <mat-card-title fxLayoutAlign="center"> Anmelden </mat-card-title>

              <mat-card-subtitle fxFlexAlign="center">
                Verwenden Sie Ihr BAFA Benutzerkonto
              </mat-card-subtitle>

              <mat-card-content fxLayout="column">
                <mat-form-field appearance="fill" fxFlexOffset="10px">
                  <mat-label>E-Mail-Adresse</mat-label>
                  <label>
                    <input
                      matInput
                      required
                      formControlName="email"
                      type="email"
                      placeholder="email@example.com"
                      [errorStateMatcher]="matcher"
                    />
                  </label>
                </mat-form-field>

                <!-- password -->
                <mat-form-field fxFlexOffset="10px" appearance="fill">
                  <mat-label>Passwort</mat-label>
                  <label>
                    <input
                      matInput
                      required
                      formControlName="password"
                      [type]="hide ? 'password' : 'text'"
                      [errorStateMatcher]="matcher"
                    />
                  </label>
                  <mat-error *ngIf="!this.success">
                    <p>
                      → Prüfen Sie die E-Mail-Adresse.<br />
                      → Prüfen Sie das Passwort.<br />
                    </p>
                    <p>
                      <br />
                      Hinweis: Nach drei fehlgeschlagenen Anmelderversuchen
                      sperren wir das Benutzerkonto temporär. Versuchen Sie es
                      nach 5 Minuten erneut.<br />
                    </p>
                  </mat-error>
                  <button
                    mat-icon-button
                    matSuffix
                    type="button"
                    (click)="hide = !hide"
                    [attr.aria-pressed]="hide"
                    matTooltip="Passwort im Klartext anzeigen"
                  >
                    <mat-icon>{{
                      hide ? 'visibility_off' : 'visibility'
                    }}</mat-icon>
                  </button>
                </mat-form-field>
              </mat-card-content>

              <!-- buttons -->
              <mat-card-actions
                class="card-actions"
                fxFlexOffset="{{ input.error ? '130px' : '0px' }}"
              >
                <button
                  mat-button
                  class="button"
                  type="button"
                  color="primary"
                  [routerLink]="['/request-reset']"
                >
                  Probleme mit der Anmeldung?
                </button>
                <button
                  mat-flat-button
                  class="button"
                  type="submit"
                  color="primary"
                >
                  Weiter
                </button>
              </mat-card-actions>

              <mat-card-subtitle
                fxFlexAlign="center"
                class="mat-caption"
                *ngIf="enableRegistration()"
              >
                <p>
                  Sie haben noch kein Konto?<br />
                  Registrieren Sie sich
                  <a [routerLink]="['/register']" class="color-link">hier</a>
                </p>
              </mat-card-subtitle>

              <mat-card-subtitle
                fxFlexAlign="center"
                class="mat-caption"
                *ngIf="!enableRegistration()"
              >
                <p>
                  Sie haben noch kein Konto?<br />
                  Stellen Sie zuerst einen Antrag unter
                  <a href="https://www.bafa.de">www.bafa.de</a>
                </p>
              </mat-card-subtitle>
            </mat-card>
          </div>
        </mat-card>
      </form>
    </div>
  </div>
</div>
