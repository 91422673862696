<div role="main" fxFlexFill fxLayout="row" fxLayoutAlign="center">
  <div
    fxFlex.="40%"
    fxFlex.md="100%"
    fxFlex.sm="100%"
    fxLayout="column"
    fxLayoutAlign="center"
  >
    <mat-card class="dialog-box" fxFlexOffset="50px">
      <form [formGroup]="form" (ngSubmit)="onSubmit()" action="">
        <!-- title -->
        <mat-card-title class="mat-h1" fxLayoutAlign="center">
          Registrieren
        </mat-card-title>

        <mat-card-content>
          <!-- email -->
          <div fxLayout="row">
            <mat-form-field fxFlex="350px" appearance="fill">
              <mat-label>E-Mail-Adresse</mat-label>
              <label>
                <input
                  matInput
                  required
                  formControlName="email"
                  type="email"
                  placeholder="email@example.com"
                  [errorStateMatcher]="matcher"
                />
              </label>
              <mat-error *ngIf="!this.success">
                <p>Bitte geben Sie eine valide E-Mail-Adresse an.<br /></p>
              </mat-error>
            </mat-form-field>
          </div>
          <p>
            <br />
          </p>

          <mat-card-actions fxLayoutAlign="center" class="card-actions">
            <button
              mat-flat-button
              class="button"
              type="submit"
              color="primary"
            >
              Registrierung starten
            </button>
          </mat-card-actions>
        </mat-card-content>

        <mat-card-subtitle class="mat-caption" fxLayoutAlign="center">
          <p>
            Sie haben bereits einen Zugang?<br />
            <a [routerLink]="['/login']" class="color-link"
              >Hier geht's zur Anmeldung</a
            >
          </p>
        </mat-card-subtitle>
      </form>
    </mat-card>
  </div>
</div>
