export interface KennzeichenType {
  key: string;
  text: string;
}

export interface AntragStatus {
  key: string;
  name: string;
  description: string;
  tooltip: string;
  icon: string;
  fulldescription: string;
}

export interface outputChannel {
  key: string;
  description: string;
}

export const channelList: outputChannel[] = [
  {
    key: 'POST',
    description: 'Postweg',
  } as outputChannel,
  {
    key: 'ELSTER',
    description: 'ELSTER Postfach',
  } as outputChannel,
  {
    key: 'NKB',
    description: 'NKB / Bund ID',
  } as outputChannel,
];

export const antragStatusList: AntragStatus[] = [
  {
    key: '1',
    description: 'Eingegangen',
    tooltip: 'Antrag eingegangen',
    icon: 'mark_email_unread',
    fulldescription: 'Ihr Antrag ist beim BAFA eingegangen.',
  } as AntragStatus,
  {
    key: '2',
    description: 'In Bearbeitung',
    tooltip: 'Antrag wird bearbeitet',
    icon: 'mark_email_read',
    fulldescription:
      'Ihr Antrag befindet sicht aktuell in Bearbeitung durch das BAFA.',
  } as AntragStatus,
  {
    key: '3',
    description: 'Genehmigt',
    tooltip: 'Zuwendungsbescheid erteilt',
    icon: 'check_circle',
    fulldescription:
      'Ihr Antrag befindet sicht aktuell in Bearbeitung durch das BAFA.',
  } as AntragStatus,
  {
    key: '4',
    description: 'VN eingegangen',
    tooltip: 'Verwendungsnachweis eingegangen',
    icon: 'mark_email_unread',
    fulldescription: 'Ihr Verwendungsnachweis ist beim BAFA eingegangen.',
  } as AntragStatus,
  {
    key: '5',
    description: 'VN in Bearbeitung',
    tooltip: 'Verwendungsnachweis wird bearbeitet',
    icon: 'mark_email_read',
    fulldescription: 'Verwendungsnachweis wird bearbeitet.',
  } as AntragStatus,
  {
    key: '6',
    description: 'Ausgezahlt',
    tooltip: 'Förderung ausgezahlt',
    icon: 'euro_symbol',
    fulldescription: 'Förderung ausgezahlt.',
  } as AntragStatus,
  {
    key: 'A',
    description: 'Abgeschlossen',
    tooltip: 'Antrag abgeschlossen',
    icon: 'done_all',
    fulldescription: 'Antrag abgeschlossen.',
  } as AntragStatus,
  {
    key: 'B',
    description: 'Abgelehnt',
    tooltip: 'Antrag abgelehnt',
    icon: 'block',
    fulldescription: 'Antrag abgelehnt',
  } as AntragStatus,
  {
    key: 'C',
    description: 'ZWB aufgehoben',
    tooltip: 'Zuwendungsbescheid aufgehoben',
    icon: 'undo',
    fulldescription: 'Zuwendungsbescheid aufgehoben.',
  } as AntragStatus,
  {
    key: 'D',
    description: 'Klageverfahren',
    tooltip: 'Klageverfahren',
    icon: 'gavel',
    fulldescription: 'Klageverfahren',
  } as AntragStatus,
  {
    key: 'R',
    description: 'Rückforderung',
    tooltip: 'Förderung zurück gefordert',
    icon: 'undo',
    fulldescription: 'Förderung zurück gefordert.',
  } as AntragStatus,
  {
    key: 'W',
    description: 'Widerspruch',
    tooltip: 'Widerspruch wird bearbeitet',
    icon: 'cancel',
    fulldescription: 'Widerspruch wird bearbeitet.',
  } as AntragStatus,
  {
    key: 'S',
    description: 'Storniert',
    tooltip: 'Storniert',
    icon: 'remove_circle_outline',
    fulldescription: 'Ihr Antrag wurde storniert.',
  } as AntragStatus,
  {
    key: 'X',
    description: 'Widerspruchsbescheid',
    tooltip: 'Widerspruchsbescheid erstellt',
    icon: 'announcement',
    fulldescription: 'Widerspruchsbescheid erstellt.',
  } as AntragStatus,
];

export const resolveUsedTypes = (allUsedTypes: string[]): KennzeichenType[] => {
  const kennzeichenTypes = new Array<KennzeichenType>();
  allUsedTypes?.forEach((typeToAdd) => {
    if (typeToAdd == 'BEGEM-FV' || typeToAdd == 'BEGEM2-FV') {
      if (kennzeichenTypes.some((type) => type.text === 'BEG EM')) {
        return;
      }
      kennzeichenTypes.push({
        key: 'BEGEM-FV',
        text: 'BEG EM',
      } as KennzeichenType);
      return;
    }

    if (typeToAdd == 'EBN-FV') {
      if (kennzeichenTypes.some((type) => type.text === 'EBN')) {
        return;
      }
      kennzeichenTypes.push({
        key: 'EBN-FV',
        text: 'EBN',
      } as KennzeichenType);
      return;
    }

    if (typeToAdd == 'EBW-FV') {
      if (kennzeichenTypes.some((type) => type.text === 'EBW')) {
        return;
      }
      kennzeichenTypes.push({
        key: 'EBW-FV',
        text: 'EBW',
      } as KennzeichenType);
      return;
    }

    if (typeToAdd == 'BEGEM-FV' || typeToAdd == 'BEGEM2-FV') {
      if (kennzeichenTypes.some((type) => type.text === 'BEG EM')) {
        return;
      }
      kennzeichenTypes.push({
        key: 'BEGEM-FV',
        text: 'BEG EM',
      } as KennzeichenType);
      return;
    }

    if (typeToAdd == 'BEGPT-FV') {
      if (kennzeichenTypes.some((type) => type.text === 'BEG PT')) {
        return;
      }
      kennzeichenTypes.push({
        key: 'BEGPT-FV',
        text: 'BEG PT',
      } as KennzeichenType);
      return;
    } else {
      if (kennzeichenTypes.some((type) => type.text === typeToAdd)) {
        return;
      }
      kennzeichenTypes.push({
        key: typeToAdd,
        text: typeToAdd,
      } as KennzeichenType);
    }
  });
  return kennzeichenTypes;
};

export const getStatusTypeText = (status: string): AntragStatus => {
  return antragStatusList.find((s) => s.key === status);
};
