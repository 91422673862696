import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BackendService } from '../shared/backend.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ErrorMessageMatcher, Field } from '../shared/matcher.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Account } from '../shared/data/account';
import { PasswordValidation } from '../shared/data/passwordValidation';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css'],
})
export class ChangePasswordComponent implements OnInit {

  form: FormGroup;
  success: boolean;
  email: any;

  pwOld = new Field();
  pwNew = new Field();

  matcherOld = new ErrorMessageMatcher(this.pwOld);
  matcherNew = new ErrorMessageMatcher(this.pwNew);

  constructor(
    private fb: FormBuilder,
    private backend: BackendService,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      oldPassword: ['', Validators.required],
      newPassword: ['', Validators.required]
    });
    this.email = this.backend.getEmail();
  }

  isAlias(): boolean {
    return this.backend.isAlias();
  }

  onSubmit(): void {
    if (this.form.valid) {
      this.changePassword(this.form.get('oldPassword').value, this.form.get('newPassword').value);
    }
  }

  changePassword(oldPassword: string, newPassword: string): void {
    this.backend
      .updatePassword(new Account(this.email, oldPassword, newPassword))
      .subscribe(stream => this.changed(stream), error => this.notChanged(error));
  }

  private changed(response: boolean): void {
    this.success = response;
    this.pwOld.error = '';
    this.pwNew.error = '';
    this.processResult(this.success);
  }

  private notChanged(error: HttpErrorResponse): void {
    this.success = false;
    const pwError = error.error as PasswordValidation;
    if (pwError != null) {
      this.pwOld.error = pwError.errorOldPassword;
      this.pwNew.error = pwError.errorNewPassword;
    }
    this.processResult(this.success);
  }

  private processResult(success: boolean): void {
    if (success) {
      this.backend.createToken(this.email, this.form.get('newPassword').value);
      this.snackBar.open('Erfolgreich Passwort geändert.', '',
        { duration: 10000, panelClass: ['success'] });
    } else {
      this.snackBar.open('Passwort konnte nicht geändert werden.', '',
        { duration: 10000, panelClass: ['alert'] });
    }
  }
}
