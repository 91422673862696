import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ActivationComponent } from './activation/activation.component';
import { RequestPasswordResetComponent } from './request-password-reset/request-password-reset.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { AntragDetailComponent } from './antrag-detail/antrag-detail.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { OverviewComponent } from './overview/overview.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './shared/auth.guard';
import { AdministrationComponent } from './administration/administration.component';
import { Role } from './shared/data/role';
import { ChangeEmailComponent } from './change-email/change-email.component';
import {OverviewStatus} from "./shared/data/overview.status";
import {LogoutComponent} from "./logout/logout.component";

const routes: Routes = [
  { path: 'uebersicht', component: OverviewComponent, canActivate: [AuthGuard], data: {
      loginText: 'Angemeldet als',
      fxFlexValue: '70%',
      overviewStatus: OverviewStatus.byUser
    }
  },
  { path: 'changePassword', component: ChangePasswordComponent, canActivate: [AuthGuard], data: { roles: [Role.Admin, Role.User] }  },
  { path: 'antragDetail', component: AntragDetailComponent, canActivate: [AuthGuard], data: { roles: [Role.Admin, Role.User] }  },
  { path: 'changeEmail', component: ChangeEmailComponent, canActivate: [AuthGuard], data: { roles: [Role.Admin, Role.User] } },
  { path: 'admin', component: AdministrationComponent, canActivate: [AuthGuard], data: { roles: [Role.Admin] } },
  // home redirects to admin, where AuthGuard checks Role, and redirects to uebersicht if not Admin, or to login if not authenticated at all
  { path: 'home', redirectTo: '/admin' },
  { path: 'request-reset', component: RequestPasswordResetComponent },
  { path: 'reset', component: ResetPasswordComponent, data: { setPw: false } },
  { path: 'setPassword', component: ResetPasswordComponent, data: { setPw: true } },
  { path: 'aktivieren', component: ActivationComponent },
  { path: 'login', redirectTo: '' },
  { path: 'logout', component: LogoutComponent},
  { path: 'register', component: RegisterComponent },
  { path: '', component: LoginComponent },
  { path: '**', redirectTo: '/home' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
