<div role="main" fxFlexFill fxLayout="row" fxLayoutAlign="center">
  <div
    fxFlex="40%"
    fxFlex.md="100%"
    fxFlex.sm="100%"
    fxLayout="column"
    fxLayoutAlign="center"
  >
    <mat-card class="dialog-box" fxFlexOffset="50px">
      <form [formGroup]="form" (ngSubmit)="onSubmit()" action="">
        <!-- title -->
        <mat-card-title fxLayoutAlign="center">
          <h1>Passwort ändern</h1>
        </mat-card-title>

        <mat-card-subtitle fxLayoutAlign="center" class="mat-body-1"
          >Ändern Sie das Passwort Ihres BAFA Benutzerkontos {{ email }}.
        </mat-card-subtitle>

        <mat-card-content fxLayout="column">
          <!-- old password -->
          <mat-form-field appearance="fill">
            <mat-label>Altes Passwort</mat-label>
            <label>
              <input
                matInput
                required
                formControlName="oldPassword"
                [type]="this.pwOld.hide ? 'password' : 'text'"
                [errorStateMatcher]="matcherOld"
              />
            </label>

            <mat-error *ngIf="this.pwOld.error !== ''">
              <mat-icon>error</mat-icon>
              {{ this.pwOld.error }}
            </mat-error>

            <button
              mat-icon-button
              matSuffix
              type="button"
              (click)="this.pwOld.hide = !this.pwOld.hide"
              [attr.aria-pressed]="this.pwOld.hide"
              matTooltip="Passwort im Klartext anzeigen"
            >
              <mat-icon>{{
                this.pwOld.hide ? 'visibility_off' : 'visibility'
              }}</mat-icon>
            </button>
          </mat-form-field>

          <!-- new password -->
          <mat-form-field class="password-field" appearance="fill">
            <mat-label>Neues Passwort</mat-label>
            <label>
              <input
                matInput
                required
                formControlName="newPassword"
                [type]="this.pwNew.hide ? 'password' : 'text'"
                [errorStateMatcher]="matcherNew"
              />
            </label>

            <mat-hint>
              <mat-icon>info</mat-icon>
              Mindestanforderung: 8 Zeichen, enthält Klein-, Großbuchstaben und
              Sonderzeichen
            </mat-hint>

            <mat-error *ngIf="this.pwNew.error !== ''">
              <mat-icon>error</mat-icon>
              {{ this.pwNew.error }}
            </mat-error>

            <button
              mat-icon-button
              matSuffix
              type="button"
              (click)="this.pwNew.hide = !this.pwNew.hide"
              [attr.aria-pressed]="this.pwNew.hide"
              matTooltip="Passwort im Klartext anzeigen"
            >
              <mat-icon>{{
                this.pwNew.hide ? 'visibility_off' : 'visibility'
              }}</mat-icon>
            </button>
          </mat-form-field>
          <mat-card-actions
            class="card-actions"
            fxFlexOffset="{{ this.pwNew.error !== '' ? '100px' : '40px' }}"
          >
            <button
              mat-button
              class="button"
              type="button"
              color="primary"
              [routerLink]="['/home']"
            >
              Zurück
            </button>
            <button
              mat-flat-button
              class="button"
              type="submit"
              color="primary"
              [disabled]="!this.form.valid || isAlias()"
            >
              Passwort ändern
            </button>
          </mat-card-actions>
        </mat-card-content>
      </form>
    </mat-card>
  </div>
</div>
