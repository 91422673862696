<div
  class="content scrollable md-padding"
  role="main"
  fxLayout
  fxLayoutAlign="center"
  fxLayout.xs="column"
  fxLayoutAlign.xs="start"
>

  <div class="admin-header">
    <mat-card-title
      class="bafa-display-1"
      title="Ihre Anträge Detail oder Meldungen an das BAFA.
          Es können eingereichte Formulare bearbeitet."
      *ngIf="!isLoading && isOpenAntragFailure"
    >
      Vorgang {{ this.kennzeichen }} {{ this.vorgangsnummer }}
    </mat-card-title>
    <mat-card-title
      class="bafa-display-3"
      *ngIf="!isLoading && isOpenAntragFailure"
    >
      Antragdaten wurden nicht erfolgreich gelesen.
    </mat-card-title>

    <mat-card-actions
      class="card-actions"
      style="margin-top: 20px !important"
      *ngIf="!isLoading && isOpenAntragFailure"
    >
      <button
        mat-flat-button
        class="button"
        type="button"
        color="primary"
        [routerLink]="['/home']"
      >
        Zur Übersicht
      </button>
    </mat-card-actions>
    <div class="loading-shade" *ngIf="isLoading">
      <mat-spinner></mat-spinner>
    </div>
  </div>
  <mat-card
    *ngIf="!isLoading && !isOpenAntragFailure"
    class="mat-elevation-z0"
    ngClass.gt-xs="overview-card"
    fxFlex="85%"
    fxFlex.md="85%"
    fxFlex.lt-md="85%"
  >
    <div
      fxLayout="column"
      fxLayoutAlign="start"
      fxFlex="85%"
      fxLayoutGap="50px"
      fxLayout.xs="column"
      fxLayoutAlign.xs="center start"
    >
      <div fxFlex="85%">
        <!-- title -->
        <div class="admin-header">
          <mat-card-title
            class="bafa-display-1"
            title="Ihre Anträge Detail oder Meldungen an das BAFA.
          Es können eingereichte Formulare bearbeitet."
          >
            Vorgang {{ this.kennzeichen }} {{ this.vorgangsnummer }}
          </mat-card-title>
        </div>

        <app-antrag-detail-status (openVnLinkEvent)="getVnLinkTarget($event)"  [antragDetail]="antragDetail" [foerderbetrag]="foerderbetrag"></app-antrag-detail-status>

        <mat-card-title
          class="flex-column-left mat-h2"
          style="margin-top: 20px !important"
        >
          Dokumente Hochladen:
        </mat-card-title>
        <mat-card-content
          class="flex-row width-100"
          fxLayoutAlign="left"
          style="margin-bottom: 5px !important"
        >
          Hier haben Sie die Möglichkeit, weitere Dokumente im Format .pdf zu Ihrem Antrag hochzuladen.
        </mat-card-content>
        <mat-card-actions class="card-actions">
          <a
            [href]="this.antragDetail.uploadLink"
            target="_blank"
            mat-icon-button
            color="primary"
            aria-label="Upload"
            matTooltip="Dokumente zum Antrag hochladen"
          >
            <button
              mat-flat-button
              class="button mat-elevation-z5"
              type="button"
            >
              <mat-icon class="icon primary-dark">upload</mat-icon>
              Dokument hochladen
            </button>
          </a>
        </mat-card-actions>

        <mat-card-title
          class="flex-column-left mat-h2"
          style="margin-top: 20px !important"
        >
          Quittung herunterladen:
        </mat-card-title>
        <mat-card-content
          class="flex-row width-100"
          fxLayoutAlign="left"
          style="margin-bottom: 5px !important"
        >
          Hier können Sie die Quittung Ihres Antrags herunterladen. Diese beinhaltet eine Übersicht
          über Ihren gestellten Antrag, inklusive eines Datums mit Eingangsbestätigung.
        </mat-card-content>
        <mat-card-actions>

              <a
                (click)="showPdf()"
                mat-icon-button
                color="primary"
                aria-label="Download"
                matTooltip="Druckquittung herunterladen"
              >
                <button
                  mat-flat-button
                  class="button mat-elevation-z5"
                  type="button"
                  [disabled]="this.antragDetail.stornoWunsch !== null || antragDetail.status == 'S'">
                  <mat-icon class="icon primary-dark">download</mat-icon>
                  QUITTUNG ANTRAG
                </button>
              </a>



          <a
            (click)="showVnPdf(antragDetail)"
            *ngIf="this.antragDetail.viewVnLink !== null"
            mat-icon-button
            color="primary"
            style="margin-left: 200px"

            aria-label="Download Verwendungsnachweis"
          >
            <button
              *ngIf="this.antragDetail.viewVnLink !== null"
              mat-flat-button
              class="button mat-elevation-z5"
              type="button"
              matTooltip="Verwendungsnachweis Druckquittung herunterladen"
              [disabled]="this.antragDetail.stornoWunsch !== null || antragDetail.status == 'S'">
              <mat-icon class="icon primary-dark">download</mat-icon>
              QUITTUNG VERWENDUNGSNACHWEIS
            </button>
          </a>

        </mat-card-actions>

        <mat-card-title
          class="flex-column-left mat-h2"
          style="margin-top: 20px !important"
          *ngIf="this.kennzeichen == 'BEGPT-FV' && antragDetail.stornoWunsch !== false && this.isEnabledStorno()"
        >
          Stornierung des Antrags:
        </mat-card-title>
        <mat-card-content
          class="flex-row width-100"
          fxLayoutAlign="left"
          style="margin-bottom: 5px !important"
          *ngIf="this.kennzeichen == 'BEGPT-FV' && antragDetail.stornoWunsch !== false && this.isEnabledStorno()"
        >
          Hier können Sie Ihren Antrag stornieren. Die Aktion ist nur vor der Einreichung des Verwendungsnachweises möglich.
        </mat-card-content>
        <mat-card-actions
          class="card-actions"
          style="margin-top: 20px !important"
        >
            <a
              (click)="openStornoAntragDialog(this.antragDetail)" mat-icon-button color="primary" aria-label="Storno"
              matTooltip="Hier können Sie Ihren Antrag stornieren. Die Aktion ist nur vor der Einreichung des Verwendungsnachweises möglich." *ngIf="(this.kennzeichen == 'BEGPT-FV' || this.kennzeichen == 'EBN-FV' || this.kennzeichen == 'EBW-FV') && this.isEnabledStorno() && antragDetail.stornoWunsch !== false">
              <button
                *ngIf="this.isEnabledStorno() && antragDetail.stornoWunsch !== false"
                mat-flat-button
                class="button mat-elevation-z5"
                type="button"
                [disabled]="this.antragDetail.stornoWunsch !== null || antragDetail.status == 'S'"
              >
                <mat-icon>remove_circle_outline</mat-icon>
                Stornierung des Antrags
              </button>
            </a>
        </mat-card-actions>

        <mat-card-actions
          class="card-actions"
          style="margin-top: 20px !important"
        >
          <button
            mat-flat-button
            class="button"
            type="button"
            color="primary"
            [routerLink]="['/uebersicht']"
            [queryParams]="{bevollmaechtigterMode: this.antragDetail.isBevollmaechtigter}"
          >
            Zur Übersicht
          </button>
        </mat-card-actions>
      </div>
    </div>
    <mat-card
      class="mat-elevation-z0"
      ngClass.gt-xs="overview-card"
      fxFlex="100%"
      fxFlex.md="100%"
      fxFlex.lt-md="100%"
    >
      <div class="flex-column-center" role="main">
        <mat-card class="mat-elevation-z5 fit-content"
        style="margin-bottom: 20px !important">
          <mat-card-title
            class="flex-column-left mat-h4"
            style="margin-top: 20px !important;"
          >
            Angaben zur antragstellenden Person
          </mat-card-title>
          <app-person-detail (savePersonEvent)="updateAntragsteller($event)" [showOrganisation]="antragstellerOriginalOrganization != null" [isOrganizationRequired]="antragstellerOriginalOrganization != null"
                             [typeOfOzgService]="typeOfOzgService" [isAuthorized]="isAuthorized" [person]="antragsteller" [editAntragsteller]="manualEditAntragsteller" [edit]="isEdit && editAntragsteller" [loading]="isLoading" [vertrauensniveau]="vertrauensniveau"></app-person-detail>

          <app-upload-files *ngIf="isEdit && editAntragsteller && isShowDokumentHochlade()" [person]="isAuthorized ? detail.antragsteller : antragsteller" (isSpeichernDisabled)="changeDisableSpeichern($event)"></app-upload-files>

          <mat-card-actions class="card-actions flex-column-center" style="padding-bottom: 20px;">
          <button *ngIf="!isEdit  && antragDetail.isBevollmaechtigter"
                  mat-flat-button
                  class="button mat-elevation-z5"
                  type="button"
                  [disabled]="!isEnabledBearbeiten() || antragDetail.stornoWunsch == true || antragDetail.status == 'S'"
                  (click)="this.openChangePersonDataDialog(true)">
            <mat-icon class="icon primary-dark">edit</mat-icon>
            BEARBEITEN
          </button>
          </mat-card-actions>

          <mat-card-title
            class="flex-column-left mat-h4"
            style="margin-top: 20px !important"
            *ngIf="antragDetail.isBevollmaechtigter"
          >
            <div *ngIf="antragDetail.isBevollmaechtigter && !editAntragsteller">
              Angaben zur Bevollmächtigung
            </div>
          </mat-card-title>
          <app-person-detail  *ngIf="antragDetail.isBevollmaechtigter && !editAntragsteller" (savePersonEvent)="updateBevollmaechtigter($event)" [isOrganizationRequired]="bevollmaechtigterOriginalOrganization != null" [typeOfOzgService]="typeOfOzgService"
                              [showOrganisation]="bevollmaechtigterOriginalOrganization != null" [isAuthorized]="isAuthorized" [person]="bevollmaechtigter" [edit]="isEdit && editBevollmaechtigter" [loading]="isLoading" [vertrauensniveau]="vertrauensniveau"></app-person-detail>

         <app-upload-files *ngIf="isEdit && !editAntragsteller && isShowDokumentHochlade()" [person]="isAuthorized ? detail.bevollmaechtigter : bevollmaechtigter" (isSpeichernDisabled)="changeDisableSpeichern($event)"></app-upload-files>
          <mat-card-actions class="card-actions flex-column-center" style="padding-bottom: 20px;">
            <button *ngIf="!isEdit"
              mat-flat-button
              class="button mat-elevation-z5"
              type="button"
              [disabled]="!isEnabledBearbeiten() || antragDetail.stornoWunsch == true || antragDetail.status == 'S'"
              (click)="this.openChangePersonDataDialog(antragDetail.isBevollmaechtigter ? false : true)">
              <mat-icon class="icon primary-dark">edit</mat-icon>
              BEARBEITEN
            </button>

            <div>
              <button *ngIf="isEdit"
                      mat-flat-button
                      class="button mat-elevation-z5"
                      type="button"
                      style="margin-top: 10px; margin-right: 60px"
                      (click)="closeAntragChange()">
                <mat-icon class="icon">close</mat-icon>
                Abbrechen
              </button>

            <button *ngIf="isEdit"
                    mat-flat-button
                    class="button mat-elevation-z5"
                    type="button"
                    style="margin-top: 10px;"
                    [disabled]="!isSaveEnabled || (this.isSpeichernDisabled && isShowDokumentHochlade())"
                    (click)="saveAntragAndChangeEditState()">
              <mat-icon class="icon ">check</mat-icon>
              SPEICHERN
            </button>


            </div>
          </mat-card-actions>
        </mat-card>
      </div>
    </mat-card>
  </mat-card>
</div>
