import {Component, NgZone, OnInit} from '@angular/core';
import { BackendService } from '../shared/backend.service';
import {Router} from "@angular/router";
import {FormGroup} from "@angular/forms";


@Component({
  selector: 'app-login',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css'],
})
export class LogoutComponent implements OnInit {

  constructor(private router: Router,
    private zone: NgZone,
    private backend: BackendService) {

  }

  ngOnInit() {
    this.logout();

  }

  logout(): void {
    this.backend.logout(false);
  }

  goToLogin(): void {
    this.router.navigate(['/login']);
  }

}

