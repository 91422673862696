import { BackendService } from './backend.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, UrlTree, Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  constructor(
    private router: Router,
    private backend: BackendService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot): true | UrlTree {
    if (this.backend.isAuthenticated()) {
      // check if route is restricted by role
      if (
        route.data.roles &&
        route.data.roles.indexOf(this.backend.getRole()) === -1
      ) {
        // role not authorised so redirect to uebersicht
        return this.router.parseUrl('/uebersicht');
      }

      return true;
    } else {
      // not authorised so redirect to login page
      return this.router.parseUrl('/login');
    }
  }
}
