import {Component} from '@angular/core';
import {BackendService} from "../backend.service";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {BuildInfoComponent} from "../build-info/build-info.component";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css'],
})
export class FooterComponent {
  urlDatenschutz = 'https://www.bafa.de/DE/Service/Datenschutzerklaerung/datenschutzerklaerung_node.html';
  urlImpressum = 'https://www.bafa.de/DE/Service/Impressum/fms/fms_node.html';

  constructor(private backend: BackendService, private buildInfoDialog: MatDialog) { }

  buildInfo(): void {
    this.backend.buildInfo().subscribe(result => {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.data = result;
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      this.buildInfoDialog.open(BuildInfoComponent, dialogConfig);
    });
  }
}
