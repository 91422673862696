import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-edit-warning',
  templateUrl: './edit-warning.component.html',
  styleUrls: ['./edit-warning.component.css']
})
export class EditWarningComponent {

  constructor(
    public dialogRef: MatDialogRef<EditWarningComponent>) { }

  close(): void {
    this.dialogRef.close();
  }
}
