import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { Antrag } from '../../shared/data/antrag';
import { Moment } from 'moment';
import { antragStatusList } from '../../shared/utils-antrags';
import { dateFormatDe } from '../../shared/utils';
import { environment } from '../../../environments/environment';

interface AntragStatusExtended {
  key: string;
  name: string;
  description: string;
  description2: string;
  icon: string;
  color: string;
  date: Date;
}

const initialStatusList = new Array<AntragStatusExtended>();

@Component({
  selector: 'antrag-detail-status',
  templateUrl: './antrag-detail-status.component.html',
  standalone: false,
})
export class AntragDetailStatusComponent implements OnInit {
  @Input() antragDetail: Antrag;
  @Input() foerderbetrag: string;
  antragStatusExtendedList = new Array<AntragStatusExtended>();
  dateFormatDe = dateFormatDe;

  constructor() {
    antragStatusList.forEach((as) => {
      initialStatusList.push({
        key: as.key,
        name: as.description,
        description: as.fulldescription,
      } as AntragStatusExtended);
    });
  }

  @Output() openVnLinkEvent: EventEmitter<Antrag> = new EventEmitter();

  ngOnInit(): void {
    this.calculateAntragStatusExtendedList(this.antragDetail?.status);
  }

  private pushAntragStatus(
    key: string,
    color: string,
    icon: string,
    date: Date,
  ): AntragStatusExtended {
    const antragStatus = initialStatusList.find((s) => s.key === key);
    antragStatus.color = color;
    antragStatus.icon = icon;
    antragStatus.date = date;
    return antragStatus;
  }

  private calculateAntragStatusExtendedList(status: string): void {
    switch (status) {
      case '1':
        this.antragStatusExtendedList.push(
          this.pushAntragStatus(
            '1',
            '#0072a3',
            'check_circle_outline',
            this.momentToDate(this.antragDetail?.antragsdatum),
          ),
        );
        this.antragStatusExtendedList.push(
          this.pushAntragStatus('2', '#8C8C8C', 'adjust', null),
        );
        break;
      case '2':
        this.antragStatusExtendedList.push(
          this.pushAntragStatus(
            '1',
            '#5EB715',
            'check_circle_outline',
            this.momentToDate(this.antragDetail?.antragsdatum),
          ),
        );
        this.antragStatusExtendedList.push(
          this.pushAntragStatus('2', '#0072a3', 'adjust', null),
        );
        if (!this.antragDetail?.stornoWunsch) {
          const antragStatus = this.pushAntragStatus(
            '3',
            '#8C8C8C',
            'adjust',
            null,
          );
          antragStatus.description = null;
          this.antragStatusExtendedList.push(antragStatus);
        } else {
          const antragStatus = this.pushAntragStatus(
            '3',
            '#8C8C8C',
            'adjust',
            null,
          );
          antragStatus.description = null;
          this.antragStatusExtendedList.push(antragStatus);
        }
        break;
      case '3':
        this.antragStatusExtendedList.push(
          this.pushAntragStatus(
            '1',
            '#5EB715',
            'check_circle_outline',
            this.momentToDate(this.antragDetail?.antragsdatum),
          ),
        );
        this.antragStatusExtendedList.push(
          this.pushAntragStatus('2', '#5EB715', 'check_circle_outline', null),
        );
        const antragStatus = this.pushAntragStatus(
          '3',
          '#0072a3',
          'adjust',
          null,
        );
        antragStatus.description =
          'Ihr Antrag wurde genehmigt.' +
          (this.foerderbetrag !== null && this.foerderbetrag != '0'
            ? '\n Fördersumme: ' + this.getGermanPrice(this.foerderbetrag)
            : '') +
          '\n Bitte reichen Sie nach Durchführung der Maßnahme/n den ';
        antragStatus.description2 =
          '\n Verwendungsnachweis für Ihren Antrag ein.';
        antragStatus.date = this.momentToDate(this.antragDetail?.zwbDatum);
        this.antragStatusExtendedList.push(antragStatus);

        break;

      case 'S':
        this.antragStatusExtendedList.push(
          this.pushAntragStatus(
            '1',
            '#5EB715',
            'check_circle_outline',
            this.momentToDate(this.antragDetail?.antragsdatum),
          ),
        );
        this.antragStatusExtendedList.push(
          this.pushAntragStatus('2', '#5EB715', 'check_circle_outline', null),
        );
        this.antragStatusExtendedList.push(
          this.pushAntragStatus('S', '#C21D00', 'remove_circle_outline', null),
        );
        this.antragStatusExtendedList.push({
          key: 'S',
          name: 'Antrag nicht mehr im Portal verfügbar.',
          icon: 'adjust',
          color: '#8C8C8C',
          date: this.getTimeToDelete(),
        } as AntragStatusExtended);
        break;

      case '4':
        this.antragStatusExtendedList.push(
          this.pushAntragStatus(
            '1',
            '#5EB715',
            'check_circle_outline',
            this.momentToDate(this.antragDetail?.antragsdatum),
          ),
        );
        this.antragStatusExtendedList.push(
          this.pushAntragStatus('2', '#5EB715', 'check_circle_outline', null),
        );
        this.antragStatusExtendedList.push(
          this.pushAntragStatus('4', '#0072a3', 'adjust', null),
        );
        break;
      case '5':
        this.antragStatusExtendedList.push(
          this.pushAntragStatus(
            '1',
            '#5EB715',
            'check_circle_outline',
            this.momentToDate(this.antragDetail?.antragsdatum),
          ),
        );
        this.antragStatusExtendedList.push(
          this.pushAntragStatus('2', '#5EB715', 'check_circle_outline', null),
        );
        this.antragStatusExtendedList.push(
          this.pushAntragStatus('5', '#0072a3', 'adjust', null),
        );
        break;
      case '6':
      case 'A':
      case 'B':
      case 'C':
      case 'D':
      case 'R':
      case 'W':
      case 'X':
        this.antragStatusExtendedList.push(
          this.pushAntragStatus(
            '1',
            '#5EB715',
            'check_circle_outline',
            this.momentToDate(this.antragDetail?.antragsdatum),
          ),
        );
        this.antragStatusExtendedList.push(
          this.pushAntragStatus('2', '#5EB715', 'check_circle_outline', null),
        );
        this.antragStatusExtendedList.push(
          this.pushAntragStatus(
            initialStatusList.find((s) => s.key === this.antragDetail.status)
              .key,
            '#0072a3',
            'adjust',
            null,
          ),
        );
        if (status == 'A' || status == 'B' || status == 'C') {
          this.antragStatusExtendedList.push({
            key: 'S',
            name: 'Antrag nicht mehr im Portal verfügbar.',
            icon: 'adjust',
            color: '#8C8C8C',
            date: this.getTimeToDelete(),
          } as AntragStatusExtended);
        }
        break;
    }
  }

  private getLifeSpanInMonth(): number {
    return environment?.lifeSpanInMonth;
  }

  private getTimeToDelete(): Date {
    if (this.getLifeSpanInMonth() == null) {
      return null;
    }
    return this.momentToDatePlusLifeSpan(
      this.antragDetail.lastStatusUpdated,
      this.getLifeSpanInMonth(),
    );
  }

  public momentToDatePlusLifeSpan(mom: Moment, lifeSpam: number): Date {
    if (mom !== null) {
      const [year, month, day] = String(mom).split('-');
      return new Date(+year, +month - 1 + lifeSpam, +day);
    }
    return null;
  }

  public momentToDate(mom: Moment): Date {
    if (mom !== null) {
      const [year, month, day] = String(mom).split('-');
      return new Date(+year, +month - 1, +day);
    }
    return null;
  }

  public openVnLink(antrag: Antrag) {
    this.openVnLinkEvent.emit(antrag);
  }

  public getGermanPrice(number: string): string {
    return new Intl.NumberFormat('de-DE', {
      style: 'currency',
      currency: 'EUR',
    }).format(Number(number));
  }
}
