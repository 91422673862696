<div
  fxLayout="column"
  fxLayoutAlign="space-evenly center"
  fxFlexFill
  class="overview-dialog-content-container"
>
  <mat-card-content fxLayout="column" fxFlexFill>
    <div fxLayout="row" fxLayoutAlign="center" fxFlex="70%">
      <antrag-overview
        loginText="Anträge für Benutzer"
        fxFlex="90%"
        [email]="email"
        [roles]="roles"
        [vorgangsnummer]="vorgangsnummer"
        [loadOverviewStatus]="loadOverviewStatus"
        [defaultKennzeichenFilterValue]="defaultKennzeichenFilterValue"
        (close)="close()"
      >
      </antrag-overview>
    </div>

    <mat-dialog-actions
      fxLayout="row"
      fxLayoutAlign="center"
      fxFlex="90px"
      fxFlexOffset="20px"
    >
      <button mat-raised-button color="primary" (click)="close()">
        Schließen
      </button>
    </mat-dialog-actions>
  </mat-card-content>
</div>
