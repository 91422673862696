<div class="flex-column-center" role="main">

  <mat-card class="flex-column-center margin-50">
    <form [formGroup]="form" (ngSubmit)="onSubmit()" action="">

      <!-- title -->
      <mat-card-title class="flex-column-center mat-h1">
        Anmelden
      </mat-card-title>

      <mat-card-subtitle class="flex-column-center">
        Verwenden Sie Ihr BAFA Benutzerkonto
      </mat-card-subtitle>

      <mat-card-content>

        <!-- email -->
        <mat-form-field class="width-100" appearance="fill">
          <mat-label>E-Mail-Adresse</mat-label>
          <label>
            <input matInput required formControlName="email" type="email" placeholder="email@example.com" [errorStateMatcher]="matcher">
          </label>
        </mat-form-field>

        <!-- password -->
        <mat-form-field class="width-100" [ngClass]="{'padding': this.success == false}" appearance="fill">

          <mat-label>Passwort</mat-label>
          <label>
            <input matInput required formControlName="password" [type]="hide ? 'password' : 'text'" [errorStateMatcher]="matcher">
          </label>
          <mat-error *ngIf="!this.success">
            <p>
              → Prüfen Sie die E-Mail-Adresse.<br/>
              → Prüfen Sie das Passwort.<br/>
            </p>
            <p>
            <br>
              Hinweis: Nach drei fehlgeschlagenen Anmelderversuchen sperren wir das Benutzerkonto temporär. Versuchen Sie es nach 5 Minuten erneut.<br/>
            </p>
          </mat-error>
          <button mat-icon-button matSuffix type="button" (click)="hide = !hide" [attr.aria-pressed]="hide"
            matTooltip="Passwort im Klartext anzeigen">
            <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
          </button>
        </mat-form-field>

      </mat-card-content>

      <!-- buttons -->
      <mat-card-actions class="card-actions">
        <button mat-button class="button" type="button" color="primary" [routerLink]="['/request-reset']">Probleme mit der Anmeldung?</button>
        <button mat-flat-button class="button" type="submit" color="primary">Weiter</button>
      </mat-card-actions>

      <mat-card-subtitle class="flex-column-center" *ngIf="enableRegistration()">
        <p>Sie haben noch kein Konto?<br>
          Registrieren Sie sich <a [routerLink]="['/register']" >hier</a></p>
      </mat-card-subtitle>

      <mat-card-subtitle class="flex-column-center" *ngIf="!enableRegistration()">
        <p>Sie haben noch kein Konto?<br>
          Stellen Sie zuerst einen Antrag unter <a href="https://www.bafa.de">www.bafa.de</a></p>
      </mat-card-subtitle>
    </form>

  </mat-card>
</div>
