import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { OverviewAdminData } from '../../shared/data/overview.admin.data';
import { OverviewStatus } from '../../shared/data/overview.status';
import { AntragRole } from '../../shared/data/antrag.role';

@Component({
  selector: 'admin-antrag-overview',
  templateUrl: './admin-antrag-overview.component.html',
  standalone: false,
})
export class AdminAntragOverviewComponent {
  email: string;
  roles: AntragRole[];
  vorgangsnummer: string;
  loadOverviewStatus: OverviewStatus;
  zeigeFilter: boolean;
  defaultKennzeichenFilterValue: string[];

  constructor(
    public dialogRef: MatDialogRef<AdminAntragOverviewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: OverviewAdminData,
  ) {
    this.email = data.email;
    this.roles = data.roles;
    this.vorgangsnummer = data.vorgangsnummer;
    this.loadOverviewStatus = data.overviewStatus;
    this.zeigeFilter = data.zeigeFilter;
    this.defaultKennzeichenFilterValue = data.defaultKennzeichenFilterValue;
  }

  close(): void {
    this.dialogRef.close();
  }
}
