<div role="main" fxFlexFill fxLayout="row" fxLayoutAlign="center">
  <div *ngIf="success !== undefined" fxLayout="column">
    <mat-card
      [ngClass]="success === false ? 'mat-error' : ''"
      fxFlexOffset="40px"
    >
      <div
        fxFlexFill
        fxLayoutGap="20px"
        fxLayout="column"
        fxLayoutAlign="center center"
      >
        <mat-card-title fxFlexOffset="15px">
          <h1>Konto aktivieren</h1>
        </mat-card-title>

        <div *ngIf="success" fxLayout="column" fxLayoutAlign="center center">
          <!-- content -->
          <mat-card-content fxLayoutAlign="center center">
            <mat-icon class="primary-dark big-icon" fxFlex="65px"
              >check_circle</mat-icon
            >
            <div class="mat-body-2">
              Herzlichen Glückwunsch, Ihr BAFA Benutzerkonto wurde aktiviert.<br />
              Nun können Sie sich mit Ihrem Konto anmelden.
            </div>
          </mat-card-content>

          <!-- buttons -->
          <mat-card-actions fxFlexOffset="15px">
            <button
              mat-flat-button
              class="button"
              type="submit"
              color="primary"
              [routerLink]="['/login']"
            >
              Weiter
            </button>
          </mat-card-actions>
        </div>

        <div *ngIf="!success" fxLayout="column" fxLayoutAlign="center center">
          <!-- content -->
          <mat-card-content fxLayoutAlign="center center">
            <mat-icon class="big-icon" fxFlex="65px">error</mat-icon>
            <div class="mat-body-2">
              Ihr BAFA Benutzerkonto konnte nicht aktiviert werden.<br />
              Bitte kontaktieren Sie uns.
            </div>
          </mat-card-content>

          <!-- buttons -->
          <mat-card-actions fxFlexOffset="15px">
            <a
              fxFlexOffset="40px"
              href="https://www.bafa.de/DE/Service/Aufgabenuebersicht/modul_aufgabenuebersicht_node.html"
              mat-flat-button
              class="button"
              type="submit"
              color="warn"
              >Zum Kontakt</a
            >
          </mat-card-actions>
        </div>
      </div>
    </mat-card>
  </div>
</div>
