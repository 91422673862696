<div
  fxLayout="column"
  fxLayoutAlign="space-between"
  fxFlexFill
  class="app-content-container"
>
  <div flex="auto" fxLayout="column">
    <div fxLayout="row" class="app-menu-container">
      <menu fxFlexFill></menu>
    </div>

    <div fxLayout="row">
      <div fxFlex="100%" fxLayoutAlign="center start">
        <div
          fxFlex="70%"
          fxFlex.xs="100%"
          fxFlex.sm="95%"
          fxFlex.md="90%"
          fxFlex.lg="80%"
          fxFlexFill
        >
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
  </div>

  <div fxLayout="row" fxFlex="40px">
    <footer fxFlex="auto" fxFlexFill></footer>
  </div>
</div>
