import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Moment } from 'moment';
import { setKenzeichenAgregateTypesForAntrag } from '../../shared/utils';
import { MatTableDataSource } from '@angular/material/table';
import {
  combineLatest,
  merge,
  Observable,
  of as observableOf,
  Subscription,
} from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { SortDirection } from '@angular/material/sort';
import { BackendService } from '../../shared/backend.service';
import { AntragRole } from '../../shared/data/antrag.role';
import { Antrag } from '../../shared/data/antrag';
import { getStatusTypeText } from '../../shared/utils-antrags';

export class AccountDeleteData {
  constructor(
    public deleteRequestDate: Moment,
    public email: string,
  ) {}
}

@Component({
  selector: 'confirm-delete-account',
  templateUrl: './confirm-delete-account.component.html',
  styleUrls: ['./confirm-delete-account.component.scss'],
  standalone: false,
})
export class ConfirmDeleteAccountComponent implements OnInit, OnDestroy {
  columnsAntragsteller: string[] = ['vorgangsnummer', 'kennzeichen', 'status'];

  listDataAntragsteller: Observable<Antrag[]>;
  dataSourceAntragsteller: MatTableDataSource<unknown>;
  listDataBevollmaechtigter: Observable<Antrag[]>;
  dataSourceBevollmaechtigter: MatTableDataSource<unknown>;

  allUsedTypes = null;
  isLoading = false;
  deletionAllowed = false;

  dataSourceAntragstellerResultsLength = 0;
  dataSourceBevollmaechtigterResultsLength = 0;

  pageSize = 10;

  direction: SortDirection = 'desc';
  active = 'antragsdatum';

  @Input() fxFlexValue: string;
  @Input() loginText: string;

  subscription = new Subscription();
  private timer: ReturnType<typeof setTimeout>;

  protected readonly getStatusTypeText = getStatusTypeText;
  protected readonly AntragRole = AntragRole;

  constructor(
    private dialogRef: MatDialogRef<ConfirmDeleteAccountComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AccountDeleteData,
    private backend: BackendService,
  ) {}

  ngOnInit() {
    if (!this.data.deleteRequestDate) {
      this.isLoading = true;
      this.subscription.add(
        combineLatest([
          (this.listDataAntragsteller = this.reloadData(
            AntragRole.ANTRAGSTELLER,
            true,
          )),
          (this.listDataBevollmaechtigter = this.reloadData(
            AntragRole.BEVOLLMAECHTIGTER,
            false,
          )),
        ]).subscribe(([antragstellerAntrags, bevollmaechtigterAntrags]) => {
          this.resolveDeletionAllowed();
          this.dataSourceAntragsteller =
            setKenzeichenAgregateTypesForAntrag(antragstellerAntrags);
          this.dataSourceBevollmaechtigter =
            setKenzeichenAgregateTypesForAntrag(bevollmaechtigterAntrags);
          clearTimeout(this.timer);
          this.timer = setTimeout(() => {
            this.isLoading = false;
          }, 500);
        }),
      );
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  // TODO into util .. statusesForDeletion
  private deleteIsDisabled(antrag: Antrag): boolean {
    return (
      (antrag.status != 'A' &&
        antrag.status != 'B' &&
        antrag.status != 'S' &&
        antrag.status != 'C' &&
        antrag.status != '6' &&
        !antrag.deleteRequestDate) ||
      antrag.timeDeleted != null
    );
  }

  private reloadData(
    role: AntragRole,
    isAntragsteller: boolean,
  ): Observable<Antrag[]> {
    return merge().pipe(
      startWith({}),
      switchMap(() => {
        return this.backend.getAllAntraegeByEmail(this.data.email, role);
      }),
      map((data) => {
        if (isAntragsteller) {
          this.dataSourceAntragstellerResultsLength = data.count;
        } else {
          this.dataSourceBevollmaechtigterResultsLength = data.count;
        }
        return data.data;
      }),
      catchError(() => {
        this.isLoading = false;
        return observableOf([]);
      }),
    );
  }

  private resolveDeletionAllowed(): void {
    this.listDataAntragsteller.forEach((antrag) => {
      antrag.forEach((item) => {
        if (this.deleteIsDisabled(item)) {
          this.deletionAllowed = false;
          return;
        }
      });
    });

    this.listDataBevollmaechtigter.forEach((antrag) => {
      antrag.forEach((item) => {
        if (this.deleteIsDisabled(item)) {
          this.deletionAllowed = false;
          return;
        }
      });
    });
    this.deletionAllowed = true;
  }

  public close(): void {
    this.dialogRef.close('close');
  }

  public confirm(): void {
    this.dialogRef.close('confirm');
  }

  public displayedColumns(): string[] {
    return this.columnsAntragsteller;
  }
}
