<div fxFlex="100%" *ngIf="!isLoading && !isOpenAntragFailure" fxFlexFill>
  <div class="dialog-box" fxFlexFill fxLayoutAlign="center center">
    <div role="main" fxLayout fxLayoutAlign="center" fxLayout.lt-md="column">
      <mat-card
        fxFlex="65%"
        fxFlex.lt-md="100%"
        fxFlex.lt-lg="40%"
        fxFlex.lt-xl="50%"
        class="mat-elevation-z0 mat-card-none-shadow"
      >
        <div fxLayout="column" fxLayoutAlign="start">
          <!-- title -->
          <div>
            <mat-card-title
              class="mat-headline-1 less-space"
              title="Ihre Anträge Detail oder Meldungen an das BAFA.
Es können eingereichte Formulare bearbeitet."
            >
              Vorgang {{ this.kennzeichen }} {{ this.vorgangsnummer }}
            </mat-card-title>
          </div>

          <antrag-detail-status
            (openVnLinkEvent)="getVnLinkTarget($event)"
            [antragDetail]="antragDetail"
            [foerderbetrag]="detail?.foerderbetrag"
          ></antrag-detail-status>

          <mat-card-header class="mat-subtitle-2">
            Dokumente Hochladen:
          </mat-card-header>
          <mat-card-content class="mat-body-1" fxLayoutAlign="left">
            Hier haben Sie die Möglichkeit, weitere Dokumente im Format .pdf zu
            Ihrem Antrag hochzuladen.
          </mat-card-content>
          <mat-card-actions class="button-action-style less-space-top">
            <a
              [href]="this.antragDetail?.uploadLink"
              target="_blank"
              color="primary"
              aria-label="Upload"
              matTooltip="Dokumente zum Antrag hochladen"
            >
              <button
                mat-flat-button
                class="button mat-elevation-z5"
                type="button"
              >
                <mat-icon class="icon primary-dark">upload</mat-icon>
                Dokument hochladen
              </button>
            </a>
          </mat-card-actions>

          <mat-card-header class="mat-subtitle-2">
            Quittung herunterladen:
          </mat-card-header>
          <mat-card-content class="mat-body-1" fxLayoutAlign="left">
            Hier können Sie die Quittung Ihres Antrags herunterladen. Diese
            beinhaltet eine Übersicht über Ihren gestellten Antrag, inklusive
            eines Datums mit Eingangsbestätigung.
          </mat-card-content>
          <mat-card-actions class="button-action-style less-space-top">
            <a
              color="primary"
              aria-label="Download"
              matTooltip="Druckquittung herunterladen"
            >
              <button
                (click)="showPdf()"
                mat-flat-button
                class="button mat-elevation-z5"
                type="button"
                [disabled]="
                  this.antragDetail?.stornoWunsch !== null ||
                  antragDetail?.status === 'S'
                "
              >
                <mat-icon class="icon primary-dark">download</mat-icon>
                Quittung Antrag
              </button>
            </a>

            <a
              *ngIf="this.antragDetail?.viewVnLink !== null"
              color="primary"
              aria-label="Download Verwendungsnachweis"
            >
              <button
                (click)="showVnPdf(antragDetail)"
                *ngIf="this.antragDetail?.viewVnLink !== null"
                fxFlexOffset="150px"
                mat-flat-button
                class="button mat-elevation-z5"
                type="button"
                matTooltip="Verwendungsnachweis Druckquittung herunterladen"
                [disabled]="
                  this.antragDetail?.stornoWunsch !== null ||
                  antragDetail?.status === 'S'
                "
              >
                <mat-icon class="icon primary-dark">download</mat-icon>
                Quittung Verwendungsnachweis
              </button>
            </a>
          </mat-card-actions>

          <mat-card-header
            class="mat-subtitle-2"
            *ngIf="
              antragDetail.stornoWunsch !== false && this.isEnabledStorno()
            "
          >
            Stornierung des Antrags:
          </mat-card-header>
          <mat-card-content
            class="mat-body-1"
            fxLayoutAlign="left"
            *ngIf="
              antragDetail.stornoWunsch !== false && this.isEnabledStorno()
            "
          >
            Hier können Sie Ihren Antrag stornieren. Die Aktion ist nur vor der
            Einreichung des Verwendungsnachweises möglich.
          </mat-card-content>
          <mat-card-actions class="button-action-style less-space-top">
            <a
              matTooltip="Hier können Sie Ihren Antrag stornieren. Die Aktion ist nur vor der Einreichung
                    des Verwendungsnachweises möglich."
              *ngIf="
                (this.kennzeichen === 'BEGPT-FV' ||
                  this.kennzeichen === 'EBN-FV' ||
                  this.kennzeichen === 'EBW-FV') &&
                this.isEnabledStorno() &&
                antragDetail?.stornoWunsch !== false
              "
            >
              <button
                (click)="openStornoAntragDialog(this.antragDetail)"
                *ngIf="
                  this.isEnabledStorno() && antragDetail?.stornoWunsch !== false
                "
                mat-flat-button
                class="button mat-elevation-z5"
                type="button"
                [disabled]="
                  this.antragDetail?.stornoWunsch !== null ||
                  antragDetail?.status === 'S'
                "
              >
                <mat-icon>remove_circle_outline</mat-icon>
                Stronierung des Antrags
              </button>
            </a>
          </mat-card-actions>
        </div>
      </mat-card>

      <mat-card
        class="mat-elevation-z0 mat-card-detail mat-card-detail-main mat-card-none-shadow"
        fxFlex="35%"
        fxFlex.lt-lg="60%"
        fxFlex.lt-md="100%"
        fxFlex.lt-xl="50%"
        fxFlexFill
      >
        <mat-card class="mat-elevation-z5 mat-card-detail-person">
          <mat-card-header>
            Angaben zur antragstellenden Person
          </mat-card-header>
          <person-detail
            (savePersonEvent)="updateAntragsteller($event)"
            [showOrganisation]="
              originalData.antragsteller?.organization !== null
            "
            [isOrganizationRequired]="
              originalData.antragsteller?.organization !== null
            "
            [typeOfOzgService]="typeOfOzgService"
            [isAuthorized]="isAuthorized"
            [person]="antragsteller"
            [editAntragsteller]="manualEditAntragsteller"
            [edit]="isEdit && editAntragsteller"
            [loading]="isLoading"
            [vertrauensniveau]="detail?.vertrauensniveau"
          ></person-detail>

          <file-upload
            *ngIf="isEdit && editAntragsteller && isShowDokumentHochlade()"
            [person]="isAuthorized ? detail.antragsteller : antragsteller"
            (isSpeichernDisabled)="changeDisableSpeichern($event)"
          ></file-upload>

          <mat-card-actions
            *ngIf="!isEdit && antragDetail?.isBevollmaechtigter"
            class="card-actions button-action-style bearbeiten-action"
          >
            <button
              mat-flat-button
              class="button mat-elevation-z5"
              type="button"
              [disabled]="
                !isEnabledBearbeiten() ||
                antragDetail.stornoWunsch === true ||
                antragDetail.status === 'S'
              "
              (click)="this.openChangePersonDataDialog(true)"
            >
              <mat-icon class="icon primary-dark">edit</mat-icon>
              Bearbeiten
            </button>
          </mat-card-actions>

          <mat-card-header *ngIf="antragDetail?.isBevollmaechtigter">
            <div
              *ngIf="antragDetail?.isBevollmaechtigter && !editAntragsteller"
            >
              Angaben zur Bevollmächtigung
            </div>
          </mat-card-header>
          <person-detail
            *ngIf="antragDetail?.isBevollmaechtigter && !editAntragsteller"
            (savePersonEvent)="updateBevollmaechtigter($event)"
            [isOrganizationRequired]="
              originalData.bevollmaechtigter?.organization !== null
            "
            [typeOfOzgService]="typeOfOzgService"
            [showOrganisation]="
              originalData.bevollmaechtigter?.organization !== null
            "
            [isAuthorized]="isAuthorized"
            [person]="bevollmaechtigter"
            [edit]="isEdit && editBevollmaechtigter"
            [loading]="isLoading"
            [vertrauensniveau]="detail?.vertrauensniveau"
          ></person-detail>

          <file-upload
            *ngIf="isEdit && !editAntragsteller && isShowDokumentHochlade()"
            [person]="
              isAuthorized ? detail.bevollmaechtigter : bevollmaechtigter
            "
            (isSpeichernDisabled)="changeDisableSpeichern($event)"
          ></file-upload>
          <mat-card-actions
            class="card-actions button-action-style bearbeiten-action"
          >
            <button
              *ngIf="!isEdit"
              mat-flat-button
              class="button mat-elevation-z5"
              type="button"
              [disabled]="
                !isEnabledBearbeiten() ||
                antragDetail?.stornoWunsch === true ||
                antragDetail?.status === 'S'
              "
              (click)="
                this.openChangePersonDataDialog(
                  !antragDetail?.isBevollmaechtigter
                )
              "
            >
              <mat-icon class="icon primary-dark">edit</mat-icon>
              Bearbeiten
            </button>

            <div>
              <button
                *ngIf="isEdit"
                mat-flat-button
                class="button mat-elevation-z5"
                type="button"
                (click)="closeAntragChange()"
              >
                <mat-icon class="icon">close</mat-icon>
                Abbrechen
              </button>

              <button
                *ngIf="isEdit"
                mat-flat-button
                fxFlexOffset="60px"
                class="button mat-elevation-z5"
                type="button"
                [disabled]="
                  !isSaveEnabled ||
                  (this.isSpeichernDisabled && isShowDokumentHochlade())
                "
                (click)="saveAntragAndChangeEditState()"
              >
                <mat-icon class="icon">check</mat-icon>
                Speichern
              </button>
            </div>
          </mat-card-actions>
        </mat-card>
      </mat-card>
    </div>
  </div>
  <mat-card-actions class="card-actions button-action-style">
    <button
      mat-flat-button
      class="button"
      type="button"
      color="primary"
      [routerLink]="['/uebersicht']"
      [queryParams]="{
        bevollmaechtigterMode: this.antragDetail?.isBevollmaechtigter
          ? true
          : false,
      }"
    >
      Zur Übersicht
    </button>
  </mat-card-actions>
</div>

<div
  role="main"
  fxFlexFill
  fxLayout="row"
  fxLayoutAlign="center"
  *ngIf="!isLoading && isOpenAntragFailure"
>
  <div
    fxFlex="50%"
    fxFlex.md="100%"
    fxFlex.sm="100%"
    fxLayout="column"
    fxLayoutAlign="center"
  >
    <mat-card fxFlexOffset="50px" class="dialog-box">
      <mat-card-title
        fxFlex="40px"
        fxLayoutAlign="center"
        title="Ihre Anträge Detail oder Meldungen an das BAFA.
Es können eingereichte Formulare bearbeitet."
        *ngIf="!isLoading && isOpenAntragFailure"
      >
        Vorgang {{ this.kennzeichen }} {{ this.vorgangsnummer }}
      </mat-card-title>
      <mat-card-header
        class="text-color_orange"
        fxLayoutAlign="center"
        *ngIf="!isLoading && isOpenAntragFailure"
      >
        Antragsdaten wurden nicht erfolgreich gelesen.
      </mat-card-header>

      <mat-card-actions
        fxFlexOffset="20px"
        class="mat-dialog-actions"
        *ngIf="!isLoading && isOpenAntragFailure"
      >
        <button
          mat-flat-button
          class="button"
          type="button"
          color="primary"
          [routerLink]="['/uebersicht']"
          [queryParams]="{
            bevollmaechtigterMode: this.isBevollmaechtigterMode,
          }"
        >
          Zur Übersicht
        </button>
      </mat-card-actions>
    </mat-card>
  </div>
</div>
<div class="loading-shade" *ngIf="isLoading">
  <mat-spinner></mat-spinner>
</div>
