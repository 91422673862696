<form [formGroup]="form">
  <div
    class="content"
    role="main"
    fxLayout.xs="column"
    fxFlex="100%"
    *ngIf="!loading"
  >
    <mat-card
      *ngIf="edit"
      class="mat-elevation-z0 none-shadow"
      ngClass.gt-xs="overview-card"
      fxFlex="100%"
      fxFlex.md="100%"
      fxFlex.lt-md="100%"
    >
      <p>
        <mat-form-field *ngIf="showOrganisation" class="mat-body1">
          <mat-label>Organisation</mat-label>
          <label>
            <input
              matInput
              type="text"
              formControlName="organisation"
              maxlength="120"
              value="{{ person.organisation }}"
            />
          </label>
        </mat-form-field>
      </p>
      <p>
        <mat-form-field>
          <mat-label>Anrede</mat-label>
          <p>
            <label>
              <mat-select formControlName="anrede">
                <mat-option
                  *ngFor="let anrede of anredeEnum | keyvalue"
                  [value]="isAuthorized ? anrede.key : anrede.value"
                  >{{
                    isAuthorized ? getAntragAnrede(anrede.key) : anrede.value
                  }}</mat-option
                >
              </mat-select>
            </label>
          </p></mat-form-field
        >
      </p>

      <p>
        <mat-form-field>
          <mat-label>Vorname</mat-label>
          <label>
            <input
              formControlName="vorname"
              matInput
              type="text"
              maxlength="30"
              value="{{ person.vorname }}"
            />
          </label>
        </mat-form-field>

        <mat-form-field fxFlexOffset="20px">
          <mat-label>Nachname</mat-label>
          <label>
            <input
              matInput
              formControlName="nachname"
              type="text"
              maxlength="40"
              value="{{ person.nachname }}"
            />
          </label>
        </mat-form-field>
      </p>

      <p>
        <mat-form-field>
          <mat-label>Land</mat-label>
          <label>
            <mat-select formControlName="land" [disabled]="this.isAuthorized">
              <mat-option
                *ngFor="let country of countries"
                [value]="country.code"
              >
                {{ country.name }}
              </mat-option>
            </mat-select>
          </label>
        </mat-form-field>
      </p>

      <p>
        <mat-form-field>
          <mat-label>PLZ</mat-label>
          <label>
            <input
              matInput
              type="text"
              formControlName="plz"
              maxlength="12"
              [placeholder]="renderOrtInput() ? 'min. 5 Zeichen' : ''"
            />
            <mat-error *ngIf="form.get('plz').hasError('pattern')">
              Bitte geben Sie eine gültige PLZ ein
            </mat-error>
            <mat-error *ngIf="form.get('plz').hasError('required')">
              PLZ ist erforderlich
            </mat-error>
          </label>
        </mat-form-field>

        <mat-form-field
          fxFlexOffset="20px"
          *ngIf="!renderOrtInput() || isAuthorized"
        >
          <mat-label>Ort</mat-label>
          <label>
            <input matInput type="text" formControlName="ort" />
            <mat-error *ngIf="form.get('ort').hasError('required')">
              Ort ist erforderlich
            </mat-error>
          </label>
        </mat-form-field>

        <mat-form-field
          fxFlexOffset="20px"
          *ngIf="renderOrtInput() && !isAuthorized"
        >
          <mat-label>Ort</mat-label>
          <label>
            <mat-select formControlName="ort">
              <mat-option *ngFor="let city of cities" [value]="city">
                {{ city }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="form.get('ort').hasError('required')">
              Ort ist erforderlich
            </mat-error>
          </label>
        </mat-form-field>
      </p>

      <p>
        <mat-form-field>
          <mat-label>Straße</mat-label>
          <label>
            <input
              type="text"
              matInput
              [matAutocomplete]="autoStreet"
              formControlName="strasse"
            />
            <mat-autocomplete #autoStreet="matAutocomplete">
              <mat-option *ngFor="let street of streets" [value]="street">
                {{ street }}
              </mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="form.get('strasse').hasError('required')">
              Ort ist erforderlich
            </mat-error>
          </label>
        </mat-form-field>
        <mat-form-field fxFlexOffset="20px">
          <mat-label>Hausnummer</mat-label>
          <label>
            <input
              type="text"
              matInput
              [matAutocomplete]="autoHouseNumber"
              formControlName="hnr"
            />
            <mat-autocomplete #autoHouseNumber="matAutocomplete">
              <mat-option *ngFor="let building of buildings" [value]="building">
                {{ building }}
              </mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="form.get('strasse').hasError('required')">
              Hausnummer ist erforderlich
            </mat-error>
          </label>
        </mat-form-field>
      </p>

      <p>
        <mat-form-field>
          <mat-label>Vorwahl</mat-label>
          <label>
            <input
              matInput
              type="text"
              formControlName="vorwahl"
              maxlength="8"
            />
          </label>
        </mat-form-field>
        <mat-form-field fxFlexOffset="20px">
          <mat-label>Rufnummer</mat-label>
          <label>
            <input
              matInput
              type="text"
              formControlName="rufnummer"
              maxlength="50"
            />
            <mat-error *ngIf="form.get('rufnummer').hasError('required')">
              Rufnummer ist erforderlich
            </mat-error>
          </label>
        </mat-form-field>
      </p>

      <p>
        <mat-form-field>
          <mat-label>Komunikationskanal</mat-label>
          <label>
            <mat-select
              formControlName="outputChannel"
              (selectionChange)="resolveOutputChannel($event.value)"
            >
              <mat-option
                *ngFor="let channel of this.channelList"
                value="{{ channel.key }}"
              >
                {{ channel.description }}
              </mat-option>
            </mat-select>
          </label>
        </mat-form-field>
      </p>
    </mat-card>

    <mat-card
      *ngIf="!edit"
      class="mat-elevation-z0 none-shadow mat-caption"
      ngClass.gt-xs="overview-card"
      fxFlex.lt-md="100%"
    >
      <div fxLayout="row" fxLayout class="mat-body1">
        <mat-label *ngIf="showOrganisation" fxFlex="80px"
          >Organisation:</mat-label
        >
        <mat-label
          *ngIf="showOrganisation"
          fxFlexOffset="150px"
          class="text-field"
        >
          {{ person.organisation }}
        </mat-label>
      </div>

      <div fxLayout="row" fxLayout class="mat-body1">
        <mat-label fxFlex="80px">Anrede:</mat-label>
        <mat-label fxFlexOffset="150px" class="text-field">
          {{ getAntragAnrede(person.anrede) }}
        </mat-label>
      </div>

      <div fxLayout="row" fxLayout class="mat-body1">
        <mat-label fxFlex="80px">Vorname:</mat-label>
        <mat-label fxFlexOffset="150px" class="text-field">
          {{ person.vorname }}
        </mat-label>
      </div>

      <div fxLayout="row" fxLayout class="mat-body1">
        <mat-label fxFlex="80px">Nachname:</mat-label>
        <mat-label fxFlexOffset="150px" class="text-field">{{
          person.nachname
        }}</mat-label>
      </div>

      <div fxLayout="row" fxLayout class="mat-body1">
        <mat-label fxFlex="80px">Land:</mat-label>
        <mat-label fxFlexOffset="150px" class="text-field">
          {{ getSelectedCountryName(person.land) }}
        </mat-label>
      </div>

      <div fxLayout="row" fxLayout class="mat-body1">
        <mat-label fxFlex="80px">PLZ / Ort:</mat-label>
        <mat-label fxFlexOffset="150px" class="text-field">
          {{ person.plz }} / {{ person.ort }}
        </mat-label>
      </div>

      <div fxLayout="row" fxLayout class="mat-body1">
        <mat-label fxFlex="80px">Straße:</mat-label>
        <mat-label fxFlexOffset="150px" class="text-field">
          {{ person.strasse }}
        </mat-label>
      </div>

      <div fxLayout="row" fxLayout class="mat-body1">
        <mat-label fxFlex="80px">Hausnummer:</mat-label>
        <mat-label fxFlexOffset="150px" class="text-field">
          {{ person.hnr }}
        </mat-label>
      </div>

      <div fxLayout="row" fxLayout class="mat-body1">
        <mat-label fxFlex="80px">Vorwahl:</mat-label>
        <mat-label fxFlexOffset="150px" class="text-field">
          {{ person.vorwahl }}
        </mat-label>
      </div>

      <div fxLayout="row" fxLayout class="mat-body1">
        <mat-label fxFlex="80px">Rufnummer:</mat-label>
        <mat-label fxFlexOffset="150px" class="text-field">
          {{ person.rufnummer }}
        </mat-label>
      </div>

      <div fxLayout="row" fxLayout class="mat-body1">
        <mat-label fxFlex="80px">Rückkanal:</mat-label>
        <mat-label fxFlexOffset="150px" class="text-field">
          {{ getSelectedChannelName(person.outputChannel) }}
        </mat-label>
      </div>
    </mat-card>
  </div>
</form>
