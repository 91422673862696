<div class="overview-info-dialog">
  <mat-card>
    <mat-card-title>Legende für Antrag-Status</mat-card-title>
    <table mat-table [dataSource]="dataSource" fixedLayout=true>
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef>Status</th>
        <td mat-cell *matCellDef="let element"> {{element.status}} </td>
      </ng-container>
      <ng-container matColumnDef="explanation">
        <th mat-header-cell *matHeaderCellDef>Erläuterung</th>
        <td mat-cell *matCellDef="let element"> {{element.explanation}} </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <mat-card-actions align="end">
      <button mat-raised-button (click)="close()" color="primary">Schließen</button>
    </mat-card-actions>
  </mat-card>
</div>
