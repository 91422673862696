<div id="antragStatus">
  <mat-card-header class="mat-subtitle-2">Status</mat-card-header>

  <p-timeline [value]="antragStatusExtendedList">
    <ng-template #opposite let-event>
      <small>{{ event.date | date: dateFormatDe }}</small>
    </ng-template>
    <ng-template #marker let-event>
      <span [style]="{ color: event.color }">
        <mat-icon>{{ event.icon }}</mat-icon>
      </span>
    </ng-template>
    <ng-template #content let-event let-idx="index">
      <small>
        {{ event.name }}
      </small>
      <br />
      <small>
        <small>
          <div class="break-spaces">
            {{ event.description }}

            <mat-icon
              *ngIf="
                antragDetail?.status === '3' &&
                antragStatusExtendedList?.length ===
                  antragStatusExtendedList.indexOf(event) + 1
              "
              class="icon primary-dark"
              >post_add</mat-icon
            >
            {{ event.description2 }}
          </div>
          <button
            *ngIf="antragDetail.submitVnLink !== null && event.key === '3'"
            (click)="openVnLink(antragDetail)"
            mat-flat-button
            class="button"
            type="button"
            color="primary"
          >
            <mat-icon class="icon">post_add</mat-icon>
            Nachweis ausfüllen
          </button>
          <br />
          <span
            *ngIf="
              this.antragDetail?.stornoWunsch === true &&
              antragDetail?.status !== 'S' &&
              antragStatusExtendedList?.length ===
                antragStatusExtendedList.indexOf(event) + 1
            "
          >
            <a mat-icon-button disabled>
              <mat-icon>remove_circle_outline</mat-icon>
            </a>
            Ihr Stornierungswunsch ist eingegangen, Sie erhalten direkt vom BAFA
            eine Nachricht dazu.
          </span>
        </small>
      </small>
    </ng-template>
  </p-timeline>
</div>
