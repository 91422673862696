<div class="flex-column-center" role="main">

  <mat-card class="flex-column-center margin-50">
    <form [formGroup]="form" (ngSubmit)="onSubmit()" action="">

      <!-- title -->
      <mat-card-title class="flex-column-center mat-h1">
        Registrieren
      </mat-card-title>

      <mat-card-content>

        <!-- email -->
        <mat-form-field class="width-100" style="width: 400px" appearance="fill">
          <mat-label>E-Mail-Adresse</mat-label>
          <label>
            <input matInput required formControlName="email" type="email" placeholder="email@example.com" [errorStateMatcher]="matcher">
          </label>
          <mat-error *ngIf="!this.success">
            <p>
              Bitte geben Sie eine valide E-Mail-Adresse an.<br/>
            </p>
          </mat-error>
        </mat-form-field>
        <p>
          <br/>
        </p>

      <!-- buttons -->
      <mat-card-actions class="card-actions flex-column-center">
        <button mat-flat-button class="button" type="submit" color="primary">Registrierung starten</button>
      </mat-card-actions>
      </mat-card-content>

        <mat-card-subtitle class="flex-column-center">
        <p>Sie haben bereits einen Zugang?<br>
      <a [routerLink]="['/login']">Hier geht's zur Anmeldung</a></p>
      </mat-card-subtitle>
    </form>

  </mat-card>
</div>
