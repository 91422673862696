<mat-card-actions class="card-actions">
  <button
    mat-flat-button
    class="button mat-elevation-z5 button-action-style menu-button"
    type="button"
    color="primary"
    [textContent]="'NACHWEISDOKUMENT HOCHLADEN'"
    (click)="openDialog()"
  ></button>
</mat-card-actions>

<input
  id="files"
  type="file"
  class="visibility-hidden"
  accept="application/pdf"
  data-max-size="10240"
  multiple
  (click)="changeFileVals()"
  (change)="onChange($event)"
/>

<label *ngIf="files?.length < 1" class="mat-caption text-color_orange">
  <br />Für Ihre Namensänderung ist ein Nachweisdokument erforderlich.
</label>

<div *ngIf="files.length" class="file-section mat-caption">
  Übersicht der hochgeladenen Dokumente:
  <section *ngFor="let file of files; let i = index" [attr.data-index]="i">
    {{ i + 1 }}. Dokumentname: {{ file.name }} [{{ file.size / 1000 }} Kb]
    <button mat-icon-button (click)="removeSelectedFile(i)">
      <mat-icon>delete</mat-icon>
    </button>
  </section>
</div>
