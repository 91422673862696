<form [formGroup]="form">
  <div
    class="content"
    role="main"
    fxLayout.xs="column"
    fxFlex="100%"
    *ngIf="!loading"
  >
    <mat-card
      *ngIf="edit"
      class="mat-elevation-z0"
      ngClass.gt-xs="overview-card"
      fxFlex="100%"
      fxFlex.md="100%"
      fxFlex.lt-md="100%"
    >
      <p>
        <mat-form-field *ngIf="showOrganisation">
          <mat-label>Organisation</mat-label>
          <label>
            <input
              matInput
              type="text"
              formControlName="organisation"
              maxlength="120"
              value="{{ person.organisation }}"
            />
          </label>
        </mat-form-field>
      </p>
      <p>
        <mat-form-field>
          <mat-label>Anrede</mat-label>
          <p>
              <label>
                <mat-select  formControlName="anrede">
                  <mat-option *ngFor="let anrede of anredeEnum | keyvalue" [value]="isAuthorized ? anrede.key :
                  anrede.value">{{isAuthorized ? getAntragAnrede(anrede.key) : anrede.value}}</mat-option>
                </mat-select>
              </label>
        </mat-form-field>
      </p>

      <p>
        <mat-form-field style="margin-right: 20px">
          <mat-label>Vorname</mat-label>
          <label>
            <input
              formControlName="vorname"
              matInput
              type="text"
              maxlength="30"
              value="{{ person.vorname }}"
            />
          </label>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Name</mat-label>
          <label>
            <input
              matInput
              formControlName="nachname"
              type="text"
              maxlength="40"
              value="{{ person.nachname }}"
            />
          </label>
        </mat-form-field>
      </p>

      <p>
        <mat-form-field>
          <mat-label>Land</mat-label>
          <label>
            <mat-select  formControlName="land"
                         [disabled]="this.isAuthorized">
              <mat-option *ngFor="let country of countries" [value]="country.code" >{{country.name}}</mat-option>
            </mat-select>
          </label>
        </mat-form-field>
      </p>

      <p>
        <mat-form-field style="margin-right: 20px">
          <mat-label>Straße</mat-label>
          <label>
            <input
              matInput
              type="text"
              formControlName="strasse"
              maxlength="50"
            />
            <mat-error *ngIf="form.get('strasse').hasError('required')">
              Straße ist erforderlich
            </mat-error>
          </label>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Hausnummer</mat-label>
          <label>
            <input
              matInput
              type="text"
              formControlName="hnr"
              maxlength="10"
            />
          </label>
        </mat-form-field>
      </p>

      <p>
        <mat-form-field style="margin-right: 20px">
          <mat-label>PLZ</mat-label>
          <label>
            <input
              matInput
              type="text"
              formControlName="plz"
              maxlength="12"
              [placeholder]="renderOrtInput() ? 'min. 5 Zeichen' : ''"

            />
            <mat-error *ngIf="form.get('plz').hasError('pattern')">
              Bitte geben Sie eine gültige PLZ ein
            </mat-error>
            <mat-error *ngIf="form.get('plz').hasError('required')">
              PLZ ist erforderlich
            </mat-error>
          </label>
        </mat-form-field>


          <mat-form-field style="margin-right: 20px" *ngIf="!renderOrtInput() || isAuthorized">
            <mat-label>Ort</mat-label>
            <label>
              <input
                matInput
              type="text"
              formControlName="ort"
              />
              <mat-error *ngIf="form.get('ort').hasError('required')">
                Ort ist erforderlich
              </mat-error>
            </label>
          </mat-form-field>

        <mat-form-field style="margin-right: 20px" *ngIf="renderOrtInput() && !isAuthorized">
          <mat-label>Ort</mat-label>
          <label>
            <mat-select  formControlName="ort">
              <mat-option *ngFor="let city of cities" [value]="city" >{{city}}</mat-option>
            </mat-select>
            <mat-error *ngIf="form.get('ort').hasError('required')">
              Ort ist erforderlich
            </mat-error>
          </label>
        </mat-form-field>
      </p>

      <p>
        <mat-form-field style="margin-right: 20px">
          <mat-label>Vorwahl</mat-label>
          <label>
            <input
              matInput
              type="text"
              formControlName="vorwahl"
              maxlength="8"
            />
          </label>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Rufnummer</mat-label>
          <label>
            <input
              matInput
              type="text"
              formControlName="rufnummer"
              maxlength="50"
            />
            <mat-error *ngIf="form.get('rufnummer').hasError('required')">
              Rufnummer ist erforderlich
            </mat-error>
          </label>
        </mat-form-field>
      </p>

      <p>
        <mat-form-field>
          <mat-label>Komunikationskanal</mat-label>
          <label>
            <mat-select  formControlName="outputChannel" (selectionChange)="resolveOutputChannel($event.value)" >
              <mat-option *ngFor="let channel of this.channelList" value="{{channel.key}}" >{{channel.description}}</mat-option>
            </mat-select>
          </label>
        </mat-form-field>
      </p>
    </mat-card>

    <mat-card
      *ngIf="!edit"
      class="mat-elevation-z0"
      ngClass.gt-xs="overview-card"
      fxFlex.lt-md="100%"
    >
        <div fxLayout="row" fxLayout>
            <mat-label *ngIf="showOrganisation"  [style.width.%]="50">Organisation:</mat-label>
            <mat-label *ngIf="showOrganisation" [style.width.%]="50"
                       style="max-width: 250px; word-wrap:break-word;">{{person.organisation}}</mat-label>
        </div>

        <div fxLayout="row" fxLayout>
          <mat-label [style.width.%]="50">Anrede:</mat-label>
          <mat-label [style.width.%]="50" style="word-wrap: break-word;">{{getAntragAnrede(person.anrede)}}</mat-label>
      </div>

      <div fxLayout="row" fxLayout>
        <mat-label [style.width.%]="50">Vorname:</mat-label>
        <mat-label [style.width.%]="50" style="word-wrap: break-word;">{{person.vorname}}</mat-label>
      </div>

      <div fxLayout="row" fxLayout>
        <mat-label [style.width.%]="50">Name:</mat-label>
        <mat-label [style.width.%]="50" style="word-wrap: break-word;">{{person.nachname}}</mat-label>
      </div>

      <div fxLayout="row" fxLayout>
        <mat-label [style.width.%]="50">Land:</mat-label>
        <mat-label [style.width.%]="50" style="word-wrap: break-word;">{{getSelectedCountryName(person.land)}}</mat-label>
      </div>

      <div fxLayout="row" fxLayout>
        <mat-label [style.width.%]="50">PLZ / Ort:</mat-label>
        <mat-label [style.width.%]="50" style="word-wrap: break-word;">{{ person.plz }} / {{ person.ort }}</mat-label>
      </div>

      <div fxLayout="row" fxLayout>
        <mat-label [style.width.%]="50">Straße:</mat-label>
        <mat-label [style.width.%]="50" style="word-wrap: break-word;">{{person.strasse}}</mat-label>
      </div>

      <div fxLayout="row" fxLayout>
        <mat-label [style.width.%]="50">Hausnummer:</mat-label>
        <mat-label [style.width.%]="50" style="word-wrap: break-word;">{{person.hnr}}</mat-label>
      </div>

      <div fxLayout="row" fxLayout>
        <mat-label [style.width.%]="50">Vorwahl:</mat-label>
        <mat-label [style.width.%]="50" style="word-wrap: break-word;">{{person.vorwahl}}</mat-label>
      </div>

      <div fxLayout="row" fxLayout>
        <mat-label [style.width.%]="50">Rufnummer:</mat-label>
        <mat-label [style.width.%]="50" style="word-wrap: break-word;">{{person.rufnummer}}</mat-label>
      </div>

      <div fxLayout="row" fxLayout>
        <mat-label [style.width.%]="50">Rückkanal:</mat-label>
        <mat-label [style.width.%]="50" style="word-wrap: break-word;">{{getSelectedChannelName(person.outputChannel)}}</mat-label>
      </div>

    </mat-card>
  </div>
</form>
