import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Router} from "@angular/router";

@Component({
  selector: 'email-exist',
  templateUrl: './email-exist.component.html',
  styleUrls: ['./email-exist.component.css'],
})
export class EmailExistComponent {

  constructor(
    private dialogRef: MatDialogRef<EmailExistComponent>,
    public router: Router,
    @Inject(MAT_DIALOG_DATA) public data: string) {
  }

  close(): void {
    this.dialogRef.close('close');
  }

  confirm(): void {
    this.dialogRef.close('confirm');
    this.router.navigate(['../login']);
  }
}
