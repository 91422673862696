import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';

interface EmailIconField {
  key: string;
  name: string;
  icon: string;
  iconClass: string;
  color: string;
}

const emailStatusList = new Array<EmailIconField>();

emailStatusList.push({
  key: 'SEND',
  name: 'Gesendet',
  iconClass: 'material-icons',
  icon: 'mail_outline',
  color: 'color_green',
});
emailStatusList.push({
  key: 'DELIVERY',
  name: 'Erfolgreich gesendet',
  iconClass: 'material-icons',
  icon: 'mark_email_read',
  color: 'color_green',
});
emailStatusList.push({
  key: 'ERROR',
  name: 'Nicht gesendet',
  iconClass: 'material-icons',
  icon: 'mark_email_unread',
  color: 'color_red',
});
emailStatusList.push({
  key: 'FAILED',
  name: 'Fehler',
  iconClass: 'material-icons',
  icon: 'mark_email_unread',
  color: 'color_red_light',
});
emailStatusList.push({
  key: 'DOMAIN_NOT_ALLOWED',
  name: 'Empfänger E-Mail-Domäne nicht erlaubt',
  iconClass: 'material-icons-outlined',
  icon: 'mark_email_unread',
  color: 'color_red',
});
emailStatusList.push({
  key: 'NOT_AVAILABLE',
  name: 'E-Mail-Status unbekannt',
  iconClass: 'material-icons',
  icon: 'mail_outline',
  color: 'none',
});

@Component({
  selector: 'email-status-info',
  templateUrl: './email-status-info.component.html',
  standalone: false,
})
export class EmailStatusInfoComponent {
  displayedColumns: string[] = ['status', 'explanation'];
  dataSource = new MatTableDataSource(emailStatusList);

  constructor(public dialogRef: MatDialogRef<EmailStatusInfoComponent>) {}

  public close(): void {
    this.dialogRef.close();
  }
}
