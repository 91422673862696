
<mat-card class="mat-elevation-z0"  fxFlex.md="75%" fxFlex.lt-md="100%">
  <!-- title -->
  <mat-card-title>Antrag stornieren</mat-card-title>
  <mat-card-content>
    <p> Soll der Antrag {{data}} storniert werden?
    </p>
<p>
      Sie können innerhalb von vier Wochen von der Stornierung zurücktreten. Bitte laden Sie hierzu ein formloses Schreiben <br>
      mit dieser Mitteilung über das BAFA-Portal zum Antrag hoch. Nach Ablauf der vier Wochen ist keine Wiederaufnahme des  <br>
      Antrags möglich. Sollten Sie bereits einen Zuwendungsbescheid erhalten haben, wird eine Aufhebung des Bescheids veranlasst. <br>
      Sie erhalten hierzu einen separaten Aufhebungsbescheid. </p>
  </mat-card-content>
</mat-card>
<mat-dialog-actions align="end" >
  <button mat-raised-button (click)="confirm()">JA</button>
  <button mat-raised-button color="primary" (click)="close()" class="close-button">NEIN</button>
</mat-dialog-actions>
