<div class="overview-info-dialog">
  <mat-card>
    <mat-card-title>Legende für E-Mail -Status</mat-card-title>
    <table mat-table [dataSource]="dataSource" fixedLayout=true>
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef>Status</th>
        <td mat-cell *matCellDef="let element">
          <a mat-icon-button disabled>
          <mat-icon *ngIf="element.key !== 'FAILED'" class="center {{element.iconClass}} {{element.color}}">
          {{ element.icon }}
          </mat-icon>
            <img *ngIf="element.key === 'FAILED'" style="width:28px" src="icon_failed_red.svg" class="center">
          </a>
        </td>
      </ng-container>
      <ng-container matColumnDef="explanation">
        <th mat-header-cell *matHeaderCellDef>Erläuterung</th>
        <td mat-cell *matCellDef="let element"> {{element.name}} </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <mat-card-actions align="end">
      <button mat-raised-button (click)="close()" color="primary">Schließen</button>
    </mat-card-actions>
  </mat-card>
</div>
