
<mat-toolbar class="flex-column-center" fxLayoutAlign="center center" color="accent">
  <div class="flex-row" fxLayoutAlign="end center"
       fxFlex="70%" fxFlex.md="75%" fxFlex.lt-md="100%">

    <button mat-button class="menu-button" (click)="buildInfo()">Version</button>
    <a style="color: white;" class="menu-button" [href]="urlDatenschutz" target="BAFA-Datenschutzerklärung" mat-button>Datenschutzerklärung</a>
    <a style="color: white;" class="menu-button" [href]="urlImpressum" target="BAFA-Impressum" mat-button>Impressum</a>

  </div>
</mat-toolbar>
