
<mat-card class="mat-elevation-z0">
  <!-- title -->
  <mat-card-title>Rolle auswählen</mat-card-title>
  <mat-card-content>

    <div style="padding-top: 20px" fxLayout fxLayoutGap="121px" fxLayout.xs="row" fxLayoutAlign.xs="start start">
      <label>Ausgewählte Rolle: </label><br>
    <mat-radio-group color="primary" [(ngModel)]="typeOfAccountSelected" (change)="changeTypeOfAccountSelected()">
      <mat-radio-button style="padding-right: 5px" value="admin" aria-label="Administrator">Administrator</mat-radio-button><br>
      <mat-radio-button style="padding-right: 5px" value="super_user" aria-label="Super User">Super User</mat-radio-button><br>
      <mat-radio-button style="padding-right: 5px" value="benutzer" aria-label="Benutzer">Benutzer</mat-radio-button>
    </mat-radio-group>
    </div>

    <div style="padding-top: 35px" fxLayout fxLayoutGap="95px" fxLayout.xs="row" fxLayoutAlign.xs="start start">
      <label>Ausgewählte Verfahren:{{typeOfAccountSelected == 'super_user' ? '*':'' }} </label><br>

    <mat-radio-group>
      <mat-checkbox color="primary"
                    style="padding-right: 5px"
                    [checked]="allComplete()"
                    [disabled]="typeOfAccountSelected !== 'super_user'"
                    [indeterminate]="someComplete()"
                    (change)="setAll($event.checked)">
        {{task.name}}
      </mat-checkbox>
      <section *ngFor="let subtask of task.subtasks" class="example-section">
        <mat-checkbox style="padding-right: 5px"
                      color="primary"
                      [(ngModel)]="subtask.completed"
                      [disabled]="typeOfAccountSelected !== 'super_user'"
                      [value]="subtask.name" >
          {{subtask.name}}
        </mat-checkbox>
        </section>
      </mat-radio-group>
    </div>
  </mat-card-content>
  <mat-dialog-actions align="start" class="card-actions">
      <button mat-button type="button" (click)="close()" color="primary">Zurück</button>
      <button [disabled]="typeOfAccountSelected !== 'admin' && !someComplete() && !allComplete() && typeOfAccountSelected !=='benutzer'" (click)="confirm()" mat-raised-button color="primary" type="submit">SPEICHERN</button>
  </mat-dialog-actions>
</mat-card>
