import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  AntragDeleteData,
  ConfirmDeleteAntragComponent,
} from '../confirm-delete-antrag/confirm-delete-antrag.component';
import {
  AntragStatus,
  antragStatusList,
  resolveUsedTypes,
} from '../../shared/utils-antrags';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Observable, Subscription, interval } from 'rxjs';
import { Sort, SortDirection } from '@angular/material/sort';
import {
  dateFormatDe,
  dateTimeDeleteFormatDe,
  setKenzeichenAgregateTypesForAntrag,
  toUtcMoment,
} from '../../shared/utils';

import { AccountOverview } from '../../shared/data/account.overview';
import { ActivatedRoute } from '@angular/router';
import { AllowedTypes } from '../../shared/data/antrag.allowedTypes';
import { Antrag } from '../../shared/data/antrag';
import { AntragOverviewFilter } from '../antrag-overview-filter/antrag-overview-filter.component';
import { AntragOverviewResult } from '../../shared/data/antrag.overview.result';
import { AntragRole } from '../../shared/data/antrag.role';
import { AntragSearchParams } from '../../shared/data/antrag.search.params';
import { AntragStatusInfoComponent } from '../antrag-status-info/antrag-status-info.component';
import { BackendService } from '../../shared/backend.service';
import { ConfirmNewAntragComponent } from '../confirm-new-antrag/confirm-new-antrag.component';
import { ConfirmStornoAntragComponent } from '../confirm-storno-antrag/confirm-storno-antrag.component';
import { KennzeichenType } from '../../shared/utils-account';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { Moment } from 'moment';
import { OverviewRoutingData } from '../../shared/data/overview.routing.data';
import { OverviewStatus } from '../../shared/data/overview.status';
import { PageEvent } from '@angular/material/paginator';
import { environment } from '../../../environments/environment';
import moment from 'moment';
import { newAntragLink } from '../../shared/data/newAntragLink';

@Component({
  selector: 'antrag-overview',
  templateUrl: './antrag-overview.component.html',
  styleUrls: ['./antrag-overview.component.scss'],
  standalone: false,
})
export class AntragOverviewComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  filter: AntragOverviewFilter = new AntragOverviewFilter();

  dataSource: MatTableDataSource<unknown>;
  dateFormatDe = dateFormatDe;
  allUsedTypes: string[];

  statusList = antragStatusList;

  bevollmaechtigterMode = true;
  isLoading = true;
  isInRefreshWaitPeriod = false;

  resultsLength = 0;
  pageIndex = 0;
  pageSize = 10;

  direction: SortDirection = 'desc';
  active = 'antragsdatum';

  newAntragLinkType: AllowedTypes;
  newAntragLinkTypes: AllowedTypes[];
  allNewAntragLinks: newAntragLink[];
  actualNewLink: string;
  overviewResult: AntragOverviewResult;
  account: AccountOverview;
  usedTypeMap = new Array<KennzeichenType>();

  subscription = new Subscription();

  @Output() filterEvent: EventEmitter<string> = new EventEmitter();

  @Input() loginText: string;
  @Input() email: string;
  @Input() roles: AntragRole[] = [];
  @Input() vorgangsnummer: string;
  @Input() loadOverviewStatus: OverviewStatus;
  @Input() defaultKennzeichenFilterValue: string[];

  constructor(
    private backend: BackendService,
    private matDialog: MatDialog,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
    private cd: ChangeDetectorRef,
    private confirmNewAntragDialog: MatDialog,
    private confirmStornoAntragComponent: MatDialog,
  ) {}

  columnsBevollmaechtigter: string[] = [
    'antragsdatum',
    'vorgangsnummer',
    'kennzeichen',
    'antragsteller',
    'standort',
    'status',
    'links',
  ];

  columnsAntragsteller: string[] = [
    'antragsdatum',
    'vorgangsnummer',
    'kennzeichen',
    'standort',
    'status',
    'links',
  ];

  ngOnInit(): void {
    this.route.data.subscribe((param: OverviewRoutingData) => {
      // data might be set either via @Input (if component is created as modal dialog) or
      // via route params (if component is created via routing)
      if (param) {
        this.loginText = param.loginText || this.loginText;
        this.loadOverviewStatus =
          param.overviewStatus || this.loadOverviewStatus;
        if (this.loadOverviewStatus === OverviewStatus.byUser) {
          this.email = this.backend.getEmail();
          this.roles = this.backend.getAntragRoles();
        }
      }
    });
    this.backend
      .requestGetAccount(this.email)
      .forEach((data) => (this.account = data));
  }

  ngAfterViewInit(): void {
    // If the user changes the sort order or filtering, reset back to the first page.
    this.reloadData();
    this.cd.detectChanges();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  public reloadData(): void {
    this.isLoading = true;
    const subscriptionReload = this.getOverview({
      role: this.getRoleToSearch(),
      status: this.filter.status,
      kennzeichen: this.filter.kennzeichen,
      antragsdatumFrom: toUtcMoment(this.filter.dateFrom),
      antragsdatumTo: toUtcMoment(this.filter.dateTo),
      filterVorgangsnummer: this.filter.vorgangsnummer,
      filterAntragsteller: this.filter.antragsteller.trim().toLowerCase(),
      filterStandort: this.filter.standort.trim().toLowerCase(),
      sortBy: this.active,
      sortOrder: this.direction,
      pageNumber: this.pageIndex,
      pageSize: this.pageSize,
      allowedTypes: this.defaultKennzeichenFilterValue,
    } as AntragSearchParams).subscribe({
      next: (data) => {
        // Flip flag to show that loading has finished.
        this.isLoading = false;
        this.resultsLength = data.count;
        if (data.allowedTypes != null) {
          this.overviewResult = data;
          this.newAntragLinkTypes = data.allowedTypes;
          this.newAntragLinkType = data.allowedTypes[0];
          this.allNewAntragLinks = data.createAntragLinks;
          this.actualNewLink = this.allNewAntragLinks.filter(
            (link) =>
              link.isBevollmaechtigter &&
              link.verfahrenskennzeichen === this.newAntragLinkType.key,
          )[0].url;
        }
        if (this.allUsedTypes == null && data.allUsedTypes != null) {
          this.allUsedTypes = data.allUsedTypes;
          this.usedTypeMap = resolveUsedTypes(this.allUsedTypes);
        }
        this.dataSource = setKenzeichenAgregateTypesForAntrag(data.data);
        this.subscription.unsubscribe();
      },
      error: () => {
        this.showAlertMessage(
          'Das System ist vorübergehend nicht verfügbar. Bitte versuchen Sie es später erneut.',
        );
      },
      complete: () => {
        subscriptionReload.unsubscribe();
      },
    });
  }

  paginationChange(event: PageEvent): void {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    this.reloadData();
  }

  sortingChange(sort: Sort): void {
    this.direction = sort.direction;
    this.active = sort.active;

    // If the user changes the sort order, reset back to the first page.
    this.pageIndex = 0;
    this.reloadData();
  }

  // A 'focus' event is triggered when the browser window or tab becomes focused,
  // and if that happens the Antrag data should be fetched again.
  // (Losing focus triggers a 'blur' event, which we do not want to listen for here)
  // Automatic refresh is limited to once per minute to avoid perpetual reload
  @HostListener('window:focus', ['$event'])
  onFocus(): void {
    if (!this.isInRefreshWaitPeriod) {
      this.isInRefreshWaitPeriod = true;
      this.reloadData();
      const source = interval(60 * 1000);
      const subscription = source.subscribe({
        next: () => {
          this.isInRefreshWaitPeriod = false;
        },
        complete: () => {
          subscription.unsubscribe();
        },
      });
    }
  }

  enableAntragEdit(): boolean {
    return !this.isAdminMode();
  }

  displayedColumns(): string[] {
    if (this.isBevollmaechtigterMode()) {
      return this.columnsBevollmaechtigter;
    } else {
      return this.columnsAntragsteller;
    }
  }

  isBevollmaechtigterMode(): boolean {
    // if user has only one role, display overview accordingly. Otherwise, use toggle setting
    return (
      this.loadOverviewStatus !== OverviewStatus.byUser ||
      (this.roles.includes(AntragRole.BEVOLLMAECHTIGTER) &&
        this.bevollmaechtigterMode)
    );
  }

  getEditLinkTarget(element: Antrag): string {
    if (this.isAdminMode()) {
      return null;
    }
    return element.editLink;
  }

  getVnLinkTarget(element: Antrag): void {
    this.isLoading = true;

    if (this.isAdminMode()) {
      return;
    }

    const subscription = this.backend
      .getVnLinkDataExist(element.kennzeichen, element.vorgangsnummer)
      .subscribe({
        next: () => this.displayVnPage(element.submitVnLink),
        error: (error) =>
          this.handlePdfWarning(
            error,
            'Die Daten sind nicht verfügbar, bitte versuchen Sie es später erneut.',
          ),
        complete: () => {
          this.isLoading = false;
          subscription.unsubscribe();
        },
      });
  }

  displayVnPage(link: string): void {
    this.isLoading = false;
    window.open(link, '_blank');
  }

  isAdminMode(): boolean {
    return (
      this.loadOverviewStatus === OverviewStatus.byVorgangsnummer ||
      this.loadOverviewStatus === OverviewStatus.byEmail
    );
  }

  getRoleToSearch(): AntragRole {
    if (
      this.roles.includes(AntragRole.BEVOLLMAECHTIGTER) &&
      this.bevollmaechtigterMode
    ) {
      return AntragRole.BEVOLLMAECHTIGTER;
    }
    return AntragRole.ANTRAGSTELLER;
  }

  showBevollmaechtigterToggle(): boolean {
    return this.roles?.length > 1;
  }

  status(antrag: Antrag): AntragStatus {
    return this.statusList.find((s) => s.key === antrag.status);
  }

  getOverview(
    searchParams: AntragSearchParams,
  ): Observable<AntragOverviewResult> {
    switch (this.loadOverviewStatus) {
      case OverviewStatus.byUser:
        return this.backend.getOverview(searchParams);
      case OverviewStatus.byVorgangsnummer:
        return this.backend.getAntragByVorgangsnummer(this.vorgangsnummer);
      case OverviewStatus.byEmail:
        return this.backend.getOverviewByEmail(this.email, searchParams);
    }
  }

  showPdf(element: Antrag): void {
    this.isLoading = true;

    const subscription = this.backend.getPdf(element.viewLink).subscribe({
      next: (response) => this.displayPdf(response),
      error: (error) =>
        this.handlePdfWarning(
          error,
          'Druckquittung generierung fehlgeschlagen.',
        ),
      complete: () => {
        this.isLoading = false;
        subscription.unsubscribe();
      },
    });
  }

  private handlePdfWarning(
    error: HttpErrorResponse,
    defaultMessage: string,
  ): void {
    if (error.status !== 200) {
      this.isLoading = false;
      this.showAlertMessage(defaultMessage);
    }
  }

  private handlePdfSuccess(defaultMessage: string): void {
    this.isLoading = false;
    this.showSuccessMessage(defaultMessage);
  }

  showVnPdf(element: Antrag): void {
    this.isLoading = true;
    const subscription = this.backend.getPdf(element.viewVnLink).subscribe({
      next: (response) => this.displayPdf(response),
      error: (error) =>
        this.handlePdfWarning(
          error,
          'Verwendungsnachweis Druckquittung generierung fehlgeschlagen.',
        ),
      complete: () => {
        this.isLoading = false;
        subscription.unsubscribe();
      },
    });
  }

  displayPdf(response: HttpResponse<ArrayBuffer>): void {
    const contentType: string = response.headers.get('Content-Type');
    const blob = new Blob([response.body], { type: contentType });
    // we assume that header 'Content-Disposition' contains a value equal to 'filename='
    let fileName = response.headers.get('Content-Disposition');
    if (fileName) {
      fileName = fileName.slice(fileName.indexOf('=') + 1);
    }
    // simulate link click in order to set file name
    const linkElement = document.createElement('a');
    const url = window.URL.createObjectURL(blob);
    linkElement.setAttribute('href', url);
    linkElement.setAttribute('download', fileName);

    const clickEvent = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: false,
    });
    linkElement.dispatchEvent(clickEvent);
    this.handlePdfSuccess('Druckquittung erfolgreich heruntergeladen.');
  }

  updateVorgangsdaten(): void {
    switch (this.loadOverviewStatus) {
      case OverviewStatus.byEmail:
        const subscription1 = this.backend
          .updateVorgangsdaten(this.email)
          .subscribe({
            next: () => this.updateVorgangsdatenSuccess(),
            error: () => this.updateVorgangsdatenFailure(),
            complete: () => {
              subscription1.unsubscribe();
            },
          });
        break;
      case OverviewStatus.byVorgangsnummer:
        const subscription2 = this.backend
          .updateVorgangsNummer(this.vorgangsnummer)
          .subscribe({
            next: () => this.updateVorgangsdatenSuccess(),
            error: () => this.updateVorgangsdatenFailure(),
            complete: () => {
              subscription2.unsubscribe();
            },
          });
        break;
      default:
        break;
    }
  }

  private updateVorgangsdatenSuccess(): void {
    this.showSuccessMessage('Vorgangsdaten wurden erfolgreich aktualisiert.');
  }

  private updateVorgangsdatenFailure(): void {
    this.showAlertMessage('Vorgangsdaten wurden nicht aktualisiert.');
  }

  private showSuccessMessage(message: string): void {
    this.snackBar.open(message, '', {
      duration: 2000,
      panelClass: ['success'],
    });
  }

  private showAlertMessage(message: string): void {
    this.snackBar.open(message, 'X', {
      duration: 10000,
      panelClass: ['alert'],
    });
  }

  public showFilters(): boolean {
    return this.loadOverviewStatus !== OverviewStatus.byVorgangsnummer;
  }

  public showPagination(): boolean {
    return this.loadOverviewStatus !== OverviewStatus.byVorgangsnummer;
  }

  overViewInfo(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    this.matDialog.open(AntragStatusInfoComponent, dialogConfig);
  }

  enableNewAntrag(): boolean {
    return (
      environment?.enableNewAntrag &&
      this.newAntragLinkTypes != null &&
      this.newAntragLinkTypes.length > 0
    );
  }

  openNewAntragDialog(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = this.overviewResult;
    dialogConfig.disableClose = true;
    this.confirmNewAntragDialog.open(ConfirmNewAntragComponent, dialogConfig);
  }

  openStornoAntragDialog(antrag: Antrag): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = antrag.vorgangsnummer;
    dialogConfig.disableClose = true;
    const subscription = this.confirmStornoAntragComponent
      .open(ConfirmStornoAntragComponent, dialogConfig)
      .afterClosed()
      .subscribe({
        next: (dialogAction) => {
          if (dialogAction === 'confirm') {
            this.saveAntragStorno(antrag.vorgangsnummer);
          }
        },
        complete: () => {
          subscription.unsubscribe();
        },
      });
  }

  openDeleteAntragDialog(antrag: Antrag): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = new AntragDeleteData(
      antrag.deleteRequestDate,
      antrag.vorgangsnummer,
    );
    dialogConfig.disableClose = true;
    const subscription = this.confirmStornoAntragComponent
      .open(ConfirmDeleteAntragComponent, dialogConfig)
      .afterClosed()
      .subscribe({
        next: (dialogAction) => {
          if (dialogAction === 'confirm') {
            this.saveDeleteOrCancelRequest(
              antrag.vorgangsnummer,
              antrag.deleteRequestDate,
            );
          }
        },
        complete: () => {
          subscription.unsubscribe();
        },
      });
  }

  saveDeleteOrCancelRequest(vorgangsnummer: string, deleteRequestDate: Moment) {
    const subscription = this.backend
      .createOrCancelDeleteAntragRequest(vorgangsnummer)
      .subscribe({
        next: () => {
          if (deleteRequestDate) {
            this.cancelDeleteRequestSuccess();
          } else {
            this.deleteRequestSuccess();
          }
        },
        error: () => this.deleteRequestFailure(),
        complete: () => {
          subscription.unsubscribe();
        },
      });
  }

  saveAntragStorno(antrag: string) {
    const subscription = this.backend.stornoAntraege(antrag).subscribe({
      next: () => this.stornoSuccess(),
      error: () => this.stornoFailure(),
      complete: () => {
        subscription.unsubscribe();
      },
    });
  }

  private stornoSuccess(): void {
    this.reloadData();
    this.showSuccessMessage(
      'Die Stornierungsanfrage wurde erfasst. Über die Bearbeitung werden Sie informiert.',
    );
  }

  private deleteRequestSuccess(): void {
    this.reloadData();
    this.showSuccessMessage('Die Löschanforderung wurde erfasst.');
  }

  private cancelDeleteRequestSuccess(): void {
    this.reloadData();
    this.showSuccessMessage('Die Löschanforderung wurde storniert.');
  }

  private deleteRequestFailure(): void {
    this.reloadData();
    this.showAlertMessage(
      'Die Löschung ist fehlgeschlagen. Bitte versuchen Sie es später noch einmal.',
    );
  }

  private stornoFailure(): void {
    this.ngOnInit();
    this.showAlertMessage(
      'Die Stornierungsanfrage ist fehlgeschlagen. Bitte versuchen Sie es später noch einmal.',
    );
  }

  isEnabledStorno(): boolean {
    return environment?.enableStorno;
  }

  getLifeSpanInMonth(): number {
    return environment?.lifeSpanInMonth;
  }

  resolveDeleteTooltipText(antrag: Antrag): string {
    if (this.account?.deleteRequestDate) {
      return (
        'Der Antrag zur Kontolöschung wurde am ' +
        moment(this.account.deleteRequestDate).format(dateTimeDeleteFormatDe) +
        '  von ' +
        this.account.deleteRequestBy +
        ' eingereicht.'
      );
    }

    if (antrag.timeDeleted) {
      return 'Die Löschung des Antrages läuft, eine Stornierung ist nicht mehr möglich.';
    }
    if (antrag.deleteRequestDate) {
      return (
        'Löschung von ' +
        antrag.deleteRequestBy +
        ' am ' +
        moment(antrag.deleteRequestDate).format(dateTimeDeleteFormatDe) +
        ' beantragt.'
      );
    }
    if (this.deleteIsDisabled(antrag)) {
      return 'Der Antrag kann nur gelöscht werden, wenn er sich im Status ausgezahlt, abgeschlossen, abgelehnt, aufgehoben oder storniert befindet.';
    } else {
      return 'Löschung anfordern';
    }
  }

  deleteIsDisabled(antrag: Antrag): boolean {
    return (
      (antrag.status != 'A' &&
        antrag.status != 'B' &&
        antrag.status != 'S' &&
        antrag.status != 'C' &&
        antrag.status != '6' &&
        !antrag.deleteRequestDate) ||
      antrag.timeDeleted != null ||
      this.account?.deleteRequestDate != null
    );
  }

  onFilterEvent(filter: AntragOverviewFilter) {
    this.bevollmaechtigterMode = filter.bevollmaechtigterMode;
    this.filter = filter;
    this.pageIndex = 0;
    this.reloadData();
  }

  onUpdateVorgangDatenEvent() {
    this.updateVorgangsdaten();
  }
}
