import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexLayoutModule } from '@angular/flex-layout';

import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatMomentDateModule, MomentDateAdapter } from '@angular/material-moment-adapter';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatInputModule } from '@angular/material/input';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatRadioModule } from '@angular/material/radio';
import { MatDividerModule } from '@angular/material/divider';
import { MatCardModule } from '@angular/material/card';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';

import { MatPaginatorIntlDe } from './shared/i18n/i18n.mat.paginator';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MenuComponent } from './shared/menu/menu.component';
import { OverviewComponent } from './overview/overview.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { AuthInterceptor } from './shared/auth.interceptor';
import { FooterComponent } from './shared/footer/footer.component';
import { ActivationComponent } from './activation/activation.component';
import { AdministrationComponent } from './administration/administration.component';
import { RequestPasswordResetComponent } from './request-password-reset/request-password-reset.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ChangeEmailComponent } from './change-email/change-email.component';
import { EditWarningComponent } from './edit-warning/edit-warning.component';
import { OverviewAdminComponent } from './overview-admin/overview-admin.component';
import { BuildInfoComponent } from './shared/build-info/build-info.component';
import { OverviewInfoComponent } from './overview-info/overview-info.component';
import { ConfirmSendEmailComponent } from './confirm-send-email/confirm-send-email.component';
import { EmailExistComponent } from './email-exist/email-exist.component';
import {AntragDetailComponent} from "./antrag-detail/antrag-detail.component";
import {ClarityModule} from "@clr/angular";
import { PersonDetailComponent } from './person-detail/person-detail.component';
import { AntragDetailStatusComponent } from './antrag-detail-status/antrag-detail-status.component';
import {EmailIsPendingComponent} from './email-is-pending/email-is-pending.component';
import { InlineStylesCSPModule } from './inline-styles-csp/inline-styles-csp.module';
import {ConfirmNewAntragComponent} from "./confirm-new-antrag/confirm-new-antrag.component";
import {EmailStatusInfoComponent} from "./email-status-info/email-status-info.component";
import {MatCheckboxModule} from "@angular/material/checkbox";
import { ConfirmStornoAntragComponent } from './confirm-storno-antrag/confirm-storno-antrag.component';
import {LogoutComponent} from "./logout/logout.component";
import {ConfirmPersonalDataAuth} from "./confirm-personal-data-auth/confirm-personal-data-auth";
import {UploadComponent} from "./upload/upload.component";
import {OverviewAccountAuditComponent} from "./overview-account-audit/overview-account-audit.component";



// Date format to be used in date picker
export const DATE_FORMATS = {
  parse: {
    dateInput: 'L',
  },
  display: {
    dateInput: 'L',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@NgModule({
  declarations: [
    AppComponent,
    MenuComponent,
    OverviewComponent,
    OverviewAccountAuditComponent,
    LoginComponent,
    LogoutComponent,
    RegisterComponent,
    ChangePasswordComponent,
    AntragDetailComponent,
    PersonDetailComponent,
    AntragDetailStatusComponent,
    FooterComponent,
    ActivationComponent,
    AdministrationComponent,
    RequestPasswordResetComponent,
    ResetPasswordComponent,
    ChangeEmailComponent,
    EditWarningComponent,
    OverviewAdminComponent,
    BuildInfoComponent,
    OverviewInfoComponent,
    ConfirmSendEmailComponent,
    EmailExistComponent,
    EmailIsPendingComponent,
    ConfirmNewAntragComponent,
    EmailStatusInfoComponent,
    ConfirmStornoAntragComponent,
    ConfirmPersonalDataAuth,
    UploadComponent,
  ],
  imports: [
    // sorted alphabetically
    AppRoutingModule,
    InlineStylesCSPModule,
    BrowserAnimationsModule,
    BrowserModule,
    FlexLayoutModule,
    FormsModule,
    HttpClientModule,
    MatButtonModule,
    MatCardModule,
    //    MatCheckboxModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatFormFieldModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatMomentDateModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSelectModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatToolbarModule,
    MatTooltipModule,
    ReactiveFormsModule,
    RouterModule,
    ClarityModule,
    MatCheckboxModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: MatPaginatorIntl,
      useClass: MatPaginatorIntlDe
    },
    {
      provide: MAT_DATE_LOCALE,
      useValue: 'de-DE'
    },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: DATE_FORMATS
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
