<div fxLayout="column" fxFlexFill>

  <!-- menu -->
  <app-menu class="app-menu"></app-menu>

  <div fxFlex class="app-margin-top">
    <!-- page content -->
    <router-outlet></router-outlet>
  </div>

  <!-- footer -->
  <app-footer class="app-footer"></app-footer>

</div>
