<mat-divider [class]="resolveEnvironmentColor()"></mat-divider>
<!-- menu -->
<mat-toolbar class="menu-toolbar" fxLayoutAlign="center center">
  <div
    fxLayoutAlign="space-between start"
    fxFlex="71%"
    fxFlex.xs="100%"
    fxFlex.sm="96%"
    fxFlex.md="91%"
    fxFlex.lg="81%"
  >
    <!-- logo -->
    <a href="https://www.bafa.de/DE/Home/home_node.html" title="Zur Startseite">
      <img
        src="assets/svg/BAFA_2017_WebSVG_de.svg"
        class="bafa-logo light"
        alt="Logo Bundesamt fuer Wirtschaft und Ausfuhrkontrolle (Link zur Startseite)"
      />
      <img
        src="assets/svg/BAFA_2017_WebSVG_de_i.svg"
        class="bafa-logo dark"
        alt="Logo Bundesamt fuer Wirtschaft und Ausfuhrkontrolle (Link zur Startseite)"
      />
    </a>

    <!-- buttons -->
    <div
      *ngIf="isAuthenticated()"
      fxLayout.lt-sm="column"
      fxLayoutAlign.lt-sm="start start"
    >
      <button
        mat-button
        class="menu-button"
        color="accent"
        [matMenuTriggerFor]="account"
        [matTooltipDisabled]="!isAlias()"
        matTooltip="Nicht möglich für Alias-Accounts"
      >
        <mat-icon>settings</mat-icon>
        KONTOVERWALTUNG
      </button>
      <mat-menu #account="matMenu">
        <button
          mat-menu-item
          routerLink="/changePassword"
          [disabled]="isAlias()"
        >
          Passwort ändern
        </button>
        <button mat-menu-item routerLink="/changeEmail" [disabled]="isAlias()">
          E-Mail-Adresse ändern
        </button>
      </mat-menu>

      <button mat-button class="menu-button" color="accent" (click)="logout()">
        <mat-icon>exit_to_app</mat-icon>
        ABMELDEN
      </button>
      <!-- show login -->
      <mat-card-header fxLayout="column" fxLayoutAlign="center end">
        <span class="header-label">Angemeldet als: </span
        ><span class="header-data">{{ getEmail() }}</span>
        <span class="header-label" *ngIf="getFormularTypes()">Rolle: </span
        ><span class="header-data" *ngIf="getFormularTypes()"
          >{{ getAccountType() }} {{ getFormularTypes().join(', ') }}</span
        >
      </mat-card-header>
    </div>
  </div>
</mat-toolbar>

<!-- space title -->
<mat-toolbar
  class="bafa-serif mat-h1 no-margin"
  [class]="resolveEnvironmentColor()"
  fxLayoutAlign="center center"
>
  <div
    fxLayoutAlign="start center"
    fxFlex="71%"
    fxFlex.xs="100%"
    fxFlex.sm="96%"
    fxFlex.md="91%"
    fxFlex.lg="81%"
  >
    <a [routerLink]="['/home']" class="portal-link">BAFA Portal</a>
  </div>
</mat-toolbar>
<mat-toolbar
  *ngIf="notEmptyNotifications()"
  fxLayoutAlign="center start"
  class="notification-container"
>
  <div
    fxLayout="row"
    fxLayoutAlign="start center"
    fxFlex="71%"
    fxFlex.xs="100%"
    fxFlex.sm="96%"
    fxFlex.md="91%"
    fxFlex.lg="81%"
  >
    <ng-container>
      <marquee>
        <p class="notification">{{ notificationsMessage }}</p>
      </marquee>
    </ng-container>
  </div>
</mat-toolbar>
