<mat-card fxFlex="auto" class="mat-elevation-z0 mat-dialog-container mat-card-none-shadow">
  <!-- title -->
  <mat-card-title
    >Rolle auswählen</mat-card-title
  >
  <mat-card-content fxFlexOffset="30px">
    <div
      fxLayout
      fxLayout.xs="row"
      fxLayoutAlign.xs="start start"
      class="mat-body-1"
    >
      <label fxFlex="200px">Ausgewählte Rolle: </label><br />
      <mat-radio-group
        color="primary"
        [(ngModel)]="typeOfAccountSelected"
        (change)="changeTypeOfAccountSelected()"
      >
        <mat-radio-button value="admin" aria-label="Administrator"
          >Administrator</mat-radio-button
        ><br />
        <mat-radio-button value="super_user" aria-label="Super User"
          >Super User</mat-radio-button
        ><br />
        <mat-radio-button value="benutzer" aria-label="Benutzer"
          >Benutzer</mat-radio-button
        >
      </mat-radio-group>
    </div>

    <div
      fxLayout
      fxLayout.xs="row"
      fxLayoutAlign.xs="start start"
      class="mat-body-1"
    >
      <label  fxFlex="200px"
        >Ausgewählte Verfahren:{{typeOfAccountSelected == 'super_user' ? '*':''
        }} </label
      ><br />

      <mat-radio-group>
        <mat-checkbox
          color="primary"
          [checked]="allComplete()"
          [disabled]="typeOfAccountSelected !== 'super_user'"
          [indeterminate]="someComplete()"
          (change)="setAll($event.checked)"
        >
          {{task.name}}
        </mat-checkbox>
        <section *ngFor="let subtask of task.subtasks" class="example-section">
          <mat-checkbox
            color="primary"
            [(ngModel)]="subtask.completed"
            [disabled]="typeOfAccountSelected !== 'super_user'"
            [value]="subtask.name"
          >
            {{subtask.name}}
          </mat-checkbox>
        </section>
      </mat-radio-group>
    </div>
  </mat-card-content>
  <mat-dialog-actions>
    <div fxFlex="100%" fxLayout="row" fxLayoutAlign="space-between end">
      <button mat-button type="button" (click)="close()" color="primary">
        Zurück
      </button>
      <button
        [disabled]="typeOfAccountSelected !== 'admin' && !someComplete() && !allComplete() && typeOfAccountSelected !=='benutzer'"
        (click)="confirm()"
        mat-raised-button
        color="primary"
        type="submit"
      >
        Speichern
      </button>
    </div>
  </mat-dialog-actions>
</mat-card>
