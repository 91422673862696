import { Component, OnInit } from '@angular/core';
import { ErrorMessageMatcher, Field } from '../../shared/matcher.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

import { AccountOverview } from '../../shared/data/account.overview';
import { ActivatedRoute } from '@angular/router';
import { BackendService } from '../../shared/backend.service';
import { EmailExistComponent } from '../email-exist/email-exist.component';
import { EmailIsPendingComponent } from '../email-is-pending/email-is-pending.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';

@Component({
  selector: 'register',
  templateUrl: './register.component.html',
  standalone: false,
})
export class RegisterComponent implements OnInit {
  account: Observable<AccountOverview>;

  form: FormGroup;
  success: boolean;

  input = new Field();
  matcher = new ErrorMessageMatcher(this.input);

  constructor(
    public route: ActivatedRoute,
    private fb: FormBuilder,
    private backend: BackendService,
    private snackBar: MatSnackBar,
    private confirmSendMailDialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      email: [null, [Validators.required, Validators.email]],
    });
  }

  private register(email: string): void {
    this.backend.requestGetAccount(email).subscribe({
      next: (data) => this.openDialog(data),
      error: (error) =>
        error.status === 404
          ? this.createAccount()
          : this.showAlertMessage(
              'Der Registrierungsversuch war nicht erfolgreich. Versucht es erneut später.',
            ),
    });
  }

  private openDialog(email: AccountOverview): void {
    if (email.status !== 'PENDING') {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.data =
        'Ein Konto mit dieser E-Mail-Adresse ' +
        email.email +
        ' existiert bereits.';
      dialogConfig.disableClose = true;
      this.confirmSendMailDialog.open(EmailExistComponent, dialogConfig);
    } else {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.data = [
        email.email,
        'Ein Konto mit dieser E-Mail-Adresse ' +
          email.email +
          ' existiert bereits, ist aber noch nicht aktiviert. Aktivierungs-E-Mail erneut versenden?',
      ];
      dialogConfig.disableClose = true;
      this.confirmSendMailDialog.open(EmailIsPendingComponent, dialogConfig);
    }
  }

  private createAccount() {
    this.backend.requestPublicRegister(this.form.get('email').value).subscribe({
      next: () =>
        this.showSuccessMessage(
          'Es wurde eine E-Mail an die Adresse ' +
            this.form.get('email').value +
            ' gesendet. Bitte folgen Sie den Anweisungen dort, um Ihr Konto für das BAFA-Portal zu aktivieren. Bitte überprüfen Sie auch Ihren Spam-Ordner.',
        ),
      error: () =>
        this.showAlertMessage(
          'Der Registrierungsversuch war nicht erfolgreich. Versucht es erneut später.',
        ),
    });
  }

  private showAlertMessage(message: string): void {
    this.snackBar.open(message, 'X', {
      duration: 10000,
      panelClass: ['alert'],
    });
  }

  private showSuccessMessage(message: string): void {
    this.snackBar.open(message, 'X', { panelClass: ['success'] });
  }

  private noRegister(): void {
    this.success = false;
    this.input.error = 'error';
  }

  public onSubmit(): void {
    if (this.form.valid) {
      this.register(this.form.get('email').value);
    } else {
      this.noRegister();
    }
  }
}
