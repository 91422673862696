<div
  role="main"
  fxFlexFill
  fxFlex="100%"
  fxLayout="row"
  fxLayoutAlign="center center"
>
  <mat-card fxFlex="360px">
    <div
      fxFlexFill
      fxLayoutGap="20px"
      fxLayout="column"
      fxLayoutAlign="space-between center"
    >
      <mat-card-title class="mat-headline-1" fxFlexOffset="15px"
        >BAFA Portal</mat-card-title
      >
      <mat-card-content fxLayout="column" fxLayoutAlign="center center">
        <mat-icon
          class="primary-dark big-icon"
          fxFlex="55px"
          (click)="toggle()"
          >{{ icon }}</mat-icon
        >
        <div class="mat-caption">
          <p>
            ©{{ currentYear }} Bundesamt für Wirtschaft und Ausfuhrkontrolle
          </p>
        </div>
        <div class="mat-caption">
          <small>Version: {{ data.version }}</small>
        </div>
        <div class="mat-caption">
          <small>Datum: {{ data.date | date: dateTimeFormatDe }}</small>
        </div>
      </mat-card-content>
      <mat-dialog-actions fxFlex="90px" fxLayout="row" fxLayoutAlign="center">
        <button
          fxFlexOffset="40px"
          mat-raised-button
          color="primary"
          (click)="close()"
          class="close-button"
        >
          Schließen
        </button>
      </mat-dialog-actions>
    </div>
  </mat-card>
</div>
