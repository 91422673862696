<div
  fxLayout="column"
  fxLayoutAlign="space-evenly center"
  fxFlexFill
  class="overview-dialog-content-container"
>
  <mat-card-content fxLayout="column" fxFlexFill>
    <mat-card
      class="mat-elevation-z0 mat-dialog-container mat-card-none-shadow"
      [ngStyle]="{
        'max-width':
          data.deleteRequestDate ||
          (dataSourceBevollmaechtigterResultsLength === 0 &&
            dataSourceAntragstellerResultsLength === 0)
            ? '550px'
            : '750px',
        overflow: isLoading ? 'hidden' : '',
      }"
      fxFlex.md="65%"
      fxFlex.lt-md="65%"
    >
      <!-- title -->
      <div *ngIf="!isLoading">
        <mat-card-title *ngIf="!data.deleteRequestDate && !deletionAllowed">
          Das Konto {{ data.email }} kann nicht gelöscht werden, weil nicht alle
          Anträge löschbar sind.
        </mat-card-title>
        <mat-card-title *ngIf="!data.deleteRequestDate && deletionAllowed">
          Möchten Sie das Löschen des Kontos {{ data.email }} wirklich
          anfordern?
        </mat-card-title>
        <mat-card-title *ngIf="data.deleteRequestDate">
          Möchten Sie die Anforderung zum Löschen des Kontos
          {{ data.email }} zurücknehmen?
        </mat-card-title>
      </div>
      <div class="loading" *ngIf="isLoading">
        <mat-spinner></mat-spinner>
      </div>

      <mat-card-content class="text-left" *ngIf="!isLoading"
        ><br />
        <mat-card-subtitle
          class="mat-subtitle-1"
          *ngIf="
            !data.deleteRequestDate &&
            dataSourceAntragstellerResultsLength !== 0
          "
        >
          Als Antragsteller
          {{ dataSourceAntragstellerResultsLength > 1 ? 'sind' : 'ist' }}
          {{ dataSourceAntragstellerResultsLength }}
          {{ dataSourceAntragstellerResultsLength > 1 ? 'Anträge' : 'Antrag' }}
          unter diesem Account:
        </mat-card-subtitle>
        <!-- table -->
        <div
          class="delete-overview-table-container"
          *ngIf="
            !data.deleteRequestDate &&
            dataSourceAntragstellerResultsLength !== 0
          "
        >
          <table
            mat-table
            [dataSource]="dataSourceAntragsteller"
            class="mat-table-layout"
          >
            <ng-container matColumnDef="vorgangsnummer">
              <th mat-header-cell *matHeaderCellDef mat-header-row>
                Vorgangsnummer
              </th>
              <td mat-cell *matCellDef="let element" class="mat-body-1">
                {{ element.vorgangsnummer }}
              </td>
            </ng-container>

            <ng-container matColumnDef="kennzeichen">
              <th mat-header-cell *matHeaderCellDef mat-header-row>
                Verfahren
              </th>
              <td mat-cell *matCellDef="let element" class="mat-body-1">
                {{ element.kennzeichenText }}
              </td>
            </ng-container>

            <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef mat-header-row>Status</th>
              <td mat-cell *matCellDef="let element" class="mat-body-1">
                {{ getStatusTypeText(element.status).description }}
              </td>
            </ng-container>

            <tr
              mat-header-row
              *matHeaderRowDef="displayedColumns(); sticky: true"
            ></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns()"></tr>
          </table>
        </div>
        <br />

        <mat-card-subtitle
          class="mat-subtitle-1"
          *ngIf="
            !data.deleteRequestDate &&
            dataSourceBevollmaechtigterResultsLength !== 0
          "
        >
          Als Bevollmächtigte
          {{ dataSourceBevollmaechtigterResultsLength > 1 ? 'sind' : 'ist' }}
          {{ dataSourceBevollmaechtigterResultsLength }}
          {{
            dataSourceBevollmaechtigterResultsLength > 1 ? 'Anträge' : 'Antrag'
          }}
          unter diesem Account:
        </mat-card-subtitle>
        <!-- table -->
        <div
          class="delete-overview-table-container"
          *ngIf="
            !data.deleteRequestDate &&
            dataSourceBevollmaechtigterResultsLength !== 0
          "
        >
          <table
            mat-table
            [dataSource]="dataSourceBevollmaechtigter"
            class="mat-table-layout"
          >
            <ng-container matColumnDef="vorgangsnummer">
              <th mat-header-cell *matHeaderCellDef mat-header-row>
                Vorgangsnummer
              </th>
              <td mat-cell *matCellDef="let element" class="mat-body-1">
                {{ element.vorgangsnummer }}
              </td>
            </ng-container>

            <ng-container matColumnDef="kennzeichen">
              <th mat-header-cell *matHeaderCellDef mat-header-row>
                Verfahren
              </th>
              <td mat-cell *matCellDef="let element" class="mat-body-1">
                {{ element.kennzeichenText }}
              </td>
            </ng-container>

            <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef mat-header-row>Status</th>
              <td mat-cell *matCellDef="let element" class="mat-body-1">
                {{ getStatusTypeText(element.status).description }}
              </td>
            </ng-container>

            <tr
              mat-header-row
              *matHeaderRowDef="displayedColumns(); sticky: true"
            ></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns()"></tr>
          </table>
        </div>

        <br />
        <mat-card-subtitle
          class="mat-subtitle-1"
          *ngIf="
            !data.deleteRequestDate &&
            (dataSourceBevollmaechtigterResultsLength !== 0 ||
              dataSourceAntragstellerResultsLength !== 0)
          "
        >
          Die Anträge werden innerhalb von 24 Stunden endgültig gelöscht. Bis
          dahin ist das Abbrechen möglich.
        </mat-card-subtitle>
      </mat-card-content>

      <mat-dialog-actions
        fxLayoutAlign="{{
          !isLoading && !data.deleteRequestDate && !deletionAllowed
            ? 'center'
            : 'end'
        }}"
        *ngIf="!isLoading"
      >
        <button mat-raised-button (click)="close()">Abbrechen</button>
        <button
          *ngIf="!data.deleteRequestDate && deletionAllowed"
          mat-raised-button
          color="primary"
          (click)="confirm()"
        >
          Löschung anfordern
        </button>
        <button
          *ngIf="data.deleteRequestDate"
          mat-raised-button
          color="primary"
          (click)="confirm()"
        >
          Löschung stornieren
        </button>
      </mat-dialog-actions>
    </mat-card>
  </mat-card-content>
</div>
