import {HttpErrorResponse, HttpResponse} from '@angular/common/http';

import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output
} from '@angular/core';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {PageEvent} from '@angular/material/paginator';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Sort, SortDirection} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {ActivatedRoute, Router} from '@angular/router';
import moment from 'moment';
import {Moment} from 'moment';
import {interval, merge, Observable, ObservableInput, of, of as observableOf, throwError} from 'rxjs';
import {catchError, debounceTime, distinctUntilChanged, map, startWith, switchMap, tap} from 'rxjs/operators';
import {BackendService} from '../shared/backend.service';
import {AccountOverview} from '../shared/data/account.overview';
import {Antrag} from '../shared/data/antrag';
import {AntragOverviewResult} from '../shared/data/antrag.overview.result';
import {AntragRole} from '../shared/data/antrag.role';
import {AntragSearchParams} from '../shared/data/antrag.search.params';
import {OverviewRoutingData} from '../shared/data/overview.routing.data';
import {OverviewStatus} from '../shared/data/overview.status';
import {dateFormatDe, toUtcMoment, setKenzeichenAgregateTypesForAntrag} from "../shared/utils";
import {EditWarningComponent} from '../edit-warning/edit-warning.component';
import {OverviewInfoComponent} from '../overview-info/overview-info.component';
import { environment } from '../../environments/environment';
import {newAntragLink} from "../shared/data/newAntragLink";
import {ConfirmNewAntragComponent} from '../confirm-new-antrag/confirm-new-antrag.component';
import {AllowedTypes} from "../shared/data/antrag.allowedTypes";
import {ConfirmStornoAntragComponent} from "../confirm-storno-antrag/confirm-storno-antrag.component";

type AntragStatus = {
  key: string,
  description: string,
  tooltip: string,
  icon: string
};

const STATUS_LIST = new Array<AntragStatus>();

STATUS_LIST.push({
  key: '1',
  description: 'Eingegangen',
  tooltip: 'Antrag eingegangen',
  icon: 'mark_email_unread'
} as AntragStatus);
STATUS_LIST.push({
  key: '2',
  description: 'In Bearbeitung',
  tooltip: 'Antrag wird bearbeitet',
  icon: 'mark_email_read'
} as AntragStatus);
STATUS_LIST.push({
  key: '3',
  description: 'Genehmigt',
  tooltip: 'Zuwendungsbescheid erteilt',
  icon: 'check_circle'
} as AntragStatus);
STATUS_LIST.push({
  key: '4',
  description: 'VN eingegangen',
  tooltip: 'Verwendungsnachweis eingegangen',
  icon: 'mark_email_unread'
} as AntragStatus);
STATUS_LIST.push({
  key: '5',
  description: 'VN in Bearbeitung',
  tooltip: 'Verwendungsnachweis wird bearbeitet',
  icon: 'mark_email_read'
} as AntragStatus);
STATUS_LIST.push({
  key: '6',
  description: 'Ausgezahlt',
  tooltip: 'Förderung ausgezahlt',
  icon: 'euro_symbol'
} as AntragStatus);
STATUS_LIST.push({
  key: 'A',
  description: 'Abgeschlossen',
  tooltip: 'Antrag abgeschlossen',
  icon: 'done_all'
} as AntragStatus);
STATUS_LIST.push({key: 'B', description: 'Abgelehnt', tooltip: 'Antrag abgelehnt', icon: 'block'} as AntragStatus);
STATUS_LIST.push({
  key: 'C',
  description: 'ZWB aufgehoben',
  tooltip: 'Zuwendungsbescheid aufgehoben',
  icon: 'undo'
} as AntragStatus);
STATUS_LIST.push({key: 'D', description: 'Klageverfahren', tooltip: 'Klageverfahren', icon: 'gavel'} as AntragStatus);
STATUS_LIST.push({
  key: 'R',
  description: 'Rückforderung',
  tooltip: 'Förderung zurück gefordert',
  icon: 'undo'
} as AntragStatus);
STATUS_LIST.push({
  key: 'W',
  description: 'Widerspruch',
  tooltip: 'Widerspruch wird bearbeitet',
  icon: 'cancel'
} as AntragStatus);
STATUS_LIST.push({
  key: 'S',
  description: 'Storniert',
  tooltip: 'Storniert',
  icon: 'remove_circle_outline'
} as AntragStatus);
STATUS_LIST.push({
  key: 'X',
  description: 'Widerspruchsbescheid',
  tooltip: 'Widerspruchsbescheid erstellt',
  icon: 'announcement'
} as AntragStatus);

type KennzeichenType = {
  key: string,
  text: string,
};

var KENNZEICHEN_TYPE_MAP = new Array<KennzeichenType>();

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.css'],
})
export class OverviewComponent implements OnInit, AfterViewInit {

  private readonly EDIT_SUPPRESSED = 'suppressed';

  dataSource: MatTableDataSource<any>;
  filterVorgangsnummer = '';
  filterAntragsteller = '';
  filterStandort = '';
  dateFrom: Moment;
  dateTo: Moment;
  dateFormatDe = "";
  allUsedTypes = null;

  statusList = STATUS_LIST;
  kennzeichenTypeMap = KENNZEICHEN_TYPE_MAP;

  bevollmaechtigterMode = true;
  isLoading = true;
  isInRefreshWaitPeriod = false;

  minAntragsdatum = moment([2021, 0, 1]); // Start date 01.01.2021
  maxAntragsdatum = moment(); // today

  resultsLength = 0;
  pageIndex = 0;
  pageSize = 10;

  direction: SortDirection = 'desc';
  active = 'antragsdatum';

  statusFilterValue: string;

  kennzeichenFilterValue: string;

  newAntragLinkType: AllowedTypes;
  newAntragLinkTypes: AllowedTypes[];
  bevollmaechtigterModeForNewAntrag = false;
  allNewAntragLinks: newAntragLink[];
  actualNewLink: string;
  overviewResult: AntragOverviewResult;

  private timer: any;

  statusObservable: ObservableInput<any> = new Array(0);
  kennzeichenObservable: ObservableInput<any> = new Array(0);
  paginatorObservable: ObservableInput<any> = new Array(0);
  filterObservable: Observable<string>;

  @Output() filterEvent: EventEmitter<string> = new EventEmitter();
  @Output() refreshEvent: EventEmitter<void> = new EventEmitter();
  @Output() otherChangeEvent: EventEmitter<any> = new EventEmitter();


  @Input() fxFlexValue: string;
  @Input() loginText: string;

  @Input() email: string;
  @Input() roles: AntragRole[] = [];
  @Input() vorgangsnummer: string;
  @Input() loadOverviewStatus: OverviewStatus;
  @Input() defaultKennzeichenFilterValue: string[];


  constructor(
    private backend: BackendService,
    private matDialog: MatDialog,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
    private cd: ChangeDetectorRef,
    private confirmNewAntragDialog: MatDialog,
    private confirmStornoAntragComponent: MatDialog
  ) {
    this.dateFormatDe = dateFormatDe;

  }

  columnsBevollmaechtigter: string[] = [
    'antragsdatum',
    'vorgangsnummer',
    'kennzeichen',
    'antragsteller',
    'standort',
    'status',
    'links',
  ];

  columnsAntragsteller: string[] = [
    'antragsdatum',
    'vorgangsnummer',
    'kennzeichen',
    'standort',
    'status',
    'links',
  ];

  ngOnInit(): void {

    this.route.queryParams
      .subscribe(params => {
        if (params.bevollmaechtigterMode == 0) {
          this.bevollmaechtigterMode = false;
        }
        this.isBevollmaechtigterMode();
      });

    this.route.data.subscribe((param: OverviewRoutingData) => {
      // data might be set either via @Input (if component is created as modal dialog) or
      // via route params (if component is created via routing)
      if (param) {
        this.fxFlexValue = param.fxFlexValue || this.fxFlexValue;
        this.loginText = param.loginText || this.loginText;
        this.loadOverviewStatus = param.overviewStatus || this.loadOverviewStatus;
        if (this.loadOverviewStatus === OverviewStatus.byUser) {
          this.email = this.backend.getEmail();
          this.roles = this.backend.getAntragRoles();
        }
      }
    });
  }

  ngAfterViewInit(): void {

    // If the user changes the sort order or filtering, reset back to the first page.
    this.otherChangeEvent.subscribe(() => this.pageIndex = 0);

    // change of filter requires some preprocessing
    this.filterObservable = this.filterEvent
      .pipe(
        debounceTime(150),
        distinctUntilChanged(),
        tap(() => {
          this.pageIndex = 0;
        }));

    this.reloadData();
    this.cd.detectChanges();
  }

  private reloadData(): void {
    merge(
      this.paginatorObservable,
      this.refreshEvent,
      this.otherChangeEvent,
      this.statusObservable,
      this.filterObservable)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoading = true;
          return this.getOverview(this.email,
            {
              role: this.getRoleToSearch(),
              status: this.statusFilterValue,
              kennzeichen: this.kennzeichenFilterValue,
              antragsdatumFrom: toUtcMoment(this.dateFrom),
              antragsdatumTo: toUtcMoment(this.dateTo),
              filterVorgangsnummer: this.filterVorgangsnummer,
              filterAntragsteller: this.filterAntragsteller,
              filterStandort: this.filterStandort,
              sortBy: this.active,
              sortOrder: this.direction,
              pageNumber: this.pageIndex,
              pageSize: this.pageSize,
              allowedTypes: this.defaultKennzeichenFilterValue
            } as AntragSearchParams);
        }),
        map(data => {
          // Flip flag to show that loading has finished.
          this.isLoading = false;
          this.resultsLength = data.count;
          if (data.allowedTypes != null) {
            this.overviewResult = data;
            this.newAntragLinkTypes = data.allowedTypes;
            this.newAntragLinkType = data.allowedTypes[0];
            this.allNewAntragLinks = data.createAntragLinks;
            this.actualNewLink = this.allNewAntragLinks.filter(link => link.isBevollmaechtigter &&
              link.verfahrenskennzeichen === this.newAntragLinkType.key)[0].url;
          }
          if (this.allUsedTypes == null && data.allUsedTypes != null) {
            this.allUsedTypes = data.allUsedTypes;
            this.setAllUsedTypes();
          }
          return data.data;
        }),
        catchError(() => {
          this.isLoading = false;
          return observableOf([]);
        })
      ).subscribe(data =>
      this.dataSource = setKenzeichenAgregateTypesForAntrag(data));
  }

  setAllUsedTypes() {
    KENNZEICHEN_TYPE_MAP = new Array<KennzeichenType>();
    this.allUsedTypes.forEach(typeToAdd => {
      if (typeToAdd == "BEGEM-FV" || typeToAdd == "BEGEM2-FV") {
        if (KENNZEICHEN_TYPE_MAP.some(type => type.text === "BEG EM")) {
          return;
        }
        KENNZEICHEN_TYPE_MAP.push({
          key: 'BEGEM-FV',
          text:'BEG EM'} as KennzeichenType);
        return;
      }

      if (typeToAdd == "EBN-FV") {
        if (KENNZEICHEN_TYPE_MAP.some(type => type.text === "EBN")) {
          return;
        }
        KENNZEICHEN_TYPE_MAP.push({
          key: 'EBN-FV',
          text:'EBN'} as KennzeichenType);
        return;
      }

      if (typeToAdd == "EBW-FV") {
        if (KENNZEICHEN_TYPE_MAP.some(type => type.text === "EBW")) {
          return;
        }
        KENNZEICHEN_TYPE_MAP.push({
          key: 'EBW-FV',
          text:'EBW'} as KennzeichenType);
        return;
      }

      if (typeToAdd == "BEGEM-FV" || typeToAdd == "BEGEM2-FV") {
        if (KENNZEICHEN_TYPE_MAP.some(type => type.text === "BEG EM")) {
          return;
        }
        KENNZEICHEN_TYPE_MAP.push({
          key: 'BEGEM-FV',
          text:'BEG EM'} as KennzeichenType);
        return;
      }

      if (typeToAdd == "BEGPT-FV") {
        if (KENNZEICHEN_TYPE_MAP.some(type => type.text === "BEG PT")) {
          return;
        }
        KENNZEICHEN_TYPE_MAP.push({
          key: 'BEGPT-FV',
          text:'BEG PT'} as KennzeichenType);
        return;
      } else {
        if (KENNZEICHEN_TYPE_MAP.some(type => type.text === typeToAdd)) {
          return;
        }
        KENNZEICHEN_TYPE_MAP.push({
          key: typeToAdd,
          text:typeToAdd} as KennzeichenType);
      }
    });
    this.kennzeichenTypeMap = KENNZEICHEN_TYPE_MAP;
  }

  paginationChange(event: PageEvent): void {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    this.paginatorObservable = of(event);
    this.reloadData();
  }

  sortingChange(sort: Sort): void {
    this.direction = sort.direction;
    this.active = sort.active;

    // If the user changes the sort order, reset back to the first page.
    this.pageIndex = 0;
    this.reloadData();
  }

  selectionChange(value: string): void {
    this.statusFilterValue = value;

    // If the user changes filtering, reset back to the first page.
    this.pageIndex = 0;

    this.statusObservable = of(value);
    this.reloadData();
  }

  selectionChangeKennzeichen(value: string): void {
    this.kennzeichenFilterValue = value;

    // If the user changes filtering, reset back to the first page.
    this.pageIndex = 0;

    this.kennzeichenObservable = of(value);
    this.reloadData();
  }

  // A 'focus' event is triggered when the browser window or tab becomes focused,
  // and if that happens the Antrag data should be fetched again.
  // (Losing focus triggers a 'blur' event, which we do not want to listen for here)
  // Automatic refresh is limited to once per minute to avoid perpetual reload
  @HostListener('window:focus', ['$event'])
  onFocus(): void {
    if (!this.isInRefreshWaitPeriod) {
      this.isInRefreshWaitPeriod = true;
      this.refreshData();
      const source = interval(60 * 1000);
      const subscription = source.subscribe(() => {
        this.isInRefreshWaitPeriod = false;
        subscription.unsubscribe();
      });
    }
  }

  refreshData(): void {
    this.refreshEvent.emit();
  }

  enableAntragEdit(): boolean {
    return !this.isAdminMode();
  }

  applyFilterVorgnagsnummer(to = true) {
    if (this.filterVorgangsnummer.length > 7 || this.filterVorgangsnummer.length === 0) {
      if (to) {
        clearTimeout(this.timer);
        this.isLoading = true;

        this.timer = setTimeout(() => {
          this.filterEvent.emit(this.filterVorgangsnummer.trim().toLowerCase());
        }, 1000);
      } else {
        this.filterEvent.emit(this.filterVorgangsnummer.trim().toLowerCase());
      }
    }
  }

  applyFilterAntragsteller(to = true) {
    if (this.filterAntragsteller.length > 2 || this.filterAntragsteller.length === 0) {
      if (to) {
        clearTimeout(this.timer);
        this.isLoading = true;

        this.timer = setTimeout(() => {
          this.filterEvent.emit(this.filterAntragsteller.trim().toLowerCase());
        }, 5000);
      } else {
        this.filterEvent.emit(this.filterAntragsteller.trim().toLowerCase());
      }
    }
  }


  applyFilterStadort(to = true) {
    if (this.filterStandort.length > 2 || this.filterStandort.length === 0) {
      if (to) {
        clearTimeout(this.timer);
        this.isLoading = true;

        this.timer = setTimeout(() => {
          this.filterEvent.emit(this.filterStandort.trim().toLowerCase());
        }, 5000);
      } else {
        this.filterEvent.emit(this.filterStandort.trim().toLowerCase());
      }
    }
  }


  deleteFilterVorgangnummer(): void {
    this.filterVorgangsnummer = '';
    this.reloadData();
  }

  deleteFilterAntragsteller(): void {
    this.filterAntragsteller = '';
    this.reloadData();
  }

  deleteFilterStandort(): void {
    this.filterStandort = '';
    this.reloadData();
  }

  displayedColumns(): string[] {
    if (this.isBevollmaechtigterMode()) {
      return this.columnsBevollmaechtigter;
    } else {
      return this.columnsAntragsteller;
    }
  }

  isBevollmaechtigterMode(): boolean {
    // if user has only one role, display overview accordingly. Otherwise, use toggle setting
    return this.loadOverviewStatus !== OverviewStatus.byUser
      || this.roles.includes(AntragRole.BEVOLLMAECHTIGTER) && this.bevollmaechtigterMode;
  }

  getEditLinkTarget(element: Antrag): string {
    if (this.isAdminMode()) {
      return null;
    }
    return element.editLink;
  }

  isEditSuppressed(element: Antrag): boolean {
    return element.editLink === this.EDIT_SUPPRESSED;
  }

  isEditAllowed(element: Antrag): boolean {
    return element.editLink !== this.EDIT_SUPPRESSED && element.editLink != null;
  }

  getVnLinkTarget(element: Antrag): void {
    this.isLoading = true;

    if (this.isAdminMode()) {
      return;
    }
    // @ts-ignore
    this.backend.getVnLinkDataExist(element.kennzeichen, element.vorgangsnummer, (err: HttpErrorResponse) =>
      this.handlePdfWarning(err, 'Die Daten sind nicht verfügbar, bitte versuchen Sie es später erneut.'))
      .subscribe(response => this.displayVnPage(element.submitVnLink),

        error =>
          this.handlePdfWarning(error, 'Die Daten sind nicht verfügbar, bitte versuchen Sie es später erneut.'));
  }

  displayVnPage(link: string): void {
    this.isLoading = false;
    window.open(link, "_blank");
  }

  isAdminMode(): boolean {
    return this.loadOverviewStatus === OverviewStatus.byVorgangsnummer
      || this.loadOverviewStatus === OverviewStatus.byEmail;
  }

  getRoleToSearch(): AntragRole {
    if (this.roles.includes(AntragRole.BEVOLLMAECHTIGTER) && this.bevollmaechtigterMode) {
      return AntragRole.BEVOLLMAECHTIGTER;
    }
    return AntragRole.ANTRAGSTELLER;
  }

  showBevollmaechtigterToggle(): boolean {
    return this.roles?.length > 1;
  }

  status(antrag: Antrag): AntragStatus {
    return this.statusList.find(s => s.key === antrag.status);
  }

  getOverview(email: string, searchParams: AntragSearchParams): Observable<AntragOverviewResult> {
    switch (this.loadOverviewStatus) {
      case OverviewStatus.byUser:
        return this.backend.getOverview(searchParams);
      case OverviewStatus.byVorgangsnummer:
        return this.backend.getAntragByVorgangsnummer(this.vorgangsnummer);
      case OverviewStatus.byEmail:
        return this.backend.getOverviewByEmail(this.email, searchParams);
    }
  }


  showPdf(element: Antrag): void {
    this.isLoading = true;

    // @ts-ignore
    this.backend.getPdf((element.viewLink), (err: HttpErrorResponse) =>
      this.handlePdfWarning(err, 'Druckquittung generierung fehlgeschlagen.'))
      .subscribe(response => this.displayPdf(response),
        error =>
          this.handlePdfWarning(error, 'Druckquittung generierung fehlgeschlagen.'));
  }

  private handlePdfWarning(error: HttpErrorResponse, defaultMessage: string): void {
    if (error.status !== 200) {
      this.isLoading = false;
      this.showAlertMessage(defaultMessage);
    }
  }

  private handlePdfSuccess(defaultMessage: string): void {
    this.isLoading = false;
    this.showSuccessMessage(defaultMessage);

  }

  showVnPdf(element: Antrag): void {
    this.isLoading = true;
    this.backend.getPdf(element.viewVnLink).subscribe(response => this.displayPdf(response));
  }


  displayPdf(response: HttpResponse<ArrayBuffer>): void {
    const contentType: string = response.headers.get('Content-Type');
    const blob = new Blob([response.body], {type: contentType});
    // we assume that header 'Content-Disposition' contains a value equal to 'filename='
    let fileName = response.headers.get('Content-Disposition');
    if (fileName) {
      fileName = fileName.slice(fileName.indexOf('=') + 1);
    }
    // simulate link click in order to set file name
    const linkElement = document.createElement('a');
    const url = window.URL.createObjectURL(blob);
    linkElement.setAttribute('href', url);
    linkElement.setAttribute('download', fileName);

    const clickEvent = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: false
    });
    linkElement.dispatchEvent(clickEvent);
    this.handlePdfSuccess('Druckquittung erfolgreich runtergeladen');
  }

  onOtherChange($event: any): void {
    this.otherChangeEvent.emit($event);
  }

  updateVorgangsdaten(): void {
    switch (this.loadOverviewStatus) {
      case OverviewStatus.byEmail:
        this.backend
          .updateVorgangsdaten(this.email)
          .subscribe(stream => this.updateVorgangsdatenSuccess(stream), error => this.updateVorgangsdatenFailure(error));
        break;
      case OverviewStatus.byVorgangsnummer:
        this.backend
          .updateVorgangsNummer(this.vorgangsnummer)
          .subscribe(stream => this.updateVorgangsdatenSuccess(stream), error => this.updateVorgangsdatenFailure(error));
        break;
      default:
        break;
    }
  }

  private updateVorgangsdatenSuccess(account: AccountOverview): void {
    this.showSuccessMessage('Vorgangsdaten wurden erfolgreich aktualisiert.');
  }

  openEditWarning(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    this.matDialog.open(EditWarningComponent, dialogConfig);
  }

  private updateVorgangsdatenFailure(account: AccountOverview): void {
    this.showAlertMessage('Vorgangsdaten wurden nicht aktualisiert.');
  }

  private showSuccessMessage(message: string): void {
    this.snackBar.open(message, '',
      {duration: 2000, panelClass: ['success']});
  }

  private showAlertMessage(message: string): void {
    this.snackBar.open(message, 'X',
      {duration: 10000, panelClass: ['alert']});
  }

  public showFilters(): boolean {
    return this.loadOverviewStatus !== OverviewStatus.byVorgangsnummer;
  }

  public showPagination(): boolean {
    return this.loadOverviewStatus !== OverviewStatus.byVorgangsnummer;
  }

  overViewInfo(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    this.matDialog.open(OverviewInfoComponent, dialogConfig);
  }

  changeNewAntragLink(): void {
    if (this.bevollmaechtigterModeForNewAntrag) {
      this.actualNewLink = this.allNewAntragLinks.filter(link => link.isBevollmaechtigter === 'true' &&
        link.verfahrenskennzeichen === this.newAntragLinkType.key)[0].url;
    } else {
      this.actualNewLink = this.allNewAntragLinks.filter(link => link.isBevollmaechtigter === 'false' &&
        link.verfahrenskennzeichen === this.newAntragLinkType.key)[0].url;
    }
  }

  enableNewAntrag(): boolean {
    return environment?.enableNewAntrag && this.newAntragLinkTypes != null && this.newAntragLinkTypes.length > 0;
  }

  openNewAntragDialog(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = this.overviewResult;
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    this.confirmNewAntragDialog.open(ConfirmNewAntragComponent, dialogConfig)
  }

  openStornoAntragDialog(antrag: Antrag): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = antrag.vorgangsnummer;
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    this.confirmStornoAntragComponent.open(ConfirmStornoAntragComponent, dialogConfig).afterClosed()
      .subscribe(dialogAction => {
        if (dialogAction === 'confirm') {
          this.saveAntragStorno(antrag.vorgangsnummer);
        }
      });
  }

  saveAntragStorno(antrag: String) {
    this.backend
      .stornoAntraege(antrag)
      .subscribe(data => this.stornoSuccess(data), error => this.stornoFailure(error));
  }

  private stornoSuccess(a): void {
    this.showSuccessMessage('Die Stornierungsanfrage wurde erfasst. Über die Bearbeitung werden Sie informiert.');
    window.location.reload();
  }

  private stornoFailure(error: any): void {
    this.ngOnInit();
    this.showAlertMessage('Die Stornierungsanfrage ist fehlgeschlagen. Bitte versuchen Sie es später noch einmal');
  }

  isEnabledStorno(): boolean {
    return environment?.enableStorno;
  }

  getLifeSpanInMonth(): number {
    return environment?.lifeSpanInMonth;
  }
}
