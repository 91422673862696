import { Component, OnDestroy, OnInit } from '@angular/core';
import { BackendService } from '../backend.service';
import { Role } from '../data/role';
import { AccountTypes } from "../data/account.types";
import {getKenzeichenAgregateTypesForUserProfile} from "../utils";
import { NotificationsResult } from "../data/notifications";
import { interval, Subscription } from "rxjs";

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css'],
})
export class MenuComponent implements OnInit, OnDestroy {
  constructor(private backend: BackendService) { }

  notificationsMessage: string | null = null;
  private subscriptions: Subscription[] = [];
  private readonly REFRESH_INTERVAL_THREE_MIN = 180 * 1000;

  private readonly TEST_ROUTE = 'formular-demo';
  private readonly DEV_ROUTE = 'formular-development';

  private readonly PROD_COLOR = 'background-primary';
  private readonly TEST_COLOR = 'background-test';
  private readonly DEV_COLOR = 'background-dev';

  ngOnInit(): void {
    this.loadNotifications();
    const source = interval(this.REFRESH_INTERVAL_THREE_MIN);
    this.subscriptions.push(source.subscribe(() => {
      this.notificationsMessage = null; //Reset notification
      this.loadNotifications();
    }));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  private isRoute(route: string): boolean {
    return window.location.href.indexOf(route) > -1;
  }

  resolveEnvironmentColor(): string {
    return this.isRoute(this.TEST_ROUTE) ? this.TEST_COLOR : (this.isRoute(this.DEV_ROUTE) ? this.DEV_COLOR : this.PROD_COLOR);
  }

  logout(): void {
    this.backend.logout(false);
  }

  isAuthenticated(): boolean {
    return this.backend.isAuthenticated();
  }

  isAdmin(): boolean {
    return this.backend.getRole() === Role.Admin;
  }

  isAlias(): boolean {
    return this.backend.isAlias();
  }

  getEmail(): string {
    return this.backend.getEmail();
  }

  getAccountType(): string {
    return AccountTypes[this.backend.getAccountType()];
  }

  getFormularTypes(): Array<any> {
    return getKenzeichenAgregateTypesForUserProfile(this.backend.getFormuralTypes());
  }

  loadNotifications(): void {
    this.notificationsMessage = null;
    this.subscriptions.push(this.backend.getNotifications('ALL,NKB,ELSTER').subscribe(
      (data: NotificationsResult) =>
        this.notificationsMessage = data.notifications ? data.notifications.join(' ') + ' ' : null,
      (error) => {
      }
    ));
  }
}
