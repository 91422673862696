import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  KennzeichenType,
  getAccountStatusList,
  getRoleList,
} from '../../shared/utils-account';
import { Subject, Subscription, debounceTime } from 'rxjs';

import { AccountTypes } from '../../shared/data/account.types';
import { BackendService } from '../../shared/backend.service';
import { resolveUsedTypes } from '../../shared/utils-antrags';

export class AccountOverviewFilter {
  email: string;
  role: string;
  verfahren: string;
  status: string;
}

@Component({
  selector: 'account-overview-filter',
  templateUrl: './account-overview-filter.component.html',
  standalone: false,
})
export class AccountOverviewFilterComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input() allUsedTypes: string[];
  @Output() filterEvent: EventEmitter<AccountOverviewFilter> =
    new EventEmitter();

  filter: AccountOverviewFilter = new AccountOverviewFilter();

  statusList = getAccountStatusList();
  verfahrenList = new Array<KennzeichenType>();
  roleList = getRoleList();

  private subject: Subject<string> = new Subject();

  isSuperUser: boolean = false;

  subscription = new Subscription();

  constructor(private backend: BackendService) {}

  ngOnInit(): void {
    this.isSuperUser = this.loadIsSuperUser();

    this.subscription = this.subject.pipe(debounceTime(500)).subscribe(() => {
      this.filterEvent.emit(this.filter);
    });
  }

  ngOnChanges(): void {
    this.verfahrenList = resolveUsedTypes(this.allUsedTypes);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private loadIsSuperUser(): boolean {
    return (
      AccountTypes[this.backend.getAccountType()] == AccountTypes.SUPER_USER
    );
  }

  selectionChangeStatus(value: string): void {
    this.filter.status = value;
    this.filterEvent.emit(this.filter);
  }

  selectionChangeKennzeichen(value: string): void {
    this.filter.verfahren = value;
    this.filterEvent.emit(this.filter);
  }

  selectionChangeRolle(value: string): void {
    this.filter.role = value;
    this.filterEvent.emit(this.filter);
  }

  onFilterEmailKeyUp(): void {
    this.subject.next(null);
  }

  deleteFilter(): void {
    this.filter = {
      ...this.filter,
      email: null,
    };
    this.onFilterEmailKeyUp();
  }
}
