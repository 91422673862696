import { Component, NgZone, OnInit } from '@angular/core';
import { ErrorMessageMatcher, Field } from '../../shared/matcher.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { BackendService } from '../../shared/backend.service';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  standalone: false,
})
export class LoginComponent implements OnInit {
  form: FormGroup;
  hide = true;
  success: boolean;

  input = new Field();
  matcher = new ErrorMessageMatcher(this.input);

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private zone: NgZone,
    private backend: BackendService,
  ) {}

  ngOnInit(): void {
    if (this.backend.isAuthenticated()) {
      this.zone.run(() => {
        this.router.navigate(['../uebersicht']);
      });
    }

    this.form = this.fb.group({
      email: ['', Validators.required],
      password: ['', Validators.required],
    });
  }

  private noLogin(): void {
    this.success = false;
    this.input.error = 'error';
  }

  public authenticate(email: string, password: string): void {
    const token = this.backend.createToken(email, password);
    this.backend.auth().subscribe({
      next: (data) => this.backend.login(data, token, email),
      error: () => this.noLogin(),
    });
  }

  public onSubmit(): void {
    if (this.form.valid) {
      this.authenticate(
        this.form.get('email').value,
        this.form.get('password').value,
      );
    }
  }

  public enableRegistration(): boolean {
    return environment?.enableRegistration;
  }
}
