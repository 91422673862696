<mat-card class="mat-elevation-z0 mat-dialog-container mat-card-none-shadow">
  <!-- title -->
  <mat-card-title>Warnung</mat-card-title>
  <mat-card-content fxFlexOffset="30px" class="mat-caption">
    <p>{{ data }}</p>
  </mat-card-content>
</mat-card>
<mat-dialog-actions class="mat-dialog-actions" align="end">
  <button mat-raised-button (click)="confirm()">Zur Anmeldung</button>
  <button
    mat-raised-button
    color="primary"
    mat-dialog-close
    class="close-button"
  >
    Schließen
  </button>
</mat-dialog-actions>
