import { Component } from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {MatTableDataSource} from '@angular/material/table';

const STATUS_DATA = [
  {status: 'Eingegangen', explanation: 'Ihr Antrag ist beim BAFA eingegangen.'},
  { status: 'In Bearbeitung', explanation: 'Ihr Antrag befindet sich aktuell in Bearbeitung durch das BAFA.'},
  { status: 'Genehmigt', explanation: 'Ihr Antrag wurde genehmigt. Sie haben nun die Möglichkeit, einen Verwendungsnachweis für Ihren Antrag einzureichen. Durch Klick auf das Icon "VN einreichen" gelangen Sie in das entsprechende Formular.'},
  { status: 'VN eingegangen', explanation: 'Ihr Verwendungsnachweis ist beim BAFA eingegangen.'},
  { status: 'VN in Bearbeitung', explanation: 'Ihr Verwendungsnachweis befindet sich aktuell in Bearbeitung durch das BAFA.'},
  { status: 'Ausgezahlt', explanation: 'Der bewilligte Betrag wurde ausgezahlt.'},
  { status: 'Abgeschlossen', explanation: 'Der gesamte Vorgang ist abgeschlossen.'},
  { status: 'Abgelehnt', explanation: 'Ihr Antrag wurde abgelehnt.'},
  { status: 'ZWB aufgehoben', explanation: 'Ein bereits erteilter Zuwendungsbescheid wurde nachträglich aufgehoben.'},
  { status: 'Klageverfahren', explanation: 'Der Vorgang befindet sich in juristischer Klärung.'},
  { status: 'Rückforderung', explanation: 'Es ist eine Rückforderung bereits ausgezahlter Beträge anhängig.'},
  { status: 'Widerspruch', explanation: 'Ihr Widerspruch ist beim BAFA eingegangen.'},
  { status: 'Widerspruchbescheid', explanation: 'Ein neuer Bescheid wurde nach bearbeitetem Widerspruch erstellt.'},
  { status: 'Storniert', explanation: 'Ihr Antrag wurde storniert.'}
  ];


@Component({
  selector: 'app-overview-info',
  templateUrl: './overview-info.component.html',
  styleUrls: ['./overview-info.component.css']
})
export class OverviewInfoComponent {

  displayedColumns: string[] = ['status', 'explanation'];
  dataSource = new MatTableDataSource(STATUS_DATA);

  constructor(
    public dialogRef: MatDialogRef<OverviewInfoComponent>) {
  }

  close(): void {
    this.dialogRef.close();
  }
}
