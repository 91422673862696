import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  OnDestroy,
  Output
} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {OverviewAccountAuditData} from "../shared/data/overview.account.audit.data";
import {merge, Observable, ObservableInput, of, of as observableOf, Subscription} from "rxjs";
import {catchError, debounceTime, distinctUntilChanged, map, startWith, switchMap, tap} from "rxjs/operators";
import {BackendService} from "../shared/backend.service";
import {AccountAuditSearchParams} from "../shared/data/account.audit.search.params";
import {AccountAuditOverviewResult} from "../shared/data/account.audit.overview.result";
import {Sort, SortDirection} from "@angular/material/sort";
import {MatTableDataSource} from "@angular/material/table";
import moment, {Moment} from "moment/moment";
import {PageEvent} from "@angular/material/paginator";
import {dateTimeFormatDe, toUtcMoment} from "../shared/utils";


const AUDIT_MAP = new Array<AuditType>();

type AuditType = {
  key: string,
  text: string
};


type AuditFilterType = {
  dateFrom: Moment,
  dateTo: Moment,
  type: string,
  modifiedBy: string,
}

type paginatorType = {
  active: string,
  direction: SortDirection,
  pageNumber: number;
  pageSize: number;
  resultsLength: number;
}

AUDIT_MAP.push({key: 'ACTIVATION_LINK_ACCEPTED', text: 'Aktivierungslink akzeptiert'} as AuditType);
AUDIT_MAP.push({key: 'ACTIVATION_LINK_SEND', text: 'Aktivierungslink gesendet'} as AuditType);
AUDIT_MAP.push({key: 'ACCOUNT_OBSOLETED', text: 'Anfrage Konto löschen'} as AuditType);
AUDIT_MAP.push({key: 'LOGIN_SUCCESSFUL', text: 'Anmeldung erfolgreich'} as AuditType);
AUDIT_MAP.push({key: 'LOGIN_FAILED', text: 'Anmeldung fehlgeschlagen'} as AuditType);
AUDIT_MAP.push({key: 'DATA_UPDATE', text: 'Anträge aktualisiert'} as AuditType);
AUDIT_MAP.push({key: 'CHANGE_EMAIL_AUTOMATIC', text: 'E-Mail automatisch geändert'} as AuditType);
AUDIT_MAP.push({key: 'CHANGE_EMAIL_MANUALLY', text: 'E-Mail manuell ändern'} as AuditType);
AUDIT_MAP.push({key: 'UNLOCK_ACCOUNT', text: 'Konto entsperrt'} as AuditType);
AUDIT_MAP.push({key: 'ACCOUNT_CREATED', text: 'Konto erstellt'} as AuditType);
AUDIT_MAP.push({key: 'LOCK_ACCOUNT', text: 'Konto gesperrt'} as AuditType);
AUDIT_MAP.push({key: 'CHANGE_PASSWORD_MANUALLY', text: 'Passwort manuell geändert'} as AuditType);
AUDIT_MAP.push({key: 'PASSWORD_RESET', text: 'Passwort neusetzen'} as AuditType);
AUDIT_MAP.push({key: 'RESET_PASSWORD_LINK', text: 'Passwort zurücksetzen, Link gesendet'} as AuditType);
AUDIT_MAP.push({key: 'EDIT_ROLE', text: 'Rolle geändert'} as AuditType);


@Component({
  selector: 'app-overview-account-audit',
  templateUrl: './overview-account-audit.component.html',
  styleUrls: ['./overview-account-audit.component.css']
})
export class OverviewAccountAuditComponent implements AfterViewInit, OnDestroy  {

  @Output() filterEvent: EventEmitter<string> = new EventEmitter();
  @Output() refreshEvent: EventEmitter<void> = new EventEmitter();
  @Output() dateChangeEvent: EventEmitter<any> = new EventEmitter();
  subscription: Subscription = Subscription.EMPTY;

  paginatorObservable: ObservableInput<any> = new Array(0);
  filterObservable: Observable<string>;

  auditFilter =  ({dateFrom: null, dateTo: null, type: null, modifiedBy: null} as AuditFilterType);
  paginator = ({active: 'created', direction: 'desc', pageSize: 10, pageNumber: 0, resultsLength: 0} as paginatorType);

  minDateFrom = moment([2024, 0, 1]); // today

  maxDateTo = moment(); // today
  minDateTo = moment([2024, 0, 1]); // today

  dateTimeFormatDe: string = dateTimeFormatDe;
  isLoading: boolean = false;
  email: string;

  overviewAuditResult: AccountAuditOverviewResult;
  dataSource: MatTableDataSource<any>;

  displayedColumnsList: string[] = [
    'created',
    'type',
    'accountBy',
    'metadata'
  ];

  constructor(
    public dialogRef: MatDialogRef<OverviewAccountAuditComponent>,
    private backend: BackendService,
    private cd: ChangeDetectorRef,
  @Inject(MAT_DIALOG_DATA)
    public data: string) {
    this.email = data
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  ngAfterViewInit() {
    // If the user changes the sort order or filtering, reset back to the first page.
    this.subscription = this.dateChangeEvent.subscribe(() => this.paginator.pageNumber = 0);

    this.filterObservable = this.filterEvent
      .pipe(
        debounceTime(150),
        distinctUntilChanged(),
        tap(() => {
          this.paginator.pageNumber = 0;
        }));

    this.reloadData();
    this.cd.detectChanges();
  }

  refreshData(): void {
    this.refreshEvent.emit();
  }

  private reloadData(): void {
    merge(
      this.paginatorObservable,
      this.refreshEvent,
      this.dateChangeEvent,
    )
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoading = true;
          return this.getAccountAudit( this.email,
            {
              modifiedBy: this.auditFilter.modifiedBy,
              datumFrom: toUtcMoment(this.auditFilter.dateFrom),
              datumTo: toUtcMoment(this.auditFilter.dateTo),
              type: this.auditFilter.type,
              sortBy: this.paginator.active,
              sortOrder: this.paginator.direction,
              pageNumber: this.paginator.pageNumber,
              pageSize: this.paginator.pageSize,
            } as AccountAuditSearchParams)
        }),
        map(data => {
          // Flip flag to show that loading has finished.
          this.isLoading = false;
          this.paginator.resultsLength = data.count;

          this.overviewAuditResult = data;
          return data.data;
        }),
        catchError(() => {
          this.isLoading = false;
          return observableOf([]);
        })
      ).subscribe(data =>
      this.dataSource = this.setTypesTextForAccountAudit(data));
  }

  getAccountAudit(email: string, searchParams: AccountAuditSearchParams): Observable<AccountAuditOverviewResult> {
    return this.backend.getAccountAudit(searchParams, email);

  }

  setTypesTextForAccountAudit(auditData: OverviewAccountAuditData[]): MatTableDataSource<any> {
    const data = auditData;
    data.forEach(x => {
      x.typeText = AUDIT_MAP.find(s => s.key === x.type).text;

    });

    return new MatTableDataSource(data);
  }

  selectionTypeChange(value: string): void {
    this.auditFilter.type = value;
    // If the user changes filtering, reset back to the first page.
    this.paginator.pageNumber = 0;
    this.reloadData();
  }

  applyFilterModifiedBy(to = true) {
    if (this.auditFilter.modifiedBy.length > 4 || this.auditFilter.modifiedBy.length === 0) {
      if (to) {
        this.isLoading = true;

       setTimeout(() => {
          if (this.auditFilter.modifiedBy.length === 0) {
            this.auditFilter.modifiedBy = null;
          } else {
          this.filterEvent.emit(this.auditFilter.modifiedBy.trim().toLowerCase());
          }
          this.reloadData();
        }, 3000);
      } else {
        this.filterEvent.emit(this.auditFilter.modifiedBy.trim().toLowerCase());
        this.reloadData();
      }
    }
  }

  deleteFilterModifiedBy(): void {
    this.auditFilter.modifiedBy = null;
    this.reloadData();
  }

  sortingChange(sort: Sort): void {
    this.paginator.direction = sort.direction;
    this.paginator.active = sort.active;

    // If the user changes the sort order, reset back to the first page.
    this.paginator.pageNumber = 0;
    this.reloadData();
  }

  paginationChange(event: PageEvent): void {
    this.paginator.pageNumber = event.pageIndex;
    this.paginator.pageSize = event.pageSize;
    this.paginatorObservable = of(event);
    this.reloadData();
  }


  displayedColumns(): string[] {
    return this.displayedColumnsList;
  }

  dateFilterChangeEvent($event: any): void {
    this.minDateTo = this.auditFilter.dateFrom;
    this.dateChangeEvent.emit($event);
  }

  close(): void {
    this.dialogRef.close();
  }

  protected readonly AUDIT_MAP = AUDIT_MAP;
}
