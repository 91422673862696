import { ActivatedRoute, Router } from '@angular/router';
import { Component, NgZone, OnInit } from '@angular/core';
import { ErrorMessageMatcher, Field } from '../../shared/matcher.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { BackendService } from '../../shared/backend.service';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';

interface TextResources {
  title: string;
  description: string;
  submit: string;
  action: string;
  actionPastTense: string;
}

@Component({
  selector: 'reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
  standalone: false,
})
export class ResetPasswordComponent implements OnInit {
  form: FormGroup;
  success: boolean;
  linkUuid: string;
  textResources: TextResources;
  isLoading: boolean = false;

  static setPwResources = {
    title: 'Passwort vergeben',
    description: 'Vergeben Sie ein Passwort für Ihr BAFA Benutzerkonto.',
    submit: 'Passwort setzen',
    action: 'Vergeben',
    actionPastTense: 'vergeben',
  } as TextResources;

  static resetPwResources = {
    title: 'Passwort zurücksetzen',
    description: 'Ändern Sie das Passwort Ihres BAFA Benutzerkontos.',
    submit: 'Passwort ändern',
    action: 'Zurücksetzen',
    actionPastTense: 'geändert',
  } as TextResources;

  pwNew = new Field();

  matcherNew = new ErrorMessageMatcher(this.pwNew);

  constructor(
    private fb: FormBuilder,
    private backend: BackendService,
    private snackBar: MatSnackBar,
    private router: Router,
    private zone: NgZone,
    private route: ActivatedRoute,
  ) {
    this.textResources = ResetPasswordComponent.setPwResources;
  }

  ngOnInit(): void {
    this.linkUuid = this.route.snapshot.queryParamMap.get('link');
    if (this.linkUuid === null) {
      this.zone.run(() => {
        this.router.navigate(['../login']);
      });
    }

    this.route.data.subscribe((data) => {
      const isSetPassword = data['setPw'];
      if (isSetPassword) {
        this.textResources = ResetPasswordComponent.setPwResources;
      } else {
        this.textResources = ResetPasswordComponent.resetPwResources;
      }
    });
    this.form = this.fb.group({
      newPassword: ['', Validators.required],
    });
  }

  private changed(response: boolean): void {
    this.success = response;
    this.pwNew.error = '';
    this.processResult(this.success);
  }

  private notChanged(error: HttpErrorResponse): void {
    this.success = false;
    this.pwNew.error = error.error.errorNewPassword;
    this.processResult(this.success);
  }

  private processResult(success: boolean): void {
    if (success) {
      this.snackBar.open(
        'Passwort erfolgreich ' + this.textResources.actionPastTense + '.',
        '',
        { duration: 10000, panelClass: ['success'] },
      );
      this.zone.run(() => {
        this.router.navigate(['login']);
      });
    } else {
      this.snackBar.open(
        'Passwort konnte nicht ' +
          this.textResources.actionPastTense +
          ' werden.',
        '',
        { duration: 10000, panelClass: ['alert'] },
      );
    }
  }

  public onClick(): void {
    if (this.form.valid && !this.isLoading) {
      this.isLoading = true; // Start loading
      const newPassword = this.form.get('newPassword').value;

      this.backend.resetPassword(this.linkUuid, newPassword).subscribe({
        next: (response) => {
          this.changed(response);
        },
        error: (error: HttpErrorResponse) => {
          this.notChanged(error);
          this.isLoading = false;
        },
        complete: () => {
          this.isLoading = false; // Stop loading after response
        },
      });
    }
  }
}
