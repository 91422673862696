import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { BackendService } from '../../shared/backend.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'email-is-pending',
  templateUrl: './email-is-pendingcomponent.html',
  standalone: false,
})
export class EmailIsPendingComponent {
  forceSendMail = false;

  constructor(
    private dialogRef: MatDialogRef<EmailIsPendingComponent>,
    public router: Router,
    private snackBar: MatSnackBar,
    private backend: BackendService,
    @Inject(MAT_DIALOG_DATA) public data: string[],
  ) {}

  private sendMailSuccess(): void {
    this.showSuccessMessage('Erfolgreich E-Mail verschickt.');
    this.forceSendMail = false;
  }

  private showSuccessMessage(message: string): void {
    this.snackBar.open(message, '', {
      duration: 2000,
      panelClass: ['success'],
    });
  }

  private showAlertMessage(message: string): void {
    this.snackBar.open(message, 'X', {
      duration: 10000,
      panelClass: ['alert'],
    });
  }

  public close(): void {
    this.dialogRef.close('close');
  }

  public confirm(): void {
    this.dialogRef.close('confirm');
    this.backend
      .requestPublicActivate(this.data[0], this.forceSendMail)
      .subscribe({
        next: () => this.sendMailSuccess(),
        error: () =>
          this.showAlertMessage(
            'Aktivierungs Email wurde nicht gesendet. Versucht es erneut später.',
          ),
      });
  }
}
