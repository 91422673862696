import { ErrorStateMatcher } from '@angular/material/core';
import { FormGroupDirective, NgForm, FormControl } from '@angular/forms';

export class ErrorMessageMatcher implements ErrorStateMatcher {
  field: Field;
  constructor(field: Field) { this.field = field; }

  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return this.field.error !== '';
  }
}

export class Field {
  error = '';
  hide = true;
}
