
<mat-card class="mat-elevation-z0 mat-dialog-container mat-card-none-shadow"  fxFlex.md="75%" fxFlex.lt-md="100%">
  <!-- title -->
  <mat-card-title>Warnung</mat-card-title>
  <mat-card-content fxFlexOffset="30px" class="mat-caption">
    <p>{{data[1]}}</p>
  </mat-card-content>
</mat-card>
<mat-dialog-actions class="mat-dialog-actions" align="end" >
  <button mat-raised-button (click)="confirm()">Trotzdem Senden</button>
  <button mat-raised-button color="primary" (click)="close()" class="close-button">Schließen</button>
</mat-dialog-actions>
