import {Component, NgZone, OnInit} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BackendService } from '../shared/backend.service';
import { AccountStatus } from '../shared/data/account.status';

@Component({
  selector: 'app-activation',
  templateUrl: './activation.component.html',
  styleUrls: ['./activation.component.css']
})
export class ActivationComponent implements OnInit {

  success: boolean;

  constructor(
    private backend: BackendService,
    private route: ActivatedRoute,
    private zone: NgZone,
    private router: Router
  ) { }

  ngOnInit(): void {
    const uuid = this.route.snapshot.queryParamMap.get('link');
    if (uuid == null) {
      this.zone.run(() => {
        this.router.navigate(['/login']);
      });
    } else {
      this.backend.activateAccount(uuid).subscribe(stream => this.activated(stream), () => this.success = false);
    }
  }

  activated(response: AccountStatus): void {
    switch (response) {
      case AccountStatus.ACTIVE:
        this.success = true;
        break;
      case AccountStatus.PENDING:
        this.zone.run(() => {
          this.router.navigate(['setPassword'], {  queryParamsHandling: 'preserve' });
        });
        break;
      default:
        this.success = false;
        break;
    }
  }
}
