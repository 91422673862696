import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'confirm-storno-antrag',
  templateUrl: './confirm-storno-antrag.component.html',
})
export class ConfirmStornoAntragComponent {

  constructor(
    private dialogRef: MatDialogRef<ConfirmStornoAntragComponent>,
    @Inject(MAT_DIALOG_DATA) public data: string) {
  }

  close(): void {
    this.dialogRef.close('close');
  }

  confirm(): void {
    this.dialogRef.close('confirm');
  }
}
