import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';

/**
 * Provides pagination labels in German.
 */
@Injectable()
export class MatPaginatorIntlDe extends MatPaginatorIntl {
  itemsPerPageLabel = 'Elemente pro Seite';
  nextPageLabel = 'Nächste Seite';
  previousPageLabel = 'Vorherige Seite';

  getRangeLabel = (page: number, pageSize: number, length: number) => {
    if (length === 0 || pageSize === 0) {
      return '0 von ' + length;
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    // If the start index exceeds the list length, do not try and fix the end index to the end.
    const endIndex =
      startIndex < length
        ? Math.min(startIndex + pageSize, length)
        : startIndex + pageSize;
    return this.rangeLabel(startIndex + 1, endIndex, length);
  };

  rangeLabel(start: number, end: number, length: number): string {
    return this.label(start + ' - ' + end, length);
  }

  label(range: string, length: number): string {
    return range + ' von ' + length;
  }
}
