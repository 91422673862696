import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {BackendService} from "./backend.service";

@Injectable({
  providedIn: 'root'
})
export class FileUploadService {
  private baseUrl = '/BafaUserPortal/portal';

  constructor(private http: HttpClient,
              private backend: BackendService) { }

   async upload(files: Array<File>, vorgangsnummer: String, editAntragsteller: boolean, kennzeichen: string) {
     const formData: FormData = new FormData();

     for (var i = 0; i < files.length; i++) {
       formData.append("files", files[i]);
     }

     const response = await this.addFollowUpAttachment(formData, vorgangsnummer, editAntragsteller, kennzeichen);
     // @ts-ignore
     const status = response.status;

     return status;

   }


  async addFollowUpAttachment(file: FormData, vorgangsnummer: String, editAntragsteller: boolean, kennzeichen: string)  {

    const Http = new XMLHttpRequest();
    Http.open('POST', this.baseUrl + "/upload/" + vorgangsnummer + "/" + kennzeichen + "/" + editAntragsteller, true);
    Http.setRequestHeader("Authorization", "Basic " +  this.backend.getAuthToken())
    Http.send(file);


    if (Http.readyState === XMLHttpRequest.DONE) {
      return Http;
    }
    let res;
    const p = new Promise((r) => res = r);
    Http.onreadystatechange = () => {
      if (Http.readyState === XMLHttpRequest.DONE) {
        res(Http);
      }
    }
    return p;
    }

  }

