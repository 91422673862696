import { BackendService } from './backend.service';
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { NotificationService } from './notification.service';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptor implements HttpInterceptor {

  constructor(private backend: BackendService, private notification: NotificationService) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (this.backend.getAuthToken()) {
      const headers = {
        'Content-Type': request.headers.get('Content-Type') || 'application/json; charset=utf-8',
        'X-Requested-With': 'XMLHttpRequest',
        Authorization: `Basic ${this.backend.getAuthToken()}`,
        Accept: request.headers.get('Accept') || 'application/json'
      };

      request = request.clone({
        setHeaders: headers,
      });
    }

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          this.backend.logout(true);
          this.notification.showError('Anmeldung fehlgeschlagen.');
          // TODO return different Obsevable here, so that no error is displayed in the browser log
          return throwError(error);
        } else {
          return throwError(error);
        }
      })
    );
  }
}
