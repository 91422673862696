<div role="main" fxFlexFill fxLayout="row" fxLayoutAlign="center">
  <div
    fxFlex="60%"
    fxFlex.md="100%"
    fxFlex.sm="100%"
    fxLayout="column"
    fxLayoutAlign="center"
  >
    <mat-card class="dialog-box" fxFlexOffset="50px">
      <form [formGroup]="form" (ngSubmit)="onSubmit()" action="">
        <mat-card-title fxLayoutAlign="center">
          <h1>Passwort vergessen oder Konto noch nicht aktiviert?</h1>
        </mat-card-title>

        <mat-card-subtitle align="center" class="mat-body-1">
          Geben Sie die E-Mail-Adresse Ihres BAFA-Benutzerkontos ein.<br />
          Wir senden Ihnen eine E-Mail mit einem Link zum Zurücksetzen Ihres
          Passworts bzw. zur Aktivierung Ihres Kontos.
        </mat-card-subtitle>

        <mat-card-content>
          <!-- email -->
          <mat-form-field fxFlexFill appearance="fill">
            <mat-label>E-Mail-Adresse</mat-label>
            <label>
              <input
                matInput
                required
                formControlName="email"
                type="email"
                placeholder="email@example.com"
              />
            </label>
          </mat-form-field>
        </mat-card-content>

        <!-- buttons -->
        <mat-card-actions class="card-actions">
          <button
            mat-button
            class="button"
            type="button"
            color="primary"
            [routerLink]="['/login']"
          >
            Zurück
          </button>
          <button
            mat-flat-button
            class="button"
            type="submit"
            color="primary"
            [disabled]="!this.form.valid"
          >
            E-Mail anfordern
          </button>
        </mat-card-actions>
      </form>
    </mat-card>
  </div>
</div>
