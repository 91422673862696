import { Component, Inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { MatCardModule } from '@angular/material/card';
import { MatOptionModule } from '@angular/material/core';
import { NgForOf } from '@angular/common';
import { FlexModule } from '@ngbracket/ngx-layout';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { getKenzeichenAgregateTypesForUserProfile } from '../../shared/utils';
import { ChangeRoleDialogData } from '../../shared/data/changeRoleDialogData';
export interface Task {
  name: string;
  completed: boolean;
  subtasks?: Task[];
}

/**
 * @title Confirm role selection
 */
@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'change-role-dialog',
  templateUrl: './change-role-dialog.html',
  standalone: true,
  imports: [
    MatCheckboxModule,
    FormsModule,
    MatRadioModule,
    MatCardModule,
    MatOptionModule,
    NgForOf,
    FlexModule,
    MatButtonModule,
    MatDialogModule,
  ],
})
export class ChangeRoleDialogComponent {
  constructor(
    private dialogRef: MatDialogRef<ChangeRoleDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ChangeRoleDialogData,
  ) {
    this.typeOfAccountSelected = this.data.accountType.toLowerCase();
    if (this.data.accountType.toLowerCase() === 'benutzer') {
      this.initialiseAvailableTypes(true);
    } else {
      this.initialiseAvailableTypes(false);
    }
  }

  typeOfAccountSelected: string;
  kennzeichenTypeSubtask = new Array<Task>();

  task: Task = {
    name: 'Alle auswählen',
    completed: false,
    subtasks: this.kennzeichenTypeSubtask,
  };

  private initialiseAvailableTypes(resetSelection: boolean) {
    this.kennzeichenTypeSubtask = [];

    const data = getKenzeichenAgregateTypesForUserProfile(
      this.data.allUsedTypes,
    );

    data.forEach((typeToAdd) => {
      if (this.kennzeichenTypeSubtask.some((type) => type.name === typeToAdd)) {
        return;
      }
      this.kennzeichenTypeSubtask.push({
        name: typeToAdd,
        completed: resetSelection
          ? false
          : this.data.formularTypes?.indexOf(typeToAdd) > -1,
      } as Task);
    });
    this.task.subtasks = this.kennzeichenTypeSubtask;
  }

  public allComplete(): boolean {
    return (
      this.task.subtasks != null && this.task.subtasks.every((t) => t.completed)
    );
  }

  public changeTypeOfAccountSelected() {
    this.initialiseAvailableTypes(true);
  }

  public someComplete(): boolean {
    if (this.task.subtasks === null) {
      return false;
    }
    return (
      this.task.subtasks.filter((t) => t.completed).length > 0 &&
      !this.allComplete()
    );
  }

  public setAll(completed: boolean) {
    if (this.task.subtasks === null) {
      return;
    }
    this.task.subtasks.forEach((t) => (t.completed = completed));
  }

  public close(): void {
    this.setAll(false);
    this.dialogRef.close('close');
  }

  public confirm(): void {
    this.dialogRef.close({
      result: 'confirm',
      data: this.task.subtasks.filter((t) => t.completed),
      typeOfAccountSelected: this.typeOfAccountSelected,
    });
  }
}
