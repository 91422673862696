<head>
  <meta name="Clear-Site-Data" content="*"/>
</head>

<div class="flex-column-center" role="main">

  <mat-card class="flex-column-center margin-50">
    <form>
      <!-- title -->
      <mat-card-title class="flex-column-center mat-h1">
        Abgemeldet
      </mat-card-title>

      <mat-card-subtitle class="flex-column-center">
        Sie wurden abgemeldet. Um sich erneut anzumelden, klicken Sie auf Anmelden.
      </mat-card-subtitle>

      <!-- buttons -->
      <mat-card-actions class="card-actions">
        <button mat-button class="button" type="button" color="primary" [routerLink]="['/request-reset']">Probleme mit der Anmeldung?</button>
        <button mat-flat-button class="button" type="submit"  (click)="this.goToLogin()" color="primary">Anmelden</button>
      </mat-card-actions>
    </form>
  </mat-card>


</div>
