import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { Antrag } from '../shared/data/antrag';
import {environment} from "../../environments/environment";
import {Moment} from "moment";
import {ClrTimelineLayout, ClrTimelineStepState} from "@clr/angular";

type AntragStatus = {
  key: string,
  description: string,
  tooltip: string,
  icon: string
};

const STATUS_LIST = new Array<AntragStatus>();


STATUS_LIST.push({
  key: '1',
  description: 'Eingegangen',
  tooltip: 'Antrag eingegangen.',
  icon: 'mark_email_unread'
} as AntragStatus);
STATUS_LIST.push({
  key: '2',
  description: 'In Bearbeitung',
  tooltip: 'Antrag wird bearbeitet.',
  icon: 'mark_email_read'
} as AntragStatus);
STATUS_LIST.push({
  key: '3',
  description: 'Genehmigt',
  tooltip: 'Zuwendungsbescheid erteilt.',
  icon: 'check_circle'
} as AntragStatus);
STATUS_LIST.push({
  key: '4',
  description: 'VN eingegangen',
  tooltip: 'Ihr Verwendungsnachweis ist beim BAFA eingegangen.',
  icon: 'mark_email_unread'
} as AntragStatus);
STATUS_LIST.push({
  key: '5',
  description: 'VN in Bearbeitung',
  tooltip: 'Verwendungsnachweis wird bearbeitet.',
  icon: 'mark_email_read'
} as AntragStatus);
STATUS_LIST.push({
  key: '6',
  description: 'Ausgezahlt',
  tooltip: 'Förderung ausgezahlt.',
  icon: 'euro_symbol'
} as AntragStatus);
STATUS_LIST.push({
  key: 'A',
  description: 'Abgeschlossen',
  tooltip: 'Antrag abgeschlossen.',
  icon: 'done_all'
} as AntragStatus);
STATUS_LIST.push({ key: 'B', description: 'Abgelehnt', tooltip: 'Antrag abgelehnt', icon: 'block' } as AntragStatus);
STATUS_LIST.push({
  key: 'C',
  description: 'ZWB aufgehoben',
  tooltip: 'Zuwendungsbescheid aufgehoben.',
  icon: 'undo'
} as AntragStatus);
STATUS_LIST.push({ key: 'D', description: 'Klageverfahren', tooltip: 'Klageverfahren', icon: 'gavel' } as AntragStatus);
STATUS_LIST.push({
  key: 'R',
  description: 'Rückforderung',
  tooltip: 'Förderung zurück gefordert.',
  icon: 'undo'
} as AntragStatus);
STATUS_LIST.push({
  key: 'W',
  description: 'Widerspruch',
  tooltip: 'Widerspruch wird bearbeitet.',
  icon: 'cancel'
} as AntragStatus);
STATUS_LIST.push({
  key: 'X',
  description: 'Widerspruchsbescheid',
  tooltip: 'Widerspruchsbescheid erstellt.',
  icon: 'announcement'
} as AntragStatus);

@Component({
  selector: 'app-antrag-detail-status',
  templateUrl: './antrag-detail-status.component.html',
})

export class AntragDetailStatusComponent implements OnInit {

  @Input() antragDetail: Antrag;
  @Input() foerderbetrag: string;

  @Output() openVnLinkEvent: EventEmitter<Antrag> = new EventEmitter();

  antragDatum: Moment;

  notStarted: ClrTimelineStepState = ClrTimelineStepState.NOT_STARTED;
  clrLayout: ClrTimelineLayout = ClrTimelineLayout.VERTICAL;
  eingegagenStatus: ClrTimelineStepState = ClrTimelineStepState.NOT_STARTED;
  bearbeitungStatus: ClrTimelineStepState = ClrTimelineStepState.NOT_STARTED;
  genehmightStatus: ClrTimelineStepState = ClrTimelineStepState.NOT_STARTED;
  storniert: ClrTimelineStepState = ClrTimelineStepState.NOT_STARTED;
  vnStatus: ClrTimelineStepState = ClrTimelineStepState.NOT_STARTED;
  fourthStatusName: string;
  fourthtatusDecsription: string;

  ngOnInit(): void {
    this.resolveStatus(this.antragDetail.status);
  }

  resolveStatus(status: string): void {

    switch (status) {
      case "1":
        this.eingegagenStatus = ClrTimelineStepState.CURRENT;
        this.bearbeitungStatus = ClrTimelineStepState.NOT_STARTED;
        this.genehmightStatus = ClrTimelineStepState.NOT_STARTED;
        this.vnStatus = ClrTimelineStepState.NOT_STARTED;
        break;
      case "2":
        this.eingegagenStatus = ClrTimelineStepState.SUCCESS;
        this.bearbeitungStatus = ClrTimelineStepState.CURRENT;
        this.genehmightStatus = ClrTimelineStepState.NOT_STARTED;
        this.vnStatus = ClrTimelineStepState.NOT_STARTED;
        break;
      case "3":
        this.eingegagenStatus = ClrTimelineStepState.SUCCESS;
        this.bearbeitungStatus = ClrTimelineStepState.SUCCESS;
        this.genehmightStatus = ClrTimelineStepState.CURRENT;
        this.vnStatus = ClrTimelineStepState.NOT_STARTED;
        break;
      case "S":
        this.eingegagenStatus = ClrTimelineStepState.SUCCESS;
        this.bearbeitungStatus = ClrTimelineStepState.SUCCESS;
        this.storniert = ClrTimelineStepState.ERROR;
        break;
      case "4":
      case "5":
      case "6":
      case "A":
      case "B":
      case "C":
      case "D":
      case "R":
      case "W":
      case "X":
        this.eingegagenStatus = ClrTimelineStepState.SUCCESS;
        this.bearbeitungStatus = ClrTimelineStepState.SUCCESS;
        this.genehmightStatus = ClrTimelineStepState.SUCCESS;
        this.vnStatus = ClrTimelineStepState.CURRENT;
        this.fourthStatusName = STATUS_LIST.find(s => s.key === this.antragDetail.status).description;
        this.fourthtatusDecsription = STATUS_LIST.find(s => s.key === this.antragDetail.status).tooltip;
        break;
    }
  }

  public openVnLink(antrag: Antrag) {
    this.openVnLinkEvent.emit(antrag);
  }

  public getGermanPrice(number: String): string {
    return new Intl.NumberFormat('de-DE', {style: 'currency', currency: 'EUR'}).format(Number(number));
  }

  getLifeSpanInMonth(): number {
    return environment?.lifeSpanInMonth;
  }

  getTimeToDelete(): Date {
    if (this.getLifeSpanInMonth() == null) {
      return null;
    }
    return this.momentToDatePlusLifeSpan(this.antragDetail.lastStatusUpdated, this.getLifeSpanInMonth());
    }

  public momentToDatePlusLifeSpan(mom: Moment, lifeSpam: number): Date {

    if (mom !== null) {
      const [year, month, day] = String(mom).split('-');
      const date = new Date(+year, +month - 1 + lifeSpam, +day);
      return date
    }
    return null;
  }

    public momentToDate(mom: Moment): Date {

    if (mom !== null) {
      const [year, month, day] = String(mom).split('-');
      const date = new Date(+year, +month - 1, +day);
      return date
    }
    return null;
    }
}
