<!-- table filters -->
<div
  fxLayoutAlign="space-between end"
  fxLayoutGap="20px"
  fxLayout.lt-md="row"
  fxLayoutAlign.xs="center start"
>
  <div
    fxLayout="column"
    fxFlex="100"
    fxLayoutAlign="space-between stretch"
    fxLayoutAlign.lt-md="space-between center"
    fxLayoutGap="10px"
    fxLayoutAlign.lt-md="center center"
  >
    <!-- toggle table layout -->
    <div
      fxFlex
      fxLayout="column"
      fxLayoutAlign="center end"
      *ngIf="showBevollmaechtigterToggle()"
      fxLayoutGap="10px"
    >
      <div class="description mat-small">Anträge anzeigen als:</div>
      <mat-radio-group
        fxLayout="row"
        color="primary"
        [(ngModel)]="filter.bevollmaechtigterMode"
        aria-label="Anträge anzeigen als"
      >
        <mat-select
          fxFlex="auto"
          [(ngModel)]="filter.bevollmaechtigterMode"
          (selectionChange)="selectionBevollmaechtigterMode($event.value)"
        >
          <mat-option [value]="true" aria-label="Bevollmächtigter"
            >Bevollmächtigte Person</mat-option
          >
          <mat-option [value]="false" aria-label="Antragsteller"
            >Antragstellende Person</mat-option
          >
        </mat-select>
      </mat-radio-group>
    </div>

    <div
      fxLayout
      fxLayout.lt-md="row"
      fxLayoutAlign.lt-md="left start"
      fxFlexFill
    >
      <!-- search field -->

      <div
        fxFlex="100"
        fxLayoutGap="17px"
        fxFlexOffset.lt-md="20px"
        fxLayoutGap.lt-md="0px"
        fxLayoutAlign.lt-md="center left"
        fxLayout.lt-md="column"
      >
        <!-- date range -->
        <mat-form-field appearance="fill" fxFlex="16" fxFlex.lt-md="100">
          <mat-label>Antragsdatum von</mat-label>
          <input
            matInput
            [min]="minAntragsdatum"
            [max]="maxAntragsdatum"
            [matDatepicker]="fromPicker"
            [(ngModel)]="filter.dateFrom"
            (dateChange)="selectionDateFrom($event.value)"
            placeholder="Antragsdatum von"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="fromPicker"
          ></mat-datepicker-toggle>
          <mat-datepicker #fromPicker></mat-datepicker>
        </mat-form-field>

        <mat-form-field appearance="fill" fxFlex="16" fxFlex.lt-md="100">
          <mat-label>Antragsdatum bis</mat-label>
          <input
            matInput
            [min]="minAntragsdatum"
            [max]="maxAntragsdatum"
            [matDatepicker]="toPicker"
            [(ngModel)]="filter.dateTo"
            (dateChange)="selectionDateTo($event.value)"
            placeholder="Antragsdatum bis"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="toPicker"
          ></mat-datepicker-toggle>
          <mat-datepicker #toPicker></mat-datepicker>
        </mat-form-field>

        <mat-form-field
          *ngIf="filter.showFilters"
          appearance="fill"
          fxFlex="15"
          fxFlex.lt-md="100"
        >
          <mat-label>Vorgangsnummer</mat-label>
          <label>
            <input
              matInput
              type="text"
              placeholder="Suchbegriff (min. 8 Zeichen)"
              [(ngModel)]="filter.vorgangsnummer"
              (keyup)="applyFilterVorgnagsnummer()"
            />
          </label>
          <button
            *ngIf="filter.vorgangsnummer"
            matSuffix
            mat-icon-button
            aria-label="Clear"
            (click)="deleteFilterVorgangnummer()"
          >
            <mat-icon matTooltip="Filter löschen">close</mat-icon>
          </button>
          <button
            *ngIf="!filter.vorgangsnummer"
            matSuffix
            mat-icon-button
            disabled
          >
            <mat-icon matTooltip="Tabelle nach Suchbegriff filtern"
              >search</mat-icon
            >
          </button>
        </mat-form-field>

        <!-- Type select -->
        <mat-form-field
          *ngIf="filter.showFilters && usedTypeMap?.length > 1"
          fxFlex="8"
          fxFlex.lt-md="100"
        >
          <mat-label>Verfahren</mat-label>
          <mat-select
            #verfahrenFilterEvent
            (selectionChange)="selectionChangeKennzeichen($event.value)"
          >
            <mat-option>(Alle)</mat-option>
            <mat-option
              *ngFor="let kennzeichen of usedTypeMap"
              value="{{ kennzeichen.key }}"
              >{{ kennzeichen.text }}</mat-option
            >
          </mat-select>
        </mat-form-field>

        <mat-form-field
          *ngIf="filter.showFilters && this.filter.bevollmaechtigterMode"
          fxFlex="15"
          fxFlex.lt-md="100"
        >
          <mat-label>Antragstellende Person, Organisation</mat-label>
          <label>
            <input
              matInput
              type="text"
              placeholder="Suchbegriff (min. 3 Zeichen)"
              [(ngModel)]="filter.antragsteller"
              (click)="deleteFilterStandort()"
              (keyup)="applyFilterAntragsteller()"
            />
          </label>
          <button
            *ngIf="filter.antragsteller"
            matSuffix
            mat-icon-button
            aria-label="Clear"
            (click)="deleteFilterAntragsteller()"
          >
            <mat-icon matTooltip="Filter löschen">close</mat-icon>
          </button>
          <button
            *ngIf="!filter.antragsteller"
            matSuffix
            mat-icon-button
            disabled
          >
            <mat-icon matTooltip="Tabelle nach Suchbegriff filtern"
              >search</mat-icon
            >
          </button>
        </mat-form-field>

        <mat-form-field
          appearance="fill"
          *ngIf="filter.showFilters"
          fxFlex="12"
          fxFlex.lt-md="100"
        >
          <mat-label>Standort</mat-label>
          <label>
            <input
              matInput
              type="text"
              placeholder="Suchbegriff (min. 3 Zeichen)"
              [(ngModel)]="filter.standort"
              (click)="deleteFilterAntragsteller()"
              (keyup)="applyFilterStandort()"
            />
          </label>
          <button
            *ngIf="filter.standort"
            matSuffix
            mat-icon-button
            aria-label="Clear"
            (click)="deleteFilterStandort()"
          >
            <mat-icon matTooltip="Filter löschen">close</mat-icon>
          </button>
          <button *ngIf="!filter.standort" matSuffix mat-icon-button disabled>
            <mat-icon matTooltip="Tabelle nach Suchbegriff filtern"
              >search</mat-icon
            >
          </button>
        </mat-form-field>

        <!-- Status filter select -->
        <mat-form-field
          appearance="fill"
          *ngIf="filter.showFilters"
          fxFlex="12"
          fxFlex.lt-md="100"
        >
          <mat-label>Status</mat-label>
          <mat-select
            #statusFilterEvent
            (selectionChange)="selectionChangeStatus($event.value)"
          >
            <mat-option>(Alle)</mat-option>
            <mat-option
              *ngFor="let status of statusList"
              value="{{ status.key }}"
              >{{ status.description }}</mat-option
            >
          </mat-select>
        </mat-form-field>

        <!-- refresh button -->
        <div fxFlex="5" fxLayout="row" fxFlexAlign="center center">
          <div
            fxLayout="column"
            fxFlexOffset="{{ isAdminMode ? '0vw' : '2vw' }}"
            fxFlexOffset.lt-md="0px"
            fxFlexOffset.lt-lg="1vw"
          >
            <button
              fxFlexOffset="1px"
              mat-mini-fab
              *ngIf="filter.showFilters"
              color="primary"
              (click)="this.refreshData()"
              matTooltip="Tabelleninhalt aktualisieren"
            >
              <mat-icon>refresh</mat-icon>
            </button>
          </div>
          <button
            fxFlexOffset="10px"
            class="btn-vorschub"
            mat-mini-fab
            *ngIf="isAdminMode"
            (click)="this.updateVorgangsdaten()"
            color="primary"
            aria-label="Vorgangsdaten aktualisieren"
            matTooltip="Vorgangsdaten aktualisieren"
          >
            <mat-icon>update</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
