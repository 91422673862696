import { SelectionModel } from '@angular/cdk/collections';
import { HttpErrorResponse } from '@angular/common/http';

import { AfterViewInit, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Sort, SortDirection } from '@angular/material/sort';
import { merge, Observable, ObservableInput, of, of as observableOf } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, startWith, switchMap, tap } from 'rxjs/operators';
import { ConfirmSendEmailComponent } from '../confirm-send-email/confirm-send-email.component';
import { OverviewAdminComponent } from '../overview-admin/overview-admin.component';
import { BackendService } from '../shared/backend.service';
import { Account } from '../shared/data/account';
import { AccountOverview } from '../shared/data/account.overview';
import { AccountSearchParams } from '../shared/data/account.search.params';
import { AntragRole } from '../shared/data/antrag.role';
import { ClientErrorMessage } from '../shared/data/clientErrorMessage';
import { OverviewAdminData } from '../shared/data/overview.admin.data';
import { OverviewStatus } from '../shared/data/overview.status';
import {
  dateTimeFormatDe, getKenzeichenAgregateKeys,
  getKenzeichenAgregateTypesForUserProfile,
  setKenzeichenAgregateTypesForAccount
} from "../shared/utils";
import { EmailStatusInfoComponent } from "../email-status-info/email-status-info.component";
import { environment } from "../../environments/environment";
import moment, { Moment } from "moment/moment";
import { ConfirmAdministrationDialog } from "../confirm-administration-dialog/confirm-administration-dialog";
import { AccountTypes } from "../shared/data/account.types";
import {MatTableDataSource} from "@angular/material/table";
import {OverviewAccountAuditComponent} from "../overview-account-audit/overview-account-audit.component";

type IconField = {
  key: string,
  name: string,
  tooltip: string,
  icon: string,
  actions: Array<string>
};

type EmailIconField = {
  key: string,
  name: string,
  icon: string,
  iconClass: string,
  color: string
};

const ACTION_LOCK = 'lock';
const ACTION_UNLOCK = 'unlock';
const ACTION_ADMIN = 'admin';
const ACTION_USER = 'user';
const ACTION_ACTIVATE = 'activate';
const ACTION_RESET = 'reset';
const ACTION_UPDATE = 'update';
const ACTION_SHOW = 'show';
const STATUS_LIST = new Array<IconField>();
const EMAIL_STATUS_LIST = new Array<EmailIconField>();

STATUS_LIST.push({
  key: 'PENDING', name: 'Ausst.', tooltip: 'Ausstehend', icon: 'pending',
  actions: [ACTION_LOCK, ACTION_UNLOCK, ACTION_ADMIN, ACTION_USER, ACTION_ACTIVATE, ACTION_UPDATE, ACTION_SHOW]
} as IconField);
STATUS_LIST.push({
  key: 'ACTIVE', name: 'Aktiv', tooltip: 'Aktiv', icon: 'check_circle',
  actions: [ACTION_LOCK, ACTION_UNLOCK, ACTION_ADMIN, ACTION_USER, ACTION_RESET, ACTION_UPDATE, ACTION_SHOW]
} as IconField);
STATUS_LIST.push({
  key: 'ALIAS', name: 'Alias', tooltip: 'Alias', icon: 'group',
  actions: []
} as IconField);
STATUS_LIST.push({
  key: 'LOCKED', name: 'Gesperrt', tooltip: 'Gesperrt', icon: 'block',
  actions: [ACTION_UNLOCK, ACTION_USER, ACTION_RESET, ACTION_UPDATE, ACTION_SHOW]
} as IconField);
STATUS_LIST.push({
  key: 'DISABLED', name: 'Deaktiviert', tooltip: 'Deaktiviert', icon: 'delete',
  actions: [ACTION_ACTIVATE, ACTION_UPDATE, ACTION_SHOW]
} as IconField);

EMAIL_STATUS_LIST.push({ key: 'SEND', name: 'Gesendet', iconClass: 'material-icons', icon: 'mail_outline', color: 'color_green' });
EMAIL_STATUS_LIST.push({ key: 'DELIVERY', name: 'Erfolgreich gesendet', iconClass: 'material-icons', icon: 'mark_email_read', color: 'color_green' });
EMAIL_STATUS_LIST.push({ key: 'ERROR', name: 'Nicht Gesendet', iconClass: 'material-icons', icon: 'mark_email_unread', color: 'color_red' });
EMAIL_STATUS_LIST.push({ key: 'FAILED', name: 'Fehler', iconClass: 'material-icons', icon: 'mark_email_unread', color: 'color_red' });
EMAIL_STATUS_LIST.push({ key: 'DOMAIN_NOT_ALLOWED', name: 'Empfänger E-Mail Domäne nicht erlaubt', iconClass: 'material-icons-outlined', icon: 'mark_email_unread', color: 'color_red' });
EMAIL_STATUS_LIST.push({ key: 'NOT_AVAILABLE', name: 'E-Mail Status unbekannt', iconClass: 'material-icons', icon: 'mail_outline', color: 'none' });


const ROLE_LIST = new Array<IconField>();

ROLE_LIST.push({ key: 'ADMIN', name: 'Admin.', tooltip: 'Administrator', icon: 'admin_panel_settings' } as IconField);
ROLE_LIST.push({ key: 'BENUTZER', name: 'Benutzer', tooltip: 'Benutzer', icon: 'account_circle' } as IconField);
ROLE_LIST.push({ key: 'SUPER_USER', name: 'Super User', tooltip: 'Super User', icon: 'supervised_user_circle' } as IconField);

type KennzeichenType = {
  key: string,
  text: string,
};

var KENNZEICHEN_TYPE_MAP = new Array<KennzeichenType>();


@Component({
  selector: 'app-administration',
  templateUrl: './administration.component.html',
  styleUrls: ['./administration.component.css'],
})
export class AdministrationComponent implements OnInit, AfterViewInit {

  filter = '';

  selection = new SelectionModel<Account>(true, []);
  statusList = STATUS_LIST;
  emailStatusList = EMAIL_STATUS_LIST;
  roleList = ROLE_LIST;
  form: FormGroup;
  editMode = false;
  isSuperUser: boolean = false;

  data: MatTableDataSource<any> = new MatTableDataSource<any>();

  vorgangsnummer: any;

  isLoadingResults = false;
  forceSendMail = false;
  dateTimeFormatDe = "";

  resultsLength = 0;
  pageIndex = 0;
  pageSize = 10;

  direction: SortDirection = 'asc';
  active = 'email';

  statusFilterValue: string;

  filterObservable: Observable<string>;

  kennzeichenFilterValue: string;
  kennzeichenObservable: ObservableInput<any> = new Array(0);
  roleFilterValue: string;
  roleObservable: ObservableInput<any> = new Array(0);

  allUsedTypes = null;
  kennzeichenTypeMap = KENNZEICHEN_TYPE_MAP;
  allowedTypes: string[];
  selectedNewTypes: string[];
  selectedNewTypesKeys: string[] = [];



  @Output() filterEvent: EventEmitter<string> = new EventEmitter();
  @Output() refreshEvent: EventEmitter<void> = new EventEmitter();
  @Output() newUserEvent: EventEmitter<string> = new EventEmitter();
  @Output() updateUserEvent: EventEmitter<string> = new EventEmitter();


  constructor(
    private fb: FormBuilder,
    private backend: BackendService,
    private snackBar: MatSnackBar,
    private matDialog: MatDialog,
    private confirmSendMailDialog: MatDialog,
    private confirmAdministrationDialog: MatDialog

  ) {

    this.dateTimeFormatDe = dateTimeFormatDe;
  }

  displayedColumns: string[] = [
    'letter',
    'email',
    'accountType',
    'formularTypes',
    'lastSuccessfulLogin',
    'temporaryLockedUntil',
    'status',
    'actions'
  ];

  ngOnInit(): void {

    this.form = this.fb.group({
      newUser: ['', Validators.required]
    });
    this.isSuperUser = this.loadIsSuperUser();
    this.allowedTypes = this.backend.getFormuralTypes();
  }

  ngAfterViewInit(): void {

    if (this.enableEmailStatus()) {
      this.displayedColumns.splice(7, 0, 'emailStatus');
    }

    // change of filter requires some preprocessing
    this.filterObservable = this.filterEvent
      .pipe(
        debounceTime(150),
        distinctUntilChanged(),
        tap(() => {
          this.pageIndex = 0;
        }));

    this.reloadData();
  }

  private reloadData(): void {
    merge(
      this.refreshEvent,
      this.newUserEvent,
      this.updateUserEvent,
      this.filterObservable)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          return this.backend.getAccounts(
            {
              status: this.statusFilterValue,
              filter: this.filter,
              sortBy: this.active,
              sortOrder: this.direction,
              pageNumber: this.pageIndex,
              pageSize: this.pageSize,
              kennzeichen: this.kennzeichenFilterValue,
              isSuperUser: this.isSuperUser,
              allowedTypes: this.allowedTypes,
              accountType: this.roleFilterValue
            } as AccountSearchParams, this.enableEmailStatus());
        }),
        map(data => {
          // Flip flag to show that loading has finished.
          this.isLoadingResults = false;
          this.resultsLength = data.count;

          if (this.allUsedTypes == null && data != null) {
            this.allUsedTypes = data.usedTypes;
            this.setAllUsedTypes();
          }

          return data.data;
        }),
        catchError(() => {
          this.isLoadingResults = false;
          return observableOf([]);
        })

      ).subscribe(data =>
      this.data = setKenzeichenAgregateTypesForAccount(data));
  }

  paginationChange(event: PageEvent): void {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    this.reloadData();
  }

  sortingChange(sort: Sort): void {
    this.direction = sort.direction;
    this.active = sort.active;

    // If the user changes the sort order, reset back to the first page.
    this.pageIndex = 0;
    this.reloadData();
  }

  selectionChange(value: string): void {
    this.statusFilterValue = value;

    // If the user changes filtering, reset back to the first page.
    this.pageIndex = 0;
    this.reloadData();
  }

  role(account: AccountOverview): IconField {
    return this.roleList.find(s => s.key === String(account.accountType));
  }

  status(account: AccountOverview): IconField {
    const status = !account.locked ? account.status : 'LOCKED';
    return this.statusList.find(s => s.key === status);
  }

  emailStatus(account: AccountOverview): EmailIconField {
    const status = (account.emailStatus != null && account.emailStatus.status != null) ? account.emailStatus.status : 'NOT_AVAILABLE';
    return this.emailStatusList.find(s => s.key === status);
  }

  emailTooltip(account: AccountOverview): String {
    const status = (account.emailStatus != null && account.emailStatus.status != null) ? account.emailStatus.status : 'NOT_AVAILABLE';

    if (status === 'NOT_AVAILABLE' || status === 'DELIVERY') {
      return this.emailStatusList.find(s => s.key === status).name
    }

    if (account.emailStatus.diagnosticCode != null) {
      return (this.emailStatusList.find(s => s.key === status).name + " [" + account.emailStatus.diagnosticCode + "]")
    }

    if (account.emailStatus.errorMessage != null) {
      return (this.emailStatusList.find(s => s.key === status).name + " [" + account.emailStatus.errorMessage + "]")
    }
    return this.emailStatusList.find(s => s.key === status).name
  }

  refreshData(): void {
    this.refreshEvent.emit();

  }

  actionDisabledForRow(account: AccountOverview, action: string): boolean {
    if (account.email === this.getEmail()) {
      return true;
    }

    const allowed = this.status(account).actions;
    if (allowed == null) {
      return true;
    }

    return allowed.indexOf(action) < 0;
  }

  applyFilter(): void {
    this.filterEvent.emit(this.filter.trim().toLowerCase());
  }

  deleteFilter(): void {
    this.filter = '';
    this.applyFilter();
  }

  getEmail(): string {
    return this.backend.getEmail();
  }

  onSubmitNewUser(): void {
    if (this.form.valid) {
      this.backend
        .createAccount(this.form.get('newUser').value)
        .subscribe(stream => this.created(stream),
          error => this.handleHttpError(error, 'Benutzerkonto konnte nicht erstellt werden.'));
    }
  }

  onCancelNewUser(): void {
    this.form.reset();
    this.editMode = false;
  }

  private created(newData: AccountOverview): void {
    this.newUserEvent.emit(newData.email);

    this.form.reset();
    this.form.get('newUser').setErrors(null);
    this.showSuccessMessage('Erfolgreich Benutzerkonto erstellt.');
  }

  updateAccount(account: AccountOverview, action: string): void {
    // Create a copy since we should change the original only after the backend call succeeded.
    const updatedAccount = Object.assign({}, account);
    switch (action) {
      case 'lock':
        updatedAccount.locked = true;
        break;
      case 'unlock':
        updatedAccount.locked = false;
        break;
      case 'admin':
        updatedAccount.admin = true;
        updatedAccount.accountType = 'ADMIN';
        updatedAccount.formularTypes = null;

        break;
      case 'super_user':
        updatedAccount.admin = true;
        updatedAccount.accountType = 'SUPER_USER';
        updatedAccount.formularTypes = getKenzeichenAgregateKeys(this.selectedNewTypes);
        break;
      case 'benutzer':
        updatedAccount.admin = false;
        updatedAccount.accountType = 'BENUTZER';
        updatedAccount.formularTypes = null;

        break;
      default:
        return;
    }
    this.backend
      .updateAccount(updatedAccount, action)
      .subscribe(stream => this.updated(stream),
        error => this.handleHttpError(error, 'Benutzerkonto konnte nicht aktualisiert werden.'));
  }

  // tslint:disable-next-line:typedef
  updateVorgangsdaten(account: AccountOverview) {
    this.backend
      .updateVorgangsdaten(account.email)
      .subscribe(stream => this.updateVorgangsdatenSuccess(),
        error => this.handleHttpError(error, 'Vorgangsdaten wurden nicht aktualisiert.'));
  }

  sendMail(account: AccountOverview, action: string): void {
    switch (action) {
      case 'activate':
        this.backend.requestAdminActivate(account.email, this.forceSendMail)
          .subscribe(() => this.sendMailSuccess(), error => this.handleMailWarning(error, 'E-Mail konnte nicht verschickt werden.', account, action));
        return;
      case 'reset':
        this.backend.requestAdminReset(account.email, this.forceSendMail)
          .subscribe(() => this.sendMailSuccess(), error => this.handleMailWarning(error, 'E-Mail konnte nicht verschickt werden.', account, action));
        return;
      default:
        return;
    }
  }

  private sendMailSuccess(): void {
    this.showSuccessMessage('Erfolgreich E-Mail verschickt.');
    this.forceSendMail = false;
  }

  private updateVorgangsdatenSuccess(): void {
    this.showSuccessMessage('Vorgangsdaten wurden erfolgreich aktualisiert.');
  }

  private updated(updatedData: AccountOverview): void {
    this.updateUserEvent.emit(updatedData.email);
    this.showSuccessMessage('Erfolgreich Benutzerkonto aktualisiert.');
  }

  private handleHttpError(error: HttpErrorResponse, defaultMessage: string): void {
    if (error.status === 400) {
      const clientError = error.error as ClientErrorMessage;
      if (clientError != null) {
        this.showAlertMessage(clientError.message);
        return;
      }
    }
    this.showAlertMessage(defaultMessage);
  }

  private handleMailWarning(error: HttpErrorResponse, defaultMessage: string, account: AccountOverview, action: string): void {
    if (error.status === 400) {
      const clientError = error.error as ClientErrorMessage;
      if (clientError != null) {
        if (clientError.warning) {
          this.confirmSendMail(clientError.message, account, action);
        } else {
          this.showAlertMessage(clientError.message);
        }
        return;
      }
    }
    this.showAlertMessage(defaultMessage);
  }

  private showSuccessMessage(message: string): void {
    this.snackBar.open(message, '',
      { duration: 2000, panelClass: ['success'] });
  }

  private showAlertMessage(message: string): void {
    this.snackBar.open(message, 'X',
      { duration: 10000, panelClass: ['alert'] });
  }

  confirmSendMail(message: string, account: AccountOverview, action: string): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = message;
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    this.confirmSendMailDialog.open(ConfirmSendEmailComponent, dialogConfig).afterClosed()
      .subscribe(dialogAction => {
        if (dialogAction === 'confirm') {
          this.forceSendMail = true;
          this.sendMail(account, action);
        } else {
          this.forceSendMail = false;
        }
      });
  }

  public openAdminOverviewAccountDialog(row: AccountOverview): void {
    this.backend.getAntragRolesForEmail(row.email).subscribe(roles => {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.data = new OverviewAdminData(row.email, roles, null, OverviewStatus.byEmail, true, this.allowedTypes);
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      this.matDialog.open(OverviewAdminComponent, dialogConfig);
    });
  }

  public openOverviewAccountAuditDialog(row: AccountOverview): void {

      const dialogConfig = new MatDialogConfig();
      dialogConfig.data = row.email
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      this.matDialog.open(OverviewAccountAuditComponent, dialogConfig);
    }


  public openAdminOverviewVorgangsnummerDialog(): void {
    this.backend.getEmailByVorgangsnummer(this.vorgangsnummer).subscribe(accountInfo => {
      const email = accountInfo.email;
      this.backend.getAntragByVorgangsnummerForSuperUser(this.vorgangsnummer, this.allowedTypes).subscribe((result) => {
        if (result.count > 0) {
          const dialogConfig = new MatDialogConfig();
          dialogConfig.data = new OverviewAdminData(email, [AntragRole.BEVOLLMAECHTIGTER], this.vorgangsnummer,
            OverviewStatus.byVorgangsnummer, false, this.allowedTypes);
          dialogConfig.disableClose = true;
          dialogConfig.autoFocus = true;
          this.matDialog.open(OverviewAdminComponent, dialogConfig);
        } else {
          this.showAlertMessage('Ein Antrag mit der angegebenen Vorgangsnummer existiert nicht.');
        }
      });
    });
  }

  emailStatusInfo(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    this.matDialog.open(EmailStatusInfoComponent, dialogConfig);
  }

  enableEmailStatus(): boolean {
    return environment?.enableEmailStatus;
  }

  public momentToDate(mom: Moment): Date {
    return new Date(moment().locale("de").add(1, 'd').format("MM.DD.YYYY HH:mm:ss"))

  }

  openAdministrationDialog(row: AccountOverview): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      allUsedTypes: this.allUsedTypes,
      accountType: row.accountType,
      formularTypes: getKenzeichenAgregateTypesForUserProfile(row.formularTypes)
    };
    this.confirmAdministrationDialog.open(ConfirmAdministrationDialog, dialogConfig).afterClosed()
      .subscribe((dialogAction) => {
        if (dialogAction.result === 'confirm') {
          if (dialogAction.typeOfAccountSelected == 'super_user') {
            this.selectedNewTypes = dialogAction.data.map(item => item.name);
          }
          this.updateAccount(row, dialogAction.typeOfAccountSelected);
        }
      });
  }

  loadIsSuperUser(): boolean {
    return AccountTypes[this.backend.getAccountType()] == AccountTypes.SUPER_USER;
  }

  selectionChangeKennzeichen(value: string): void {
    this.kennzeichenFilterValue = value;

    // If the user changes filtering, reset back to the first page.
    this.pageIndex = 0;

    this.kennzeichenObservable = of(value);
    this.reloadData();
  }

  selectionChangeRolle(value: string): void {
    this.roleFilterValue = value;

    // If the user changes filtering, reset back to the first page.
    this.pageIndex = 0;

    this.roleObservable = of(value);
    this.reloadData();
  }

  setAllUsedTypes() {
    KENNZEICHEN_TYPE_MAP = new Array<KennzeichenType>();
    this.allUsedTypes.forEach(typeToAdd => {
      if (typeToAdd == "BEGEM-FV" || typeToAdd == "BEGEM2-FV") {
        if (KENNZEICHEN_TYPE_MAP.some(type => type.text === "BEG EM")) {
          return;
        }
        KENNZEICHEN_TYPE_MAP.push({
          key: 'BEGEM-FV',
          text: 'BEG EM'
        } as KennzeichenType);
        return;
      }

      if (typeToAdd == "EBN-FV") {
        if (KENNZEICHEN_TYPE_MAP.some(type => type.text === "EBN")) {
          return;
        }
        KENNZEICHEN_TYPE_MAP.push({
          key: 'EBN-FV',
          text: 'EBN'
        } as KennzeichenType);
        return;
      }

      if (typeToAdd == "EBW-FV") {
        if (KENNZEICHEN_TYPE_MAP.some(type => type.text === "EBW")) {
          return;
        }
        KENNZEICHEN_TYPE_MAP.push({
          key: 'EBW-FV',
          text: 'EBW'
        } as KennzeichenType);
        return;
      }


      if (typeToAdd == "BEGPT-FV") {
        if (KENNZEICHEN_TYPE_MAP.some(type => type.text === "BEG PT")) {
          return;
        }
        KENNZEICHEN_TYPE_MAP.push({
          key: 'BEGPT-FV',
          text: 'BEG PT'
        } as KennzeichenType);
        return;
      } else {
        if (KENNZEICHEN_TYPE_MAP.some(type => type.text === typeToAdd)) {
          return;
        }
        KENNZEICHEN_TYPE_MAP.push({
          key: typeToAdd,
          text: typeToAdd
        } as KennzeichenType);
      }
    });
    this.kennzeichenTypeMap = KENNZEICHEN_TYPE_MAP;
  }

  noAntragsByUser(account: AccountOverview): boolean {
    return (account.accountType == 'BENUTZER' && !account.formularTypes);
  }
}
