import { Component, NgZone, OnInit } from '@angular/core';

import { BackendService } from '../../shared/backend.service';
import { Router } from '@angular/router';

@Component({
  selector: 'logout',
  templateUrl: './logout.component.html',
  standalone: false,
})
export class LogoutComponent implements OnInit {
  constructor(
    private router: Router,
    private zone: NgZone,
    private backend: BackendService,
  ) {}

  public ngOnInit() {
    this.logout();
  }

  public logout(): void {
    this.backend.logout(false);
  }

  public goToLogin(): void {
    this.router.navigate(['/login']);
  }
}
