<div role="main" fxFlex="100%" fxLayout.xs="column" fxLayoutAlign.xs="start">
  <mat-card class="mat-elevation-z0" ngClass.gt-xs="overview-card">
    <mat-card-header
      *ngIf="loadOverviewStatus !== 1"
      fxLayout="column"
      fxLayoutAlign="center end"
    >
      <body class="description mat-small">
        {{ loginText }}:
      </body>
      {{ email }}
    </mat-card-header>
    <!-- title -->
    <mat-card-title
      title="Ihre Übersicht über eingereichte Anträge oder Meldungen an das BAFA.
Es können bisher eingereichte Formulare bearbeitet oder heruntergeladen werden.
Nutzen Sie die Funktionen unter der Spalte Aktionen."
      >Übersicht</mat-card-title
    >

    <!-- toggle table layout -->

    <div
      fxLayout
      fxLayoutGap="40px"
      fxLayout.xs="column"
      fxLayoutAlign.xs="start start"
      *ngIf="enableNewAntrag() && !isAdminMode()"
    >
      <button
        mat-flat-button
        class="button"
        type="button"
        color="primary"
        (click)="this.openNewAntragDialog()"
      >
        <mat-icon class="icon">add</mat-icon>
        Neuer Antrag
      </button>
    </div>

    <mat-card-content>
      <!-- table filters -->
      <antrag-overview-filter
        [loadOverviewStatus]="loadOverviewStatus"
        [usedTypeMap]="usedTypeMap"
        [email]="email"
        (filterEvent)="onFilterEvent($event)"
        (updateVorgangsdatenEvent)="onUpdateVorgangDatenEvent()"
      >
      </antrag-overview-filter>
      <!-- table -->
      <br />
      <div class="table-container">
        <table
          mat-table
          [dataSource]="dataSource"
          matSort
          [matSortActive]="active"
          matSortDisableClear
          [matSortDirection]="direction"
          class="mat-elevation-z3"
          ngClass.lt-lg="auto-column-width;"
          (matSortChange)="sortingChange($event)"
        >
          <ng-container matColumnDef="vorgangsnummer">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Vorgangsnummer
            </th>
            <td
              mat-cell
              *matCellDef="let element"
              matTooltip="{{ element.vorgangsnummer }}"
            >
              <a
                class="mat-body-1 color-link"
                *ngIf="enableAntragEdit()"
                [routerLink]="['/antragDetail']"
                [queryParams]="{
                  vorgangsnummer: element.vorgangsnummer,
                  type: element.kennzeichen,
                  bevollmaechtigterMode: filter?.bevollmaechtigterMode,
                }"
              >
                {{ element.vorgangsnummer }}</a
              >
              <span *ngIf="!enableAntragEdit()">{{
                element.vorgangsnummer
              }}</span>
            </td>
          </ng-container>

          <ng-container matColumnDef="kennzeichen">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Verfahren</th>
            <td
              mat-cell
              *matCellDef="let element"
              matTooltip="{{ element.kennzeichenText }}"
            >
              {{ element.kennzeichenText }}
            </td>
          </ng-container>

          <ng-container matColumnDef="antragsdatum">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Antragsdatum
            </th>
            <td
              mat-cell
              *matCellDef="let element"
              matTooltip="{{ element.antragsdatum | date: dateFormatDe }}"
            >
              {{ element.antragsdatum | date: dateFormatDe }}
            </td>
          </ng-container>

          <ng-container matColumnDef="standort">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Standort</th>
            <td
              mat-cell
              *matCellDef="let element"
              matTooltip="{{ element.standort }}"
            >
              {{ element.standort }}
            </td>
          </ng-container>

          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef>
              <div fxLayout="row">
                <mat-label>
                  <button
                    mat-icon-button
                    color="primary"
                    (click)="this.overViewInfo()"
                    matTooltip="Antrag Status"
                  >
                    <mat-icon>info</mat-icon>
                  </button>
                </mat-label>
                <mat-label
                  fxLayout="column"
                  fxLayoutAlign="center"
                  fxFlexOffset="10px"
                  mat-sort-header
                >
                  Status
                </mat-label>
              </div>
            </th>

            <td
              mat-cell
              *matCellDef="let element"
              class="table-cell"
              matTooltip="{{ status(element).tooltip }}"
            >
              <div fxFlex fxLayout="row" fxFlexFill fxLayoutAlign="left center">
                <a mat-icon-button disabled>
                  <mat-icon>{{ status(element).icon }}</mat-icon>
                </a>
                {{ status(element).description }}
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="antragsteller">
            <th
              class="break-spaces"
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header
            >
              <label>Antragstellende Person, Organisation</label>
            </th>
            <td
              mat-cell
              *matCellDef="let element"
              matTooltip="{{ element.antragsteller }}"
            >
              {{ element.antragsteller }}
            </td>
          </ng-container>

          <ng-container matColumnDef="links">
            <th mat-header-cell *matHeaderCellDef>Aktionen</th>

            <td mat-cell *matCellDef="let element" class="button-cell">
              <div fxLayout="row" fxLayoutGap="-1px">
                <button
                  (click)="showPdf(element)"
                  [disabled]="isLoading"
                  mat-icon-button
                  color="primary"
                  aria-label="Download"
                  matTooltip="Druckquittung herunterladen"
                >
                  <mat-icon>save_alt</mat-icon>
                </button>

                <div
                  class="my-custom-tooltip mat-icon-action"
                  matTooltip="{{
                    !isAdminMode()
                      ? 'Dokumente zum Antrag hochladen'
                      : 'Aktion für den Benutzer ' + email + ' aktiv.'
                  }}"
                  [matTooltipDisabled]="false"
                >
                  <a
                    [href]="element.uploadLink"
                    target="_blank"
                    mat-icon-button
                    color="primary"
                    aria-label="Upload"
                    [disabled]="isAdminMode()"
                  >
                    <mat-icon>publish</mat-icon>
                  </a>
                </div>

                <div
                  class="my-custom-tooltip"
                  matTooltip="{{
                    !isAdminMode()
                      ? 'Verwendungsnachweis Druckquittung herunterladen'
                      : !(
                            element.stornoWunsch !== null ||
                            element.status === 'S' ||
                            isLoading
                          )
                        ? 'Verwendungsnachweis Druckquittung herunterladen'
                        : 'Aktion für den Benutzer ' +
                          email +
                          (element.stornoWunsch !== null ||
                          element.status === 'S' ||
                          isLoading
                            ? ' inaktive.'
                            : ' aktiv.')
                  }}"
                  [matTooltipDisabled]="false"
                >
                  <button
                    (click)="showVnPdf(element)"
                    *ngIf="element.viewVnLink !== null"
                    [disabled]="
                      element.stornoWunsch !== null ||
                      element.status === 'S' ||
                      isLoading
                    "
                    mat-icon-button
                    color="primary"
                    aria-label="Download Verwendungsnachweis"
                  >
                    <mat-icon>receipt</mat-icon>
                  </button>
                </div>

                <div
                  class="my-custom-tooltip"
                  matTooltip="{{
                    !isAdminMode()
                      ? 'Verwendungsnachweis einreichen'
                      : 'Aktion für den Benutzer ' +
                        email +
                        (element.status === 'S' || isLoading
                          ? ' inaktiv.'
                          : ' aktiv.')
                  }}"
                  [matTooltipDisabled]="false"
                >
                  <button
                    (click)="getVnLinkTarget(element)"
                    target="_blank"
                    *ngIf="element.submitVnLink !== null"
                    [disabled]="
                      isAdminMode() || element.status === 'S' || isLoading
                    "
                    mat-icon-button
                    color="primary"
                    aria-label="Verwendungsnachweis Öffnen"
                  >
                    <mat-icon>post_add</mat-icon>
                  </button>
                </div>

                <div
                  class="my-custom-tooltip"
                  matTooltip="{{
                    !isAdminMode()
                      ? 'Hier können Sie Ihren Antrag stornieren. Die Aktion ist nur vor der Einreichung des Verwendungsnachweises möglich.'
                      : 'Aktion für den Benutzer ' +
                        email +
                        (element.stornoWunsch !== null || element.status === 'S'
                          ? ' inaktiv.'
                          : ' aktiv.')
                  }}"
                  [matTooltipDisabled]="false"
                >
                  <button
                    (click)="openStornoAntragDialog(element)"
                    mat-icon-button
                    color="primary"
                    aria-label="Storno"
                    *ngIf="
                      (element.kennzeichenText === 'BEG PT' ||
                        element.kennzeichenText === 'EBN' ||
                        element.kennzeichenText === 'EBW') &&
                      element.status !== 'S' &&
                      this.isEnabledStorno() &&
                      (element.stornoWunsch === true ||
                        element.stornoWunsch === null)
                    "
                    [disabled]="
                      isAdminMode() ||
                      element.stornoWunsch !== null ||
                      element.status === 'S'
                    "
                  >
                    <mat-icon>remove_circle_outline</mat-icon>
                  </button>
                </div>
                <!-- TODO remove style -->
                <mat-icon
                  mat-icon-button
                  color="primary"
                  disabled="false"
                  style="transform: scale(0.7)"
                  *ngIf="
                    element.stornoWunsch === true && this.isEnabledStorno()
                  "
                  matTooltip="Ihr Stornierungswunsch ist eingegangen, Sie erhalten direkt vom BAFA eine Nachricht dazu."
                  >info</mat-icon
                >

                <div
                  class="my-custom-tooltip"
                  matTooltip="{{ resolveDeleteTooltipText(element) }}"
                  [matTooltipDisabled]="false"
                >
                  <a
                    (click)="openDeleteAntragDialog(element)"
                    [disabled]="deleteIsDisabled(element)"
                    color="primary"
                    aria-label="Delete antraege"
                    mat-icon-button
                    *ngIf="isAdminMode()"
                  >
                    <mat-icon
                      *ngIf="!element.timeDeleted"
                      class="center {{
                        account?.deleteRequestDate
                          ? 'color_red'
                          : element.deleteRequestDate
                            ? 'color_orange'
                            : 'primary'
                      }}"
                      >delete</mat-icon
                    >
                    <mat-icon *ngIf="element.timeDeleted" class="center primary"
                      >delete_forever</mat-icon
                    >
                  </a>
                </div>
              </div>
            </td>
          </ng-container>

          <!-- show this when there is no matching data -->
          <tr *matNoDataRow>
            <td class="mat-cell" colspan="4">
              Keine Ergebnisse zu Ihrem Suchbegriff "{{
                'Vorgangsnummer:' +
                  filter.vorgangsnummer +
                  ' ; Antragstellende Person:' +
                  filter.antragsteller +
                  ' ; Standort:' +
                  filter.standort
              }}".
            </td>
          </tr>

          <tr
            mat-header-row
            *matHeaderRowDef="displayedColumns(); sticky: true"
          ></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns()"></tr>
        </table>
      </div>
      <div fxLayout fxLayoutAlign="space-arround center" fxLayout.xs="column">
        <div
          fxFlex="50"
          *ngIf="getLifeSpanInMonth() !== null && getLifeSpanInMonth() !== 0"
        >
          <small
            >Hinweis: Anträge, die seit mehr als
            {{ getLifeSpanInMonth() }} Monaten abgeschlossen sind, werden aus
            Datenschutzgründen automatisch gelöscht.</small
          >
        </div>
        <div fxFlex="50">
          <mat-paginator
            #paginator
            [length]="resultsLength"
            [pageIndex]="pageIndex"
            [pageSizeOptions]="[10, 20]"
            (page)="paginationChange($event)"
            *ngIf="showPagination()"
          >
          </mat-paginator>
        </div>
      </div>
      <div class="loading-shade" *ngIf="isLoading">
        <mat-spinner></mat-spinner>
      </div>
    </mat-card-content>
  </mat-card>
</div>
