import {Component, NgZone, OnInit} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BackendService } from '../shared/backend.service';
import { ErrorMessageMatcher, Field } from '../shared/matcher.service';
import { environment } from '../../environments/environment';
import {Router} from "@angular/router";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {

  form: FormGroup;
  hide = true;
  success: boolean;

  input = new Field();
  matcher = new ErrorMessageMatcher(this.input);

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private zone: NgZone,
    private backend: BackendService) { }

  ngOnInit(): void {
    if (this.backend.isAuthenticated()) {
      // role is authorised - redirect to uebersicht
      this.zone.run(() => {
        this.router.navigate(['../uebersicht']);
      });
    }

    this.form = this.fb.group({
      email: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  onSubmit(): void {
    if (this.form.valid) {
      this.authenticate(this.form.get('email').value, this.form.get('password').value);
    }
  }

  authenticate(email: string, password: string): void {
    const token = this.backend.createToken(email, password);
    this.backend.auth().subscribe(data => this.backend.login(data, token, email), () => this.noLogin());
  }

  noLogin(): void {
    this.success = false;
    this.input.error = 'error';
  }

  enableRegistration(): boolean {
    return environment?.enableRegistration;
  }
}
