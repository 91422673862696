<div
  fxLayout="column"
  fxFlex="900px"
  fxLayoutAlign="space-evenly center"
  fxFlexFill
  class="overview-dialog-content-container mat-dialog-container"
>
  <div role="main" fxFlex="100%" fxLayout.xs="column" fxLayoutAlign.xs="start">
    <mat-card
      fxFlexFill
      fxFlex="100%"
      fxFlexOffset="10px"
      class="mat-dialog-container mat-card-none-shadow"
    >
      <mat-card-title>Legende für Antrag-Status</mat-card-title>
      <mat-card-content fxFlexOffset="30px">
        <table mat-table [dataSource]="dataSource" fixedLayout="true">
          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef>Status</th>
            <td mat-cell *matCellDef="let element">{{ element.status }}</td>
          </ng-container>
          <ng-container matColumnDef="explanation">
            <th mat-header-cell *matHeaderCellDef>Erläuterung</th>
            <td mat-cell *matCellDef="let element">
              {{ element.explanation }}
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </mat-card-content>
      <mat-card-actions fxLayoutAlign="center">
        <button mat-raised-button (click)="close()" color="primary">
          Schließen
        </button>
      </mat-card-actions>
    </mat-card>
  </div>
</div>
