import { ErrorStateMatcher } from '@angular/material/core';

export class ErrorMessageMatcher implements ErrorStateMatcher {
  field: Field;

  constructor(field: Field) {
    this.field = field;
  }

  isErrorState(): boolean {
    return this.field.error !== '';
  }
}

export class Field {
  error = '';
  hide = true;
}
