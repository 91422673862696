<mat-divider [class]="resolveEnvironmentColor()"></mat-divider>
<!-- menu -->
<mat-toolbar class="flex-column-center menu-toolbar" fxLayoutAlign="center center">

  <div class="flex-row" fxLayoutAlign="space-between start"
       fxFlex="70%" fxFlex.md="75%" fxFlex.lt-md="100%">

    <!-- logo -->
    <a href="https://www.bafa.de/DE/Home/home_node.html" title="Zur Startseite">
      <img src="assets/svg/BAFA_2017_WebSVG_de.svg" class="bafa-logo"
           alt="Logo Bundesamt fuer Wirtschaft und Ausfuhrkontrolle (Link zur Startseite)">
    </a>

    <!-- buttons -->
    <div *ngIf="isAuthenticated()" fxLayout.lt-sm="column" fxLayoutAlign.lt-sm="start start">

      <button mat-button class="menu-button" color="accent" [matMenuTriggerFor]="account" [matTooltipDisabled]="!isAlias()"
              matTooltip="Nicht möglich für Alias-Accounts">
        <mat-icon>settings</mat-icon>
        Kontoverwaltung
      </button>
      <mat-menu #account="matMenu">
        <button mat-menu-item routerLink="/changePassword" [disabled]="isAlias()">
          Passwort ändern
        </button>
        <button mat-menu-item routerLink="/changeEmail" [disabled]="isAlias()">
          E-Mail-Adresse ändern
        </button>
      </mat-menu>

      <button mat-button class="menu-button" color="accent" (click)="logout()">
        <mat-icon>exit_to_app</mat-icon>
        Abmelden
      </button>
      <!-- show login -->
      <mat-card-header fxLayout="column" fxLayoutAlign="center end">

        <span class="header-label">Angemeldet als: </span><span class="header-data">{{ getEmail() }}</span>
        <span class="header-label">Rolle: </span><span class="header-data">{{getAccountType()}} {{getFormularTypes()}}</span>

      </mat-card-header>

    </div>

  </div>

</mat-toolbar>

<!-- space title -->
<mat-toolbar class="flex-column-center bafa-serif mat-h1" [class]="resolveEnvironmentColor()" fxLayoutAlign="center center">

  <div class="flex-row" fxLayoutAlign="start center"
       fxFlex="70%" fxFlex.md="75%" fxFlex.lt-md="100%">
    <a [routerLink]="['/home']" class="portal-link">
      BAFA Portal
    </a>

  </div>
</mat-toolbar>
<mat-toolbar *ngIf="notificationsMessage" class="flex-column-center notification" fxLayoutAlign="center center">
  <div class="flex-row" fxLayoutAlign="start center" fxFlex="70%" fxFlex.md="75%" fxFlex.lt-md="100%">
    <ng-container>
      <marquee><p class="notification">{{ notificationsMessage }}</p></marquee>
    </ng-container>
  </div>
</mat-toolbar>
