import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import {
  MatMomentDateModule,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import {
  MatPaginatorIntl,
  MatPaginatorModule,
} from '@angular/material/paginator';

import { AccountAuditOverviewComponent } from './administration/account-audit-overview/account-audit-overview.component';
import { AccountOverviewComponent } from './administration/account-overview/account-overview.component';
import { AccountOverviewFilterComponent } from './administration/account-overview-filter/account-overview-filter.component';
import { ActivationComponent } from './diverse/activation/activation.component';
import { AdminAntragOverviewComponent } from './administration/admin-antrag-overview/admin-antrag-overview.component';
import { AntragDetailComponent } from './antrag/antrag-detail/antrag-detail.component';
import { AntragDetailStatusComponent } from './antrag/antrag-detail-status/antrag-detail-status.component';
import { AntragOverviewComponent } from './antrag/antrag-overview/antrag-overview.component';
import { AntragStatusInfoComponent } from './antrag/antrag-status-info/antrag-status-info.component';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import Aura from '@primeng/themes/aura';
import { AuthInterceptor } from './shared/auth.interceptor';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { BuildInfoComponent } from './shared/build-info/build-info.component';
import { Button } from 'primeng/button';
import { Card } from 'primeng/card';
import { ChangeEmailComponent } from './diverse/change-email/change-email.component';
import { ChangePasswordComponent } from './diverse/change-password/change-password.component';
import { ConfirmDeleteAccountComponent } from './administration/confirm-delete-account/confirm-delete-account.component';
import { ConfirmDeleteAntragComponent } from './antrag/confirm-delete-antrag/confirm-delete-antrag.component';
import { ConfirmNewAntragComponent } from './antrag/confirm-new-antrag/confirm-new-antrag.component';
import { ConfirmPersonalDataAuthComponent } from './antrag/confirm-personal-data-auth/confirm-personal-data-auth';
import { ConfirmSendEmailComponent } from './diverse/confirm-send-email/confirm-send-email.component';
import { ConfirmStornoAntragComponent } from './antrag/confirm-storno-antrag/confirm-storno-antrag.component';
import { EmailExistComponent } from './diverse/email-exist/email-exist.component';
import { EmailIsPendingComponent } from './diverse/email-is-pending/email-is-pending.component';
import { EmailStatusInfoComponent } from './administration/email-status-info/email-status-info.component';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { FooterComponent } from './shared/footer/footer.component';
import { LoginComponent } from './diverse/login/login.component';
import { LogoutComponent } from './diverse/logout/logout.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorIntlDe } from './shared/i18n/i18n.mat.paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MenuComponent } from './shared/menu/menu.component';
import { NgModule } from '@angular/core';
import { PersonDetailComponent } from './antrag/person-detail/person-detail.component';
import { RegisterComponent } from './diverse/register/register.component';
import { RequestPasswordResetComponent } from './diverse/request-password-reset/request-password-reset.component';
import { ResetPasswordComponent } from './diverse/reset-password/reset-password.component';
import { RouterModule } from '@angular/router';
import { Timeline } from 'primeng/timeline';
import { UploadComponent } from './antrag/file-upload/file-upload.component';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { providePrimeNG } from 'primeng/config';
import { AntragOverviewFilterComponent } from './antrag/antrag-overview-filter/antrag-overview-filter.component';

// Date format to be used in date picker
export const DATE_FORMATS = {
  parse: {
    dateInput: 'L',
  },
  display: {
    dateInput: 'L',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@NgModule({
  declarations: [
    AppComponent,
    AntragOverviewComponent,
    AccountAuditOverviewComponent,
    LoginComponent,
    LogoutComponent,
    RegisterComponent,
    ChangePasswordComponent,
    AntragDetailComponent,
    PersonDetailComponent,
    AntragDetailStatusComponent,
    FooterComponent,
    ActivationComponent,
    AccountOverviewComponent,
    RequestPasswordResetComponent,
    ResetPasswordComponent,
    ChangeEmailComponent,
    AdminAntragOverviewComponent,
    BuildInfoComponent,
    AntragStatusInfoComponent,
    ConfirmSendEmailComponent,
    EmailExistComponent,
    EmailIsPendingComponent,
    ConfirmNewAntragComponent,
    EmailStatusInfoComponent,
    ConfirmStornoAntragComponent,
    UploadComponent,
    ConfirmDeleteAntragComponent,
    ConfirmDeleteAccountComponent,
    MenuComponent,
    AccountOverviewFilterComponent,
    AntragOverviewFilterComponent,
  ],
  bootstrap: [AppComponent],
  imports: [
    // sorted alphabetically
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    FlexLayoutModule,
    FormsModule,
    MatButtonModule,
    MatCardModule,
    //    MatCheckboxModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatFormFieldModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatMomentDateModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSelectModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatToolbarModule,
    MatTooltipModule,
    ReactiveFormsModule,
    RouterModule,
    MatCheckboxModule,
    MatAutocompleteModule,
    Timeline,
    Card,
    Button,
    ConfirmPersonalDataAuthComponent,
  ],
  providers: [
    provideAnimationsAsync(),
    providePrimeNG({
      theme: {
        preset: Aura,
      },
    }),

    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: MatPaginatorIntl,
      useClass: MatPaginatorIntlDe,
    },
    {
      provide: MAT_DATE_LOCALE,
      useValue: 'de-DE',
    },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: DATE_FORMATS,
    },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}
