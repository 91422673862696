import {OverviewStatus} from "./overview.status";
import {AntragRole} from "./antrag.role";

export class OverviewAdminData {

  constructor(public email: string,
              public roles: AntragRole[],
              public vorgangsnummer: string,
              public overviewStatus: OverviewStatus,
              public zeigeFilter: boolean,
              public defaultKennzeichenFilterValue: string[]) {

  }
}
