import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription, interval } from 'rxjs';

import { AccountTypes } from '../data/account.types';
import { BackendService } from '../backend.service';
import { NotificationsResult } from '../data/notifications';
import { getKenzeichenAgregateTypesForUserProfile } from '../utils';

@Component({
  selector: 'menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  standalone: false,
})
export class MenuComponent implements OnInit, OnDestroy {
  constructor(private backend: BackendService) {}

  notificationsMessage: string | null = null;
  private subscriptions: Subscription[] = [];
  private readonly REFRESH_INTERVAL_THREE_MIN = 180 * 1000;

  private readonly TEST_ROUTE = 'formular-demo';
  private readonly DEV_ROUTE = 'formular-development';

  private readonly PROD_COLOR = 'background-primary';
  private readonly TEST_COLOR = 'background-test';
  private readonly DEV_COLOR = 'background-dev';

  ngOnInit(): void {
    this.loadNotifications();
    const source = interval(this.REFRESH_INTERVAL_THREE_MIN);
    this.subscriptions.push(
      source.subscribe(() => {
        this.notificationsMessage = null; //Reset notification
        this.loadNotifications();
      }),
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  private loadNotifications(): void {
    this.notificationsMessage = null;

    this.subscriptions.push(
      this.backend.getNotifications('ALL,NKB,ELSTER,DENA').subscribe({
        next: (data: NotificationsResult) =>
          (this.notificationsMessage = data.notifications
            ? data.notifications.join(' ') + ' '
            : null),
        error: () => {
          this.notificationsMessage = '';
        },
      }),
    );
  }

  private isRoute(route: string): boolean {
    return window.location.href.indexOf(route) > -1;
  }

  public resolveEnvironmentColor(): string {
    return this.isRoute(this.TEST_ROUTE)
      ? this.TEST_COLOR
      : this.isRoute(this.DEV_ROUTE)
        ? this.DEV_COLOR
        : this.PROD_COLOR;
  }

  public logout(): void {
    this.backend.logout(false);
  }

  public isAuthenticated(): boolean {
    return this.backend.isAuthenticated();
  }

  public isAlias(): boolean {
    return this.backend.isAlias();
  }

  public getEmail(): string {
    return this.backend.getEmail();
  }

  public getAccountType(): string {
    return AccountTypes[this.backend.getAccountType()];
  }

  public getFormularTypes(): Array<unknown> {
    return getKenzeichenAgregateTypesForUserProfile(
      this.backend.getFormuralTypes(),
    );
  }

  public notEmptyNotifications(): boolean {
    return this.notificationsMessage?.trim()?.length > 0;
  }
}
