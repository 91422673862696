import { ActivatedRoute, Params, Router } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

import { Antrag } from '../../shared/data/antrag';
import { AntragWithXmlData } from '../../shared/data/antragWithXmlData';
import { Antragsteller } from '../../shared/data/antragsteller';
import { BackendService } from '../../shared/backend.service';
import { Bevollmaechtigter } from '../../shared/data/bevollmaechtigter';
import { ConfirmPersonalDataAuthComponent } from '../confirm-personal-data-auth/confirm-personal-data-auth';
import { ConfirmStornoAntragComponent } from '../confirm-storno-antrag/confirm-storno-antrag.component';
import { FileUploadService } from '../../shared/upload.service';
import { FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { OriginalData } from '../../shared/data/detail.original.data';
import { OriginalPersonData } from '../../shared/data/original.person.data';
import { UploadComponent } from '../file-upload/file-upload.component';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'antrag-detail',
  templateUrl: './antrag-detail.component.html',
  styleUrls: ['./antrag-detail.component.scss'],
  standalone: false,
})
export class AntragDetailComponent implements OnInit {
  @ViewChild(UploadComponent) uploadComponent;
  files: Array<File> = new Array<File>();

  form: FormGroup;
  // data needed from the URL to return to the antrag screen or to display error screen for antrag
  vorgangsnummer: string;
  kennzeichen: string;
  isAuthorized: boolean;

  //data that is sent in person detail (antragsteller, bevollmaechtigter)
  detail: AntragWithXmlData;
  antragsteller: Antragsteller;
  bevollmaechtigter: Bevollmaechtigter;
  typeOfOzgService: string;

  // data needed to control and render elements on the screen
  isLoading = true;
  isEdit: boolean = false;
  isSaveEnabled: boolean = true;
  isOpenAntragFailure: boolean = true;
  isSpeichernDisabled: boolean = true;
  isNameOrVornamaOrOrganizationChanged: boolean = false;
  editAntragsteller: boolean = false;
  editBevollmaechtigter: boolean = false;
  manualEditAntragsteller: boolean = false;
  isBevollmaechtigterMode: boolean;

  // saving the original data for comparison
  originalData: OriginalData = new OriginalData();

  //required data for detail status
  antragDetail: Antrag;

  constructor(
    public route: ActivatedRoute,
    private backendService: BackendService,
    private uploadService: FileUploadService,
    private snackBar: MatSnackBar,
    private router: Router,
    private confirmStornoAntragComponent: MatDialog,
    private confirmPersonalData: MatDialog,
  ) {}

  ngOnInit(): void {
    this.subscribeInit();
  }

  private subscribeInit(): void {
    this.isLoading = true;
    const subscription = this.route.queryParams.subscribe({
      next: (params) => {
        this.parseQueryParams(params);
        this.subscribeCompleteAntragData(params);
      },
      error: (error) => this.openAntragFailure(error),
      complete: () => {
        subscription.unsubscribe();
      },
    });
  }

  private subscribeCompleteAntragData(params: Params) {
    this.isBevollmaechtigterMode =
      params?.bevollmaechtigterMode && params?.bevollmaechtigterMode == 'true'
        ? true
        : false;
    const subscription = this.backendService
      .getCompleteAntragByVorgangsnummerAndType(
        params.vorgangsnummer,
        params.type,
      )
      .subscribe({
        next: (antrag) => {
          if (this.isAuthorized && antrag.ozgServiceType == null) {
            this.isAuthorized = false;
            this.router.navigate([], {
              queryParams: {
                authorized: null,
              },
              queryParamsHandling: 'merge',
            });
          }
          //Load data from Antrag
          this.loadDataFromAntragDetail(antrag);
          // Change person data from OZG
          if (this.isAuthorized && !antrag.antragData.isBevollmaechtigter) {
            this.detail.antragsteller = this.antragsteller;
            this.changeAntragstellerMainDataFromOzg(antrag);
            this.changeAddresseDataFromOzg(this.antragsteller, antrag);
          }
          if (this.isAuthorized && antrag.antragData.isBevollmaechtigter) {
            this.detail.bevollmaechtigter = this.bevollmaechtigter;
            this.changeBevollMainDataFromOzg(antrag);
            this.changeAddresseDataFromOzg(this.bevollmaechtigter, antrag);
          }
        },
        error: (error) => this.openAntragFailure(error),
        complete: () => {
          subscription.unsubscribe();
        },
      });
  }

  private parseQueryParams(params: Params) {
    this.vorgangsnummer = params.vorgangsnummer;
    this.kennzeichen = params.type;
    if (params.authorized == null) {
      this.isAuthorized = false;
    } else {
      this.isAuthorized = params.authorized;
    }
  }

  private loadDataFromAntragDetail(antrag: AntragWithXmlData): void {
    this.detail = antrag;
    this.antragsteller = antrag.antragsteller;
    this.originalData.postfach = antrag.postfach;
    this.storageOriginalData(antrag.antragsteller, true);
    this.storageOriginalData(antrag.bevollmaechtigter, false);
    this.bevollmaechtigter = antrag.bevollmaechtigter;
    this.typeOfOzgService = antrag.ozgServiceType;
    this.antragDetail = antrag.antragData;
    this.isOpenAntragFailure = false;
    this.isLoading = false;
  }

  private storageOriginalData(
    antragData: Antragsteller | Bevollmaechtigter,
    antragstellerData: boolean,
  ) {
    const personData = new OriginalPersonData();
    personData.vorname = antragData.vorname;
    personData.nachname = antragData.nachname;
    personData.organization = antragData.organisation;
    if (antragstellerData) {
      this.originalData.antragsteller = personData;
    } else {
      this.originalData.bevollmaechtigter = personData;
    }
  }

  private changeAddresseDataFromOzg(
    antragData: Antragsteller | Bevollmaechtigter,
    antrag: AntragWithXmlData,
  ): void {
    antragData.land = antrag.ozgPerson.land;
    antragData.ort = antrag.ozgPerson.ort;
    antragData.plz = antrag.ozgPerson.plz;
    antragData.strasse = antrag.ozgPerson.strasse;
    antragData.hnr = antrag.ozgPerson.hnr;
  }

  private changeAntragstellerMainDataFromOzg(antrag: AntragWithXmlData): void {
    this.editAntragsteller = true;
    this.isEdit = true;
    this.antragsteller.organisation =
      antrag.ozgPerson.organisation == null
        ? antrag.antragsteller.organisation
        : antrag.ozgPerson.organisation;
    this.antragsteller.anrede =
      antrag.ozgPerson.anrede == null
        ? antrag.antragsteller.anrede
        : antrag.ozgPerson.anrede;
    this.antragsteller.vorname =
      antrag.ozgPerson.vorname == null
        ? antrag.antragsteller.vorname
        : antrag.ozgPerson.vorname;
    this.antragsteller.nachname =
      antrag.ozgPerson.nachname == null
        ? antrag.antragsteller.nachname
        : antrag.ozgPerson.nachname;

    if (antrag.vertrauensniveau == 'NIEDRIG') {
      this.antragsteller.outputChannel = 'POST';
    } else {
      this.antragsteller.outputChannel = antrag.ozgServiceType;
    }
    this.resolveShowDokumentHochladen(
      this.originalData.postfach,
      antrag.postfach,
      this.antragsteller,
      this.originalData.antragsteller.organization,
    );
  }

  private changeBevollMainDataFromOzg(antrag: AntragWithXmlData): void {
    this.editBevollmaechtigter = true;
    this.isEdit = true;

    this.bevollmaechtigter.organisation =
      antrag.ozgPerson.organisation == null
        ? antrag.bevollmaechtigter.organisation
        : antrag.ozgPerson.organisation;
    this.bevollmaechtigter.anrede =
      antrag.ozgPerson.anrede == null
        ? antrag.bevollmaechtigter.anrede
        : antrag.ozgPerson.anrede;
    this.bevollmaechtigter.vorname =
      antrag.ozgPerson.vorname == null
        ? antrag.bevollmaechtigter.vorname
        : antrag.ozgPerson.vorname;
    this.bevollmaechtigter.nachname =
      antrag.ozgPerson.nachname == null
        ? antrag.bevollmaechtigter.nachname
        : antrag.ozgPerson.nachname;
    if (antrag.vertrauensniveau == 'NIEDRIG') {
      this.bevollmaechtigter.outputChannel = 'POST';
    } else {
      this.bevollmaechtigter.outputChannel = antrag.ozgServiceType;
    }
    this.resolveShowDokumentHochladen(
      this.originalData.postfach,
      antrag.postfach,
      this.bevollmaechtigter,
      this.originalData.bevollmaechtigter.organization,
    );
  }

  private openAntragFailure(error: HttpErrorResponse): void {
    if (error.status === 403) {
      this.router.navigate(['uebersicht']);
      this.isLoading = false;
      return;
    }
    this.isLoading = false;
    this.showAlertMessage('Antragsformulardaten können nicht geparsed werden.');
  }

  showPdf(): void {
    this.isLoading = true;
    const subscription = this.backendService
      .getPdf(this.antragDetail.viewLink)
      .subscribe({
        next: (response) => this.displayPdf(response),
        error: (error) =>
          this.handlePdfWarning(
            error,
            'Druckquittung generierung fehlgeschlagen.',
          ),
        complete: () => {
          subscription.unsubscribe();
        },
      });
  }

  private handlePdfWarning(
    error: HttpErrorResponse,
    defaultMessage: string,
  ): void {
    if (error.status !== 200) {
      this.isLoading = false;
      this.showAlertMessage(defaultMessage);
    }
  }

  displayPdf(response: HttpResponse<ArrayBuffer>): void {
    const contentType: string = response.headers.get('Content-Type');
    const blob = new Blob([response.body], { type: contentType });
    let fileName = response.headers.get('Content-Disposition');
    if (fileName) {
      fileName = fileName.slice(fileName.indexOf('=') + 1);
    }
    const linkElement = document.createElement('a');
    const url = window.URL.createObjectURL(blob);
    linkElement.setAttribute('href', url);
    linkElement.setAttribute('download', fileName);

    const clickEvent = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: false,
    });
    linkElement.dispatchEvent(clickEvent);
    this.handlePdfSuccess('Druckquittung erfolgreich heruntergeladen.');
  }

  private handlePdfSuccess(defaultMessage: string): void {
    this.isLoading = false;
    this.showSuccessMessage(defaultMessage);
  }

  private showSuccessMessage(message: string): void {
    this.snackBar.open(message, '', {
      duration: 2000,
      panelClass: ['success'],
    });
  }

  private showAlertMessage(message: string): void {
    this.snackBar.open(message, 'X', { duration: 2000, panelClass: ['alert'] });
  }

  showVnPdf(element: Antrag): void {
    const subscription = this.backendService
      .getPdf(element.viewVnLink)
      .subscribe({
        next: (response) => this.displayPdf(response),
        complete: () => {
          subscription.unsubscribe();
        },
      });
  }

  getVnLinkTarget(element: Antrag): void {
    this.isLoading = true;
    const subscription = this.backendService
      .getVnLinkDataExist(element.kennzeichen, element.vorgangsnummer)
      .subscribe({
        next: () => this.displayVnPage(element.submitVnLink),
        error: (error) =>
          this.handlePdfWarning(
            error,
            'Die Daten sind nicht verfügbar, bitte versuchen Sie es später erneut.',
          ),
        complete: () => {
          subscription.unsubscribe();
        },
      });
  }

  displayVnPage(link: string): void {
    this.isLoading = false;
    window.open(link, '_blank');
  }

  updateAntragsteller(person: Antragsteller) {
    if (person == null) {
      this.isSaveEnabled = false;
      return;
    }
    this.resolveShowDokumentHochladen(
      null,
      null,
      person,
      this.originalData.antragsteller?.organization,
    );

    this.isSaveEnabled = true;
    this.antragsteller = {
      ...person,
    };
    this.detail.antragsteller = this.antragsteller;
  }

  updateBevollmaechtigter(person: Bevollmaechtigter) {
    if (person == null) {
      this.isSaveEnabled = false;
      return;
    }

    this.resolveShowDokumentHochladen(
      null,
      null,
      person,
      this.originalData.bevollmaechtigter?.organization,
    );

    this.isSaveEnabled = true;
    this.bevollmaechtigter = {
      ...person,
    };
    this.detail.bevollmaechtigter = this.bevollmaechtigter;
  }

  resolveShowDokumentHochladen(
    originalPostfach: string,
    newPostfach: string,
    person: Antragsteller | Bevollmaechtigter,
    originalOrganization: string,
  ) {
    if (
      (originalPostfach == null && originalPostfach != null) ||
      (originalPostfach != null &&
        newPostfach != null &&
        originalPostfach != newPostfach) ||
      (originalPostfach == null && newPostfach == null)
    ) {
      if (this.editAntragsteller) {
        this.isNameOrVornamaOrOrganizationChanged =
          this.resolveNameOrVornameOrOrganizationChanged(
            person,
            originalOrganization,
            this.originalData.antragsteller?.vorname,
            this.originalData.antragsteller?.nachname,
          );
      } else {
        this.isNameOrVornamaOrOrganizationChanged =
          this.resolveNameOrVornameOrOrganizationChanged(
            person,
            originalOrganization,
            this.originalData.bevollmaechtigter?.vorname,
            this.originalData.bevollmaechtigter?.nachname,
          );
      }
    }
  }

  resolveNameOrVornameOrOrganizationChanged(
    person: Antragsteller | Bevollmaechtigter,
    originalOrganization: string,
    originalVoname: string,
    originalNachname: string,
  ) {
    if (originalOrganization == null) {
      return (
        originalVoname != person.vorname ||
        originalNachname != person.nachname ||
        person.organisation != null
      );
    } else {
      return originalOrganization != person.organisation;
    }
  }

  closeAntragChange() {
    this.isEdit = !this.isEdit;
    this.editAntragsteller = false;
    this.editBevollmaechtigter = false;
    this.isAuthorized = false;
    this.isNameOrVornamaOrOrganizationChanged = false;
    this.router.navigate([], {
      queryParams: {
        authorized: null,
      },
      queryParamsHandling: 'merge',
    });
    this.subscribeInit();
  }

  saveAntragAndChangeEditState() {
    if (this.uploadComponent != null && this.uploadComponent.files != null) {
      this.files = this.uploadComponent.files;
    }
    this.isLoading = true;
    const antragDetail = this.detail;
    if (this.files != null && this.files.length > 0) {
      this.uploadService
        .upload(
          this.files,
          this.vorgangsnummer,
          this.editAntragsteller,
          this.kennzeichen,
        )
        .then((result) => {
          if (result === 0 || (result >= 200 && result < 400)) {
            const subscription = this.backendService
              .updatePersonlicheDaten(antragDetail)
              .subscribe({
                next: () => this.updateSuccess(),
                error: () => this.updateFailure(),
                complete: () => {
                  subscription.unsubscribe();
                },
              });
          } else {
            this.showAlertMessage('Dokument upload hat fehlgeschlagen.');
            this.isEdit = !this.isEdit;
            this.isLoading = false;
            this.subscribeInit();
            return;
          }
        });
    } else {
      const subscription = this.backendService
        .updatePersonlicheDaten(antragDetail)
        .subscribe({
          next: () => this.updateSuccess(),
          error: () => this.updateFailure(),
          complete: () => {
            subscription.unsubscribe();
          },
        });
    }
    this.isNameOrVornamaOrOrganizationChanged = false;
  }
  private updateSuccess(): void {
    this.isAuthorized = false;
    this.subscribeInit();
    this.isEdit = !this.isEdit;
    this.editAntragsteller = false;
    this.editBevollmaechtigter = false;
    this.showSuccessMessage('Persönliche Daten erfolgreich gespeichert.');
    this.router.navigate([], {
      queryParams: {
        authorized: null,
      },
      queryParamsHandling: 'merge',
    });
    return;
  }

  private updateFailure(): void {
    this.editAntragsteller = false;
    this.editBevollmaechtigter = false;
    this.subscribeInit();
    this.showAlertMessage(
      'Persönliche Daten  wurden nicht aktualisiert, bitte versuchen Sie es später erneut.',
    );
  }

  openStornoAntragDialog(antrag: Antrag): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = antrag.vorgangsnummer;
    dialogConfig.disableClose = true;
    const subscription = this.confirmStornoAntragComponent
      .open(ConfirmStornoAntragComponent, dialogConfig)
      .afterClosed()
      .subscribe({
        next: (dialogAction) => {
          if (dialogAction === 'confirm') {
            this.saveAntragStorno(antrag.vorgangsnummer);
          }
        },
        complete: () => {
          subscription.unsubscribe();
        },
      });
  }

  openChangePersonDataDialog(editAntragsteller: boolean): void {
    const dialogConfig = new MatDialogConfig();
    this.detail.editAntragsteller = editAntragsteller;
    dialogConfig.data = this.detail;
    dialogConfig.disableClose = true;
    const subscription = this.confirmPersonalData
      .open(ConfirmPersonalDataAuthComponent, dialogConfig)
      .afterClosed()
      .subscribe({
        next: (dialogAction) => {
          if (dialogAction === 'close') {
            this.subscribeInit();
          }
          if (dialogAction === 'confirm') {
            this.isEdit = true;
            if (this.antragDetail.isBevollmaechtigter && editAntragsteller) {
              this.editAntragsteller = true;
              this.editBevollmaechtigter = false;
              this.manualEditAntragsteller = true;
              this.antragsteller.outputChannel = 'POST';
              this.resolveShowDokumentHochladen(
                null,
                null,
                this.antragsteller,
                this.originalData.antragsteller?.organization,
              );
            }
            if (this.antragDetail.isBevollmaechtigter && !editAntragsteller) {
              this.editAntragsteller = false;
              this.editBevollmaechtigter = true;
              this.manualEditAntragsteller = false;
              this.bevollmaechtigter.outputChannel = 'POST';
              this.resolveShowDokumentHochladen(
                null,
                null,
                this.bevollmaechtigter,
                this.originalData.bevollmaechtigter?.organization,
              );
            }
          }
          if (!this.antragDetail.isBevollmaechtigter) {
            this.editAntragsteller = true;
            this.editBevollmaechtigter = false;
            this.manualEditAntragsteller = true;
            this.antragsteller.outputChannel = 'POST';
            this.resolveShowDokumentHochladen(
              null,
              null,
              this.antragsteller,
              this.originalData.antragsteller?.organization,
            );
          }
        },
        complete: () => {
          subscription.unsubscribe();
        },
      });
  }

  private saveAntragStorno(antrag: string) {
    const subscription = this.backendService.stornoAntraege(antrag).subscribe({
      next: () => this.stornoSuccess(),
      error: () => this.stornoFailure(),
      complete: () => {
        subscription.unsubscribe();
      },
    });
  }

  private stornoSuccess(): void {
    this.showSuccessMessage(
      'Die Stornierungsanfrage wurde erfasst. Über die Bearbeitung werden Sie informiert.',
    );
    this.subscribeInit();
  }

  private stornoFailure(): void {
    this.subscribeInit();
    this.showAlertMessage(
      'Die Stornierungsanfrage ist fehlgeschlagen. Bitte versuchen Sie es später noch einmal',
    );
  }

  isEnabledStorno(): boolean {
    return environment?.enableStorno;
  }

  changeDisableSpeichern(eventInput: boolean) {
    this.isSpeichernDisabled = eventInput;
  }

  isShowDokumentHochlade(): boolean {
    return (
      this.detail.antragData.status != '1' &&
      this.detail.antragData.status != '2' &&
      this.isNameOrVornamaOrOrganizationChanged
    );
  }

  isEnabledBearbeiten(): boolean {
    return environment?.enablePersonDataEdit;
  }
}
