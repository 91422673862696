<mat-toolbar fxLayoutAlign="center stretch" color="accent">
  <div
    fxLayoutAlign="end center"
    fxFlex="70%"
    fxFlex.md="75%"
    fxFlex.lt-md="100%"
  >
    <button mat-button (click)="buildInfo()">
      <small>VERSION</small>
    </button>
    <a [href]="urlDatenschutz" target="BAFA-Datenschutzerklärung" mat-button
      ><small>DATENSCHUTZERKLÄRUNG</small></a
    >
    <a [href]="urlImpressum" target="BAFA-Impressum" mat-button
      ><small>IMPRESSUM</small></a
    >
  </div>
</mat-toolbar>
