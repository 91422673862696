import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {BuildInfoData} from "../data/build.info.data";
import {dateTimeFormatDe} from "../utils";

@Component({
  selector: 'build-info',
  templateUrl: './build-info.component.html',
  styleUrls: ['./build-info.component.css'],
})
export class BuildInfoComponent {
  dateTimeFormatDe = "";

  constructor(
    private dialogRef: MatDialogRef<BuildInfoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: BuildInfoData) {

    this.dateTimeFormatDe = dateTimeFormatDe;
  }

  close(): void {
    this.dialogRef.close();
  }
}
