<div class="flex-column-center" role="main">

  <mat-card class="flex-column-center margin-50">
    <form [formGroup]="form" (ngSubmit)="onSubmit()" action="">

      <!-- title -->
      <mat-card-title class="flex-column-center mat-h1">
        {{textResources.title}}
      </mat-card-title>

      <mat-card-subtitle class="flex-column-center width-442">
        {{textResources.description}}
      </mat-card-subtitle>

      <mat-card-content>

        <!-- new password -->
        <mat-form-field class="width-100" [ngClass]="{'error': this.success == false}" appearance="fill">

          <mat-label>Neues Passwort</mat-label>
          <label>
            <input matInput required formControlName="newPassword" [type]="this.pwNew.hide ? 'password' : 'text'" [errorStateMatcher]="matcherNew">
          </label>

          <mat-hint>
            <mat-icon>info</mat-icon>
            Mindestanforderung: 8 Zeichen, enthält Klein-, Großbuchstaben und Sonderzeichen
          </mat-hint>

          <mat-error *ngIf="!this.success">
            <p><mat-icon>error</mat-icon> Mögliche Probleme:</p>
            <p>
              Der Link ist abgelaufen oder wurde bereits genutzt.<br/>
              → Fordern Sie unter <em>Login > Probleme mit der Anmeldung</em> einen neuen Link zum {{textResources.action}} Ihres Passwortes an.<br/>
            </p>
            <p>
              Das Passwort erfüllt nicht die Passwort-Regeln.<br/>
              → Prüfen Sie, ob Ihr neues Passwort die folgenden Mindestanforderungen erfüllt:<br/>
              8 Zeichen, enthält Klein-, Großbuchstaben und Sonderzeichen.
            </p>
          </mat-error>

          <button mat-icon-button matSuffix type="button" (click)="this.pwNew.hide = !this.pwNew.hide" [attr.aria-pressed]="this.pwNew.hide"
            matTooltip="Passwort im Klartext anzeigen">
            <mat-icon>{{ this.pwNew.hide ? 'visibility_off' : 'visibility' }}</mat-icon>
          </button>

        </mat-form-field>

      </mat-card-content>

      <!-- buttons -->
      <mat-card-actions class="card-actions">
        <button mat-button class="button" type="button" color="primary" [routerLink]="['/login']">zum Login</button>
        <button mat-flat-button class="button" type="submit" color="primary" [disabled]="!this.form.valid">{{textResources.submit}}</button>
      </mat-card-actions>

    </form>

  </mat-card>
</div>
