<mat-form-field fxFlex="30" fxFlex.lt-md="100" appearance="fill">
  <mat-label>E-Mail-Adresse</mat-label>
  <label>
    <input
      matInput
      type="text"
      placeholder="Suchbegriff"
      [(ngModel)]="filter.email"
      (keyup)="onFilterEmailKeyUp()"
    />
  </label>
  <button
    *ngIf="filter.email"
    matSuffix
    mat-icon-button
    aria-label="Clear"
    (click)="deleteFilter()"
  >
    <mat-icon matTooltip="Filter löschen">close</mat-icon>
  </button>
  <button *ngIf="!filter.email" matSuffix mat-icon-button disabled>
    <mat-icon matTooltip="Email nach Suchbegriff filtern">search</mat-icon>
  </button>
</mat-form-field>

<mat-form-field
  fxFlex="20"
  fxFlex.lt-md="100"
  appearance="fill"
  *ngIf="!isSuperUser"
>
  <mat-label>Rolle</mat-label>
  <mat-select
    #rolleFilterEvent
    (selectionChange)="selectionChangeRolle($event.value)"
  >
    <mat-option>(Alle)</mat-option>
    <mat-option *ngFor="let rolle of roleList" value="{{ rolle.key }}">{{
      rolle.tooltip
    }}</mat-option>
  </mat-select>
</mat-form-field>

<mat-form-field fxFlex="20" fxFlex.lt-md="100" appearance="fill">
  <mat-label>Verfahren</mat-label>
  <mat-select
    #verfahrenFilterEvent
    (selectionChange)="selectionChangeKennzeichen($event.value)"
  >
    <mat-option>(Alle)</mat-option>
    <mat-option
      *ngFor="let kennzeichen of verfahrenList"
      value="{{ kennzeichen.key }}"
      >{{ kennzeichen.text }}</mat-option
    >
  </mat-select>
</mat-form-field>

<mat-form-field fxFlex="30" fxFlex.lt-md="100" appearance="fill">
  <mat-label>Status</mat-label>
  <mat-select
    #statusFilterEvent
    (selectionChange)="selectionChangeStatus($event.value)"
  >
    <mat-option>(Alle)</mat-option>
    <mat-option *ngFor="let status of statusList" value="{{ status.key }}">{{
      status.name
    }}</mat-option>
  </mat-select>
</mat-form-field>
