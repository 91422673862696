import { Countries } from './country';

export const countries: Countries[] = [
  { code: 'AF', name: 'Afghanistan' },
  { code: 'EG', name: 'Ägypten' },
  { code: 'AL', name: 'Albanien' },
  { code: 'DZ', name: 'Algerien' },
  { code: 'UM', name: 'Amerik. Überseeinseln, Kleinere' },
  { code: 'VI', name: 'Amerikan. Jungferninseln' },
  { code: 'AS', name: 'Amerikanisch-Samoa' },
  { code: 'AD', name: 'Andorra' },
  { code: 'AO', name: 'Angola' },
  { code: 'AI', name: 'Anguilla' },
  { code: 'AQ', name: 'Antarktis' },
  { code: 'AG', name: 'Antigua und Barbuda' },
  { code: 'GQ', name: 'Äquatorialguinea' },
  { code: 'SY', name: 'Arabische Rep. Syrien' },
  { code: 'AR', name: 'Argentinien' },
  { code: 'AM', name: 'Armenien' },
  { code: 'AW', name: 'Aruba' },
  { code: 'AZ', name: 'Aserbaidschan' },
  { code: 'ET', name: 'Äthiopien' },
  { code: 'QZ', name: 'Ausserhalb nat. Hoheitsgebiet' },
  { code: 'AU', name: 'Australien' },
  { code: 'BS', name: 'Bahamas' },
  { code: 'BH', name: 'Bahrain' },
  { code: 'BD', name: 'Bangladesch' },
  { code: 'BB', name: 'Barbados' },
  { code: 'BY', name: 'Belarus' },
  { code: 'BE', name: 'Belgien' },
  { code: 'BZ', name: 'Belize' },
  { code: 'BJ', name: 'Benin' },
  { code: 'BM', name: 'Bermuda' },
  { code: 'PS', name: 'Besetzte Palästinensische Geb.' },
  { code: 'BT', name: 'Bhutan' },
  { code: 'VE', name: 'Bolivarische Republik Venezuela' },
  { code: 'BQ', name: 'Bonaire, Sint Eustatius und Saba' },
  { code: 'BA', name: 'Bosnien U.Herzegowina' },
  { code: 'BW', name: 'Botsuana' },
  { code: 'BV', name: 'Bouvetinsel' },
  { code: 'BR', name: 'Brasilien' },
  { code: 'IO', name: 'Brit. Territorium im Ind. Ozean' },
  { code: 'VG', name: 'Britische Jungferninseln' },
  { code: 'BN', name: 'Brunei (Darussalam)' },
  { code: 'BG', name: 'Bulgarien' },
  { code: 'BF', name: 'Burkina Faso' },
  { code: 'BI', name: 'Burundi' },
  { code: 'XC', name: 'Ceuta' },
  { code: 'CL', name: 'Chile' },
  { code: 'CK', name: 'Cookinseln' },
  { code: 'CR', name: 'Costa Rica' },
  { code: 'CI', name: "Côte d'Ivoire" },
  { code: 'CW', name: 'Curacao' },
  { code: 'DK', name: 'Dänemark' },
  { code: 'CD', name: 'Dem. Rep. Kongo' },
  { code: 'KP', name: 'Dem. Volksrep. Korea' },
  { code: 'LA', name: 'Dem. Volksrep. Laos' },
  { code: 'DE', name: 'Deutschland' },
  { code: 'DM', name: 'Dominica' },
  { code: 'DO', name: 'Dominikanische Republik' },
  { code: 'DJ', name: 'Dschibuti' },
  { code: 'EC', name: 'Ecuador' },
  { code: 'SV', name: 'El Salvador' },
  { code: 'ER', name: 'Eritrea' },
  { code: 'EE', name: 'Estland' },
  { code: 'FK', name: 'Falklandinseln' },
  { code: 'FO', name: 'Färöer' },
  { code: 'FJ', name: 'Fidschi' },
  { code: 'FI', name: 'Finnland' },
  { code: 'FM', name: 'Föder. Staaten v. Mikronesien' },
  { code: 'FR', name: 'Frankreich' },
  { code: 'TF', name: 'Französische Süd- und Antarktisgebiete' },
  { code: 'PF', name: 'Französisch-Polynesien' },
  { code: 'GA', name: 'Gabun' },
  { code: 'GM', name: 'Gambia' },
  { code: 'GE', name: 'Georgien' },
  { code: 'GH', name: 'Ghana' },
  { code: 'GI', name: 'Gibraltar' },
  { code: 'GD', name: 'Grenada' },
  { code: 'GR', name: 'Griechenland' },
  { code: 'GL', name: 'Grönland' },
  { code: 'GU', name: 'Guam' },
  { code: 'GT', name: 'Guatemala' },
  { code: 'GN', name: 'Guinea' },
  { code: 'GW', name: 'Guinea-Bissau' },
  { code: 'GY', name: 'Guyana' },
  { code: 'HT', name: 'Haiti' },
  { code: 'HM', name: 'Heard und McDonald Inseln' },
  { code: 'HN', name: 'Honduras' },
  { code: 'HK', name: 'Hongkong' },
  { code: 'IN', name: 'Indien' },
  { code: 'ID', name: 'Indonesien' },
  { code: 'IQ', name: 'Irak' },
  { code: 'IE', name: 'Irland' },
  { code: 'IR', name: 'Islamische Rep. Iran' },
  { code: 'IS', name: 'Island' },
  { code: 'IL', name: 'Israel' },
  { code: 'IT', name: 'Italien' },
  { code: 'JM', name: 'Jamaika' },
  { code: 'JP', name: 'Japan' },
  { code: 'YE', name: 'Jemen' },
  { code: 'JO', name: 'Jordanien' },
  { code: 'KY', name: 'Kaimaninseln' },
  { code: 'KH', name: 'Kambodscha' },
  { code: 'CM', name: 'Kamerun' },
  { code: 'CA', name: 'Kanada' },
  { code: 'CV', name: 'Kap Verde' },
  { code: 'KZ', name: 'Kasachstan' },
  { code: 'QA', name: 'Katar' },
  { code: 'KE', name: 'Kenia' },
  { code: 'KG', name: 'Kirgisistan' },
  { code: 'KI', name: 'Kiribati' },
  { code: 'CC', name: 'Kokosinseln (Keelinginseln)' },
  { code: 'CO', name: 'Kolumbien' },
  { code: 'KM', name: 'Komoren' },
  { code: 'XK', name: 'Kosovo' },
  { code: 'HR', name: 'Kroatien' },
  { code: 'CU', name: 'Kuba' },
  { code: 'KW', name: 'Kuwait' },
  { code: 'LS', name: 'Lesotho' },
  { code: 'LV', name: 'Lettland' },
  { code: 'LB', name: 'Libanon' },
  { code: 'LR', name: 'Liberia' },
  { code: 'LY', name: 'Libyen' },
  { code: 'LI', name: 'Liechtenstein' },
  { code: 'LT', name: 'Litauen' },
  { code: 'LU', name: 'Luxemburg' },
  { code: 'MO', name: 'Macau' },
  { code: 'MG', name: 'Madagaskar' },
  { code: 'MW', name: 'Malawi' },
  { code: 'MY', name: 'Malaysia' },
  { code: 'MV', name: 'Malediven' },
  { code: 'ML', name: 'Mali' },
  { code: 'MT', name: 'Malta' },
  { code: 'MA', name: 'Marokko' },
  { code: 'MH', name: 'Marshallinseln' },
  { code: 'MR', name: 'Mauretanien' },
  { code: 'MU', name: 'Mauritius' },
  { code: 'YT', name: 'Mayotte' },
  { code: 'XL', name: 'Melilla' },
  { code: 'MX', name: 'Mexiko' },
  { code: 'MC', name: 'Monaco' },
  { code: 'MN', name: 'Mongolei' },
  { code: 'ME', name: 'Montenegro' },
  { code: 'MS', name: 'Montserrat' },
  { code: 'MZ', name: 'Mosambik' },
  { code: 'MM', name: 'Myanmar' },
  { code: 'NA', name: 'Namibia' },
  { code: 'NR', name: 'Nauru' },
  { code: 'NP', name: 'Nepal' },
  { code: 'NC', name: 'Neukaledonien' },
  { code: 'NZ', name: 'Neuseeland' },
  { code: 'NI', name: 'Nicaragua' },
  { code: 'NL', name: 'Niederlande' },
  { code: 'NE', name: 'Niger' },
  { code: 'NG', name: 'Nigeria' },
  { code: 'NU', name: 'Niue' },
  { code: 'MK', name: 'Nord Mazedonien' },
  { code: 'MP', name: 'Nördliche Marianen' },
  { code: 'NF', name: 'Norfolkinsel' },
  { code: 'NO', name: 'Norwegen' },
  { code: 'OM', name: 'Oman' },
  { code: 'AT', name: 'Österreich' },
  { code: 'PK', name: 'Pakistan' },
  { code: 'PW', name: 'Palau' },
  { code: 'PA', name: 'Panama' },
  { code: 'PG', name: 'Papua-Neuguinea' },
  { code: 'PY', name: 'Paraguay' },
  { code: 'PE', name: 'Peru' },
  { code: 'PH', name: 'Philippinen' },
  { code: 'PN', name: 'Pitcairn' },
  { code: 'BO', name: 'Plurinationaler Staat Bolivien' },
  { code: 'PL', name: 'Polen' },
  { code: 'PT', name: 'Portugal' },
  { code: 'CG', name: 'Republik Kongo' },
  { code: 'KR', name: 'Republik Korea' },
  { code: 'MD', name: 'Republik Moldau' },
  { code: 'RW', name: 'Ruanda' },
  { code: 'RO', name: 'Rumänien' },
  { code: 'RU', name: 'Russische Föderation' },
  { code: 'SB', name: 'Salomonen' },
  { code: 'ZM', name: 'Sambia' },
  { code: 'WS', name: 'Samoa' },
  { code: 'SM', name: 'San Marino' },
  { code: 'ST', name: 'Sao Tome und Principe' },
  { code: 'SA', name: 'Saudi-Arabien' },
  { code: 'SE', name: 'Schweden' },
  { code: 'CH', name: 'Schweiz' },
  { code: 'SN', name: 'Senegal' },
  { code: 'RS', name: 'Serbien' },
  { code: 'SC', name: 'Seychellen' },
  { code: 'SL', name: 'Sierra Leone' },
  { code: 'ZW', name: 'Simbabwe' },
  { code: 'SG', name: 'Singapur' },
  { code: 'SK', name: 'Slowakei' },
  { code: 'SI', name: 'Slowenien' },
  { code: 'SO', name: 'Somalia' },
  { code: 'ES', name: 'Spanien' },
  { code: 'LK', name: 'Sri Lanka' },
  { code: 'SH', name: 'St. Helena, Ascension und Tristan Da Cunha' },
  { code: 'KN', name: 'St. Kitts und Nevis' },
  { code: 'SX', name: 'St. Martin (Niederländischer Teil)' },
  { code: 'PM', name: 'St. Pierre und Miquelon' },
  { code: 'VC', name: 'St. Vincent U. D. Grenadinen' },
  { code: 'BL', name: 'St.Barthelemy' },
  { code: 'LC', name: 'St.Lucia' },
  { code: 'ZA', name: 'Südafrika' },
  { code: 'SD', name: 'Sudan' },
  { code: 'GS', name: 'Südgeorgien u. südl. Sandwich' },
  { code: 'SS', name: 'Südsudan' },
  { code: 'SR', name: 'Suriname' },
  { code: 'SZ', name: 'Swasiland' },
  { code: 'TJ', name: 'Tadschikistan' },
  { code: 'TW', name: 'Taiwan' },
  { code: 'TH', name: 'Thailand' },
  { code: 'TL', name: 'Timor-Leste' },
  { code: 'TG', name: 'Togo' },
  { code: 'TK', name: 'Tokelau' },
  { code: 'TO', name: 'Tonga' },
  { code: 'TT', name: 'Trinidad und Tobago' },
  { code: 'TD', name: 'Tschad' },
  { code: 'CZ', name: 'Tschechische Republik' },
  { code: 'TN', name: 'Tunesien' },
  { code: 'TR', name: 'Türkei' },
  { code: 'TM', name: 'Turkmenistan' },
  { code: 'TC', name: 'Turks- und Caicosinseln' },
  { code: 'TV', name: 'Tuvalu' },
  { code: 'UG', name: 'Uganda' },
  { code: 'UA', name: 'Ukraine' },
  { code: 'HU', name: 'Ungarn' },
  { code: 'UY', name: 'Uruguay' },
  { code: 'UZ', name: 'Usbekistan' },
  { code: 'VU', name: 'Vanuatu' },
  { code: 'VA', name: 'Vatikanstadt' },
  { code: 'AE', name: 'Vereinigte Arabische Emirate' },
  { code: 'TZ', name: 'Vereinigte Rep. Tansania' },
  { code: 'US', name: 'Vereinigte Staaten' },
  { code: 'GB', name: 'Vereinigtes Königreich' },
  { code: 'VN', name: 'Vietnam' },
  { code: 'CN', name: 'Volksrepublik China' },
  { code: 'WF', name: 'Wallis und Futuna' },
  { code: 'CX', name: 'Weihnachtsinsel' },
  { code: 'EH', name: 'Westsahara' },
  { code: 'CF', name: 'Zentralafrikanische Republik' },
  { code: 'CY', name: 'Zypern' },
];
