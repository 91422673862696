import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'confirm-send-email',
  templateUrl: './confirm-send-email.component.html',
  standalone: false,
})
export class ConfirmSendEmailComponent {
  constructor(
    private dialogRef: MatDialogRef<ConfirmSendEmailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: string,
  ) {}

  public close(): void {
    this.dialogRef.close('close');
  }

  public confirm(): void {
    this.dialogRef.close('confirm');
  }
}
