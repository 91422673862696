<head>
  <meta name="Clear-Site-Data" content="*" />
</head>

<div role="main" fxFlexFill fxLayout="row" fxLayoutAlign="center">
  <div
    fxFlex="50%"
    fxFlex.md="100%"
    fxFlex.sm="100%"
    fxLayout="column"
    fxLayoutAlign="center"
  >
    <mat-card fxFlexOffset="50px" class="dialog-box">
      <!-- title -->
      <mat-card-title fxLayoutAlign="center">Abgemeldet</mat-card-title>

      <mat-card-subtitle
        fxLayoutAlign="center"
        fxFlexOffset="30px"
        class="mat-body-1"
      >
        Sie wurden abgemeldet. Um sich erneut anzumelden, klicken Sie auf
        Anmelden.
      </mat-card-subtitle>

      <!-- buttons -->
      <mat-card-actions class="card-actions">
        <button
          mat-button
          class="button"
          type="button"
          color="primary"
          [routerLink]="['/request-reset']"
        >
          Probleme mit der Anmeldung?
        </button>
        <button
          mat-flat-button
          class="button"
          type="submit"
          (click)="this.goToLogin()"
          color="primary"
        >
          Anmelden
        </button>
      </mat-card-actions>
    </mat-card>
  </div>
</div>
