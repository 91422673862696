import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {newAntragLink} from "../shared/data/newAntragLink";
import {AntragOverviewResult} from "../shared/data/antrag.overview.result";
import {AllowedTypes} from "../shared/data/antrag.allowedTypes";

@Component({
  selector: 'confirm-new-antrag',
  templateUrl: './confirm-new-antrag.component.html',
  styleUrls: ['./confirm-new-antrag.component.css'],
})
export class ConfirmNewAntragComponent {

  newAntragLinkType: AllowedTypes;
  newAntragLinkTypes: AllowedTypes[];
  bevollmaechtigterModeForNewAntrag = false;
  allNewAntragLinks: newAntragLink[];
  actualNewLink: string;
  bevollmaechtigterMode = true;


  constructor(
    private dialogRef: MatDialogRef<ConfirmNewAntragComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AntragOverviewResult) {
  }

  ngOnInit(): void {
    if (this.data.allowedTypes != null) {
      this.newAntragLinkTypes = this.data.allowedTypes;
      this.newAntragLinkType = null;
      this.allNewAntragLinks = this.data.createAntragLinks;
      this.actualNewLink = this.allNewAntragLinks.filter(link => link.isBevollmaechtigter &&
        link.verfahrenskennzeichen === this.newAntragLinkType?.key)[0]?.url;
    }
  }

  close(): void {
    this.dialogRef.close('close');
  }

  confirm(): void {
    this.dialogRef.close('confirm');
  }

  changeNewAntragLink(): void {
    if (this.bevollmaechtigterModeForNewAntrag) {
      this.actualNewLink = this.allNewAntragLinks.filter(link => link.isBevollmaechtigter === 'true' &&
        link.verfahrenskennzeichen === this.newAntragLinkType?.key)[0]?.url;
    } else {
      this.actualNewLink = this.allNewAntragLinks.filter(link => link.isBevollmaechtigter === 'false' &&
        link.verfahrenskennzeichen === this.newAntragLinkType?.key)[0]?.url;
    }
  }
}
