import { BackendService } from '../shared/backend.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpErrorResponse } from '@angular/common/http';
import {ClientErrorMessage} from '../shared/data/clientErrorMessage';

@Component({
  selector: 'app-request-password-reset',
  templateUrl: './request-password-reset.component.html',
  styleUrls: ['./request-password-reset.component.css']
})
export class RequestPasswordResetComponent implements OnInit {

  form: FormGroup;
  success: boolean;

  constructor(
    private fb: FormBuilder,
    private backend: BackendService,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      email: ['', Validators.required],
    });
  }

  onSubmit(): void {
    if (this.form.valid) {
      this.backend.requestReset(this.form.get('email').value, null)
        .subscribe(stream => this.onSuccess(stream), error => this.onFailure(error));
    }
  }

  onFailure(error: HttpErrorResponse): void {
    this.success = false;
    this.handleHttpError(error, 'Die E-Mail konnte nicht gesendet werden.');
  }

  onSuccess(response: boolean): void {
    this.success = response;
    if (response) {
      this.showSuccessMessage('Es wurde eine E-Mail an Sie gesendet.');
    } else {
      this.showAlertMessage('Die E-Mail konnte nicht gesendet werden.');
    }
  }

  private handleHttpError(error: HttpErrorResponse, defaultMessage: string): void {
    if (error.status === 400) {
      const clientError = error.error as ClientErrorMessage;
      if (clientError != null) {
        this.showAlertMessage(clientError.message);
        return;
      }
    }
    this.showAlertMessage(defaultMessage);
  }

  private showSuccessMessage(message: string): void {
    this.snackBar.open(message, '',
      { duration: 2000, panelClass: ['success'] });
  }

  private showAlertMessage(message: string): void {
    this.snackBar.open(message, 'X',
      { duration: 10000, panelClass: ['alert'] });
  }

}
