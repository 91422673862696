
<mat-card class="mat-elevation-z0"  fxFlex.md="75%" fxFlex.lt-md="100%">
  <!-- title -->
  <mat-card-title>BAFA Portal</mat-card-title>
  <mat-card-subtitle>(c) 2022 Bundesamt für Wirtschaft und Ausfuhrkontrolle</mat-card-subtitle>
  <mat-card-content>
    <p>Version: {{data.version}}</p>
    <p>Datum: {{data.date | date : dateTimeFormatDe }}</p>
  </mat-card-content>
</mat-card>
<mat-dialog-actions align="end" >
  <button mat-raised-button color="primary" (click)="close()" class="close-button">Schließen</button>
</mat-dialog-actions>
