<div class="flex-column-center" role="main">

  <div *ngIf="success != undefined" >
    <mat-card class="flex-column-center margin-50" [ngClass]="success == false ? 'mat-error' : ''">

      <!-- title -->
      <mat-card-title class="flex-column-center mat-h1">
        Konto aktivieren
      </mat-card-title>

      <div *ngIf="success">
        <!-- content -->
        <mat-card-content class="flex-row width-100" fxLayoutAlign="center center">

          <mat-icon class="icon primary-dark">check_circle</mat-icon>
          <div class="mat-body-2">
            Herzlichen Glückwunsch, Ihr BAFA Benutzerkonto wurde aktiviert.<br/>
            Nun können Sie sich mit Ihrem Konto anmelden.
          </div>

        </mat-card-content>

        <!-- buttons -->
        <mat-card-actions class="flex-column-center">
          <button mat-flat-button class="button" type="submit" color="primary" [routerLink]="['/login']">Weiter</button>
        </mat-card-actions>
      </div>

      <div *ngIf="!success">
        <!-- content -->
        <mat-card-content class="flex-row width-100" fxLayoutAlign="center center">

          <mat-icon class="icon">error</mat-icon>
          <div class="mat-body-2">
            Ihr BAFA Benutzerkonto konnte nicht aktiviert werden.<br/>
            Bitte kontaktieren Sie uns.
          </div>

        </mat-card-content>

        <!-- buttons -->
        <mat-card-actions class="flex-column-center">
          <a href="https://www.bafa.de/DE/Service/Aufgabenuebersicht/modul_aufgabenuebersicht_node.html"
             mat-flat-button class="button" type="submit" color="warn">Zum Kontakt</a>
        </mat-card-actions>
      </div>

    </mat-card>
  </div>

</div>
