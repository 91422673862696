<mat-card
  fxFlex="600px"
  class="mat-elevation-z0 mat-dialog-container mat-card-none-shadow"
>
  <!-- title -->
  <mat-card-title *ngIf="!data.deleteRequestDate">
    Möchten Sie den Antrag mit der Vorgangsnummer
    {{ data.vorgangsnummer }} wirklich löschen?</mat-card-title
  >
  <mat-card-title *ngIf="data.deleteRequestDate">
    Möchten Sie das Löschen des Antrags mit der Vorgangsnummer
    {{ data.vorgangsnummer }} stornieren?</mat-card-title
  >

  <mat-card-content fxFlexOffset="30px" class="text-center mat-caption">
    <p *ngIf="!data.deleteRequestDate">
      Dieser Antrag wird innerhalb von 24 Stunden endgültig gelöscht. Nur bis
      dahin ist die Wiederherstellung möglich.
    </p>
  </mat-card-content>
  <mat-dialog-actions align="end">
    <button mat-raised-button mat-dialog-close>Abbrechen</button>
    <button
      *ngIf="!data.deleteRequestDate"
      mat-raised-button
      color="primary"
      (click)="confirm()"
    >
      Löschung anfordern
    </button>
    <button
      *ngIf="data.deleteRequestDate"
      mat-raised-button
      color="primary"
      (click)="confirm()"
    >
      Löschung stornieren
    </button>
  </mat-dialog-actions>
</mat-card>
