import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { FormGroup } from "@angular/forms";
import { BackendService } from "../shared/backend.service";
import { AntragWithXmlData } from "../shared/data/antragWithXmlData";
import { Observable, Subscription } from "rxjs";
import { Antrag } from "../shared/data/antrag";
import { Antragsteller } from "../shared/data/antragsteller";
import { Bevollmaechtigter } from "../shared/data/bevollmaechtigter";
import { HttpErrorResponse, HttpResponse } from "@angular/common/http";
import { MatSnackBar } from "@angular/material/snack-bar";
import {dateFormatDe} from "../shared/utils";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { ConfirmStornoAntragComponent } from "../confirm-storno-antrag/confirm-storno-antrag.component";
import { environment } from "../../environments/environment";
import { ConfirmPersonalDataAuth } from "../confirm-personal-data-auth/confirm-personal-data-auth";
import { UploadComponent } from "../upload/upload.component";
import { FileUploadService } from "../shared/upload.service";


@Component({
  selector: 'app-antrag-detail',
  templateUrl: './antrag-detail.component.html',
  styleUrls: ['./antrag-detail.component.css'],
})

export class AntragDetailComponent implements OnInit, OnDestroy {

  @ViewChild(UploadComponent) uplaodComponent;
  files: Array<File> = new Array<File>();

  form: FormGroup;
  vorgangsnummer: string;
  kennzeichen: string;
  isAuthorized: boolean;
  data: Observable<AntragWithXmlData> = new Observable<AntragWithXmlData>();
  detail: AntragWithXmlData;
  antragsteller: Antragsteller;
  bevollmaechtigter: Bevollmaechtigter;
  antragDetail: Antrag;
  isLoading = true;
  foerderbetrag: string;
  dateFormatDe = "";
  isEdit: boolean = false;
  isSaveEnabled: boolean = true;
  isOpenAntragFailure: boolean = true;
  editAntragsteller: boolean = false;
  editBevollmaechtigter: boolean = false;
  manualEditAntragsteller: boolean = false
  isSpeichernDisabled: boolean = true;
  subscription = new Subscription();
  isNameOrVornamaOrOrganizationChanged: boolean = false;
  antragstellerOriginalVorname: string;
  antragstellerOriginalNachname: string;
  antragstellerOriginalOrganization: string;
  bevollmaechtigterOriginalVorname: string;
  bevollmaechtigterOriginalNachname: string;
  originalPostfach: string;
  bevollmaechtigterOriginalOrganization:  string;
  typeOfOzgService: string;
  vertrauensniveau: string;


  constructor(
    public route: ActivatedRoute,
    private backendService: BackendService,
    private uploadService: FileUploadService,
    private snackBar: MatSnackBar,
    private router: Router,
    private confirmStornoAntragComponent: MatDialog,
    private confirmPersonalData: MatDialog

  ) {
    this.dateFormatDe = dateFormatDe;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnInit(): void {
    this.subscribeInit();
  }

  private subscribeInit(): void {
    this.isLoading = true;
    this.route.queryParams
      .subscribe(params => {
        this.parseQueryParams(params);
        this.subscribeCompleteAntragData(params),
          error => this.openAntragFailure(error);
      });
  }

  private subscribeCompleteAntragData(params: Params) {
    this.subscription.add(this.backendService.getCompleteAntragByVorgangsnummerAndType(params.vorgangsnummer, params.type)
      .subscribe(antrag => {
        if (this.isAuthorized && antrag.ozgServiceType == null) {
          this.isAuthorized = false;
          this.router.navigate([], {
            queryParams: {
              'authorized': null,
            },
            queryParamsHandling: 'merge'
          })
        }

        //Load data from Antrag
        this.loadDataFromAntragDetail(antrag);
        // Change person data from OZG
        if (this.isAuthorized && !antrag.antragData.isBevollmaechtigter) {
          this.changeAntragstellerMainDataFromOzg(antrag);
          this.changeAntragstellerAddresseDataFromOzg(antrag);
        }
        if (this.isAuthorized && antrag.antragData.isBevollmaechtigter) {
          this.changeBevollMainDataFromOzg(antrag);
          this.changeBevollAddresseDataFromOzg(antrag);
        }
      }, error => this.openAntragFailure(error)));
  }

  private parseQueryParams(params: Params) {
    this.vorgangsnummer = params.vorgangsnummer;
    this.kennzeichen = params.type;
    if (params.authorized == null) {
      this.isAuthorized = false;
    } else {
      this.isAuthorized = params.authorized;
    }
  }

  private loadDataFromAntragDetail(antrag: AntragWithXmlData): void {
    this.detail = antrag;
    this.antragsteller = antrag.antragsteller;
    this.antragstellerOriginalVorname = antrag.antragsteller.vorname;
    this.antragstellerOriginalNachname = antrag.antragsteller.nachname
    this.antragstellerOriginalOrganization = antrag.antragsteller.organisation;
    this.originalPostfach = antrag.postfach;
    this.bevollmaechtigter = antrag.bevollmaechtigter;
    this.bevollmaechtigterOriginalVorname = antrag.bevollmaechtigter.vorname
    this.bevollmaechtigterOriginalNachname = antrag.bevollmaechtigter.nachname;
    this.bevollmaechtigterOriginalOrganization = antrag.bevollmaechtigter.organisation;
    this.typeOfOzgService = antrag.ozgServiceType;
    this.vertrauensniveau = antrag.vertrauensniveau;
    this.antragDetail = antrag.antragData;
    this.foerderbetrag = antrag.foerderbetrag;
    this.isOpenAntragFailure = false;
    this.isLoading = false;
  }

  private changeAntragstellerAddresseDataFromOzg(antrag: AntragWithXmlData): void {
    this.antragsteller.land = antrag.ozgPerson.land;
    this.antragsteller.ort = antrag.ozgPerson.ort;
    this.antragsteller.plz = antrag.ozgPerson.plz;
    this.antragsteller.strasse = antrag.ozgPerson.strasse;
    this.antragsteller.hnr = antrag.ozgPerson.hnr;
    this.detail.antragsteller = this.antragsteller;
  }

  private changeAntragstellerMainDataFromOzg(antrag: AntragWithXmlData): void {
    this.editAntragsteller = true;
    this.isEdit = true;

    this.antragsteller.organisation = antrag.ozgPerson.organisation == null ? antrag.antragsteller.organisation : antrag.ozgPerson.organisation;
    this.antragsteller.anrede = antrag.ozgPerson.anrede == null ? antrag.antragsteller.anrede : antrag.ozgPerson.anrede;
    this.antragsteller.vorname = antrag.ozgPerson.vorname == null ? antrag.antragsteller.vorname : antrag.ozgPerson.vorname;
    this.antragsteller.nachname = antrag.ozgPerson.nachname == null ? antrag.antragsteller.nachname : antrag.ozgPerson.nachname;

    if (antrag.vertrauensniveau == 'NIEDRIG') {
      this.antragsteller.outputChannel = 'POST';
    } else {
      this.antragsteller.outputChannel = antrag.ozgServiceType;
    }


    if ((antrag.antragsteller.postfach == null && this.originalPostfach != null)
      || (this.originalPostfach != null && antrag.antragsteller.postfach != null && this.originalPostfach != antrag.antragsteller.postfach)) {
      this.resolveShowDokumentHochladen(this.antragsteller, this.antragstellerOriginalOrganization);
    }
  }

  private changeBevollAddresseDataFromOzg(antrag: AntragWithXmlData): void {
    this.bevollmaechtigter.land = antrag.ozgPerson.land;
    this.bevollmaechtigter.ort = antrag.ozgPerson.ort;
    this.bevollmaechtigter.plz = antrag.ozgPerson.plz;
    this.bevollmaechtigter.strasse = antrag.ozgPerson.strasse;
    this.bevollmaechtigter.hnr = antrag.ozgPerson.hnr;
    this.detail.bevollmaechtigter = this.bevollmaechtigter;
  }

  private changeBevollMainDataFromOzg(antrag: AntragWithXmlData): void {
    this.editBevollmaechtigter = true;
    this.isEdit = true;

    this.bevollmaechtigter.organisation = antrag.ozgPerson.organisation == null ? antrag.bevollmaechtigter.organisation : antrag.ozgPerson.organisation;
    this.bevollmaechtigter.anrede = antrag.ozgPerson.anrede == null ? antrag.bevollmaechtigter.anrede : antrag.ozgPerson.anrede;
    this.bevollmaechtigter.vorname = antrag.ozgPerson.vorname == null ? antrag.bevollmaechtigter.vorname : antrag.ozgPerson.vorname;
    this.bevollmaechtigter.nachname = antrag.ozgPerson.nachname == null ? antrag.bevollmaechtigter.nachname : antrag.ozgPerson.nachname;
    if (antrag.vertrauensniveau == 'NIEDRIG') {
      this.bevollmaechtigter.outputChannel = 'POST';
    } else {
      this.bevollmaechtigter.outputChannel = antrag.ozgServiceType;
    }

    if ((antrag.bevollmaechtigter.postfach == null && this.originalPostfach != null)
      || (this.originalPostfach != null && antrag.bevollmaechtigter.postfach != null && this.originalPostfach != antrag.bevollmaechtigter.postfach)) {
      this.resolveShowDokumentHochladen(this.bevollmaechtigter, this.bevollmaechtigterOriginalOrganization);
    }
  }

  private openAntragFailure(error: HttpErrorResponse): void {

    if (error.status === 403) {
      this.router.navigate(['uebersicht']);
      this.isLoading = false;
      return
    }
    this.isLoading = false;
    this.showAlertMessage('Antrags Formular-daten koennen nicht ge-parsed werden.');
  }

  showPdf(): void {
    this.isLoading = true;

    // @ts-ignore
    this.backendService.getPdf((this.antragDetail.viewLink), (err: HttpErrorResponse) =>
      this.handlePdfWarning(err, 'Druckquittung generierung fehlgeschlagen.'))
      .subscribe(response => this.displayPdf(response),
        error =>
          this.handlePdfWarning(error, 'Druckquittung generierung fehlgeschlagen.'));
  }

  private handlePdfWarning(error: HttpErrorResponse, defaultMessage: string): void {
    if (error.status !== 200) {
      this.isLoading = false;
      this.showAlertMessage(defaultMessage);
    }
  }

  displayPdf(response: HttpResponse<ArrayBuffer>): void {
    const contentType: string = response.headers.get('Content-Type');
    const blob = new Blob([response.body], { type: contentType });
    // we assume that header 'Content-Disposition' contains a value equal to 'filename='
    let fileName = response.headers.get('Content-Disposition');
    if (fileName) {
      fileName = fileName.slice(fileName.indexOf('=') + 1);
    }
    // simulate link click in order to set file name
    const linkElement = document.createElement('a');
    const url = window.URL.createObjectURL(blob);
    linkElement.setAttribute('href', url);
    linkElement.setAttribute('download', fileName);

    const clickEvent = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: false
    });
    linkElement.dispatchEvent(clickEvent);
    this.handlePdfSuccess('Druckquittung erfolgreich runtergeladen');
  }

  private handlePdfSuccess(defaultMessage: string): void {
    this.isLoading = false;
    this.showSuccessMessage(defaultMessage);
  }

  private showSuccessMessage(message: string): void {
    this.snackBar.open(message, '',
      { duration: 2000, panelClass: ['success'] });
  }

  private showAlertMessage(message: string): void {
    this.snackBar.open(message, 'X',
      { duration: 2000, panelClass: ['alert'] });
  }

  showVnPdf(element: Antrag): void {
    this.subscription.add(this.backendService.getPdf(element.viewVnLink).subscribe(response => this.displayPdf(response)));
  }

  getVnLinkTarget(element: Antrag): void {
    this.isLoading = true;
    // @ts-ignore
    this.backendService.getVnLinkDataExist(element.kennzeichen, element.vorgangsnummer, (err: HttpErrorResponse) =>
      this.handlePdfWarning(err, 'Die Daten sind nicht verfügbar, bitte versuchen Sie es später erneut.'))
      .subscribe(response => this.displayVnPage(element.submitVnLink),
        error =>
          this.handlePdfWarning(error, 'Die Daten sind nicht verfügbar, bitte versuchen Sie es später erneut.'));
  }

  displayVnPage(link: string): void {
    this.isLoading = false;
    window.open(link, "_blank");
  }

  updateAntragsteller(person: Antragsteller) {
    if (person == null) {
      this.isSaveEnabled = false;
      return;
    }

    this.resolveShowDokumentHochladen(person, this.antragstellerOriginalOrganization);

    this.isSaveEnabled = true;
    this.antragsteller = {
      ...person,
      organisation: person.organisation,
      nachname: person.nachname,
      vorname: person.vorname,
      hnr: person.hnr,
      plz: person.plz,
      ort: person.ort,
      strasse: person.strasse,
      land: person.land,
      vorwahl: person.vorwahl,
      outputChannel: person.outputChannel

    }
    this.detail.antragsteller = this.antragsteller;
  }


  updateBevollmaechtigter(person: Bevollmaechtigter) {
    if (person == null) {
      this.isSaveEnabled = false;
      return;
    }

    this.resolveShowDokumentHochladen(person, this.bevollmaechtigterOriginalOrganization);

    this.isSaveEnabled = true;
    this.bevollmaechtigter = {
      ...person,
      organisation: person.organisation,
      nachname: person.nachname,
      vorname: person.vorname,
      hnr: person.hnr,
      plz: person.plz,
      ort: person.ort,
      strasse: person.strasse,
      land: person.land,
      vorwahl: person.vorwahl,
      outputChannel: person.outputChannel
    }
    this.detail.bevollmaechtigter = this.bevollmaechtigter;
  }

  resolveShowDokumentHochladen(person: Antragsteller | Bevollmaechtigter, originalOrganization: string) {

    if (this.editAntragsteller) {
      this.isNameOrVornamaOrOrganizationChanged = this.resolveNameOrVornameOrOrganizationChanged(person, originalOrganization,
        this.antragstellerOriginalVorname, this.antragstellerOriginalNachname)
    } else {
      this.isNameOrVornamaOrOrganizationChanged = this.resolveNameOrVornameOrOrganizationChanged(person, originalOrganization,
        this.bevollmaechtigterOriginalVorname, this.bevollmaechtigterOriginalNachname)
    }
  }

  resolveNameOrVornameOrOrganizationChanged(person: Antragsteller | Bevollmaechtigter, originalOrganization: string,
                                            originalVoname: string, originalNachname: string) {
    if (originalOrganization == null) {
      if ((originalVoname != person.vorname
        || originalNachname != person.nachname) || person.organisation != null) {
        return true;
      } else {
        return false;
      }
    } else {
      if (originalOrganization != person.organisation) {
       return true;
      } else {
        return false;
      }
    }
  }

  closeAntragChange() {
    this.isEdit = !this.isEdit;
    this.editAntragsteller = false;
    this.editBevollmaechtigter = false;
    this.isAuthorized = false;
    this.isNameOrVornamaOrOrganizationChanged = false;
    this.router.navigate([], {
      queryParams: {
        'authorized': null,
      },
      queryParamsHandling: 'merge'
    })
    this.subscribeInit();
  }

  saveAntragAndChangeEditState() {

    if (this.uplaodComponent != null && this.uplaodComponent.files != null) {
      this.files = this.uplaodComponent.files;
    }
    this.isLoading = true;
    var antragDetail = this.detail;
    if (this.files != null && this.files.length > 0) {
      this.uploadService.upload(this.files, this.vorgangsnummer, this.editAntragsteller, this.kennzeichen)
        .then(result => {
          if (result === 0 || (result >= 200 && result < 400)) {
            this.subscription.add(this.backendService
              .updatePersonlicheDaten(antragDetail)
              .subscribe(data => this.updateSuccess(data), error => this.updateFailure(error)));

          } else {
            this.showAlertMessage('Dokument upload hat fehlgeschlagen.');
            this.isEdit = !this.isEdit;
            this.isLoading = false;
            this.subscribeInit();
            return;
          }
        }
        )
    } else {
      this.subscription.add(this.backendService
        .updatePersonlicheDaten(antragDetail)
        .subscribe(data => this.updateSuccess(data), error => this.updateFailure(error)));
    }
    this.isNameOrVornamaOrOrganizationChanged = false;

  }
  private updateSuccess(awx: AntragWithXmlData): void {
    this.isAuthorized = false;
    this.subscribeInit();
    this.isEdit = !this.isEdit;
    this.editAntragsteller = false;
    this.editBevollmaechtigter = false;
    this.showSuccessMessage('Persönliche Daten erfolgreich gespeichert');
    this.router.navigate([], {
      queryParams: {
        'authorized': null,
      },
      queryParamsHandling: 'merge'
    })
    return;
  }

  private updateFailure(awx: AntragWithXmlData): void {
    this.editAntragsteller = false;
    this.editBevollmaechtigter = false;
    this.subscribeInit();
    this.showAlertMessage('Persönliche Daten  wurden nicht aktualisiert, bitte versuchen Sie es später erneut.');
  }

  openStornoAntragDialog(antrag: Antrag): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = antrag.vorgangsnummer;
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    this.subscription.add(this.confirmStornoAntragComponent.open(ConfirmStornoAntragComponent, dialogConfig).afterClosed()
      .subscribe(dialogAction => {
        if (dialogAction === 'confirm') {
          this.saveAntragStorno(antrag.vorgangsnummer);
        }
      }));
  }

  openChangePersonDataDialog(editAntragsteller: boolean): void {
    const dialogConfig = new MatDialogConfig();
    this.detail.editAntragsteller = editAntragsteller;
    dialogConfig.data = this.detail;
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    this.subscription.add(this.confirmPersonalData.open(ConfirmPersonalDataAuth, dialogConfig).afterClosed().subscribe(dialogAction => {
      if (dialogAction === 'close') {
        this.subscribeInit();

      }
      if (dialogAction === 'confirm') {
        this.isEdit = true;
        if (this.antragDetail.isBevollmaechtigter && editAntragsteller) {
          this.editAntragsteller = true;
          this.editBevollmaechtigter = false;
          this.manualEditAntragsteller = true;
          this.antragsteller.outputChannel = 'POST';
        }
        if (this.antragDetail.isBevollmaechtigter && !editAntragsteller) {
          this.editAntragsteller = false;
          this.editBevollmaechtigter = true;
          this.manualEditAntragsteller = false;
          this.bevollmaechtigter.outputChannel = 'POST';
        }
      }
      if (!this.antragDetail.isBevollmaechtigter) {
        this.editAntragsteller = true;
        this.editBevollmaechtigter = false;
        this.manualEditAntragsteller = true;
        this.antragsteller.outputChannel = 'POST';

      }
    }));
  }

  private saveAntragStorno(antrag: String) {
    this.subscription.add(this.backendService
      .stornoAntraege(antrag)
      .subscribe(data => this.stornoSuccess(data), error => this.stornoFailure(error)));
  }

  private stornoSuccess(a): void {
    this.showSuccessMessage('Die Stornierungsanfrage wurde erfasst. Über die Bearbeitung werden Sie informiert.');
    this.subscribeInit();
  }

  private stornoFailure(error: any): void {
    this.subscribeInit();
    this.showAlertMessage('Die Stornierungsanfrage ist fehlgeschlagen. Bitte versuchen Sie es später noch einmal');
  }

  isEnabledStorno(): boolean {
    return environment?.enableStorno;
  }

  changeDisableSpeichern(eventInput: boolean) {
    this.isSpeichernDisabled = eventInput;
  }

  isShowDokumentHochlade(): boolean {
    return this.detail.antragData.status != '1' && this.detail.antragData.status != '2' && this.isNameOrVornamaOrOrganizationChanged;
  }

  isEnabledBearbeiten(): boolean {
    return environment?.enablePersonDataEdit;
  }
}
