export class Account {
  email: string;
  oldPassword: string;
  password: string;

  constructor(email: any, oldPassword: string, newPassword: string) {
    this.email = email;
    this.oldPassword = oldPassword;
    this.password = newPassword;
  }
}
