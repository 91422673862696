<div role="main" fxFlexFill fxLayout="row" fxLayoutAlign="center">
  <div
    fxFlex="40%"
    fxFlex.md="100%"
    fxFlex.sm="100%"
    fxLayout="column"
    fxLayoutAlign="center"
  >
    <mat-card fxFlexOffset="50px" class="dialog-box">
      <form [formGroup]="form" action="" fxLayout="column">
        <!-- title -->
        <mat-card-title fxLayoutAlign="center">
          {{ textResources.title }}
        </mat-card-title>

        <mat-card-subtitle
          fxFlexOffset="30px"
          fxLayoutAlign="center"
          class="mat-body-1"
        >
          {{ textResources.description }}
        </mat-card-subtitle>

        <mat-card-content fxLayout="column">
          <!-- new password -->
          <mat-form-field appearance="fill">
            <mat-label>Neues Passwort</mat-label>
            <label>
              <input
                matInput
                required
                formControlName="newPassword"
                [type]="this.pwNew.hide ? 'password' : 'text'"
                [errorStateMatcher]="matcherNew"
              />
            </label>
            <mat-hint>
              <mat-icon>info</mat-icon>
              Mindestanforderung: 8 Zeichen, enthält Klein-, Großbuchstaben und
              Sonderzeichen
            </mat-hint>
            <mat-error *ngIf="!this.success">
              <p><mat-icon>error</mat-icon> Mögliche Probleme:</p>
              <p>1. <b>Link-Probleme:</b></p>
              <p>
                Der Link ist abgelaufen oder wurde bereits genutzt.<br />
                → Fordern Sie unter
                <em><b>Login > Probleme mit der Anmeldung</b></em> einen neuen
                Link zum {{ textResources.action }} Ihres Passwortes an.<br />
              </p>
              <p>2. <b>Passwort-Regeln:</b></p>
              <p>
                Das Passwort erfüllt nicht die Passwort-Regeln.<br />
                → Prüfen Sie, ob Ihr neues Passwort die folgenden
                Mindestanforderungen erfüllt:<br />
                ▪ Mindestens 8 Zeichen<br />
                ▪ Enthält Klein- und Großbuchstaben<br />
                ▪ Enthält Sonderzeichen<br />
                ▪ Das Passwort darf keine unzulässigen Ziffernfolgen wie
                '12345' enthalten.
              </p>
            </mat-error>
            <button
              mat-icon-button
              matSuffix
              type="button"
              (click)="this.pwNew.hide = !this.pwNew.hide"
              [attr.aria-pressed]="this.pwNew.hide"
              matTooltip="Passwort im Klartext anzeigen"
            >
              <mat-icon>{{
                this.pwNew.hide ? 'visibility_off' : 'visibility'
              }}</mat-icon>
            </button>
          </mat-form-field>
          <!-- buttons -->
          <mat-card-actions
            class="card-actions"
            fxFlexOffset="{{ this.pwNew.error !== '' ? '320px' : '40px' }}"
          >
            <button
              mat-button
              class="button"
              type="button"
              color="primary"
              [routerLink]="['/login']"
            >
              Zum Login
            </button>
            <button
              mat-flat-button
              class="button"
              type="submit"
              color="primary"
              [disabled]="!this.form.valid || isLoading"
              (click)="onClick()"
            >
              <span *ngIf="!isLoading">{{ textResources.submit }}</span>
              <mat-spinner *ngIf="isLoading" diameter="20"></mat-spinner>
            </button>
          </mat-card-actions>
        </mat-card-content>
      </form>
    </mat-card>
  </div>
</div>
