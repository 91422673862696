import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'confirm-send-email',
  templateUrl: './confirm-send-email.component.html',
  styleUrls: ['./confirm-send-email.component.css'],
})
export class ConfirmSendEmailComponent {

  constructor(
    private dialogRef: MatDialogRef<ConfirmSendEmailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: string) {
  }

  close(): void {
    this.dialogRef.close('close');
  }

  confirm(): void {
    this.dialogRef.close('confirm');
  }
}
