import { Component, Inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from "@angular/material/radio";
import { MatCardModule } from "@angular/material/card";
import { MatOptionModule } from "@angular/material/core";
import { NgForOf, NgIf } from "@angular/common";
import { FlexModule } from "@angular/flex-layout";
import { MatButtonModule } from "@angular/material/button";
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import {getKenzeichenAgregateTypesForUserProfile} from "../shared/utils";

export interface Task {
  name: string;
  completed: boolean;
  subtasks?: Task[];
}

/**
 * @title Confirm role selection
 */
@Component({
  selector: 'confirm-administration-dialog',
  templateUrl: './confirm-administration-dialog.html',
  styleUrls: ['./confirm-administration-dialog.component.css'],
  standalone: true,
  imports: [MatCheckboxModule, FormsModule, MatRadioModule, MatCardModule, MatOptionModule, NgForOf, FlexModule, MatButtonModule, MatDialogModule, NgIf],
})
export class ConfirmAdministrationDialog {
  constructor(
    private dialogRef: MatDialogRef<ConfirmAdministrationDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.typeOfAccountSelected = this.data.accountType.toLowerCase();
    if (this.data.accountType.toLowerCase() == 'benutzer') {
      this.initialiseAvailableTypes(true);
    } else {
      this.initialiseAvailableTypes(false);

    }
  }
  typeOfAccountSelected: string;
  kennzeichenTypeSubtask = new Array<Task>();

  task: Task = {
    name: 'Alle auswählen',
    completed: false,
    subtasks: this.kennzeichenTypeSubtask,
  };

  allComplete(): boolean {
    return this.task.subtasks != null && this.task.subtasks.every(t => t.completed);
  }

  changeTypeOfAccountSelected(){
    this.initialiseAvailableTypes(true);
  }

  someComplete(): boolean {
    if (this.task.subtasks == null) {
      return false;
    }
    return this.task.subtasks.filter(t => t.completed).length > 0 && !this.allComplete();
  }

  setAll(completed: boolean) {
    if (this.task.subtasks == null) {
      return;
    }
    this.task.subtasks.forEach(t => (t.completed = completed));
  }

  close(): void {
    this.setAll(false)
    this.dialogRef.close('close');
  }

  confirm(): void {
    this.dialogRef.close({ result: 'confirm', data: this.task.subtasks.filter(t => t.completed), typeOfAccountSelected: this.typeOfAccountSelected });
  }

  initialiseAvailableTypes(resetSelection: boolean) {
    this.kennzeichenTypeSubtask = [];

    var data = getKenzeichenAgregateTypesForUserProfile(this.data.allUsedTypes);

    data.forEach(typeToAdd => {
      if (this.kennzeichenTypeSubtask.some(type => type.name === typeToAdd)) {
        return;
      }
      this.kennzeichenTypeSubtask.push({
        name: typeToAdd,
        completed: resetSelection ? false : this.data.formularTypes?.indexOf(typeToAdd) > -1
      } as Task);
    });
    this.task.subtasks = this.kennzeichenTypeSubtask;
  }
}
