import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Antragsteller } from '../../shared/data/antragsteller';
import { Bevollmaechtigter } from '../../shared/data/bevollmaechtigter';

@Component({
  selector: 'file-upload',
  templateUrl: './file-upload.component.html',
  standalone: false,
})
export class UploadComponent implements OnInit {
  status: 'initial' | 'uploading' | 'success' | 'fail' = 'initial';
  files: Array<File> = new Array<File>();

  @Input() person: Antragsteller | Bevollmaechtigter;

  @Output() isSpeichernDisabled: EventEmitter<boolean> = new EventEmitter();

  constructor(private http: HttpClient) {}

  ngOnInit(): void {
    this.uploadParametersChange(true, false);
  }

  onChange(event: Event) {
    const input = event.target as HTMLInputElement;

    if (!input.files) {
      return;
    }

    const files = input.files;

    if (this.files.length > 0) {
      for (let i = 0, file; (file = files[i]); i++) {
        this.files.push(file);
      }
      this.uploadParametersChange(false, true);
      return;
    }

    this.status = 'initial';
    this.files = Array.from(files);
    this.uploadParametersChange(false, true);

    return;
  }

  removeSelectedFile(index: number) {
    if (index < 0 || index >= this.files.length) {
      return;
    }

    this.files.splice(index, 1);
    this.uploadParametersChange(this.files.length < 1, this.files.length > 0);
  }

  changeFileVals(): void {
    (<HTMLInputElement>document.getElementById('files')).value = null;
  }

  uploadParametersChange(speichern: boolean, nachweiseDoc: boolean): void {
    this.isSpeichernDisabled.emit(speichern);
    this.person.nachweisDokumentVorhanden = nachweiseDoc;
  }

  openDialog() {
    document.getElementById('files').click();
  }
}
