<mat-card
  class="mat-elevation-z0 mat-dialog-container mat-card-none-shadow"
  fxFlex="800px"
>
  <!-- title -->
  <mat-card-title fxFlex="60px">Neuer Antrag</mat-card-title>
  <mat-card-content>
    <div
      fxLayout
      fxLayout.xs="row"
      fxLayoutAlign.xs="start start"
      class="mat-body-1"
    >
      <div fxLayout="column">
        <div fxLayout="row" fxFlexOffset="8px">
          <label fxFlex="170px">Verfahren:</label>
        </div>
      </div>
      <mat-radio-group
        color="primary"
        [(ngModel)]="newAntragLinkType"
        (change)="changeNewAntragLink()"
        aria-label="Anträge anzeigen als"
        *ngIf="newAntragLinkTypes.length > 0"
      >
        <mat-radio-button
          *ngFor="let type of newAntragLinkTypes"
          [id]="type.key"
          [value]="type"
          >{{ type.fullName }}</mat-radio-button
        >
      </mat-radio-group>
    </div>

    <div
      fxLayout
      fxLayout.xs="row"
      fxLayoutAlign.xs="start start"
      class="mat-body-1"
    >
      <label fxFlex="170px" fxFlexAlign="center">Ich stelle den Antrag:</label>
      <mat-radio-group
        color="primary"
        [(ngModel)]="bevollmaechtigterModeForNewAntrag"
        (change)="changeNewAntragLink()"
        aria-label="Anträge anzeigen als"
      >
        <mat-radio-button [value]="false" aria-label="Antragsteller"
          >für mich selbst</mat-radio-button
        >
        <mat-radio-button [value]="true" aria-label="Bevollmächtigter"
          >als bevollmächtigte Person</mat-radio-button
        >
      </mat-radio-group>
    </div>
  </mat-card-content>
  <mat-dialog-actions align="end">
    <div
      class="card-actions"
      fxLayout
      fxLayoutGap="20px"
      fxLayout.xs="row"
      fxLayoutAlign.xs="start start"
    >
      <button mat-raised-button mat-dialog-close class="close-button">
        Schließen
      </button>
      <a href="{{ actualNewLink }}" target="_blank">
        <button
          mat-raised-button
          color="primary"
          (click)="confirm()"
          [disabled]="newAntragLinkType === null"
        >
          Weiter
        </button>
      </a>
    </div>
  </mat-dialog-actions>
</mat-card>
