import {Component, Inject } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {OverviewAdminData} from '../shared/data/overview.admin.data';
import {OverviewStatus} from '../shared/data/overview.status';
import {AntragRole} from '../shared/data/antrag.role';

@Component({
  selector: 'app-overview-admin',
  templateUrl: './overview-admin.component.html',
  styleUrls: ['./overview-admin.component.css']
})
export class OverviewAdminComponent {
  email: string;
  roles: AntragRole[];
  vorgangsnummer: string;
  loadOverviewStatus: OverviewStatus;
  zeigeFilter: boolean;
  defaultKennzeichenFilterValue: string[]

  constructor(
    public dialogRef: MatDialogRef<OverviewAdminComponent>,
    @Inject(MAT_DIALOG_DATA) public data: OverviewAdminData) {
    this.email = data.email;
    this.roles = data.roles;
    this.vorgangsnummer = data.vorgangsnummer;
    this.loadOverviewStatus = data.overviewStatus;
    this.zeigeFilter = data.zeigeFilter;
    this.defaultKennzeichenFilterValue = data.defaultKennzeichenFilterValue;
  }

  close(): void {
    this.dialogRef.close();
  }
}
