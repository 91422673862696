import { Component, OnInit } from '@angular/core';
import { ErrorMessageMatcher, Field } from '../../shared/matcher.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { BackendService } from '../../shared/backend.service';
import { ClientErrorMessage } from '../../shared/data/clientErrorMessage';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserProfile } from '../../shared/data/user.profile';

@Component({
  selector: 'change-email',
  templateUrl: './change-email.component.html',
  standalone: false,
})
export class ChangeEmailComponent implements OnInit {
  form: FormGroup;
  success: boolean;
  email: string;
  isAlias: boolean;
  isLoading: boolean = false;

  emailNew = new Field();

  matcherNew = new ErrorMessageMatcher(this.emailNew);

  constructor(
    private fb: FormBuilder,
    private backend: BackendService,
    private snackBar: MatSnackBar,
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      newEmail: ['', Validators.required],
    });
    this.email = this.backend.getEmail();
    this.isAlias = this.backend.isAlias();
  }

  onClick(): void {
    if (this.form.valid && !this.isLoading) {
      this.isLoading = true; // Start loading
      const newEmail = this.form.get('newEmail').value;

      this.backend.changeEmail(this.email, newEmail).subscribe({
        next: () => {
          this.changed();
        },
        error: (error: HttpErrorResponse) => {
          this.notChanged(error);
          this.isLoading = false;
        },
        complete: () => {
          this.isLoading = false; // Stop loading after response
        },
      });
    }
  }

  private changed(): void {
    this.success = true;
    this.emailNew.error = '';
    this.backend.auth().subscribe((data) => this.setAliasStatus(data));
    this.processResult(this.success);
  }

  private notChanged(error: HttpErrorResponse): void {
    this.success = false;
    const clientError = error.error as ClientErrorMessage;
    if (clientError != null) {
      this.emailNew.error = clientError.message;
    }
    this.processResult(this.success);
  }

  private setAliasStatus(profile: UserProfile): void {
    this.backend.setProfile(profile);
    this.isAlias = this.backend.isAlias();
  }

  private processResult(success: boolean): void {
    if (success) {
      this.snackBar.open('Erfolgreich E-Mail-Adresse geändert.', '', {
        duration: 10000,
        panelClass: ['success'],
      });
    } else {
      this.snackBar.open('E-Mail-Adresse konnte nicht geändert werden.', '', {
        duration: 10000,
        panelClass: ['alert'],
      });
    }
  }

  public changeEmail(oldEmail: string, newEmail: string): void {
    this.backend.changeEmail(oldEmail, newEmail).subscribe(
      () => this.changed(),
      (error) => this.notChanged(error),
    );
  }
}
