import { Component } from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {MatTableDataSource} from '@angular/material/table';

type EmailIconField = {
  key: string,
  name: string,
  icon: string,
  iconClass: string,
  color: string
};

const EMAIL_STATUS_LIST = new Array<EmailIconField>();

EMAIL_STATUS_LIST.push({ key: 'SEND', name: 'Gesendet', iconClass:'material-icons', icon: 'mail_outline', color: 'color_green'});
EMAIL_STATUS_LIST.push({ key: 'DELIVERY', name: 'Erfolgreich gesendet', iconClass:'material-icons', icon: 'mark_email_read', color: 'color_green'});
EMAIL_STATUS_LIST.push({ key: 'ERROR', name: 'Nicht gesendet', iconClass:'material-icons',  icon: 'mark_email_unread', color: 'color_red'});
EMAIL_STATUS_LIST.push({ key: 'FAILED', name: 'Fehler', iconClass:'material-icons',  icon: 'mark_email_unread', color: 'color_red_light'});
EMAIL_STATUS_LIST.push({ key: 'DOMAIN_NOT_ALLOWED', name: 'Empfänger E-Mail-Domäne nicht erlaubt', iconClass:'material-icons-outlined',  icon: 'mark_email_unread',  color: 'color_red'});
EMAIL_STATUS_LIST.push({ key: 'NOT_AVAILABLE', name: 'E-Mail-Status unbekannt',  iconClass:'material-icons',  icon: 'mail_outline',  color: 'none'});




@Component({
  selector: 'email-status-info',
  templateUrl: './email-status-info.component.html',
  styleUrls: ['./email-status-info.component.css']
})
export class EmailStatusInfoComponent {

  displayedColumns: string[] = ['status', 'explanation'];
  dataSource = new MatTableDataSource(EMAIL_STATUS_LIST);

  constructor(
    public dialogRef: MatDialogRef<EmailStatusInfoComponent>) {
  }

  close(): void {
    this.dialogRef.close();
  }
}
