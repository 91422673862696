import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogRef,
} from '@angular/material/dialog';
import { BackendService } from '../../shared/backend.service';
import { AntragWithXmlData } from '../../shared/data/antragWithXmlData';
import { MatCard, MatCardContent, MatCardTitle } from '@angular/material/card';
import { MatRadioButton, MatRadioGroup } from '@angular/material/radio';
import { FormsModule } from '@angular/forms';
import {
  DefaultFlexAlignDirective,
  DefaultFlexDirective,
  DefaultFlexOffsetDirective,
  DefaultLayoutAlignDirective,
  DefaultLayoutDirective,
  DefaultLayoutGapDirective,
} from '@ngbracket/ngx-layout';
import { MatButton } from '@angular/material/button';
import { NgIf } from '@angular/common';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'confirm-personal-data-auth',
  templateUrl: './confirm-personal-data-auth.html',
  imports: [
    MatCard,
    MatCardTitle,
    MatCardContent,
    MatRadioGroup,
    FormsModule,
    MatRadioButton,
    DefaultFlexAlignDirective,
    DefaultLayoutDirective,
    DefaultLayoutAlignDirective,
    DefaultFlexDirective,
    DefaultFlexOffsetDirective,
    MatDialogActions,
    DefaultLayoutGapDirective,
    MatButton,
    NgIf,
  ],
})
export class ConfirmPersonalDataAuthComponent implements OnInit {
  digitalAuthentifizierung = true;
  privatPerson = true;
  isLoadingResults = false;

  @Output() manual: EventEmitter<boolean> = new EventEmitter();
  constructor(
    private dialogRef: MatDialogRef<ConfirmPersonalDataAuthComponent>,
    private backend: BackendService,
    @Inject(MAT_DIALOG_DATA) public data: AntragWithXmlData,
  ) {}

  ngOnInit(): void {
    if (this.data.editAntragsteller == true) {
      this.digitalAuthentifizierung = false;
      this.privatPerson = this.data.antragsteller.organisation == null;
    } else {
      this.privatPerson = this.data.bevollmaechtigter.organisation == null;
    }
  }

  public close(): void {
    this.dialogRef.close('close');
  }

  public confirm(): void {
    if (this.digitalAuthentifizierung) {
      this.isLoadingResults = true;
      this.backend
        .getOzgServiceAuthentication(
          this.data.antragData.kennzeichen,
          this.data.antragData.vorgangsnummer,
          this.privatPerson,
        )
        .subscribe((data) => window.location.replace(data.toString()));
    }
  }

  public confirmManual(): void {
    if (!this.digitalAuthentifizierung) {
      this.dialogRef.close('confirm');
    }
  }
}
