<mat-card class="mat-elevation-z0">
  <!-- title -->
  <mat-card-title>Neuer Antrag</mat-card-title>
  <mat-card-content>

    <div style="padding-top: 20px" fxLayout fxLayoutGap="130px" fxLayout.xs="row" fxLayoutAlign.xs="start start">
    <label>Verfahren: </label>
    <mat-radio-group color="primary" [(ngModel)]="newAntragLinkType" (change)="changeNewAntragLink()"
                     aria-label="Anträge anzeigen als" *ngIf="newAntragLinkTypes.length > 0">

      <mat-radio-button style="padding-right: 5px;width: 100%;" *ngFor="let type of newAntragLinkTypes" [id]="type.key" [value]="type">{{type.fullName}}</mat-radio-button>
    </mat-radio-group>
    </div>


    <div style="padding-top: 20px" fxLayout fxLayoutGap="60px" fxLayout.xs="row" fxLayoutAlign.xs="start start">
    <label>Ich stelle den Antrag: </label>
    <mat-radio-group color="primary" [(ngModel)]="bevollmaechtigterModeForNewAntrag" (change)="changeNewAntragLink()"
                     aria-label="Anträge anzeigen als">
      <mat-radio-button style="padding-right: 5px;" [value]="false" aria-label="Antragsteller">für mich selbst</mat-radio-button>
      <mat-radio-button [value]="true" aria-label="Bevollmächtigter">als bevollmächtigte Person</mat-radio-button>
    </mat-radio-group>
    </div>
  </mat-card-content>
<mat-dialog-actions align="end" >
  <div style="padding-top: 2%" fxLayout fxLayoutGap="20px" fxLayout.xs="row" fxLayoutAlign.xs="start start">
  <button mat-raised-button (click)="close()" class="close-button">Schließen</button>
  <a href={{actualNewLink}} target="_blank">
    <button mat-raised-button color="primary"  (click)="confirm()" [disabled]="(newAntragLinkType != null ? false : true)">
      Weiter
    </button>
  </a>

  </div>
</mat-dialog-actions>
</mat-card>
