import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Subject, Subscription, debounceTime } from 'rxjs';
import moment, { Moment } from 'moment/moment';

import { ActivatedRoute } from '@angular/router';
import { AntragRole } from '../../shared/data/antrag.role';
import { BackendService } from '../../shared/backend.service';
import { KennzeichenType } from '../../shared/utils-account';
import { OverviewStatus } from '../../shared/data/overview.status';
import { antragStatusList } from '../../shared/utils-antrags';

export class AntragOverviewFilter {
  showFilters: boolean;
  bevollmaechtigterMode: boolean;
  kennzeichen: string;
  vorgangsnummer: string = '';
  antragsteller: string = '';
  standort: string = '';
  status: string;
  dateFrom: Moment;
  dateTo: Moment;
}

@Component({
  selector: 'antrag-overview-filter',
  templateUrl: './antrag-overview-filter.component.html',
  standalone: false,
})
export class AntragOverviewFilterComponent implements OnInit, OnDestroy {
  constructor(
    private route: ActivatedRoute,
    private backend: BackendService,
  ) {}

  @Output() filterEvent: EventEmitter<AntragOverviewFilter> =
    new EventEmitter();
  @Output() updateVorgangsdatenEvent: EventEmitter<void> = new EventEmitter();
  @Input() loadOverviewStatus: OverviewStatus;
  @Input() allUsedTypes: string[];
  @Input() email: string;
  @Input() usedTypeMap = new Array<KennzeichenType>();

  filter: AntragOverviewFilter = new AntragOverviewFilter();
  minAntragsdatum = moment([2021, 0, 1]); // Start date 01.01.2021
  maxAntragsdatum = moment(); // today
  statusList = antragStatusList;
  isAdminMode: boolean;
  roles: AntragRole[];

  private subject: Subject<string> = new Subject();
  subscription = new Subscription();

  ngOnInit(): void {
    const subscriptionQuery = this.route.queryParams.subscribe({
      next: (params) => {
        this.filter.bevollmaechtigterMode =
          params?.bevollmaechtigterMode &&
          params?.bevollmaechtigterMode === 'false'
            ? false
            : true;
      },
      complete: () => {
        subscriptionQuery.unsubscribe();
      },
    });
    this.subscription = this.subject.pipe(debounceTime(700)).subscribe(() => {
      this.filterEvent.emit(this.filter);
    });
    this.isAdminMode =
      this.loadOverviewStatus === OverviewStatus.byVorgangsnummer ||
      this.loadOverviewStatus === OverviewStatus.byEmail;
    if (this.isAdminMode) {
      const subscription = this.backend
        .getAntragRolesForEmail(this.email)
        .subscribe({
          next: (roles) => {
            this.roles = roles;
          },
          complete: () => subscription?.unsubscribe(),
        });
    } else {
      this.roles = this.backend.getAntragRoles();
    }
    this.filter.showFilters =
      this.loadOverviewStatus !== OverviewStatus.byVorgangsnummer;
    this.filterEvent.emit(this.filter);
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  public showBevollmaechtigterToggle(): boolean {
    return (
      this.roles?.length > 1 &&
      this.loadOverviewStatus !== OverviewStatus.byVorgangsnummer
    );
  }

  selectionBevollmaechtigterMode(value: boolean): void {
    this.filter.bevollmaechtigterMode = value;
    this.filterEvent.emit(this.filter);
  }

  selectionDateFrom(value: Moment): void {
    this.filter.dateFrom = value;
    this.filterEvent.emit(this.filter);
  }

  selectionDateTo(value: Moment): void {
    this.filter.dateTo = value;
    this.filterEvent.emit(this.filter);
  }

  applyFilterVorgnagsnummer() {
    if (
      this.filter.vorgangsnummer.length > 7 ||
      this.filter.vorgangsnummer.length === 0
    ) {
      this.subject.next(null);
    }
  }

  applyFilterStandort() {
    if (this.filter.standort.length > 2 || this.filter.standort.length === 0) {
      this.subject.next(null);
    }
  }

  applyFilterAntragsteller() {
    if (
      this.filter.antragsteller.length > 2 ||
      this.filter.antragsteller.length === 0
    ) {
      this.subject.next(null);
    }
  }

  deleteFilterVorgangnummer(): void {
    this.filter.vorgangsnummer = '';
    this.filterEvent.emit(this.filter);
  }

  selectionChangeKennzeichen(value: string): void {
    this.filter.kennzeichen = value;
    this.filterEvent.emit(this.filter);
  }

  selectionChangeStatus(value: string): void {
    this.filter.status = value;
    this.filterEvent.emit(this.filter);
  }

  deleteFilterAntragsteller(): void {
    if (this.filter.antragsteller === '') {
      return;
    }
    this.filter.antragsteller = '';
    this.filterEvent.emit(this.filter);
  }

  deleteFilterStandort(): void {
    if (this.filter.standort === '') {
      return;
    }
    this.filter.standort = '';
    this.filterEvent.emit(this.filter);
  }

  refreshData(): void {
    this.filterEvent.emit(this.filter);
  }

  updateVorgangsdaten(): void {
    this.updateVorgangsdatenEvent.emit();
  }
}
