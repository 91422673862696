<mat-card class="mat-elevation-z0 mat-dialog-container mat-card-none-shadow" fxFlex="auto">
  <!-- title -->
  <mat-card-title>Möchten Sie Ihre persönlichen Daten ändern ?</mat-card-title>
  <mat-card-content fxFlexOffset="30px">
    <div
      fxLayout
      fxLayout.xs="row"
      fxLayoutAlign.xs="start start"
      class="mat-body-1"
    >

      <mat-radio-group
        color="primary"
        [(ngModel)]="digitalAuthentifizierung"
        disabled="{{data.editAntragsteller && data.antragData.isBevollmaechtigter == true}}"
      >
        <mat-radio-button
          [value]="false"
          aria-label="Ja, manuell"
          >Ja, manuell</mat-radio-button
        ><br />
        <mat-radio-button
          [value]="true"
          aria-label="Ja, über digitale Authentifizierung"
          >Ja, über digitale Authentifizierung</mat-radio-button
        >
      </mat-radio-group>
    </div>

    <div
      fxLayout
      fxLayout.xs="row"
      fxLayoutAlign.xs="start start"
      class="mat-body-1"
    >
      <label fxFlexAlign="center" fxFlex="120px">Ich bin eine: </label>

      <mat-radio-group
        color="primary"
        [(ngModel)]="privatPerson"
        disabled="{{true}}"
      >
        <mat-radio-button
          [value]="true"
          aria-label="Privatperson"
          >Privatperson</mat-radio-button
        >
        <mat-radio-button
          [value]="false"
          aria-label="Oraganisation"
          >Organisation</mat-radio-button
        >
      </mat-radio-group>
    </div>
  </mat-card-content>
  <label
    class="mat-caption text-color_orange" fxFlexOffset="20px"
    *ngIf="data.editAntragsteller && data.antragData.isBevollmaechtigter == true"
  >
    Als Bevollmächtigter können Sie die Daten des Antragstellers nur manuell
    bearbeiten.</label
  >

  <mat-dialog-actions align="start" class="mat-dialog-actions" fxFlexOffset="20px">
    <div
      fxLayout
      fxLayoutGap="20px"
      fxLayout.xs="row"
      fxLayoutAlign.xs="start start"
    >
      <button mat-raised-button
              color="primary"
              (click)="close()">
        Schließen
      </button>

      <button
        mat-raised-button
        *ngIf="!digitalAuthentifizierung"
        (click)=" confirmManual()"
      >
        Manuell bearbeiten
      </button>
      <button
        mat-raised-button
        *ngIf="digitalAuthentifizierung && privatPerson"
        (click)="confirm()"
      >
        Authentifizierung (als antragstellende Person)
      </button>
      <button
        *ngIf="digitalAuthentifizierung && !privatPerson"
        mat-raised-button
        (click)="confirm()"
      >
        Authentifizierung (über ELSTER Mein Unternehmenskonto)
      </button>
    </div>
  </mat-dialog-actions>
</mat-card>
