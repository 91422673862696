<div id="antragStatus">
  <mat-card-title class="flex-column-left mat-h2">
    Status
  </mat-card-title>

  <clr-timeline [clrLayout]="clrLayout" style="width: 100%">
    <!-- Eingegangen -->
    <clr-timeline-step [clrState]="eingegagenStatus">
      <clr-timeline-step-header>{{ this.momentToDate(antragDetail.antragsdatum) | date: 'dd.MM.yyyy'}}</clr-timeline-step-header>
      <clr-timeline-step-title>Eingegangen</clr-timeline-step-title>
      <clr-timeline-step-description
        >Ihr Antrag ist beim BAFA
        eingegangen.
      </clr-timeline-step-description
      >
    </clr-timeline-step>

    <!-- In Bearbeitung -->
    <clr-timeline-step [clrState]="bearbeitungStatus" id="inbearbeitung">
      <clr-timeline-step-header></clr-timeline-step-header>
      <clr-timeline-step-title>
        In Bearbeitung</clr-timeline-step-title>
      <clr-timeline-step-description>
        Ihr Antrag befindet sicht aktuell in Bearbeitung durch das
        BAFA.
      </clr-timeline-step-description
      >
      <clr-timeline-step-description *ngIf="this.antragDetail.stornoWunsch == true && storniert !== 'error'  && this.bearbeitungStatus == 'not-started'">
        <a mat-icon-button disabled>
          <mat-icon>remove_circle_outline</mat-icon>
        </a>
        Ihr Stornierungswunsch ist eingegangen, Sie erhalten direkt vom BAFA eine Nachricht dazu.
      </clr-timeline-step-description>
    </clr-timeline-step>

    <!-- Storniert -->
    <clr-timeline-step [clrState]="storniert" *ngIf=" storniert === 'error'"  id="Storniert" >
      <clr-timeline-step-header></clr-timeline-step-header>
      <clr-timeline-step-title>
        Storniert</clr-timeline-step-title>
      <clr-timeline-step-description>
        Ihr Antrag wurde storniert.</clr-timeline-step-description
      >
    </clr-timeline-step>

    <!-- Genehmigt -->
    <clr-timeline-step [clrState]="genehmightStatus" *ngIf=" genehmightStatus === 'current' ||
      (vnStatus === 'current' && foerderbetrag !== null  &&  antragDetail.zwbDatum !== null && genehmightStatus === 'success')
      || bearbeitungStatus === 'current'">
      <clr-timeline-step-header> {{ this.momentToDate(antragDetail.zwbDatum) | date: 'dd.MM.yyyy'}}</clr-timeline-step-header>
      <clr-timeline-step-title>Genehmigt</clr-timeline-step-title>
      <clr-timeline-step-description
        *ngIf="vnStatus == 'current'" >Ihr Antrag wurde genehmigt.
        <clr-timeline-step-description
          *ngIf="foerderbetrag !== null && foerderbetrag !== '0'">Fördersumme: {{this.getGermanPrice(foerderbetrag)}}.
        </clr-timeline-step-description>
      </clr-timeline-step-description>
      <clr-timeline-step-description
        *ngIf="genehmightStatus === 'current'" >Ihr Antrag wurde genehmigt.
        <clr-timeline-step-description
          *ngIf="foerderbetrag !== null && foerderbetrag !== '0'">Fördersumme: {{this.getGermanPrice(foerderbetrag)}}.
        </clr-timeline-step-description>
          Bitte
        reichen Sie nach Durchführung der Maßnahme/n den
        <mat-icon class="icon primary-dark">post_add</mat-icon>
        Verwendungsnachweis für Ihren Antrag ein.

        <button
          *ngIf="antragDetail.submitVnLink !== null"
          (click)="openVnLink(antragDetail)"
          mat-flat-button
          class="button"
          type="button"
          color="primary"
        >
          <mat-icon class="icon">post_add</mat-icon>
          NACHWEIS AUSFÜLLEN
        </button>
      </clr-timeline-step-description>
      <clr-timeline-step-description *ngIf="this.antragDetail.stornoWunsch == true && storniert !== 'error' && (this.genehmightStatus == 'not-started' || genehmightStatus === 'current')">
        <a mat-icon-button disabled>
          <mat-icon>remove_circle_outline</mat-icon>
        </a>
        Ihr Stornierungswunsch ist eingegangen, Sie erhalten direkt vom BAFA eine Nachricht dazu.
      </clr-timeline-step-description>
    </clr-timeline-step>

    <!-- VN eingegangen -->
    <clr-timeline-step [clrState]="vnStatus" id="vnEingegangen" *ngIf="vnStatus === 'success' || vnStatus === 'current'">
      <clr-timeline-step-header></clr-timeline-step-header>
      <clr-timeline-step-title>{{fourthStatusName}}</clr-timeline-step-title>
      <clr-timeline-step-description
        >{{fourthtatusDecsription}}</clr-timeline-step-description
      >
      <clr-timeline-step-description *ngIf="this.antragDetail.stornoWunsch == true && storniert !== 'error' && (vnStatus === 'success' || vnStatus === 'current')">
        <a mat-icon-button disabled>
          <mat-icon>remove_circle_outline</mat-icon>
        </a>
        Ihr Stornierungswunsch ist eingegangen, Sie erhalten direkt vom BAFA eine Nachricht dazu.
      </clr-timeline-step-description>
    </clr-timeline-step>

    <!-- Status to be deleted -->
    <clr-timeline-step [clrState]="notStarted" *ngIf="(this.antragDetail.status == 'A' || this.antragDetail.status == 'B'
      || this.antragDetail.status == 'C' || this.antragDetail.status == '6' || this.antragDetail.status == 'S') && antragDetail.lastStatusUpdated != null
      && getLifeSpanInMonth() != null && getLifeSpanInMonth() != 0">
    <clr-timeline-step-header>{{ this.getTimeToDelete() | date: 'dd.MM.yyyy'}}</clr-timeline-step-header>
      <clr-timeline-step-description>
        Antrag nicht mehr im Portal verfügbar.
      </clr-timeline-step-description>
    </clr-timeline-step>
  </clr-timeline>
</div>
