import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output,} from '@angular/core';
import { Antragsteller } from "../shared/data/antragsteller";
import { Bevollmaechtigter } from "../shared/data/bevollmaechtigter";
import { countries } from "../shared/data/country-data-store";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {Subscription} from 'rxjs';
import {AntragAnrede} from "../shared/data/antrag.anrede";
import {BackendService} from "../shared/backend.service";

type outputChannel = {
  key: string,
  description: string,
};

const CHANNEL_LIST = new Array<outputChannel>();

CHANNEL_LIST.push({
  key: 'POST',
  description: 'Postweg',
} as outputChannel);

CHANNEL_LIST.push({
  key: 'ELSTER',
  description: 'ELSTER Postfach',
} as outputChannel);
CHANNEL_LIST.push({
  key: 'NKB',
  description: 'NKB / Bund ID',

} as outputChannel);

@Component({
  selector: 'app-person-detail',
  templateUrl: './person-detail.component.html',
})

export class PersonDetailComponent implements OnInit, OnDestroy, OnChanges {

  constructor(
    private fb: FormBuilder,
    private backend: BackendService
  ) {
  }

  form: FormGroup;
  subscription: Subscription = Subscription.EMPTY;
  countries:any = countries;
  channelList:any = CHANNEL_LIST;
  public anredeEnum = AntragAnrede;
  cities: string[];


  @Input() person: Antragsteller | Bevollmaechtigter;
  @Input() loading: boolean = false;
  @Input() edit: boolean = false;
  @Input() showOrganisation: boolean = false;
  @Input() isAuthorized: boolean = false;
  @Input() editAntragsteller: boolean = false;
  @Input() typeOfOzgService: string;
  @Input() vertrauensniveau: string;
  @Input() isOrganizationRequired: boolean = false;



  @Output() savePersonEvent: EventEmitter<Antragsteller | Bevollmaechtigter> = new EventEmitter();

  ngOnChanges(): void {
    this.initOrChangeForm();

    this.form.get('land').valueChanges.subscribe(() => {
      this.form.get('ort').setValue(null);
    });

    this.form.get('plz').valueChanges.subscribe(() => {
      this.updateCities();
      this.form.get('ort').setValue(null);
    });

    this.subscription = this.form.valueChanges.subscribe(() => {
      this.renderOrtInput();
      this.resolvePersonAndEmit();

      this.form.get('vorwahl').valueChanges.subscribe(() => {
        this.form.get('rufnummer').updateValueAndValidity();
      });
    });
  }

  ngOnInit(): void {
    this.channelList = CHANNEL_LIST.filter(item => item.key == this.typeOfOzgService || item.key == 'POST')

    this.initOrChangeForm();
    this.updateCities();

  }

  private initOrChangeForm(): void {
    this.form = this.fb.group({
      organisation:  new FormControl({ value: this.person.organisation, disabled: this.isAuthorized}, this.isOrganizationRequired ? [Validators.required] : []),
      anrede: new FormControl({ value: this.person.anrede, disabled: this.isAuthorized}, [Validators.required]),
      vorname: new FormControl({ value: this.person.vorname, disabled: this.isAuthorized}, [Validators.required]),
      nachname: new FormControl({ value: this.person.nachname, disabled: this.isAuthorized}, [Validators.required]),
      land: new FormControl({ value: this.person.land, disabled: this.isAuthorized}, [Validators.required]),
      strasse: new FormControl({ value: this.person.strasse, disabled: this.isAuthorized}, [Validators.required]),
      hnr: new FormControl({ value: this.person.hnr, disabled: this.isAuthorized}, [Validators.required]),
      plz: new FormControl({ value: this.person.plz, disabled: this.isAuthorized || this.person.plz === null}, [Validators.required, Validators.pattern(/^[0-9]*$/)]),
      ort: new FormControl({ value: this.person.ort, disabled: this.isAuthorized}, [Validators.required]),
      vorwahl: new FormControl({ value: this.person.vorwahl, disabled: this.isAuthorized}, [Validators.pattern(/^[0-9]*$/)]),
      rufnummer: new FormControl({ value: this.person.rufnummer, disabled: this.isAuthorized}, [this.validateVorwahlAndRufnummer().bind(this), Validators.pattern(/^[0-9]*$/)]),
      outputChannel:new FormControl({value: this.person.outputChannel, disabled: (!this.isAuthorized || this.vertrauensniveau == 'NIEDRIG')},[Validators.required] )
    });
  }

  private resolvePersonAndEmit() {
    if (!this.form.valid) {
      this.savePersonEvent.emit(null);
    }
    else {
      this.person = {
        ...this.person,
        organisation: this.form.get('organisation').value,
        anrede: this.form.get('anrede').value,
        vorname: this.form.get('vorname').value,
        nachname: this.form.get('nachname').value,
        strasse: this.form.get('strasse').value,
        hnr: this.form.get('hnr').value,
        plz: this.form.get('plz').value,
        land: this.form.get('land').value,
        vorwahl: this.form.get('vorwahl').value,
        rufnummer: this.form.get('rufnummer').value,
        outputChannel: this.form.get('outputChannel').value,
        ort: this.form.get('ort').value
      };
      this.savePersonEvent.emit(this.person);
    }
  }

  public resolveOutputChannel(value: string) {
     this.person.outputChannel = value;
     this.savePersonEvent.emit(this.person);
  }


  validateVorwahlAndRufnummer(){
    return (control: any) => {
      if (!this.form) return null;

      let mandatory = this.form.get('vorwahl').value;

      return mandatory && !control.value ? { required: true } : null;
    };
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  getSelectedCountryName (code: string) {
    return this.countries.find(x => x.code === code).name;
  }

  getSelectedChannelName (key: string) {
    if(key == null || key == undefined) {
      return null;
    }
    return CHANNEL_LIST.find(x => x.key === key).description;
  }

  getAntragAnrede (key: string): string {
    if (AntragAnrede[key] ==  null) {
      return key;
    } else {
      return AntragAnrede[key];
    }
  }

 updateCities() {
   if (!this.isAuthorized && this.form.get('plz').value.length == 5 && this.form.get('land').value == "DE") {
     this.backend.getCitiesByZip(this.form.get('plz').value)
       .subscribe(data => {
         this.cities = data.cities;
         if (this.cities?.length == 1) {
           this.form.get('ort').setValue(this.cities[0]);
         }
       });
   } else {
     this.cities = null;
   }
 }

 renderOrtInput(): boolean {
    if (this.form.get('land').value) {
      return this.form.get('land').value == "DE"
    }
 }
}


