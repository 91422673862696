import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';

import { AntragAnrede } from '../../shared/data/antrag.anrede';
import { Antragsteller } from '../../shared/data/antragsteller';
import { BackendService } from '../../shared/backend.service';
import { Bevollmaechtigter } from '../../shared/data/bevollmaechtigter';
import { Countries } from '../../shared/data/country';
import { Subscription } from 'rxjs';
import { countries } from '../../shared/data/country-data-store';
import { channelList } from '../../shared/utils-antrags';

@Component({
  selector: 'person-detail',
  templateUrl: './person-detail.component.html',
  styleUrls: ['./person-detail.component.scss'],
  standalone: false,
})
export class PersonDetailComponent implements OnInit, OnDestroy, OnChanges {
  constructor(
    private fb: FormBuilder,
    private backend: BackendService,
  ) {}

  form: FormGroup;
  subscription: Subscription = new Subscription();
  countries: Countries[] = countries;
  channelList = channelList;
  public anredeEnum = AntragAnrede;
  cities: string[];
  streets: string[];
  buildings: string[];
  private timer: ReturnType<typeof setTimeout>;

  @Input() person: Antragsteller | Bevollmaechtigter;
  @Input() loading: boolean = false;
  @Input() edit: boolean = false;
  @Input() showOrganisation: boolean = false;
  @Input() isAuthorized: boolean = false;
  @Input() editAntragsteller: boolean = false;
  @Input() typeOfOzgService: string;
  @Input() vertrauensniveau: string;
  @Input() isOrganizationRequired: boolean = false;

  @Output() savePersonEvent: EventEmitter<Antragsteller | Bevollmaechtigter> =
    new EventEmitter();

  ngOnChanges(): void {
    this.initOrChangeForm();
    this.subscription.unsubscribe();
    this.subscription.closed = false;

    this.subscription.add(
      this.form.get('land').valueChanges.subscribe(() => {
        this.form.get('ort').setValue(null);
      }),
    );

    this.subscription.add(
      this.form.get('plz').valueChanges.subscribe(() => {
        this.updateCities();
        this.streets = null;
        this.form.get('strasse').setValue(null);
        this.buildings = null;
        this.form.get('hnr').setValue(null);
        this.form.get('ort').setValue(null);
      }),
    );

    this.subscription.add(
      this.form.get('ort').valueChanges.subscribe(() => {
        this.form.get('strasse').setValue(null);
        this.form.get('hnr').setValue(null);
        this.streets = null;
        this.buildings = null;
      }),
    );

    this.subscription.add(
      this.form.get('strasse').valueChanges.subscribe(() => {
        this.form.get('hnr').setValue(null);
        if (this.form.dirty) {
          this.updateStreets();
        }
      }),
    );

    this.subscription.add(
      this.form.get('hnr').valueChanges.subscribe(() => {
        if (this.form.dirty) {
          this.updateBuildings();
        }
      }),
    );

    this.subscription.add(
      this.form.valueChanges.subscribe(() => {
        this.renderOrtInput();
        this.resolvePersonAndEmit();

        this.form.get('vorwahl').valueChanges.subscribe(() => {
          this.form.get('rufnummer').updateValueAndValidity();
        });
      }),
    );
  }

  ngOnInit(): void {
    this.channelList = channelList.filter(
      (item) => item.key == this.typeOfOzgService || item.key == 'POST',
    );

    this.initOrChangeForm();
    this.updateCities();
    this.updateStreets();
    this.updateBuildings();
  }

  public initOrChangeForm(): void {
    this.form = this.fb.group({
      organisation: new FormControl(
        { value: this.person?.organisation, disabled: this.isAuthorized },
        this.isOrganizationRequired ? [Validators.required] : [],
      ),
      anrede: new FormControl(
        { value: this.person?.anrede, disabled: this.isAuthorized },
        [Validators.required],
      ),
      vorname: new FormControl(
        { value: this.person?.vorname, disabled: this.isAuthorized },
        [Validators.required],
      ),
      nachname: new FormControl(
        { value: this.person?.nachname, disabled: this.isAuthorized },
        [Validators.required],
      ),
      land: new FormControl(
        { value: this.person?.land, disabled: this.isAuthorized },
        [Validators.required],
      ),
      strasse: new FormControl(
        { value: this.person?.strasse, disabled: this.isAuthorized },
        [Validators.required],
      ),
      hnr: new FormControl(
        { value: this.person?.hnr, disabled: this.isAuthorized },
        [Validators.required],
      ),
      plz: new FormControl(
        {
          value: this.person?.plz,
          disabled: this.isAuthorized || this.person?.plz === null,
        },
        [Validators.required, Validators.pattern(/^[0-9]*$/)],
      ),
      ort: new FormControl(
        { value: this.person?.ort, disabled: this.isAuthorized },
        [Validators.required],
      ),
      vorwahl: new FormControl(
        { value: this.person?.vorwahl, disabled: this.isAuthorized },
        [Validators.pattern(/^[0-9]*$/)],
      ),
      rufnummer: new FormControl(
        { value: this.person?.rufnummer, disabled: this.isAuthorized },
        [
          this.validateVorwahlAndRufnummer().bind(this),
          Validators.pattern(/^[0-9]*$/),
        ],
      ),
      outputChannel: new FormControl(
        {
          value: this.person?.outputChannel,
          disabled: !this.isAuthorized || this.vertrauensniveau == 'NIEDRIG',
        },
        [Validators.required],
      ),
    });
  }

  private resolvePersonAndEmit() {
    if (!this.form.valid) {
      this.savePersonEvent.emit(null);
    } else {
      this.person = {
        ...this.person,
        organisation: this.form.get('organisation').value,
        anrede: this.form.get('anrede').value,
        vorname: this.form.get('vorname').value,
        nachname: this.form.get('nachname').value,
        strasse: this.form.get('strasse').value,
        hnr: this.form.get('hnr').value,
        plz: this.form.get('plz').value,
        land: this.form.get('land').value,
        vorwahl: this.form.get('vorwahl').value,
        rufnummer: this.form.get('rufnummer').value,
        outputChannel: this.form.get('outputChannel').value,
        ort: this.form.get('ort').value,
      };
      this.savePersonEvent.emit(this.person);
    }
  }

  public resolveOutputChannel(value: string) {
    this.person.outputChannel = value;
    this.savePersonEvent.emit(this.person);
  }

  validateVorwahlAndRufnummer() {
    return (control: FormControl) => {
      if (!this.form) return null;

      const mandatory = this.form.get('vorwahl').value;

      return mandatory && !control.value ? { required: true } : null;
    };
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  getSelectedCountryName(code: string) {
    return this.countries.find((x) => x.code === code).name;
  }

  getSelectedChannelName(key: string) {
    if (key == null) {
      return null;
    }
    return channelList.find((x) => x.key === key).description;
  }

  getAntragAnrede(key: string): string {
    if (AntragAnrede[key] == null) {
      return key;
    } else {
      return AntragAnrede[key];
    }
  }

  updateCities() {
    if (
      !this.isAuthorized &&
      this.form?.get('plz').value?.length == 5 &&
      this.form.get('land').value == 'DE'
    ) {
      const subscription = this.backend
        .getCitiesByZip(this.form.get('plz').value)
        .subscribe({
          next: (data) => {
            this.cities = data.cities;
            if (this.cities?.length == 1) {
              this.form.get('ort').setValue(this.cities[0]);
            }
          },
          complete: () => {
            subscription.unsubscribe();
          },
        });
    } else {
      this.cities = null;
    }
  }

  updateStreets() {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      if (
        !this.isAuthorized &&
        this.form.get('strasse').value?.length != 0 &&
        this.form.get('land').value == 'DE'
      ) {
        const subscription = this.backend
          .getStreetsByZip(
            this.form.get('plz').value,
            this.form.get('ort').value,
            this.form.get('strasse').value,
          )
          .subscribe({
            next: (data) => {
              this.streets = data;
              this.buildings = null;
            },
            complete: () => {
              subscription.unsubscribe();
            },
          });
      } else {
        this.streets = null;
      }
    }, 500);
  }

  updateBuildings() {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      if (
        !this.isAuthorized &&
        this.form.get('hnr').value?.length != 0 &&
        this.form?.get('land').value == 'DE'
      ) {
        const subscription = this.backend
          .getBuildingsByCityAndStreet(
            this.form.get('plz').value,
            this.form.get('ort').value,
            this.form.get('strasse').value,
            this.form.get('hnr').value,
          )
          .subscribe({
            next: (data) => {
              this.buildings = data;
            },
            complete: () => {
              subscription.unsubscribe();
            },
          });
      } else {
        this.buildings = null;
      }
    }, 500);
  }

  renderOrtInput(): boolean {
    if (this.form.get('land').value) {
      return this.form.get('land').value == 'DE';
    }
  }
}
