<div
  role="main"
  fxFlexFill
  fxLayout="row"
  fxLayoutAlign="left"
  class="mat-dialog-container"
>
  <div
    fxFlex="100%"
    fxFlex.md="100%"
    fxFlex.sm="100%"
    fxLayout="column"
    fxLayoutAlign="left"
    fxFlexOffset="10px"
  >
    <mat-card-title> Legende für E-Mail -Status </mat-card-title>
    <mat-card-content fxFlexOffset="30px">
      <table mat-table [dataSource]="dataSource" fixedLayout="true">
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef>Status</th>
          <td mat-cell *matCellDef="let element">
            <a mat-icon-button disabled>
              <mat-icon
                *ngIf="element.key !== 'FAILED'"
                class=" {{ element.iconClass }} {{ element.color }}"
              >
                {{ element.icon }}
              </mat-icon>
              <img
                *ngIf="element.key === 'FAILED'"
                fxFlex="28px"
                src="assets/svg/emailStatusFailed.svg"
                alt=""
              />
            </a>
          </td>
        </ng-container>
        <ng-container matColumnDef="explanation">
          <th mat-header-cell *matHeaderCellDef>Erläuterung</th>
          <td mat-cell *matCellDef="let element">{{ element.name }}</td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </mat-card-content>
    <mat-card-actions fxFlex="90px" fxFlexOffset="20px" fxLayout="column">
      <button mat-raised-button (click)="close()" color="primary">
        Schließen
      </button>
    </mat-card-actions>
  </div>
</div>
