<div class="content" role="main" fxLayout fxLayoutAlign="center" fxLayout.xs="column" fxLayoutAlign.xs="start">

  <mat-card class="mat-elevation-z0" ngClass.gt-xs="overview-card" fxFlex.md="75%" fxFlex.lt-md="100%">

<mat-card-title class="bafa-display-1">Account Audit für {{this.email}}</mat-card-title>

<mat-card-content>

  <!-- table filters -->



    <div fxLayout="column" fxFlex fxLayoutAlign="space-between stretch" fxLayoutGap="10px" fxLayout.xs="column" fxLayoutAlign.xs="center start"
         style="position: relative; overflow: hidden;">
        <!-- search field -->

        <div style="display: block !important" fxLayoutGap="17px" fxLayoutAlign.xs="center start" fxLayout.xs="column">
          <!-- date range -->
          <mat-form-field  appearance="standard"  style="width: 155px !important;">
            <mat-label>von</mat-label>
            <input matInput [matDatepicker]="fromPicker"placeholder="von" [(ngModel)]="auditFilter.dateFrom"
                   [min]="minDateFrom" [max]="maxDateTo"
                   (dateChange)="dateFilterChangeEvent($event)">
            <mat-datepicker-toggle matSuffix [for]="fromPicker"></mat-datepicker-toggle>
            <mat-datepicker #fromPicker></mat-datepicker>
          </mat-form-field>

          <mat-form-field  appearance="standard"  style="width: 155px !important;">
            <mat-label>bis</mat-label>
            <input matInput  [matDatepicker]="toPicker" placeholder="bis" [(ngModel)]="auditFilter.dateTo"
                   [min]="minDateTo" [max]="maxDateTo"
                   (dateChange)="dateFilterChangeEvent($event)">
            <mat-datepicker-toggle matSuffix [for]="toPicker"></mat-datepicker-toggle>
            <mat-datepicker #toPicker></mat-datepicker>
          </mat-form-field>

          <!-- Type filter select -->
          <mat-form-field appearance="standard" style="width: 170px !important;">
            <mat-label>Typ</mat-label>
            <mat-select #typFilterEvent (selectionChange)="selectionTypeChange($event.value)">
              <mat-option>(Alle)</mat-option>
              <mat-option  *ngFor="let auditType of AUDIT_MAP" value="{{auditType.key}}">{{auditType.text}}</mat-option>
            </mat-select>
          </mat-form-field>

          <!-- Geändert von-->
          <mat-form-field appearance="standard" style="width: 170px !important;">
            <mat-label>Geändert von</mat-label>
            <label>
              <input matInput type="text" placeholder="Platzhalter min. 5 Zeichen" [(ngModel)]="auditFilter.modifiedBy" (keyup)="applyFilterModifiedBy(true)">
            </label>
            <button *ngIf="auditFilter.modifiedBy" matSuffix mat-icon-button aria-label="Clear" (click)="deleteFilterModifiedBy()">
              <mat-icon matTooltip="Filter löschen">close</mat-icon>
            </button>
            <button *ngIf="!auditFilter.modifiedBy" matSuffix mat-icon-button disabled>
              <mat-icon matTooltip="Tabelle nach Suchbegriff filtern">search</mat-icon>
            </button>
          </mat-form-field>

          <!-- refresh button -->
          <button class="btn-margin" mat-mini-fab color="primary" (click)="this.refreshData()" matTooltip="Tabelleninhalt aktualisieren">
            <mat-icon>refresh</mat-icon>
          </button>

        </div>


    <!-- table -->
    <div class="overview-table-container">
      <table mat-table [dataSource]="dataSource" matSort matSortDisableClear [matSortActive]="paginator.active"  [matSortDirection]="paginator.direction"
      class="mat-elevation-z3" ngClass.lt-lg="auto-column-width;" (matSortChange)="sortingChange($event)">

        <ng-container matColumnDef="created">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Datum</th>
          <td mat-cell *matCellDef="let element" matTooltip="{{ element.created | date: dateTimeFormatDe}}">
            {{ element.created | date: dateTimeFormatDe}}</td>
        </ng-container>

        <ng-container matColumnDef="type">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Typ</th>
          <td mat-cell *matCellDef="let element" matTooltip="{{element.typeText}}">{{ element.typeText }}</td>
        </ng-container>

        <ng-container matColumnDef="accountBy">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Geändert von</th>
        <td mat-cell *matCellDef="let element" matTooltip="{{ element.accountBy }}">{{ element.accountBy }}</td>
        </ng-container>

        <ng-container matColumnDef="metadata">
          <th mat-header-cell *matHeaderCellDef >Weitere Informationen</th>
          <td mat-cell *matCellDef="let element" matTooltip="{{ element.metadata }}">{{ element.metadata }}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns() sticky:true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns()"></tr>

        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4">Keine Ergebnisse zu Ihrem Suchbegriff.</td>
        </tr>

      </table>
    </div>
  </div>

</mat-card-content>

    <mat-paginator #matPaginator
                   [length]="paginator.resultsLength"
                   [pageIndex]="paginator.pageNumber"
                   [pageSizeOptions]="[10, 20]"
                   (page)="paginationChange($event)"></mat-paginator>


    <div class="loading-shade" *ngIf="isLoading">
      <mat-spinner></mat-spinner>
    </div>

<mat-dialog-actions align="end" >
  <button mat-raised-button color="primary" (click)="close()" class="close-button">Schließen</button>
</mat-dialog-actions>

  </mat-card>
</div>
