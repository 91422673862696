import { AccountOverview } from './data/account.overview';
import { Antrag } from './data/antrag';
import { MatTableDataSource } from '@angular/material/table';
import { Moment } from 'moment';

const KENNZEICHEN_MAP = new Map<string, string>([
  ['BEGEM-FV', 'BEG EM'],
  ['BEGEM2-FV', 'BEG EM'],
  ['BEGPT-FV', 'BEG PT'],
  ['EBN-FV', 'EBN'],
  ['EBW-FV', 'EBW'],
]);

const KENNZEICHEN_KEY_MAP = new Map<string, string>([
  ['BEG EM', 'BEGEM-FV'],
  ['BEG EM', 'BEGEM2-FV'],
  ['BEG PT', 'BEGPT-FV'],
  ['EBN', 'EBN-FV'],
  ['EBW', 'EBW-FV'],
]);

export const dateFormatDe = 'dd.MM.yyyy';
export const dateTimeFormatDe = dateFormatDe + ' HH:mm:ss';
export const dateTimeDeleteFormatDe = 'DD.MM.yyyy [um] HH:mm';

export const toUtcMoment = (date: Moment): Moment => {
  if (date == null) {
    return null;
  }
  return date.utc(true);
};

export const setKenzeichenAgregateTypesForAntrag = (
  antrags: Antrag[],
): MatTableDataSource<Antrag> => {
  const data = antrags;
  data.forEach((x) => {
    x.kennzeichenText = KENNZEICHEN_MAP.get(x.kennzeichen);
  });

  return new MatTableDataSource(data);
};

export const setKenzeichenAgregateTypesForAccount = (
  accounts: AccountOverview[],
): MatTableDataSource<AccountOverview> => {
  const data = accounts;
  data.forEach((x) => {
    if (x.formularTypes) {
      const kenzeichens = new Array<string>();
      x.formularTypes.forEach((type) => {
        kenzeichens.push(' ' + KENNZEICHEN_MAP.get(type));
      });
      x.formularTypesText = Array.from(new Set(kenzeichens));
    }
  });
  return new MatTableDataSource(data);
};

export const getKenzeichenAgregateTypesForUserProfile = (
  types: string[],
): Array<string> => {
  const kenzeichens = new Array<string>();
  if (types == null) {
    return;
  }
  types.forEach((type) => {
    kenzeichens.push(KENNZEICHEN_MAP.get(type));
  });
  return Array.from(new Set(kenzeichens));
};

export const getKenzeichenAgregateKeys = (types: string[]): Array<string> => {
  const kenzeichens = new Array<string>();
  if (types == null) {
    return;
  }
  types.forEach((type) => {
    if (type == 'BEG EM') {
      kenzeichens.push('BEGEM-FV');
      kenzeichens.push('BEGEM2-FV');
    } else {
      kenzeichens.push(KENNZEICHEN_KEY_MAP.get(type));
    }
  });
  return Array.from(new Set(kenzeichens));
};
