import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

import { BackendService } from '../backend.service';
import { BuildInfoComponent } from '../build-info/build-info.component';
import { Component } from '@angular/core';

@Component({
  selector: 'footer',
  templateUrl: './footer.component.html',
  standalone: false,
})
export class FooterComponent {
  urlDatenschutz =
    'https://www.bafa.de/DE/Service/Datenschutzerklaerung/datenschutzerklaerung_node.html';
  urlImpressum = 'https://www.bafa.de/DE/Service/Impressum/fms/fms_node.html';

  constructor(
    private backend: BackendService,
    private buildInfoDialog: MatDialog,
  ) {}

  public buildInfo(): void {
    this.backend.buildInfo().subscribe((result) => {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.data = result;
      dialogConfig.disableClose = true;
      this.buildInfoDialog.open(BuildInfoComponent, dialogConfig);
    });
  }
}
