import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Antragsteller} from "../shared/data/antragsteller";
import {Bevollmaechtigter} from "../shared/data/bevollmaechtigter";

@Component({
  selector: 'app-upload-files',
  templateUrl: './upload.component.html',
})

export class UploadComponent implements OnInit {
  status: "initial" | "uploading" | "success" | "fail" = "initial";
  files: Array<File> =  new Array<File>();

  @Input() person: Antragsteller | Bevollmaechtigter;

  @Output() isSpeichernDisabled: EventEmitter<boolean> = new EventEmitter();

  constructor(private http: HttpClient) {}

  ngOnInit(): void {
    this.uploadParametersChange(true, false);

  }

  onChange(event: any) {
    const files = event.target.files;

    if (this.files.length > 0) {
      for(var i=0,file;file=files[i];i++) {
        this.files.push(file)
      }
      this.uploadParametersChange(false, true);

      return;
    }

      this.status = "initial";
      this.files = Array.from(files);
    this.uploadParametersChange(false, true);

    return;
  }

  removeSelectedFile(index) {
    delete this.files[index];
    this.files = this.files.filter(n => n);
    if (this.files.length < 1) {
      this.uploadParametersChange(true, false);
    } else {
      this.uploadParametersChange(false, true);
    }
  }

  changeFileVals(): void {
    (<HTMLInputElement>document.getElementById('files')).value = null;
  }

  uploadParametersChange(speichern: boolean, nachweiseDoc: boolean): void {
    this.isSpeichernDisabled.emit(speichern);
    this.person.nachweisDokumentVorhanden = nachweiseDoc;
  }
}
