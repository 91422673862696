<div
  role="main"
  fxFlex="100%"
  fxFlexFill
  fxLayout.lt-md="column"
  fxLayoutAlign.lt-md="start"
>
  <mat-card class="mat-elevation-z0">
    <div fxLayout="column" fxFlexFill>
      <div fxLayout="row">
        <!-- title -->
        <div fxFlex="100">
          <mat-card-title
            title="Accounts sperren und entsperren sowie Passwörter zurücksetzen.
Diese Funktionen können nicht auf den eigenen Account angewendet werden."
            >Account-Verwaltung
          </mat-card-title>
        </div>
        <div fxFlex="350px" fxFlexFill>
          <div
            class="vorgangsnr-box dialog-box"
            fxLayout="row"
            fxLayoutAlign="center center"
          >
            <mat-form-field appearance="fill">
              <input
                matInput
                placeholder="Vorgangsnummer"
                [(ngModel)]="vorgangsnummer"
              />
            </mat-form-field>
            <!-- -->
            <button
              mat-raised-button
              class="button vorgangsnr-btn"
              type="button"
              color="primary"
              matTooltip="Vorgang anzeigen"
              (click)="this.openAdminOverviewVorgangsnummerDialog()"
            >
              <mat-card>
                <mat-icon>search</mat-icon>
              </mat-card>
            </button>
          </div>
        </div>
      </div>
      <mat-card-content fxFlexOffset="20px">
        <div class="loading-shade" *ngIf="isLoadingResults">
          <mat-spinner></mat-spinner>
        </div>

        <!-- table actions-->
        <div
          fxLayoutAlign="space-between end"
          fxLayoutGap="20px"
          fxLayout.lt-md="row"
          fxLayoutAlign.lt-md="center start"
        >
          <div
            fxLayout="column"
            fxFlex="100"
            fxLayoutAlign="space-between stretch"
            fxLayoutAlign.lt-md="space-between center"
            fxLayoutGap="10px"
            fxLayoutAlign.lt-md="center center"
          >
            <!-- search field -->
            <div
              fxLayout
              fxLayout.lt-md="row"
              fxLayoutAlign.lt-md="left start"
              fxFlexFill
              fxFlex="67px"
              fxFlex.lt-md="100%"
            >
              <div
                fxFlex="100"
                fxLayoutGap="17px"
                fxFlexOffset.lt-md="20px"
                fxLayoutGap.lt-md="0px"
                fxLayoutAlign.lt-md="center left"
                fxLayout.lt-md="column"
              >
                <account-overview-filter
                  fxFlex="70%"
                  fxFlex.lt-md="100%"
                  fxLayout.lt-md="column"
                  fxLayoutGap="10px"
                  [allUsedTypes]="allUsedTypes"
                  (filterEvent)="onFilterEvent($event)"
                ></account-overview-filter>

                <!-- refresh button -->
                <div
                  fxFlex="30%"
                  fxFlex.lt-md="100%"
                  fxFlexFill
                  fxFlexOffset.lt-md="20px"
                  fxLayoutGap.lt-md="0px"
                  fxLayoutAlign.lt-md="space-between center"
                  fxLayout="row"
                >
                  <div fxFlex="20">
                    <button
                      mat-mini-fab
                      color="primary"
                      (click)="this.reloadData()"
                      matTooltip="Tabelleninhalt aktualisieren"
                    >
                      <mat-icon>refresh</mat-icon>
                    </button>
                  </div>
                  <!-- create new user -->
                  <form
                    [formGroup]="form"
                    fxFlex="80"
                    fxFlex.lt-md="100%"
                    class="edit-form"
                    fxLayoutAlign="end start"
                  >
                    <button
                      mat-mini-fab
                      *ngIf="!this.editMode && !isSuperUser"
                      color="primary"
                      (click)="this.editMode = true"
                      matTooltip="Account hinzufügen"
                    >
                      <mat-icon>add</mat-icon>
                    </button>

                    <mat-form-field
                      *ngIf="this.editMode"
                      appearance="fill"
                      fxFlex="350px"
                    >
                      <mat-label>E-Mail-Adresse</mat-label>
                      <label>
                        <input
                          matInput
                          required
                          formControlName="newUser"
                          type="email"
                          placeholder="email@example.com"
                        />
                      </label>
                      <div fxLayout="row" matSuffix>
                        <button
                          *ngIf="this.editMode"
                          mat-icon-button
                          type="button"
                          color="primary"
                          aria-label="Benutzer anlegen"
                          matTooltip="Benutzer anlegen"
                          (click)="onClickNewUser()"
                          [disabled]="!form.valid || isLoading"
                        >
                          <ng-container *ngIf="!isLoading">
                            <mat-icon>save</mat-icon>
                          </ng-container>
                          <mat-progress-spinner
                            *ngIf="isLoading"
                            diameter="20"
                          ></mat-progress-spinner>
                        </button>
                        <button
                          *ngIf="this.editMode"
                          mat-icon-button
                          type="button"
                          color="accent"
                          (click)="onCancelNewUser()"
                          aria-label="Eingabe abbrechen"
                          matTooltip="Eingabe abbrechen"
                        >
                          <mat-icon>cancel</mat-icon>
                        </button>
                      </div>
                    </mat-form-field>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- table -->

        <!--<div class="mat-elevation-z3 highlight example-container mat-elevation-z8">-->
        <div class="table-container">
          <table
            mat-table
            [dataSource]="data"
            matSort
            [matSortActive]="active"
            matSortDisableClear
            [matSortDirection]="direction"
            class="mat-elevation-z3"
            (matSortChange)="sortingChange($event)"
          >
            <ng-container matColumnDef="email">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                E-Mail-Adresse
              </th>
              <td
                mat-cell
                *matCellDef="let row"
                class="email-cell"
                matTooltip="{{ row.email }}"
              >
                {{ row.email }}
              </td>
            </ng-container>

            <ng-container matColumnDef="accountType">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Rolle</th>
              <td mat-cell *matCellDef="let row">
                <div fxLayout="row">
                  <button
                    (click)="this.openChangeRoleDialog(row)"
                    [disabled]="
                      actionDisabledForRow(row, 'admin') || isSuperUser
                    "
                    mat-icon-button
                    color="primary"
                    aria-label="Befördern"
                    matTooltip="Rolle ändern"
                    [matTooltipDisabled]="
                      actionDisabledForRow(row, 'admin') || isSuperUser
                    "
                  >
                    <mat-icon>{{ role(row).icon }}</mat-icon>
                  </button>
                  <mat-label fxLayout="column" fxLayoutAlign="center">
                    {{ role(row).name }}
                  </mat-label>
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="formularTypes">
              <th
                class="break-spaces"
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                matTooltip="Letzte fehlgeschlagene Anmeldung"
              >
                Verfahren
              </th>
              <td mat-cell *matCellDef="let row">
                {{
                  this.noAntragsByUser(row)
                    ? 'Keine Anträge'
                    : row?.formularTypesText
                }}
              </td>
            </ng-container>

            <ng-container matColumnDef="lastSuccessfulLogin">
              <th
                class="break-spaces"
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                matTooltip="Letzte erfolgreiche Anmeldung und Letzte fehlgeschlagene Anmeldung"
              >
                <p>Erfolgreiche Anmeldung<br />Fehlerhafte Anmeldung</p>
              </th>
              <td
                mat-cell
                *matCellDef="let row"
                matTooltip="{{
                  row.lastSuccessfulLogin | date: this.dateTimeFormatDe
                }}"
              >
                {{ row.lastSuccessfulLogin | date: dateTimeFormatDe }}
                <br />
                {{ row.lastFailedLogin | date: dateTimeFormatDe }}
              </td>
            </ng-container>

            <ng-container matColumnDef="temporaryLockedUntil">
              <th
                class="break-spaces"
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                matTooltip="Konto temporär gesperrt bis"
              >
                Gesperrt bis
              </th>
              <td
                class="break-spaces"
                mat-cell
                *matCellDef="let row"
                matTooltip="{{
                  row.temporaryLockedUntil | date: dateTimeFormatDe
                }}"
              >
                {{ row.temporaryLockedUntil | date: dateTimeFormatDe }}
              </td>
            </ng-container>

            <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef>Status</th>
              <td
                mat-cell
                *matCellDef="let row"
                class="table-cell bottom"
                matTooltip="{{ status(row).tooltip }}"
              >
                <div fxLayout="row">
                  <button mat-icon-button disabled>
                    <mat-icon>{{ status(row).icon }}</mat-icon>
                  </button>
                  <mat-label fxLayout="column" fxLayoutAlign="center"
                    >{{ status(row).name }}
                  </mat-label>
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="emailStatus">
              <th mat-header-cell *matHeaderCellDef>
                <div fxLayout="row">
                  <mat-label
                    fxLayout="column"
                    fxLayoutAlign="center"
                    fxFlexOffset="10px"
                  >
                    E-Mail-Status
                  </mat-label>
                  <mat-label>
                    <button
                      mat-icon-button
                      color="primary"
                      (click)="this.emailStatusInfo()"
                      matTooltip="E-Mail Status"
                    >
                      <mat-icon>info</mat-icon>
                    </button>
                  </mat-label>
                </div>
              </th>
              <td
                mat-cell
                *matCellDef="let row"
                class="email-status-cell bottom"
                matTooltip="{{ emailTooltip(row) }}"
              >
                <a mat-icon-button disabled>
                  <mat-icon
                    [id]="row.email + '_' + emailStatus(row).key"
                    *ngIf="emailStatus(row).key !== 'FAILED'"
                    class="{{ emailStatus(row).iconClass }} center {{
                      emailStatus(row).color
                    }}"
                    >{{ emailStatus(row).icon }}</mat-icon
                  >
                  <img
                    width="28"
                    [id]="row.email + '_' + emailStatus(row).key"
                    *ngIf="emailStatus(row).key === 'FAILED'"
                    src="assets/svg/emailStatusFailed.svg"
                    class="center"
                    alt=""
                  />
                </a>
              </td>
            </ng-container>

            <ng-container matColumnDef="actions">
              <th mat-header-cell *matHeaderCellDef>Aktionen</th>
              <td mat-cell *matCellDef="let row" class="table-cell">
                <div
                  fxFlex
                  fxLayout="row"
                  fxFlexFill
                  fxLayoutAlign="left center"
                >
                  <button
                    height="120px"
                    *ngIf="!row.locked"
                    (click)="this.updateAccount(row, 'lock')"
                    [disabled]="actionDisabledForRow(row, 'lock')"
                    mat-icon-button
                    color="primary"
                    aria-label="Sperren"
                    matTooltip="Account sperren"
                  >
                    <mat-icon>lock</mat-icon>
                  </button>

                  <button
                    *ngIf="row.locked"
                    (click)="this.updateAccount(row, 'unlock')"
                    [disabled]="actionDisabledForRow(row, 'unlock')"
                    mat-icon-button
                    color="primary"
                    aria-label="Entsperren"
                    matTooltip="Account entsperren"
                  >
                    <mat-icon>lock_open</mat-icon>
                  </button>

                  <button
                    (click)="this.sendMail(row, 'activate')"
                    [disabled]="actionDisabledForRow(row, 'activate')"
                    mat-icon-button
                    color="primary"
                    aria-label="Aktivierungs-Link erneut versenden"
                    matTooltip="Aktivierungs-Link erneut versenden"
                  >
                    <mat-icon>forward_to_inbox</mat-icon>
                  </button>

                  <button
                    (click)="this.sendMail(row, 'reset')"
                    [disabled]="actionDisabledForRow(row, 'reset')"
                    mat-icon-button
                    color="primary"
                    aria-label="Passwort zurücksetzen"
                    matTooltip="Passwort zurücksetzen"
                  >
                    <mat-icon>settings_backup_restore</mat-icon>
                  </button>

                  <button
                    (click)="this.updateVorgangsdaten(row)"
                    [disabled]="actionDisabledForRow(row, 'update')"
                    mat-icon-button
                    color="primary"
                    aria-label="Vorgangsdaten aktualisieren"
                    matTooltip="Vorgangsdaten aktualisieren"
                  >
                    <mat-icon>update</mat-icon>
                  </button>

                  <button
                    (click)="this.openAdminOverviewAccountDialog(row)"
                    [disabled]="
                      actionDisabledForRow(row, 'show') || noAntragsByUser(row)
                    "
                    mat-icon-button
                    color="primary"
                    aria-label="Zugeordnete Vorgänge anzeigen"
                    matTooltip="Zugeordnete Vorgänge anzeigen"
                  >
                    <mat-icon>view_list</mat-icon>
                  </button>

                  <button
                    (click)="this.openOverviewAccountAuditDialog(row)"
                    *ngIf="!this.isSuperUser"
                    mat-icon-button
                    color="primary"
                    aria-label="Zugeordnete Account audit"
                    matTooltip="Konto-Audit für {{ row.email }}"
                  >
                    <mat-icon>search</mat-icon>
                  </button>

                  <div
                    class="my-custom-tooltip"
                    matTooltip="{{ resolveDeleteTooltipText(row) }}"
                    [matTooltipDisabled]="false"
                  >
                    <button
                      (click)="openDeleteAccountDialog(row)"
                      *ngIf="!this.isSuperUser"
                      mat-icon-button
                      color="primary"
                      disabled="{{ row.accountType === 'ADMIN' }}"
                      aria-label="Löschung anfordern"
                    >
                      <mat-icon
                        class="center {{
                          row.deleteRequestDate ? 'color_orange' : 'primary'
                        }}"
                        >delete</mat-icon
                      >
                    </button>
                  </div>
                </div>
              </td>
            </ng-container>

            <!-- show this when there is no matching data -->
            <tr *matNoDataRow>
              <td class="mat-cell" colspan="4">
                Keine Ergebnisse zu Ihrem Suchbegriff "{{ filter.email }}".
              </td>
            </tr>

            <tr
              mat-header-row
              *matHeaderRowDef="displayedColumns; sticky: true"
            ></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          </table>
        </div>
        <mat-paginator
          #paginator
          [length]="resultsLength"
          [pageIndex]="pageIndex"
          [pageSizeOptions]="[10, 20, 50]"
          (page)="paginationChange($event)"
        ></mat-paginator>
      </mat-card-content>
    </div>
  </mat-card>
</div>
