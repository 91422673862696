import {
  AccountDeleteData,
  ConfirmDeleteAccountComponent,
} from '../confirm-delete-account/confirm-delete-account.component';
import {
  AccountOrRoleItem,
  EmailItem,
  getAccountStatusList,
  getEmailStatusList,
  getRoleList,
} from '../../shared/utils-account';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Sort, SortDirection } from '@angular/material/sort';
import {
  dateTimeDeleteFormatDe,
  dateTimeFormatDe,
  getKenzeichenAgregateKeys,
  getKenzeichenAgregateTypesForUserProfile,
  setKenzeichenAgregateTypesForAccount,
} from '../../shared/utils';
import moment, { Moment } from 'moment/moment';

import { AccountAuditOverviewComponent } from '../account-audit-overview/account-audit-overview.component';
import { AccountOverview } from '../../shared/data/account.overview';
import { AccountOverviewFilter } from '../account-overview-filter/account-overview-filter.component';
import { AccountSearchParams } from '../../shared/data/account.search.params';
import { AccountTypes } from '../../shared/data/account.types';
import { AdminAntragOverviewComponent } from '../admin-antrag-overview/admin-antrag-overview.component';
import { AntragRole } from '../../shared/data/antrag.role';
import { BackendService } from '../../shared/backend.service';
import { ChangeRoleDialogComponent } from '../change-role-dialog/change-role-dialog';
import { ClientErrorMessage } from '../../shared/data/clientErrorMessage';
import { ConfirmSendEmailComponent } from '../../diverse/confirm-send-email/confirm-send-email.component';
import { EmailStatusInfoComponent } from '../email-status-info/email-status-info.component';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { OverviewAdminData } from '../../shared/data/overview.admin.data';
import { OverviewStatus } from '../../shared/data/overview.status';
import { PageEvent } from '@angular/material/paginator';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'account-overview',
  templateUrl: './account-overview.component.html',
  styleUrls: ['./account-overview.component.scss'],
  standalone: false,
})
export class AccountOverviewComponent implements OnInit, AfterViewInit {
  filter: AccountOverviewFilter = new AccountOverviewFilter();

  statusList = getAccountStatusList();
  emailStatusList = getEmailStatusList();
  roleList = getRoleList();
  form: FormGroup;
  editMode = false;
  isSuperUser: boolean = false;
  isLoading: boolean = false;

  data: MatTableDataSource<AccountOverview> =
    new MatTableDataSource<AccountOverview>();

  vorgangsnummer: string;

  isLoadingResults = false;
  forceSendMail = false;
  dateTimeFormatDe = '';

  resultsLength = 0;
  pageIndex = 0;
  pageSize = 10;
  count: number;

  direction: SortDirection = 'asc';
  active = 'email';

  allUsedTypes = null;
  allowedTypes: string[];
  selectedNewTypes: string[];

  constructor(
    private fb: FormBuilder,
    private backend: BackendService,
    private snackBar: MatSnackBar,
    private matDialog: MatDialog,
    private confirmSendMailDialog: MatDialog,
    private changeRoleDialog: MatDialog,
    private confirmDeleteAccountComponent: MatDialog,
  ) {
    this.dateTimeFormatDe = dateTimeFormatDe;
  }

  displayedColumns: string[] = [
    'email',
    'accountType',
    'formularTypes',
    'lastSuccessfulLogin',
    'temporaryLockedUntil',
    'status',
    'actions',
  ];

  ngOnInit(): void {
    this.form = this.fb.group({
      newUser: ['', [Validators.required, Validators.email]],
    });
    this.isSuperUser = this.loadIsSuperUser();
    this.allowedTypes = this.backend.getFormuralTypes();
  }

  ngAfterViewInit(): void {
    if (this.enableEmailStatus()) {
      this.displayedColumns.splice(6, 0, 'emailStatus');
    }
    this.reloadData();
  }

  onFilterEvent(filter: AccountOverviewFilter) {
    this.filter = filter;
    this.pageIndex = 0;
    this.reloadData();
  }

  public reloadData(): void {
    this.isLoadingResults = true;
    const subscriptionReload = this.backend
      .getAccounts(
        {
          status: this.filter.status,
          filter: this.filter.email,
          sortBy: this.active,
          sortOrder: this.direction,
          pageNumber: this.pageIndex,
          pageSize: this.pageSize,
          kennzeichen: this.filter.verfahren,
          isSuperUser: this.isSuperUser,
          allowedTypes: this.allowedTypes,
          accountType: this.filter.role,
        } as AccountSearchParams,
        this.enableEmailStatus(),
      )
      .subscribe({
        next: (data) => {
          this.isLoadingResults = false;
          this.resultsLength = data.count;

          if (this.allUsedTypes == null && data != null) {
            this.allUsedTypes = data.usedTypes;
          }
          this.data = setKenzeichenAgregateTypesForAccount(data.data);
        },
        error: () => {
          this.showAlertMessage(
            'Das System ist vorübergehend nicht verfügbar. Bitte versuchen Sie es später erneut.',
          );
        },
        complete: () => {
          subscriptionReload?.unsubscribe();
        },
      });
  }

  paginationChange(event: PageEvent): void {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    this.reloadData();
  }

  sortingChange(sort: Sort): void {
    this.direction = sort.direction;
    this.active = sort.active;

    // If the user changes the sort order, reset back to the first page.
    this.pageIndex = 0;
    this.reloadData();
  }

  role(account: AccountOverview): AccountOrRoleItem {
    return this.roleList.find((s) => s.key === String(account.accountType));
  }

  status(account: AccountOverview): AccountOrRoleItem {
    const status = !account.locked ? account.status : 'LOCKED';
    return this.statusList.find((s) => s.key === status);
  }

  emailStatus(account: AccountOverview): EmailItem {
    const status =
      account.emailStatus != null && account.emailStatus.status != null
        ? account.emailStatus.status
        : 'NOT_AVAILABLE';
    return this.emailStatusList.find((s) => s.key === status);
  }

  emailTooltip(account: AccountOverview): string {
    const status =
      account.emailStatus != null && account.emailStatus.status != null
        ? account.emailStatus.status
        : 'NOT_AVAILABLE';

    if (status === 'NOT_AVAILABLE' || status === 'DELIVERY') {
      return this.emailStatusList.find((s) => s.key === status).name;
    }

    if (account.emailStatus.diagnosticCode != null) {
      return (
        this.emailStatusList.find((s) => s.key === status).name +
        ' [' +
        account.emailStatus.diagnosticCode +
        ']'
      );
    }

    if (account.emailStatus.errorMessage != null) {
      return (
        this.emailStatusList.find((s) => s.key === status).name +
        ' [' +
        account.emailStatus.errorMessage +
        ']'
      );
    }
    return this.emailStatusList.find((s) => s.key === status).name;
  }

  actionDisabledForRow(account: AccountOverview, action: string): boolean {
    if (account.deleteRequestDate && action !== 'show') {
      return true;
    }

    if (account.email === this.getEmail()) {
      return true;
    }

    const allowed = this.status(account).actions;
    if (allowed == null) {
      return true;
    }

    return allowed.indexOf(action) < 0;
  }

  getEmail(): string {
    return this.backend.getEmail();
  }

  onClickNewUser(): void {
    if (this.form.valid && !this.isLoading) {
      this.isLoading = true;

      const subscription = this.backend
        .createAccount(this.form.get('newUser').value)
        .subscribe({
          next: () => {
            this.created();
          },
          error: (error) => {
            this.handleHttpError(
              error,
              'Benutzerkonto konnte nicht erstellt werden.',
            );
          },
          complete: () => {
            this.isLoading = false;
            subscription?.unsubscribe();
            return;
          },
        });
    }
  }

  onCancelNewUser(): void {
    this.form.reset();
    this.editMode = false;
    this.isLoading = false;
  }

  private created(): void {
    this.reloadData();

    this.form.reset();
    this.form.get('newUser').setErrors(null);
    this.showSuccessMessage('Erfolgreich Benutzerkonto erstellt.');
  }

  updateAccount(account: AccountOverview, action: string): void {
    // Create a copy since we should change the original only after the backend call succeeded.
    const updatedAccount = Object.assign({}, account);
    switch (action) {
      case 'lock':
        updatedAccount.locked = true;
        break;
      case 'unlock':
        updatedAccount.locked = false;
        break;
      case 'admin':
        updatedAccount.admin = true;
        updatedAccount.accountType = 'ADMIN';
        updatedAccount.formularTypes = null;

        break;
      case 'super_user':
        updatedAccount.admin = true;
        updatedAccount.accountType = 'SUPER_USER';
        updatedAccount.formularTypes = getKenzeichenAgregateKeys(
          this.selectedNewTypes,
        );
        break;
      case 'benutzer':
        updatedAccount.admin = false;
        updatedAccount.accountType = 'BENUTZER';
        updatedAccount.formularTypes = null;

        break;
      default:
        return;
    }
    const subscription = this.backend
      .updateAccount(updatedAccount, action)
      .subscribe({
        next: () => this.updated(),
        error: (error) =>
          this.handleHttpError(
            error,
            'Benutzerkonto konnte nicht aktualisiert werden.',
          ),
        complete: () => subscription?.unsubscribe(),
      });
  }

  updateVorgangsdaten(account: AccountOverview) {
    const subscription = this.backend
      .updateVorgangsdaten(account.email)
      .subscribe({
        next: () => this.updateVorgangsdatenSuccess(),
        error: (error) =>
          this.handleHttpError(
            error,
            'Vorgangsdaten wurden nicht aktualisiert.',
          ),
        complete: () => subscription?.unsubscribe(),
      });
  }

  sendMail(account: AccountOverview, action: string): void {
    switch (action) {
      case 'activate':
        const subscription1 = this.backend
          .requestAdminActivate(account.email, this.forceSendMail)
          .subscribe({
            next: () => this.sendMailSuccess(),
            error: (error) =>
              this.handleMailWarning(
                error,
                'E-Mail konnte nicht verschickt werden.',
                account,
                action,
              ),
            complete: () => subscription1?.unsubscribe(),
          });
        return;
      case 'reset':
        const subscription2 = this.backend
          .requestAdminReset(account.email, this.forceSendMail)
          .subscribe({
            next: () => this.sendMailSuccess(),
            error: (error) =>
              this.handleMailWarning(
                error,
                'E-Mail konnte nicht verschickt werden.',
                account,
                action,
              ),
            complete: () => subscription2?.unsubscribe(),
          });
        return;
      default:
        return;
    }
  }

  private sendMailSuccess(): void {
    this.showSuccessMessage('Erfolgreich E-Mail verschickt.');
    this.forceSendMail = false;
  }

  private updateVorgangsdatenSuccess(): void {
    this.showSuccessMessage('Vorgangsdaten wurden erfolgreich aktualisiert.');
  }

  private updated(): void {
    this.reloadData();
    this.showSuccessMessage('Erfolgreich Benutzerkonto aktualisiert.');
  }

  private handleHttpError(
    error: HttpErrorResponse,
    defaultMessage: string,
  ): void {
    if (error.status === 400) {
      const clientError = error.error as ClientErrorMessage;
      if (clientError != null) {
        this.showAlertMessage(clientError.message);
        return;
      }
    }
    this.showAlertMessage(defaultMessage);
    this.isLoading = false;
  }

  private handleMailWarning(
    error: HttpErrorResponse,
    defaultMessage: string,
    account: AccountOverview,
    action: string,
  ): void {
    if (error.status === 400) {
      const clientError = error.error as ClientErrorMessage;
      if (clientError != null) {
        if (clientError.warning) {
          this.confirmSendMail(clientError.message, account, action);
        } else {
          this.showAlertMessage(clientError.message);
        }
        return;
      }
    }
    this.showAlertMessage(defaultMessage);
  }

  private showSuccessMessage(message: string): void {
    this.snackBar.open(message, '', {
      duration: 2000,
      panelClass: ['success'],
    });
  }

  private showAlertMessage(message: string): void {
    this.snackBar.open(message, 'X', {
      duration: 10000,
      panelClass: ['alert'],
    });
  }

  confirmSendMail(
    message: string,
    account: AccountOverview,
    action: string,
  ): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = message;
    dialogConfig.disableClose = true;
    this.confirmSendMailDialog
      .open(ConfirmSendEmailComponent, dialogConfig)
      .afterClosed()
      .subscribe((dialogAction) => {
        if (dialogAction === 'confirm') {
          this.forceSendMail = true;
          this.sendMail(account, action);
        } else {
          this.forceSendMail = false;
        }
      });
  }

  public openAdminOverviewAccountDialog(row: AccountOverview): void {
    const subscription = this.backend
      .getAntragRolesForEmail(row.email)
      .subscribe({
        next: (roles) => {
          const dialogConfig = new MatDialogConfig();
          dialogConfig.data = new OverviewAdminData(
            row.email,
            roles,
            null,
            OverviewStatus.byEmail,
            true,
            this.allowedTypes,
          );
          dialogConfig.disableClose = true;
          this.matDialog.open(AdminAntragOverviewComponent, dialogConfig);
        },
        complete: () => subscription?.unsubscribe(),
      });
  }

  public openOverviewAccountAuditDialog(row: AccountOverview): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = row.email;
    dialogConfig.disableClose = true;
    this.matDialog.open(AccountAuditOverviewComponent, dialogConfig);
  }

  public openAdminOverviewVorgangsnummerDialog(): void {
    this.backend
      .getEmailByVorgangsnummer(this.vorgangsnummer)
      .subscribe((accountInfo) => {
        const email = accountInfo.email;

        this.backend
          .getAntragByVorgangsnummerForSuperUser(
            this.vorgangsnummer,
            this.allowedTypes,
          )
          .subscribe((result) => {
            if (result.count > 0) {
              const dialogConfig = new MatDialogConfig();
              dialogConfig.data = new OverviewAdminData(
                email,
                [AntragRole.BEVOLLMAECHTIGTER],
                this.vorgangsnummer,
                OverviewStatus.byVorgangsnummer,
                false,
                this.allowedTypes,
              );
              dialogConfig.disableClose = true;
              this.matDialog.open(AdminAntragOverviewComponent, dialogConfig);
            } else {
              this.showAlertMessage(
                'Ein Antrag mit der angegebenen Vorgangsnummer existiert nicht.',
              );
            }
          });
      });
  }

  emailStatusInfo(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    this.matDialog.open(EmailStatusInfoComponent, dialogConfig);
  }

  enableEmailStatus(): boolean {
    return environment?.enableEmailStatus;
  }

  openChangeRoleDialog(row: AccountOverview): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      allUsedTypes: this.allUsedTypes,
      accountType: row.accountType,
      formularTypes: getKenzeichenAgregateTypesForUserProfile(
        row.formularTypes,
      ),
    };
    this.changeRoleDialog
      .open(ChangeRoleDialogComponent, dialogConfig)
      .afterClosed()
      .subscribe((dialogAction) => {
        if (dialogAction.result === 'confirm') {
          if (dialogAction.typeOfAccountSelected == 'super_user') {
            this.selectedNewTypes = dialogAction.data.map((item) => item.name);
          }
          this.updateAccount(row, dialogAction.typeOfAccountSelected);
        }
      });
  }

  loadIsSuperUser(): boolean {
    return (
      AccountTypes[this.backend.getAccountType()] == AccountTypes.SUPER_USER
    );
  }

  noAntragsByUser(account: AccountOverview): boolean {
    return account.accountType == 'BENUTZER' && !account.formularTypes;
  }

  resolveDeleteTooltipText(account: AccountOverview): string {
    if (account.deleteRequestDate) {
      return (
        'Die Löschung dieses Kontos wurde am ' +
        moment(account.deleteRequestDate).format(dateTimeDeleteFormatDe) +
        ' von ' +
        account.deleteRequestBy +
        ' angefordert.'
      );
    }
    if (account.accountType == 'ADMIN') {
      return 'Das Administratorkonto kann nicht gelöscht werden.';
    }
    return 'Löschung anfordern';
  }

  openDeleteAccountDialog(account: AccountOverview): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = new AccountDeleteData(
      account.deleteRequestDate,
      account.email,
    );
    dialogConfig.disableClose = true;

    const subscription = this.confirmDeleteAccountComponent
      .open(ConfirmDeleteAccountComponent, dialogConfig)
      .afterClosed()
      .subscribe({
        next: (dialogAction) => {
          if (dialogAction === 'confirm') {
            this.saveDeleteOrCancelRequest(
              account.email,
              account.deleteRequestDate,
            );
          }
        },
        complete: () => subscription?.unsubscribe(),
      });
  }

  saveDeleteOrCancelRequest(email: string, deleteRequestDate: Moment) {
    const subscription = this.backend
      .createOrCancelDeleteAccountRequest(email)
      .subscribe({
        next: () => {
          if (deleteRequestDate) {
            this.cancelDeleteRequestSuccess();
          } else {
            this.deleteRequestSuccess();
          }
        },
        error: () => this.deleteRequestFailure(),
        complete: () => subscription?.unsubscribe(),
      });
  }

  private deleteRequestSuccess(): void {
    this.reloadData();
    this.showSuccessMessage('Die Löschanforderung wurde erfasst.');
  }

  private cancelDeleteRequestSuccess(): void {
    this.reloadData();
    this.showSuccessMessage('Die Löschanforderung wurde storniert.');
  }

  private deleteRequestFailure(): void {
    this.reloadData();
    this.showAlertMessage(
      'Die Löschung ist fehlgeschlagen. Bitte versuchen Sie es später noch einmal.',
    );
  }
}
