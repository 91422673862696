export interface AccountOrRoleItem {
  key: string;
  name: string;
  tooltip: string;
  icon: string;
  actions: Array<string>;
}

export interface EmailItem {
  key: string;
  name: string;
  icon: string;
  iconClass: string;
  color: string;
}

export interface KennzeichenType {
  key: string;
  text: string;
}

const ACTION_LOCK = 'lock';
const ACTION_UNLOCK = 'unlock';
const ACTION_ADMIN = 'admin';
const ACTION_USER = 'user';
const ACTION_ACTIVATE = 'activate';
const ACTION_RESET = 'reset';
const ACTION_UPDATE = 'update';
const ACTION_SHOW = 'show';

export const getRoleList = (): AccountOrRoleItem[] => {
  return [
    {
      key: 'ADMIN',
      name: 'Admin.',
      tooltip: 'Administrator',
      icon: 'admin_panel_settings',
    } as AccountOrRoleItem,
    {
      key: 'BENUTZER',
      name: 'Benutzer',
      tooltip: 'Benutzer',
      icon: 'account_circle',
    } as AccountOrRoleItem,
    {
      key: 'SUPER_USER',
      name: 'Super User',
      tooltip: 'Super User',
      icon: 'supervised_user_circle',
    } as AccountOrRoleItem,
  ];
};

export const getEmailStatusList = (): EmailItem[] => {
  return [
    {
      key: 'SEND',
      name: 'Gesendet',
      iconClass: 'material-icons',
      icon: 'mail_outline',
      color: 'color_green',
    },
    {
      key: 'DELIVERY',
      name: 'Erfolgreich gesendet',
      iconClass: 'material-icons',
      icon: 'mark_email_read',
      color: 'color_green',
    },
    {
      key: 'ERROR',
      name: 'Nicht Gesendet',
      iconClass: 'material-icons',
      icon: 'mark_email_unread',
      color: 'color_red',
    },
    {
      key: 'FAILED',
      name: 'Fehler',
      iconClass: 'material-icons',
      icon: 'mark_email_unread',
      color: 'color_red',
    },
    {
      key: 'DOMAIN_NOT_ALLOWED',
      name: 'Empfänger E-Mail Domäne nicht erlaubt',
      iconClass: 'material-icons-outlined',
      icon: 'mark_email_unread',
      color: 'color_red',
    },
    {
      key: 'NOT_AVAILABLE',
      name: 'E-Mail Status unbekannt',
      iconClass: 'material-icons',
      icon: 'mail_outline',
      color: 'none',
    },
  ];
};

export const getAccountStatusList = (): AccountOrRoleItem[] => {
  return [
    {
      key: 'PENDING',
      name: 'Ausst.',
      tooltip: 'Ausstehend',
      icon: 'pending',
      actions: [
        ACTION_LOCK,
        ACTION_UNLOCK,
        ACTION_ADMIN,
        ACTION_USER,
        ACTION_ACTIVATE,
        ACTION_UPDATE,
        ACTION_SHOW,
      ],
    } as AccountOrRoleItem,
    {
      key: 'ACTIVE',
      name: 'Aktiv',
      tooltip: 'Aktiv',
      icon: 'check_circle',
      actions: [
        ACTION_LOCK,
        ACTION_UNLOCK,
        ACTION_ADMIN,
        ACTION_USER,
        ACTION_RESET,
        ACTION_UPDATE,
        ACTION_SHOW,
      ],
    } as AccountOrRoleItem,
    {
      key: 'ALIAS',
      name: 'Alias',
      tooltip: 'Alias',
      icon: 'group',
      actions: [],
    } as AccountOrRoleItem,
    {
      key: 'LOCKED',
      name: 'Gesperrt',
      tooltip: 'Gesperrt',
      icon: 'block',
      actions: [
        ACTION_UNLOCK,
        ACTION_USER,
        ACTION_RESET,
        ACTION_UPDATE,
        ACTION_SHOW,
      ],
    } as AccountOrRoleItem,
    {
      key: 'DISABLED',
      name: 'Deaktiviert',
      tooltip: 'Deaktiviert',
      icon: 'delete',
      actions: [ACTION_ACTIVATE, ACTION_UPDATE, ACTION_SHOW],
    } as AccountOrRoleItem,
  ];
};
