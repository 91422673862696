import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BuildInfoData } from '../data/build.info.data';
import { dateTimeFormatDe } from '../utils';

@Component({
  selector: 'build-info',
  templateUrl: './build-info.component.html',
  standalone: false,
})
export class BuildInfoComponent {
  dateTimeFormatDe = '';
  currentYear = new Date().getFullYear();
  icon = 'settings';
  icons = [
    'settings',
    'light_mode',
    'sentiment_satisfied',
    'engineering',
    'spa',
    'memory',
  ];

  constructor(
    private dialogRef: MatDialogRef<BuildInfoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: BuildInfoData,
  ) {
    this.dateTimeFormatDe = dateTimeFormatDe;
  }

  public close(): void {
    this.dialogRef.close();
  }

  public toggle(): void {
    const ind = Math.floor(Math.random() * this.icons.length);
    this.icon = this.icons[ind];
  }
}
