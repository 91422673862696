
<mat-card class="mat-elevation-z0"  fxFlex.md="75%" fxFlex.lt-md="100%">
  <!-- title -->
  <mat-card-title>Warnung</mat-card-title>
  <mat-card-content>
    <p>{{data}}</p>
  </mat-card-content>
</mat-card>
<mat-dialog-actions align="end" >
  <button mat-raised-button (click)="confirm()">Zur Anmeldung</button>
  <button mat-raised-button color="primary" (click)="close()" class="close-button">Schließen</button>
</mat-dialog-actions>
