import { RouterModule, Routes } from '@angular/router';

import { AccountOverviewComponent } from './administration/account-overview/account-overview.component';
import { ActivationComponent } from './diverse/activation/activation.component';
import { AntragDetailComponent } from './antrag/antrag-detail/antrag-detail.component';
import { AntragOverviewComponent } from './antrag/antrag-overview/antrag-overview.component';
import { AuthGuard } from './shared/auth.guard';
import { ChangeEmailComponent } from './diverse/change-email/change-email.component';
import { ChangePasswordComponent } from './diverse/change-password/change-password.component';
import { LoginComponent } from './diverse/login/login.component';
import { LogoutComponent } from './diverse/logout/logout.component';
import { NgModule } from '@angular/core';
import { OverviewStatus } from './shared/data/overview.status';
import { RegisterComponent } from './diverse/register/register.component';
import { RequestPasswordResetComponent } from './diverse/request-password-reset/request-password-reset.component';
import { ResetPasswordComponent } from './diverse/reset-password/reset-password.component';
import { Role } from './shared/data/role';

const routes: Routes = [
  {
    path: 'uebersicht',
    component: AntragOverviewComponent,
    canActivate: [AuthGuard],
    data: {
      loginText: 'Angemeldet als',
      fxFlexValue: '70%',
      overviewStatus: OverviewStatus.byUser,
    },
  },
  {
    path: 'changePassword',
    component: ChangePasswordComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Admin, Role.User] },
  },
  {
    path: 'antragDetail',
    component: AntragDetailComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Admin, Role.User] },
  },
  {
    path: 'changeEmail',
    component: ChangeEmailComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Admin, Role.User] },
  },
  {
    path: 'admin',
    component: AccountOverviewComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Admin] },
  },
  // home redirects to admin, where AuthGuard checks Role, and redirects to uebersicht if not Admin, or to login if not authenticated at all
  { path: 'home', redirectTo: '/admin' },
  { path: 'request-reset', component: RequestPasswordResetComponent },
  { path: 'reset', component: ResetPasswordComponent, data: { setPw: false } },
  {
    path: 'setPassword',
    component: ResetPasswordComponent,
    data: { setPw: true },
  },
  { path: 'aktivieren', component: ActivationComponent },
  { path: 'login', redirectTo: '' },
  { path: 'logout', component: LogoutComponent },
  { path: 'register', component: RegisterComponent },
  { path: '', component: LoginComponent },
  { path: '**', redirectTo: '/home' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
